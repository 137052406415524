import {
  ConfigurationOption,
  FloridaCounty,
  IIlegalSign,
  IllegalSignStatus,
  ISection,
  IllegalSignMaterial,
  SectionSide,
  IllegalSignResolution,
  Theme,
  NoticeRemovedBy,
  IApplicableSection,
  InternalRole,
  YesNo,
  TaskAssignmentStatus,
  IDocument,
  IllegalSignNoticeType,
  stringUtils,
  Rights,
  HasRoles,
} from "oda-shared";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CustomCard } from "../common/CustomCard";
import EnumOptions from "../common/EnumOptions";
import { SectionSelector } from "../common/SectionSelector";
import { ButtonWithIcon } from "../common/ButtonWithIcon";
import { FilePond } from "react-filepond";
import { illegalSignDocumentUrl, documentUrl } from "../../apis/DocumentApi";
import "filepond/dist/filepond.min.css";
import { RootState } from "../../redux/RootReducer";
import { useSelector, useDispatch } from "react-redux";
import DocumentTypeSelector from "../common/DocumentTypeSelector";
import TaskAssignment from "../tasks/TaskAssignmentPanel";
import { deleteIllegalSign, getIllegalSign, updateIllegalSign } from "../../redux/slices/IllegalSignSlice";
import { format } from "date-fns";
import { getCombinedIllegalSignDocumentsPdf, getNoticeUrl } from "../../apis/IllegalSignApi";
import { formatDateWithNoTimeZone, inputFieldDateFormat } from "../../utils/DateUtils";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { getAllInspectionRegions, getUserCounties } from "../../redux/slices/InspectionRegionsSlice";
import CountyPropertyAppraisalLink from "../common/CountyPropertyAppraisalLink";
import { useNavigate } from "react-router-dom";
import useInspectorProfiles from "../../hooks/useInspectorProfiles";
import DocumentLink from "../common/DocumentLink";
import { useLocation } from "react-router-dom";
import { PermitSelector } from "../common/PermitSelector";

const IllegalSign: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  enum PageTab {
    signInfo = "Sign Info",    
    photos = "Documents and Photos",
    status = "Status",
  }

  const [isDocumentsTab, setIsDocumentsTab] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.endsWith("Documents")) {
      setIsDocumentsTab(true);
    } else {
      setIsDocumentsTab(false);
    }
  }, [location]);

  useEffect(() => {
    if (isDocumentsTab && isDocumentsTab === true) {
      setActiveTab(PageTab.photos);
    }
  }, [PageTab.photos, isDocumentsTab]);

  const { theme } = useSelector((state: RootState) => state.userReducer);
  //const canEdit = true;
  const filePondRef = useRef(null);
  const { id } = useParams();
  const [section, setSection] = useState<ISection>(null);
  const [currentSign, setCurrentSign] = useState<IIlegalSign>();
  const currentIllegalSign = useSelector((state: RootState) => state.illegalSignReducer.currentIllegalSign);
  const [inspectorId, setInspectorId] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const [noticeType, setNoticeType] = useState<IllegalSignNoticeType>();
  const [activeTab, setActiveTab] = useState<PageTab>(PageTab.status);
  const isFieldConsultantInspector = useSelector(
    (state: RootState) => state.userReducer.user.isFieldConsultantInspector,
  );
  const isFieldConsultantManager = useSelector((state: RootState) => state.userReducer.user.isFieldConsultantManager);
  const user = useSelector((state: RootState) => state.userReducer.user);
  const userCounties = useSelector((state: RootState) => state.inspectionRegionsReducer.userCounties);
  const inspectorsUserProfiles = useInspectorProfiles();
  
  const getTotalArea = () => {
    const area = currentSign?.dimensions.reduce((total, dimension) => {
      return total + (dimension.facingArea ? dimension.facingArea : 0);
    }, 0);
    if (area && !isNaN(area) && area > 0){
      return (<span>{area}</span>);
    }
    return (<></>);
  } 

  const [availableStatuses, setAvailableStatuses] = useState<IllegalSignStatus[]>(
    Object.values(IllegalSignStatus) as IllegalSignStatus[],
  );

  // const canCreateEditIllegalSign = useHasInternalRole([
  //   InternalRole.FdotAdmin,
  //   InternalRole.OfficeStaff,
  //   InternalRole.OfficeSupervisor,
  //   InternalRole.FdotInspector,
  //   InternalRole.ManagementReviewer,
  // ]);

  const canCreateEditIllegalSign = Rights.canCreateEditIllegalSigns(user);
  const canCreateEditInvoice = Rights.canManageAccountingInfo(user);

  //const isFdotInspector = useHasInternalRole([InternalRole.FdotInspector]);
  const isFdotInspector = HasRoles(user, [InternalRole.FdotInspector]);

  const [docsToCombine, setDocsToCombine] = useState<{ id: string; label: string }[]>([]);
  const regions = useSelector((state: RootState) => state.inspectionRegionsReducer.inspectionRegions);

  useEffect(() => {
    dispatch(getAllInspectionRegions());
  }, []);

  const sectionChanged = (section: ISection) => {
    if (section) {
      setSection(section);
      setCurrentSign({
        ...currentSign,
        sectionId: section.rciSection,
        highwayNumber: section.stateRdNumber,
      });
    }
  };

  const canEdit = useMemo(() => {
    if (
      canCreateEditIllegalSign ||
      (user?.isFieldConsultantInspector &&
        currentIllegalSign.foundById === user?._id &&
        (currentIllegalSign.status === IllegalSignStatus.FieldConsultant ||
          currentIllegalSign.status === IllegalSignStatus.InitialReportOfViolationConsultant))
    ) {
      return true;
    }

    // if (user.isFieldConsultantInspector && currentIllegalSign.foundById === user._id) {
    //   return true;
    // } else
    if (user?.isFieldConsultantInspector && currentIllegalSign.status === IllegalSignStatus.FieldConsultant) {
      const taskIndex = currentIllegalSign.taskAssignments?.findIndex(t => {
        return (
          t.assignedConsultantInspectorId === user._id &&
          t.taskStatus !== TaskAssignmentStatus.Complete &&
          t.taskStatus !== TaskAssignmentStatus.Cancelled
        );
      });

      return taskIndex !== -1;
    }

    return false;
  }, [
    currentIllegalSign.foundById,
    currentIllegalSign.taskAssignments,
    canCreateEditIllegalSign,
    isFieldConsultantManager,
    user?._id,
    user?.isFieldConsultantInspector,
  ]);

  useEffect(() => {
    if (id && dispatch) {
      dispatch(getIllegalSign(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (currentIllegalSign && currentIllegalSign._id) {
      //
      setCurrentSign(currentIllegalSign);
      setInspectorId(currentIllegalSign.inspectorId ? currentIllegalSign.inspectorId : "");

      let statuses = Object.values(IllegalSignStatus);

      if (user?.internalRoles.length === 1 && isFdotInspector) {
        statuses = [
          IllegalSignStatus.LetterOfConcernIssued,
          IllegalSignStatus.ResolutionRecommendationByInspector,
          IllegalSignStatus.SupplementalRequestCompleted,
          IllegalSignStatus.NoticePosted30Day,
          IllegalSignStatus.NoticePostedROW10Day,
          IllegalSignStatus.WorkOrderCompleted,
        ];
      }
      if (user?.isFieldConsultantInspector || user?.isFieldConsultantManager) {
        statuses = [IllegalSignStatus.WarningPosted, IllegalSignStatus.ResolutionRecommendationByConsultant];
      }

      if (!statuses.some(s => s === currentIllegalSign?.status)) statuses.push(currentIllegalSign?.status);

      if (
        currentIllegalSign?.status !== IllegalSignStatus.WorkOrderIssued &&
        currentIllegalSign?.status !== IllegalSignStatus.WorkOrderCompleted
      ) {
        statuses = statuses.filter(s => s !== IllegalSignStatus.WorkOrderCompleted);
      }
      if (
        currentIllegalSign?.status !== IllegalSignStatus.SupplementalRequestToRegionalInspector &&
        currentIllegalSign?.status !== IllegalSignStatus.SupplementalRequestCompleted
      ) {
        statuses = statuses.filter(s => s !== IllegalSignStatus.SupplementalRequestCompleted);
      }

      setAvailableStatuses(statuses);
    }
  }, [currentIllegalSign]);

  useEffect(() => {
    if (currentSign && currentSign?.sectionId) {
      setSection({
        rciSection: currentSign?.sectionId,
      } as any);
    }
  }, [currentSign]);

  useEffect(() => {
    if (isFdotInspector) {
      dispatch(getUserCounties());
    }
  }, [dispatch, isFdotInspector]);

  const removeAdditionalDocument = (index: number) => {
    const documents = [...(currentSign?.documents ?? [])].sort((a, b) => {
      return new Date(b.added) - new Date(a.added);
    });
    documents.splice(index, 1);
    const updatedCurrentSign = {
      ...currentSign,
      documents: documents,
    } as IIlegalSign;

    dispatch(updateIllegalSign(updatedCurrentSign));
  };

  const assignInspector = () => {
    const hasBeenSentToRegional =
      (currentSign?.statusHistory?.findIndex(s => {
        return s.status === IllegalSignStatus.SentToRegional;
      }) ?? -1) > -1;

    const updatedSign = {
      ...currentSign,
      status: hasBeenSentToRegional ? currentSign?.status : IllegalSignStatus.SentToRegional,
      inspectorId: inspectorId,
      inspectorAssignedDate: new Date(),
      assignedInspector: true,
    } as IIlegalSign;

    dispatch(updateIllegalSign(updatedSign));
  };

  const saveIllegalSign = e => {
    if (activeTab === PageTab.signInfo && !section) {
      toast.error("Please select a section");
      e.preventDefault();
      return;
    }
    //2024-04-18 Apparently the permit number is not required after all, work item 60949
    // if (activeTab === PageTab.signInfo && currentSign?.dimensions && currentSign?.dimensions?.length !== 0) {
    //   if (currentSign?.dimensions.some(d => !d.permit)) {
    //     toast.error("Please select a permit for each facing");
    //     e.preventDefault();
    //     return;
    //   }
    // }

    if (
      activeTab === PageTab.status &&
      currentSign?.status === IllegalSignStatus.NOVForSignOnROW &&
      stringUtils.isNullOrWhitespace(currentSign?.certifiedMailNumber) &&
      stringUtils.isNullOrWhitespace(currentSign?.certifiedMailNumberPropertyOwner)
    ) {
      toast.error(
        "This notice cannot be generated if there is no certified mail number recorded for at least one owner.",
      );
      e.preventDefault();
      return;
    }
    const updatedSign = {
      ...currentSign,
    } as IIlegalSign;
    dispatch(updateIllegalSign(updatedSign));

    if (e && e.preventDefault) {
      e.preventDefault();
    }
  };

  const getOwnerInfo = () => {
    return (
      <>
        <CustomCard>
          <div className="row">
            <div className="col-lg font-weight-bold">
              <label htmlFor="propertyOwnerName">Property Owner Name:</label>
              <input
                type="text"
                className="form-control"
                id="propertyOwnerName"
                value={currentSign?.propertyOwnerName}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, propertyOwnerName: e.target.value as any } as IIlegalSign)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">
              <label htmlFor="propertyOwnerAddressLine1">Address Line 1:</label>
              <input
                type="text"
                className="form-control"
                id="propertyOwnerAddressLine1"
                value={currentSign?.propertyOwnerAddressLine1}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, propertyOwnerAddressLine1: e.target.value as any } as IIlegalSign)
                }
              />
            </div>
            <div className="col-lg font-weight-bold">
              <label htmlFor="propertyOwnerAddressLine2">Address Line 2:</label>
              <input
                type="text"
                className="form-control"
                id="propertyOwnerAddressLine2"
                value={currentSign?.propertyOwnerAddressLine2}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, propertyOwnerAddressLine2: e.target.value as any } as IIlegalSign)
                }
              />
            </div>
            <div className="col-lg-2 font-weight-bold">
              <label htmlFor="propertyOwnerCity">City:</label>
              <input
                type="text"
                className="form-control"
                id="propertyOwnerCity"
                value={currentSign?.propertyOwnerCity}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, propertyOwnerCity: e.target.value as any } as IIlegalSign)
                }
              />
            </div>
            <div className="col-lg-1 font-weight-bold">
              <label htmlFor="propertyOwnerAddressState">State:</label>
              <input
                type="text"
                className="form-control"
                id="propertyOwnerAddressState"
                value={currentSign?.propertyOwnerAddressState}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, propertyOwnerAddressState: e.target.value as any } as IIlegalSign)
                }
              />
            </div>
            <div className="col-lg-1 font-weight-bold">
              <label htmlFor="propertyOwnerZip">Zip Code:</label>
              <input
                type="text"
                className="form-control"
                id="propertyOwnerZip"
                value={currentSign?.propertyOwnerZip}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, propertyOwnerZip: e.target.value as any } as IIlegalSign)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg">
              <label htmlFor="propertyOwnerCertifiedMailNumber" className="font-weight-bold">
                Certified Mail Number:
              </label>
              <input
                className="form-control"
                id="propertyOwnerCertifiedMailNumber"
                type="text"
                value={currentSign?.certifiedMailNumberPropertyOwner}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({
                    ...currentSign,
                    certifiedMailNumberPropertyOwner: e.target.value as any,
                  } as IIlegalSign)
                }></input>
            </div>
            <div className="col-lg">
              <label htmlFor="propertyOwnerDeliveryConfirmationDate" className="font-weight-bold">
                Delivery Confirmation Date:
              </label>
              <input
                className="form-control"
                id="propertyOwnerDeliveryConfirmationDate"
                type="date"
                value={inputFieldDateFormat(currentSign?.deliveryConfirmationDate)}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({ ...currentSign, deliveryConfirmationDate: e.target.value as any } as IIlegalSign)
                }></input>
            </div>
            <div className="col-lg font-weight-bold">
              <label htmlFor="signOwnerSameAsPropertyOwner">Sign Owner Same as Property Owner:</label>
              <input
                type="checkbox"
                className="form-control"
                id="signOwnerSameAsPropertyOwner"
                checked={currentSign?.signOwnerSameAsPropertyOwner}
                disabled={!canCreateEditIllegalSign}
                onChange={e =>
                  setCurrentSign({
                    ...currentSign,
                    signOwnerSameAsPropertyOwner: e.target.checked as any,
                  } as IIlegalSign)
                }
              />
            </div>
          </div>
        </CustomCard>
        <br />
        {currentSign?.signOwnerSameAsPropertyOwner !== true ? (
          <CustomCard>
            <div className="row">
              <div className="col-lg font-weight-bold">
                <label htmlFor="signOwnerName">Sign Owner Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="signOwnerName"
                  value={currentSign?.signOwnerName}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({ ...currentSign, signOwnerName: e.target.value as any } as IIlegalSign)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg font-weight-bold">
                <label htmlFor="signOwnerAddressLine1">Address Line 1:</label>
                <input
                  type="text"
                  className="form-control"
                  id="signOwnerAddressLine1"
                  value={currentSign?.signOwnerAddressLine1}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({ ...currentSign, signOwnerAddressLine1: e.target.value as any } as IIlegalSign)
                  }
                />
              </div>
              <div className="col-lg font-weight-bold">
                <label htmlFor="signOwnerAddressLine2">Address Line 2:</label>
                <input
                  type="text"
                  className="form-control"
                  id="signOwnerAddressLine2"
                  value={currentSign?.signOwnerAddressLine2}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({ ...currentSign, signOwnerAddressLine2: e.target.value as any } as IIlegalSign)
                  }
                />
              </div>
              <div className="col-lg-2 font-weight-bold">
                <label htmlFor="signOwnerCity">City:</label>
                <input
                  type="text"
                  className="form-control"
                  id="signOwnerCity"
                  value={currentSign?.signOwnerCity}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({ ...currentSign, signOwnerCity: e.target.value as any } as IIlegalSign)
                  }
                />
              </div>
              <div className="col-lg-1 font-weight-bold">
                <label htmlFor="signOwnerAddressState">State:</label>
                <input
                  type="text"
                  className="form-control"
                  id="signOwnerAddressState"
                  value={currentSign?.signOwnerAddressState}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({ ...currentSign, signOwnerAddressState: e.target.value as any } as IIlegalSign)
                  }
                />
              </div>
              <div className="col-lg-1 font-weight-bold">
                <label htmlFor="signOwnerZip">Zip Code:</label>
                <input
                  type="text"
                  className="form-control"
                  id="signOwnerZip"
                  value={currentSign?.signOwnerZip}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e => setCurrentSign({ ...currentSign, signOwnerZip: e.target.value as any } as IIlegalSign)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label htmlFor="certifiedMailNumber" className="font-weight-bold" title="Certified Mail Number">
                  Certified Mail Number:
                </label>
                <input
                  className="form-control"
                  id="certifiedMailNumber"
                  title="Certified Mail Number"
                  type="text"
                  value={currentSign?.certifiedMailNumber}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({ ...currentSign, certifiedMailNumber: e.target.value as any } as IIlegalSign)
                  }></input>
              </div>
              <div className="col-lg">
                <label htmlFor="deliveryConfirmationDate" className="font-weight-bold">
                  Delivery Confirmation Date:
                </label>
                <input
                  className="form-control"
                  id="deliveryConfirmationDate"
                  type="date"
                  value={inputFieldDateFormat(currentSign?.deliveryConfirmationDatePropertyOwner)}
                  disabled={!canCreateEditIllegalSign}
                  onChange={e =>
                    setCurrentSign({
                      ...currentSign,
                      deliveryConfirmationDatePropertyOwner: e.target.value as any,
                    } as IIlegalSign)
                  }></input>
              </div>
            </div>
            <div className="row">
              <div className="col text-left">
                <ButtonWithIcon
                  icon="save"
                  titleText="Save Illegal Sign"
                  buttonType="primary"
                  buttonText="Save Illegal Sign"
                  buttonAction="submit"
                  disabled={!canEdit}
                />
              </div>
            </div>
          </CustomCard>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getPhotos = () => {
    return (
      <>
        <CustomCard collapsible={true} headerText="Documents">
          {(currentSign?.documents?.length ?? 0) > 0 && (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <Typeahead
                    id={"violationStatus"}
                    inputProps={{ title: "Select Documents to combine", id: "pdfCombine" }}
                    placeholder="Select Documents to combine"
                    options={(currentSign?.documents ?? [])
                      .filter(d => {
                        return (
                          d.mimetype.toLowerCase() === "application/pdf" ||
                          d.mimetype.toLowerCase() === "image/jpeg" ||
                          d.mimetype.toLowerCase() === "image/jpg" ||
                          d.mimetype.toLowerCase() === "image/png"
                        );
                      })
                      .map((x: IDocument) => {
                        return { id: x._id, label: x.originalname };
                      })}
                    onChange={selected => setDocsToCombine(selected as any)}
                    selected={docsToCombine}
                    multiple={true}
                  />
                </div>
                <div className="col-lg-6">
                  <a
                    className={`btn btn-primary ${docsToCombine.length === 0 ? "disabled" : ""}`}
                    id="combinePdf"
                    href={getCombinedIllegalSignDocumentsPdf(JSON.stringify(docsToCombine))}
                    title="Download Combine Pdf"
                    rel="noopener noreferrer">
                    Combine Pdf
                  </a>
                </div>
              </div>
            </>
          )}
          {canEdit && (
            <>

              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="documentType" title="Document Type" className={`font-weight-bold required-field`}>
                    Document Type
                  </label>
                  <DocumentTypeSelector
                    idSuffix="IllegalSign"
                    applicableSections={[IApplicableSection.IllegalSigns]}
                    isExternalUser={false}
                    isInternalUser={true}
                    selectedValue={documentType}
                    onChange={(selectedOption: string) => {
                      setDocumentType(selectedOption);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label htmlFor="fileType" title="File Type">
                    <i>Accepted file types (Pdf, Images)</i>
                  </label>
                  <FilePond
                    server={{
                      process: {
                        url: illegalSignDocumentUrl(currentSign!._id, documentType),
                        withCredentials: true,
                      },
                    }}
                    disabled={documentType === ""}
                    acceptedFileTypes={["image/*", "application/pdf"]}
                    allowRevert={false}
                    ref={filePondRef}
                    onprocessfile={(error, file) => {
                      if (!error) {
                        setTimeout(() => {
                          filePondRef.current.removeFile(file);
                        }, 1000);
                      }
                    }}
                    onprocessfiles={() => {
                      dispatch(getIllegalSign(currentSign!._id));
                      setDocumentType("");
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col font-weight-bold">Image for Notices</div>
            <div className="col-4 font-weight-bold">Name</div>
            <div className="col-4 font-weight-bold">Image</div>
            <div className="col font-weight-bold">Delete</div>
          </div>
          {currentSign && currentSign?.documents ? (
            [...currentSign?.documents]
              ?.sort((a, b) => {
                return new Date(b.added) - new Date(a.added);
              })
              .map((doc, i) => {
                return (
                  <div className="row" key={doc._id}>
                    {canEdit ? (
                      <div className="col">
                        {doc.originalname.toUpperCase().indexOf(".PDF") === -1 ? (
                          <input
                            name="selectedNoticePhotoId"
                            className="form-control"
                            type="radio"
                            checked={currentSign?.selectedNoticePhotoId === doc._id}
                            onChange={e => setCurrentSign({ ...currentSign, selectedNoticePhotoId: doc._id as any })}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-4">
                      <a title={`Download ${doc.originalname}`} download={doc.originalname} href={documentUrl(doc._id)}>
                        <DocumentLink doc={doc}></DocumentLink>
                      </a>
                    </div>
                    <div className="col-4">
                      {doc.originalname.toUpperCase().indexOf(".PDF") === -1 ? (
                        <img alt="Tag" src={documentUrl(doc._id)} height="150" style={{ maxWidth: "100%" }}></img>
                      ) : (
                        ""
                      )}
                    </div>
                    {canEdit ? (
                      <div className="col">
                        <ButtonWithIcon
                          icon="trash"
                          titleText="Remove Document"
                          onClick={() => {
                            if (window.confirm("Are you sure you want to delete this document?")) {
                              removeAdditionalDocument(i);
                            }
                          }}
                          buttonType="danger"></ButtonWithIcon>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })
          ) : (
            <></>
          )}
        </CustomCard>
      </>
    );
  };

  const getCounties = () => {
    if (isFdotInspector && currentIllegalSign.isConverted !== true) {
      const countyOptions: any = [];
      const countiesCopy = [...userCounties];

      if (currentSign?.county) {
        if (!countiesCopy.find(c => c === currentSign?.county)) {
          countiesCopy.push(currentSign?.county);
        }

        countiesCopy.sort();
      }

      countyOptions.push(
        <option value="" key="select">
          Select
        </option>,
      );
      countyOptions.push(
        countiesCopy.map((county: string) => {
          return (
            <option key={county} value={county}>
              {county}
            </option>
          );
        }),
      );

      return countyOptions;
    }
    return <EnumOptions enumeration={FloridaCounty}></EnumOptions>;
  };

  const getSignInfo = () => {
    return (
      <>
        <div className="row">
          <div className="col-lg-2">
            {" "}
            <label title="Tracking Number" className="font-weight-bold">
              Tracking Number:
            </label>
            <div>{currentSign?.number}</div>
          </div>
          <div className="col-lg-2">
            {" "}
            <label title="Notice Number" className="font-weight-bold">
              Notice Number:
            </label>
            <div>{currentSign?.greenNoticeNumber}</div>
          </div>
          <div className="col-lg-3">
            {" "}
            <label title="Status" className="font-weight-bold">
              Status:
            </label>
            <div>{currentSign?.status}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg">
            <label htmlFor="county" className="font-weight-bold  required-field">
              County:
            </label>
            <select
              className="form-control"
              id="county"
              value={currentSign?.county}
              required
              disabled={!canEdit}
              onChange={e => {
                const newSignValue = { ...currentSign, county: e.target.value as any } as any;
                setCurrentSign(newSignValue);
              }}>
              {getCounties()}
            </select>
          </div>
          <div className="col-lg">
            <label className="font-weight-bold">Inspection Region:</label>
            <div>{currentSign?.inspectionRegion?.name}</div>
          </div>
          <div className="col-lg">
            <label htmlFor="sectionId" className="font-weight-bold  required-field">
              Section Id:
            </label>
            <SectionSelector
              id="sectionId"
              required
              disabled={!canEdit}
              selectedSection={section}
              onChange={sect => sectionChanged(sect)}></SectionSelector>
          </div>
          <div className="col-lg">
            <label htmlFor="milepost" className="font-weight-bold required-field">
              Milepost:
            </label>
            <input
              className="form-control"
              id="milepost"
              pattern="^[0-9]{1,11}(?:\.[0-9]{1,3})?$"
              title="Milepost must be a number with a maximum of 3 decimal places"
              required
              type="text"
              step={0.001}
              disabled={!canEdit}
              value={currentSign?.milepost}
              onChange={e => setCurrentSign({ ...currentSign, milepost: e.target.value as any } as any)}></input>
          </div>
          <div className="col-lg">
            <label htmlFor="sideOfSection" className="font-weight-bold required-field">
              Side of Section:
            </label>
            <select
              className="form-control"
              id="sideOfSection"
              value={currentSign?.sideOfSection}
              required
              disabled={!canEdit}
              onChange={e => setCurrentSign({ ...currentSign, sideOfSection: e.target.value } as any)}>
              <EnumOptions enumeration={SectionSide}></EnumOptions>
            </select>
          </div>
          <div className="col-lg">
            <label htmlFor="highwayNumber" className="font-weight-bold">
              Highway Number:
            </label>
            <div>{currentSign?.highwayNumber}</div>
          </div>
          <div className="col-lg">
            <label htmlFor="propertyId" className="font-weight-bold">
              <CountyPropertyAppraisalLink
                county={currentSign?.county}
                label={`Property ID#:`}></CountyPropertyAppraisalLink>
            </label>
            <input
              className="form-control"
              id="propertyId"
              type="text"
              value={currentSign?.propertyId}
              disabled={!canCreateEditIllegalSign}
              onChange={e => setCurrentSign({ ...currentSign, propertyId: e.target.value as any } as any)}></input>
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            <label htmlFor="configuration" className="font-weight-bold required-field">
              Configuration:
            </label>
            <select
              className="form-control"
              id="configuration"
              value={currentSign?.configuration}
              required
              disabled={!canEdit}
              onChange={e => setCurrentSign({ ...currentSign, configuration: e.target.value } as any)}>
              <EnumOptions enumeration={ConfigurationOption}></EnumOptions>
            </select>
          </div>
          <div className="col-lg">
            <label htmlFor="material" className="font-weight-bold">
              Material:
            </label>
            <select
              className="form-control"
              id="material"
              value={currentSign?.material}
              disabled={!canEdit}
              onChange={e => setCurrentSign({ ...currentSign, material: e.target.value } as any)}>
              <EnumOptions enumeration={IllegalSignMaterial}></EnumOptions>
            </select>
          </div>
          <div className="col-lg">
            <label htmlFor="heightAboveGroundLevel" className="font-weight-bold">
              HAGL:
            </label>
            <input
              className="form-control"
              id="heightAboveGroundLevel"
              type="number"
              value={currentSign?.heightAboveGroundLevel}
              disabled={!canEdit}
              onChange={e =>
                setCurrentSign({ ...currentSign, heightAboveGroundLevel: e.target.value as any } as any)
              }></input>
          </div>
          <div className="col-lg">
            <label htmlFor="numberOfFacings" className="font-weight-bold required-field">
              Number of Facings:
            </label>
            <input
              className="form-control"
              id="numberOfFacings"
              required
              type="number"
              value={currentSign?.numberOfFacings}
              disabled={!canEdit}
              onChange={e => {
                const num = parseInt(e.target.value);
                let dimensions = currentSign?.dimensions;
                if (dimensions && num > dimensions.length) {
                  while (dimensions.length < num) {
                    dimensions = [...dimensions, {} as any];
                  }
                } else if (dimensions && num < dimensions.length) {
                  dimensions = dimensions.slice(0, num);
                }
                setCurrentSign({
                  ...currentSign,
                  numberOfFacings: e.target.value as any,
                  dimensions: dimensions,
                } as any);
              }}></input>
          </div>
          <div className="col-lg">
            <label title="Total Area for Facings" className="font-weight-bold">
              Total Area for Facings:
            </label>
            <div>
              {getTotalArea()}
            </div>
          </div>
          <div className="col-lg">
            <label htmlFor="newJurisdiction" className="font-weight-bold">
              New Jurisdiction:
            </label>
            <select
              className="form-control"
              id="newJurisdiction"
              value={currentSign?.newJurisdiction}
              disabled={!canEdit}
              onChange={e => setCurrentSign({ ...currentSign, newJurisdiction: e.target.value } as any)}>
              <EnumOptions enumeration={YesNo}></EnumOptions>
            </select>
          </div>
        </div>
        {currentSign?.dimensions.map((dimension, index) => {
          const controlId = `permit${index}`;
          return (
            <div className="row" key={index}>
              <div className="col-lg-2">
                <label htmlFor="facingHeight" className="font-weight-bold">
                  Permit {index + 1}:
                </label>
                <PermitSelector
                  id={controlId}
                  selectedPermitId={dimension.permit?._id}
                  disabled={!canEdit}
                  onChange={selectedPermit => {
                    const newDimensions = [...currentSign?.dimensions];
                    newDimensions[index] = { ...newDimensions[index], permit: selectedPermit };
                    setCurrentSign({
                      ...currentSign,
                      dimensions: newDimensions,
                    });
                  }}></PermitSelector>
                {dimension.permit && (
                  <button className="btn btn-link">
                    <a
                      key={`${dimension.permit._id}`}
                      target="_blank"
                      title={`Permit # ${dimension.permit.permitNumber} - Opens in a new Window`}
                      rel="noopener noreferrer"
                      href={`/Permit/${dimension.permit._id}`}>
                      {`Permit # ${dimension.permit.permitNumber}`}
                    </a>
                  </button>
                )}
              </div>
              <div className="col-lg-2">
                <label htmlFor="facingHeight" className="font-weight-bold">
                  Facing {index + 1} Height (ft):
                </label>
                <input
                  className="form-control"
                  id="facingHeight"
                  type="number"
                  value={dimension.facingHeight}
                  disabled={!canEdit}
                  onChange={e => {
                    const newDimensions = [...currentSign?.dimensions];
                    newDimensions[index] = { ...newDimensions[index], facingHeight: e.target.value as any };
                    newDimensions[index].facingArea =
                      newDimensions[index].facingHeight * newDimensions[index].facingWidth;
                    setCurrentSign({
                      ...currentSign,
                      dimensions: newDimensions,
                    });
                  }}></input>
              </div>
              <div className="col-lg-2">
                <label htmlFor="facingWidth" className="font-weight-bold">
                  Facing {index + 1} Width (ft):
                </label>
                <input
                  className="form-control"
                  id="facingWidth"
                  type="number"
                  value={dimension.facingWidth}
                  disabled={!canEdit}
                  onChange={e => {
                    const newDimensions = [...currentSign?.dimensions];
                    newDimensions[index] = { ...newDimensions[index], facingWidth: e.target.value as any };
                    newDimensions[index].facingArea = newDimensions[index].facingHeight * newDimensions[index].facingWidth;
                    setCurrentSign({
                      ...currentSign,
                      dimensions: newDimensions,
                    });
                  }}></input>
              </div>
              <div className="col-lg-2">
                <label className="font-weight-bold">Facing {index + 1} Area:</label>
                <div>{!isNaN(dimension.facingArea) ? dimension.facingArea : ""}</div>
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-lg-3">
            <label className="font-weight-bold">Sign Removal Cost Estimate:</label>

            <div>{currentSign?.removalCost ? `$ ${currentSign?.removalCost}` : ""}</div>
          </div>
          <div className="col-lg">
            <label htmlFor="adCopy" className="font-weight-bold">
              Ad Copy:
            </label>
            <input
              className="form-control"
              id="adCopy"
              type="text"
              required
              value={currentSign?.adCopy}
              disabled={!canEdit}
              onChange={e => setCurrentSign({ ...currentSign, adCopy: e.target.value as any } as any)}></input>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <ButtonWithIcon
              icon="save"
              titleText="Save Illegal Sign"
              buttonType="primary"
              buttonText="Save Illegal Sign"
              buttonAction="submit"
              disabled={!canEdit}
            />
          </div>
        </div>
      </>
    );
  };

  const getSubmissionSection = () => {
    if (
      currentSign?.status === IllegalSignStatus.InitialReportOfViolationConsultant &&
      !isFieldConsultantManager &&
      isFieldConsultantInspector &&
      currentSign?.awaitingManagerApproval !== true
    ) {
      return (
        <div className="col-lg-3">
          <div>&nbsp;</div>
          <button
            className="btn btn-primary"
            id="sendToManagerForApproval"
            title="Send to Field Consultant Manager for Approval"
            onClick={sendToManagerForApproval}>
            Send to Manager for Approval
          </button>
        </div>
      );
    }

    if (currentSign?.status === IllegalSignStatus.InitialReportOfViolationConsultant && isFieldConsultantManager) {
      return (
        <div className="col-lg-3">
          <div>&nbsp;</div>
          <button
            className="btn btn-primary"
            id="SubmittoCentralOffice"
            title="Submit to Central Office"
            onClick={submitToCentralOffice}
            key="Submit to Central Office">
            Submit to Central Office
          </button>
        </div>
      );
    }
  };

  const getDeleteButton = () => {
    if (
      currentIllegalSign.foundById === user?._id &&
      (currentIllegalSign.status === IllegalSignStatus.ReceivedInOffice ||
        currentIllegalSign.status === IllegalSignStatus.InitialReportOfViolationConsultant ||
        currentIllegalSign.status === IllegalSignStatus.InitialReportOfViolationFDOT)
    ) {
      return (
        <div className="row">
          <div className="col">
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                const confirmed = window.confirm("Are you sure you want to delete this illegal sign?");
                if (confirmed) {
                  dispatch(
                    deleteIllegalSign(currentIllegalSign._id, () => {
                      navigate("/IllegalSigns");
                    }),
                  );
                }
              }}>
              Cancel Illegal Sign
            </button>
          </div>
        </div>
      );
    }
    return <> </>;
  };

  const getStatusSection = () => {
    return (
      <>
        <div className="row">
          <div className="col-lg-2">
            <label title="Tracking Number" className="font-weight-bold">
              Tracking Number:
            </label>
            <div>{currentSign?.number}</div>
          </div>
          <div className="col-lg-2">
            <label htmlFor="greenNoticeNumber" className="font-weight-bold">
              Notice Number:
            </label>
            <input
              className="form-control"
              id="greenNoticeNumber"
              type="number"
              value={currentSign?.greenNoticeNumber ? currentSign?.greenNoticeNumber : ""}
              onChange={e =>
                setCurrentSign({ ...currentSign, greenNoticeNumber: e.target.value as any } as any)
              }></input>
          </div>
          <div className="col-lg-2">
            <label htmlFor="status" title="Status" className="font-weight-bold">
              Status:
            </label>
            <select
              className="form-control"
              id="status"
              disabled={!canEdit}
              value={currentSign?.status}
              onChange={e => setCurrentSign({ ...currentSign, status: e.target.value } as any)}>
              <EnumOptions enumeration={availableStatuses}></EnumOptions>
            </select>
          </div>
          <div className="col-lg-2">
            <label title="Found By" className="font-weight-bold">
              Found By:
            </label>
            <div>{currentSign?.foundBy?.displayName}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label htmlFor="resolution" className="font-weight-bold">
              Resolution:
            </label>
            <select
              className="form-control"
              id="resolution"
              value={currentSign?.resolution}
              onChange={e => setCurrentSign({ ...currentSign, resolution: e.target.value } as any)}>
              <EnumOptions
                enumeration={IllegalSignResolution}
                excludeList={[
                  IllegalSignResolution.Paid,
                  IllegalSignResolution.Upheld,
                  IllegalSignResolution.Adverse,
                  IllegalSignResolution.Dismissed,
                ]}></EnumOptions>
            </select>
          </div>
          <div className="col-lg-2">
            <label htmlFor="resolutionDate" className="font-weight-bold">
              Resolution Date:
            </label>
            <input
              className="form-control"
              id="resolutionDate"
              type="date"
              value={inputFieldDateFormat(currentSign?.resolutionDate)}
              onChange={e => setCurrentSign({ ...currentSign, resolutionDate: e.target.value as any } as any)}></input>
          </div>
          <div className="col-lg-2">
            <label htmlFor="removedBy" className="font-weight-bold">
              Removed By:
            </label>
            <select
              className="form-control"
              id="removedBy"
              value={currentSign?.removedBy}
              onChange={e => setCurrentSign({ ...currentSign, removedBy: e.target.value } as any)}>
              <EnumOptions enumeration={NoticeRemovedBy}></EnumOptions>
            </select>
          </div>
          {currentSign?.status === IllegalSignStatus.Resolved &&
          currentSign?.resolution === IllegalSignResolution.Permitted ? (
            <>
              <div className="col-lg">
                <label htmlFor="resolvedPermit" className="font-weight-bold">
                  New Permit Number(s):
                </label>
                <PermitSelector
                  id="resolvedPermit"
                  selectedPermitId={null}
                  clearAfterSelect={true}
                  onChange={selectedPermit => {
                    const permits = [...currentSign?.resolvedPermits];
                    permits.push(selectedPermit);
                    setCurrentSign({
                      ...currentSign,
                      resolvedPermits: permits,
                    });
                  }}></PermitSelector>
                {[...currentSign?.resolvedPermits].map(p => (
                  <>
                    <button className="btn btn-link">
                      <a
                        key={`${p._id}`}
                        target="_blank"
                        title={`Permit # ${p.permitNumber} - Opens in a new Window`}
                        rel="noopener noreferrer"
                        href={`/Permit/${p._id}`}>
                        {`Permit # ${p.permitNumber}`}
                      </a>
                    </button>
                    <button className="btn btn-link" type="button">
                      <a
                        key={`${p._id}`}
                        title={`Remove Permit # ${p.permitNumber}`}
                        rel="noopener noreferrer"
                        onClick={() => {
                          const permits = [...currentSign?.resolvedPermits];
                          //console.log("permits");
                          //console.log(permits);
                          const newPermits = permits.filter(pt => pt._id !== p._id);
                          setCurrentSign({
                            ...currentSign,
                            resolvedPermits: newPermits,
                          });
                        }}>
                        {`x`}
                      </a>
                    </button>
                  </>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="row">
          <div className="col-lg">
            <label htmlFor="comments" className="font-weight-bold">
              Comments:
            </label>
            <textarea
              className="form-control"
              id="comments"
              value={currentSign?.comments}
              onChange={e => setCurrentSign({ ...currentSign, comments: e.target.value } as any)}></textarea>
          </div>
        </div>
        {getDeleteButton()}
        <div className="row">
          {getNoticeButtons()}
          {getSubmissionSection()}
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label htmlFor="invoiceNumber" className="font-weight-bold">
              Removal Invoice Number:
            </label>
            <div>{currentSign?.invoiceNumber}</div>
          </div>
          <div className="col-lg-2">
            <label htmlFor="invoiceDate" className="font-weight-bold">
              Removal Invoice Date:
            </label>
            <div>{formatDateWithNoTimeZone(currentSign?.invoiceDate)}</div>
          </div>
          <div className="col-lg-2">
            <label htmlFor="invoiceAmount" className="font-weight-bold">
              Override Invoice Amount:
            </label>
            <input
              className="form-control"
              id="invoiceAmount"
              type="number"
              value={currentSign?.invoiceAmount ? currentSign?.invoiceAmount : currentSign?.removalCost}
              disabled={!canCreateEditInvoice}
              onChange={e => setCurrentSign({ ...currentSign, invoiceAmount: e.target.value as any } as any)}></input>
          </div>
        </div>
        {getOwnerInfo()}
        <div className="row">
          <div className="col-lg-5 my-4">
            <CustomCard collapsible={true} headerText="Status History">
              <div className="row">
                <div className="col">
                  <table className="table table-striped table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Status</th>
                        <th>User</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...(currentSign?.statusHistory ?? [])]
                        ?.sort()
                        .reverse()
                        .map((status, index) => (
                          <tr key={index}>
                            <td>{format(new Date(status.date), "MM/dd/yyyy")}</td>
                            <td>{status.status}</td>
                            <td>{status.by.displayName}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </CustomCard>
          </div>
          <div className="col-lg">
            <TaskAssignment
              illegalSignId={currentSign?._id}
              isDisabled={currentSign?.status === IllegalSignStatus.Resolved}
              canCreateTasks={Rights.canCreateTasks(user)}
              canCreateVOC={false}
            />
          </div>
        </div>
      </>
    );
  };

  const sendToManagerForApproval = () => {
    const updatedSign = {
      ...currentSign,
      awaitingManagerApproval: true,
    } as IIlegalSign;
    dispatch(updateIllegalSign(updatedSign));
  };

  const submitToCentralOffice = () => {
    const updatedSign = {
      ...currentSign,
      awaitingManagerApproval: false,
      submittedToCentralOffice: true,
    } as IIlegalSign;
    dispatch(updateIllegalSign(updatedSign));
  };

  const generateNotice = () => {
    //This timeout is to allow the notice generation, upload to blob storage, and save to DB to finish before we refresh the redux store
    //If the save is not done before the refresh then the new doc won't appear on the docs and photos tab
    //Task work order package takes longer
    const timeoutLength = noticeType === IllegalSignNoticeType.TaskWorkOrderPackage ? 10000 : 5000;
    if (
      noticeType === IllegalSignNoticeType.NoticeOfViolationIllegalSignInTheRightOfWay &&
      stringUtils.isNullOrWhitespace(currentIllegalSign.certifiedMailNumber) &&
      stringUtils.isNullOrWhitespace(currentIllegalSign.certifiedMailNumberPropertyOwner)
    ) {
      toast.error(
        "This notice cannot be generated if there is no certified mail number recorded for at least one owner",
      );
      return;
    }
    if (
      !currentIllegalSign.dimensions[0]?.permit &&
      (noticeType === IllegalSignNoticeType.RemovalCostLetterForCancelled ||
        noticeType === IllegalSignNoticeType.RemovalCostLetterForExpired ||
        noticeType === IllegalSignNoticeType.RemovalCostLetterForRevoked)
    ) {
      toast.error("This illegal sign has no associated permits. A Removal Cost Letter cannot be generated for it.");
      return;
    }
    saveIllegalSign(currentIllegalSign._id);
    setTimeout(() => {
      window.open(getNoticeUrl(noticeType, currentIllegalSign._id, currentSign?.usePropertyOwnerAddressForInvoice));
      setTimeout(() => {
        dispatch(getIllegalSign(currentIllegalSign._id));
      }, timeoutLength);
    }, 2000);
  };

  const getNoticeButtons = () => {
    if (isFieldConsultantInspector || isFieldConsultantManager || !canEdit) {
      return <></>;
    }
    return (
      <>
        <div className="col-lg-3">
          <label htmlFor="notice" className="font-weight-bold" title="Notice">
            Notice to be sent:
          </label>
          <select
            id="notice"
            name="notice"
            className="form-control"
            title="Select Notice"
            value={noticeType}
            onChange={e => setNoticeType(e.target.value)}>
            {isFdotInspector ? (
              <>
                <option value="">Select</option>
                <option value={IllegalSignNoticeType.LetterOfConcern}>{IllegalSignNoticeType.LetterOfConcern}</option>
              </>
            ) : (
              <EnumOptions
                enumeration={IllegalSignNoticeType}
                excludeList={[IllegalSignNoticeType.TaskWorkOrderPackage]}></EnumOptions>
            )}
          </select>
        </div>
        <div className="col-lg-3">
          <label htmlFor="usePropertyOwnerAddressForInvoice" className="font-weight-bold">
            Use Property Owner for Notice:
          </label>
          <input
            className="form-control"
            id="usePropertyOwnerAddressForInvoice"
            type="checkbox"
            checked={currentSign?.usePropertyOwnerAddressForInvoice}
            onChange={e =>
              setCurrentSign({ ...currentSign, usePropertyOwnerAddressForInvoice: e.target.checked as any } as any)
            }></input>
        </div>
        <div className="col-lg-2">
          <div>&nbsp;</div>
          <button className="btn btn-primary" type="button" onClick={generateNotice} title="Generate Notice">
            Generate Notice
          </button>
        </div>
      </>
    );
  };

  const getTabComponent = () => {
    switch (activeTab) {
      case PageTab.photos:
        return getPhotos();
      //case PageTab.ownerInfo:
      //return getOwnerInfo();
      case PageTab.status:
        return getStatusSection();
      case PageTab.signInfo:
      default:
        return getSignInfo();
    }
  };

  const getPillClass = (activeTabToCompare: PageTab) => {
    return activeTab === activeTabToCompare ? "nav-link btn btn-link active" : '"nav-link btn btn-link';
  };

  const getNavItem = (tab: PageTab) => {
    return (
      <li className="nav-item">
        <button
          type="button"
          style={theme === Theme.Standard && getPillClass(tab).includes("active") ? { backgroundColor: "#006bd6" } : {}}
          className={getPillClass(tab)}
          aria-current="page"
          onClick={e => setActiveTab(tab)}>
          {tab}
        </button>
      </li>
    );
  };

  if (!currentSign || !currentSign?._id) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <form onSubmit={saveIllegalSign}>
        <div className="row">
          <div className="col text-right">
            <ButtonWithIcon
              icon="save"
              titleText="Save Illegal Sign"
              buttonType="primary"
              buttonText="Save Illegal Sign"
              buttonAction="submit"
              disabled={!canEdit}
            />
          </div>
        </div>
        <CustomCard headerText={`Illegal Sign - ${currentSign?.number}`}>
          <div className="row">
            <div className="col">
              <ul className="nav nav-pills">
                {getNavItem(PageTab.status)}
                <br />
                {getNavItem(PageTab.signInfo)}
                {/* <br />
                geTab.ownerInfo)} */}
                <br />
                {getNavItem(PageTab.photos)}
              </ul>
            </div>
          </div>
          {getTabComponent()}
          <br />
        </CustomCard>
      </form>
    </div>
  );
};

export default IllegalSign;
