import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import StructureSearchCriteria from "./StructureSearchCriteria";
import StructureSearchResults from "./StructureSearchResults";

const StructureSearch: React.FC = () => {
  const results = useSelector((state: RootState) => state.searchReducer.structureResults);

  return (
    <>
      <StructureSearchCriteria></StructureSearchCriteria>
      <StructureSearchResults results={results}></StructureSearchResults>
    </>
  );
};

export default StructureSearch;
