import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInspectionRegion } from "oda-shared";
import * as inspectionRegionApi from "../../apis/InspectionRegionApi";

type SliceState = {
  inspectionRegions: Record<string, IInspectionRegion>;
  userCounties: string[];
};

const inspectionRegionsSlice = createSlice({
  name: "inspectionRegions",
  initialState: {
    inspectionRegions: {},
    userCounties: [],
  } as SliceState,
  reducers: {
    loadInspectionRegions: (state: SliceState, action: PayloadAction<IInspectionRegion[]>) => {
      action.payload.sort((a, b) => a.name.localeCompare(b.name));
      action.payload.forEach(ir => {
        state.inspectionRegions[ir._id] = ir;
      });
      if (!action.payload || action.payload.length == 0) {
        state.inspectionRegions = {};
      }
    },
    loadInspectionRegion: (state: SliceState, action: PayloadAction<IInspectionRegion>) => {
      state.inspectionRegions[action.payload._id] = action.payload;
    },
    loadUserCounties: (state: SliceState, action: PayloadAction<string[]>) => {
      state.userCounties = action.payload;
    },
  },
});

export const { loadInspectionRegion, loadInspectionRegions, loadUserCounties } = inspectionRegionsSlice.actions;

export default inspectionRegionsSlice.reducer;

export const clearInspectionRegions = () => async (dispatch: any, getState: any) => {
  dispatch(loadInspectionRegions([] as IInspectionRegion[]));
};

export const getAllInspectionRegions = () => async (dispatch: any, getState: any) => {
  if (Object.keys(getState().inspectionRegionsReducer.inspectionRegions).length === 0) {
    inspectionRegionApi
      .getInspectionRegions()
      .then((inspectionRegions: IInspectionRegion[]) => {
        dispatch(loadInspectionRegions(inspectionRegions));
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};

export const addNewInspectionRegion = (inspectionRegionName: string) => async (dispatch: any) => {
  inspectionRegionApi
    .addInspectionRegion(inspectionRegionName)
    .then((inspectionRegion: IInspectionRegion) => {
      dispatch(loadInspectionRegion(inspectionRegion));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateInspectionRegion = (inspectionRegion: IInspectionRegion) => async (dispatch: any) => {
  inspectionRegionApi
    .updateInspectionRegion(inspectionRegion)
    .then((inspectionRegionData: IInspectionRegion) => {
      dispatch(loadInspectionRegion(inspectionRegionData));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getUserCounties = () => async (dispatch: any) => {
  inspectionRegionApi
    .getUserCounties()
    .then((counties: string[]) => {
      dispatch(loadUserCounties(counties));
    })
    .catch((error: any) => {
      console.log(error);
    });
};
