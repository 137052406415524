"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchType = void 0;
var SearchType;
(function (SearchType) {
    SearchType["Account"] = "Account";
    SearchType["Document"] = "Document";
    SearchType["Invoice"] = "Invoice";
    SearchType["IllegalSign"] = "IllegalSign";
    SearchType["Litigation"] = "Litigation";
    SearchType["Notice"] = "Notice";
    SearchType["Payment"] = "Payment";
    SearchType["PermitApplication"] = "PermitApplication";
    SearchType["Permit"] = "Permit";
    SearchType["Structure"] = "Structure";
    SearchType["Section"] = "Section";
    SearchType["Violation"] = "Violation";
    SearchType["Finding"] = "Finding";
    SearchType["Case"] = "Case";
    SearchType["Transfer"] = "Transfer";
    SearchType["Facing"] = "Facing";
})(SearchType = exports.SearchType || (exports.SearchType = {}));
