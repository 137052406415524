"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Example NIC Payment - Verify Payment Response JSON:  {
//   "matchingOrders": 1,
//   "orders": [
//      {
//         "processorId": 0,
//         "serviceName": "5011 Outdoor Advertising Permits",
//         "orderAmount": 72.78,
//         "instantSale": true,
//         "committed": true,
//         "orderId": 5927769,
//         "localRefId": "93403",
//         "orderStatus": "COMPLETE",
//         "timestamp": "2022-02-15T14:22:24Z",
//         "customerAddress": {
//            "name": "Cody Raffensperger",
//            "firstName": "Cody",
//            "lastName": "Raffensperger",
//            "address1": "2824 Kilkierane Dr",
//            "city": "Tallahassee",
//            "state": "FL",
//            "zip": "32309",
//            "country": "US",
//            "phone": "3146296374"
//         },
//         "items": [
//            {
//               "sku": "TPEFEE_SERVICEFEE",
//               "description": "CC/ACH Fee",
//               "unitPrice": 1.78,
//               "quantity": 1,
//               "itemId": 7152176,
//               "itemAttributes": []
//            },
//            {
//               "sku": "permitSku",
//               "description": "Permit Fee",
//               "unitPrice": 71,
//               "quantity": 1,
//               "itemId": 7152174,
//               "itemAttributes": []
//            }
//         ],
//         "orderAttributes": [
//            {
//               "fieldName": "sku",
//               "fieldValue": "oda"
//            },
//            {
//               "fieldName": "authCookie",
//               "fieldValue": "s:EyXfn2EU31LRgx8vElydiPuuUFph_rAX.mQGSDzQwCd9ElJiO43+IFmEQmyT5MgjHCswutx1YrtQ"
//            },
//            {
//               "fieldName": "IP_ADDRESS",
//               "fieldValue": "98.192.130.37"
//            },
//            {
//               "fieldName": "BIN_CHARGETYPE",
//               "fieldValue": "CREDIT"
//            },
//            {
//               "fieldName": "BIN_CARDNETWORK",
//               "fieldValue": "Visa"
//            },
//            {
//               "fieldName": "BIN_ISCOMMERCIALCARD",
//               "fieldValue": "False"
//            },
//            {
//               "fieldName": "BIN_ISFLEETCARD",
//               "fieldValue": "False"
//            },
//            {
//               "fieldName": "BIN_ISPINCAPABLECARD ",
//               "fieldValue": "False"
//            },
//            {
//               "fieldName": "ORIG_COS_AMOUNT",
//               "fieldValue": "71.00"
//            },
//            {
//               "fieldName": "ORIG_FEE_AMOUNT",
//               "fieldValue": "1.78"
//            },
//            {
//               "fieldName": "ORIG_TRANS_TOTAL",
//               "fieldValue": "72.78"
//            },
//            {
//               "fieldName": "RECEIPT_EMAIL1",
//               "fieldValue": "[Empty]"
//            },
//            {
//               "fieldName": "RECEIPT_EMAIL2",
//               "fieldValue": "[Empty]"
//            },
//            {
//               "fieldName": "RECEIPT_EMAIL3",
//               "fieldValue": "[Empty]"
//            },
//            {
//               "fieldName": "CCP",
//               "fieldValue": "Y"
//            },
//            {
//               "fieldName": "CCP_NAME",
//               "fieldValue": "Cody Raffensperger"
//            },
//            {
//               "fieldName": "CCP_TOKEN",
//               "fieldValue": "281b6a90-42ff-4e4a-a42f-304f577ffe36"
//            },
//            {
//               "fieldName": "CCP_REFID",
//               "fieldValue": "5f56989c-78e0-4d18-9347-a43b1eb25cd3"
//            },
//            {
//               "fieldName": "FINANCIAL_SESSION_ID",
//               "fieldValue": "[Empty]"
//            }
//         ],
//         "fTrans": [
//            {
//               "ftransId": 6665246,
//               "ftransTypeString": "PAYMENT",
//               "ftransTypeId": 2,
//               "paymentImplementTypeString": "CC",
//               "paymentImplementTypeId": 1,
//               "lastFour": "****1111",
//               "expirationDate": "12/25",
//               "accountType": "VISA",
//               "amount": 72.78,
//               "authCode": "OK9065",
//               "clientTrace": "93403",
//               "failure": false,
//               "timestamp": "2022-02-15T14:22:24Z",
//               "billingAddress": {
//                  "name": "Cody Raffy",
//                  "address1": "2824 Kilkierane Dr",
//                  "city": "Tallahassee",
//                  "state": "FL",
//                  "zip": "32309",
//                  "country": "US"
//               }
//            }
//         ]
//      }
//   ]
// }
