import axios from 'axios';
import { IAppConfig } from 'oda-shared';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/config`;

export const getConfig = async (): Promise<IAppConfig> => {
    const response = await axios.get(baseUrl);
    return response.data;
};

export const getDevStats = async(): Promise<any> => {    
    const response = await axios.get(`${baseUrl}/devtools`);
    return response.data;
}