import axios from "axios";
import {
  CanceledTagStatus,
  IPermit,
  ITag,
  IStructure,
  ReinstateTagStatus,
  InspectionStatus,
  InspectionReviewStatus,
  IInspectionRegion,
  IPermitTransferRequest,
  IPermitCancellationRequest,
} from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/permit`;
const permitTransferBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/permitTransfer`;
const permitCancellationBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/permitCancellation`;
const tagBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/tag`;
const structureBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/structure`;

export const getPermit = async (id: string): Promise<IPermit> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const canVoid = async (id: string): Promise<boolean> => {
  const response = await axios.post(`${baseUrl}/${id}/canVoid`);
  return response.data;
};

export const updatePermit = async (permit: IPermit) => {
  const response = await axios.patch(`${baseUrl}/${permit._id}`, permit);
  return response.data;
};

export const issuePermitTag = async (permit: IPermit) => {
  const response = await axios.post(`${baseUrl}/${permit._id}/issueTag`, permit);
  return response.data;
};

export const updateReplacementTagReason = async (tag: ITag, reason: string) => {
  const response = await axios.patch(`${tagBaseUrl}/${tag._id}`, { issueReason: reason });
  return response.data;
};

export const issueReplacementTag = async (tag: ITag) => {
  const response = await axios.patch(`${tagBaseUrl}/${tag._id}/issue`);
  return response.data;
};

export const denyReplacementTag = async (tag: ITag) => {
  const response = await axios.patch(`${tagBaseUrl}/${tag._id}/deny`);
  return response.data;
};

export const selfCertifyTag = async (tag: ITag) => {
  const response = await axios.patch(`${tagBaseUrl}/${tag._id}/selfCertify`);
  return response.data;
};

export const certifyTag = async (tag: ITag) => {
  const response = await axios.patch(`${tagBaseUrl}/${tag._id}/certify`);
  return response.data;
};

export const notPostedTag = async (tag: ITag) => {
  const response = await axios.patch(`${tagBaseUrl}/${tag._id}/NotPostedOrVisible`);
  return response.data;
};

export const assignConsultantInspectorToApplication = async (id: string, inspectorId: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/AssignConsultantInspector/${inspectorId}`);
  return response.data;
};

export const assignFdotInspectorToApplication = async (id: string, inspectorId: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/AssignFdotInspector/${inspectorId}`);
  return response.data;
};

export const noticeToRevokePermit = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/NoticeToRevoke`);
  return response.data;
};

export const revokePermit = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/Revoke`);
  return response.data;
};

export const reinstatePermit = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/Reinstate`);
  return response.data;
};

export const reactivatePermit = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/Reactivate`);
  return response.data;
};


export const manualPermitCancellation = async (id: string, signRemovedDate: Date | null): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/ManualPermitCancellation`, {
    signRemovedDate,
  });
  return response.data;
};

export const cancel = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/Cancel`);
  return response.data;
};

export const requestReinstatement = async (
  id: string,
  reinstateStatusOfPermitTag: ReinstateTagStatus,
): Promise<IPermit> => {
  const body = {
    reinstateStatusOfPermitTag: reinstateStatusOfPermitTag,
  };
  const response = await axios.patch(`${baseUrl}/${id}/RequestReinstatement`, body);
  return response.data;
};

export const approveReinstatement = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/ApproveReinstatement`);
  return response.data;
};

export const assignBuildInspectionRequired = async (id: string, buildInspectionRequired: boolean): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/BuildInspectionRequired/${buildInspectionRequired}`);
  return response.data;
};

export const updateStructure = async (structure: IStructure, region: IInspectionRegion) => {
  const response = await axios.patch(`${structureBaseUrl}/${structure._id}/${region._id}`, structure);
  return response.data;
};

export const moveStructure = async (structure: IStructure): Promise<IStructure> => {
  const response = await axios.patch(`${structureBaseUrl}/${structure._id}`, structure);
  return response.data;
};

export const createLocationHistory = async (structure: IStructure, comment: string): Promise<IStructure> => {
  const response = await axios.post(`${structureBaseUrl}/${structure._id}/${comment}`, structure);
  return response.data;
};

export const acceptTransfer = async (id: number) => {
  const response = await axios.patch(`${baseUrl}/${id}/AcceptTransfer`);
  return response.data;
};

export const approveTransfer = async (id: number) => {
  const response = await axios.patch(`${baseUrl}/${id}/ApproveTransfer`);
  return response.data;
};

export const initiateTransfer = async (permitIds: [string], odaAccount: string) => {
  const response = await axios.patch(`${baseUrl}/InitiateTransfer/${odaAccount}`, {
    permits: permitIds,
  });
  return response.data;
};

export const denyTransfer = async (id: number) => {
  const response = await axios.patch(`${baseUrl}/${id}/DenyTransfer`);
  return response.data;
};

export const assignInspectorToPermitInspection = async (id: string, inspectorId: string) => {
  const response = await axios.patch(`${baseUrl}/${id}/AssignInspectorToInspection/${inspectorId}`);
  return response.data;
};

export const updatePermitInspectionStatus = async (id: string, status: InspectionStatus, comment: string) => {
  const response = await axios.patch(`${baseUrl}/${id}/ChangeInspectionStatus/${status}`, { comment: comment });
  return response.data;
};

export const updatePermitInspectionReviewStatus = async (
  id: string,
  status: InspectionReviewStatus,
  comment: string,
) => {
  const response = await axios.patch(`${baseUrl}/${id}/ChangeInspectionReviewStatus/${status}`, { comment: comment });
  return response.data;
};

export const getPermitsForOdaAccount = async (odaAccountId: string): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/odaAccount/${odaAccountId}`);
  return response.data;
};

export const voidPermit = async (id: string): Promise<IPermit> => {
  const response = await axios.patch(`${baseUrl}/${id}/Void`);
  return response.data;
};

export const searchPermitByNumber = async (searchString: string): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/permit-number-search/${searchString}`);
  return response.data;
};

export const getPermitPdfUri = (permitId: string, withRequirements: boolean = false) => {
  return !withRequirements ? `${baseUrl}/${permitId}/permitPdf` : `${baseUrl}/${permitId}/permitPdfwithRequirements`;
};

export const getPermitVoidPdfUri = (permitId: string, violationId: string, facingId: string) =>
  `${baseUrl}/${permitId}/permitVoidPdf/${violationId}/${facingId}`;

export const generateShowCauseForNonPaymentUri = (permitId: string) => `${baseUrl}/${permitId}/showCauseForNonPayment`;

export const createPermitTransferRequest = async (permitIds: [string], odaAccount: string) => {
  const response = await axios.post(`${permitTransferBaseUrl}/createNew/${odaAccount}`, {
    permits: permitIds,
  });
  return response.data;
};

export const getPermitTransferRequest = async (id: string): Promise<IPermitTransferRequest> => {
  const response = await axios.get(`${permitTransferBaseUrl}/${id}`);
  return response.data;
};

export const updatePermitTransferRequest = async (request: IPermitTransferRequest) => {
  const response = await axios.patch(`${permitTransferBaseUrl}/${request._id}`, request);
  return response.data;
};

export const createPermitCancellationRequest = async (request: IPermitCancellationRequest, permitIds: [string]) => {
  const response = await axios.post(`${permitCancellationBaseUrl}/createNew`, {
    permits: permitIds,
    cancellationRequest: request,
  });
  return response.data;
};

export const getPermitCancellationRequest = async (id: string): Promise<IPermitCancellationRequest> => {
  const response = await axios.get(`${permitCancellationBaseUrl}/${id}`);
  return response.data;
};

export const updateStatusOfPermitCancellationRequest = async (request: IPermitCancellationRequest) => {
  const response = await axios.patch(`${permitCancellationBaseUrl}/${request._id}`, request);
  return response.data;
};
