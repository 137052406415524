import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  performSectionSearch,
  updateCurrentPageIndex,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
  updateTotalItemCount,
} from "../../../redux/slices/SearchSlice";
import { ButtonWithIcon } from "../../common/ButtonWithIcon";
import { CustomCard } from "../../common/CustomCard";
import EnumToOptions from "../../common/EnumOptions";
import { FloridaCounty, ISection, SectionSide } from "oda-shared";
import { useParams } from "react-router-dom";
import { SectionSelector } from "../../common/SectionSelector";
import { getAllInspectionRegions } from "../../../redux/slices/InspectionRegionsSlice";

const SectionSearchCriteria: React.FC = () => {
  const { id } = useParams();
  const [sectionNumber, setSectionNumber] = useState<string>("");
  const [section, setSection] = useState<ISection | null>(null);
  const [region, setRegion] = useState("");
  const [county, setCounty] = useState("");
  const { inspectionRegions } = useSelector((state: RootState) => state.inspectionRegionsReducer);

  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, pageChanged } = useSelector(
    (state: RootState) => state.searchReducer,
  );
  const [initialLoad, setInitialLoad] = useState(true);

  const dispatch = useDispatch();

  const criteria = useMemo(() => {
    console.log("sectionNumber", sectionNumber);
    return {
      sectionNumber,
      region,
      county,
      sortColumn,
      sortDirection,
      currentPageIndex,
      itemsPerPage,
    };
  }, [sectionNumber, region, county, sortColumn, sortDirection, currentPageIndex, itemsPerPage]);

  useEffect(() => {
    if (pageChanged) {
      dispatch(performSectionSearch(criteria));
      dispatch(updatePageChanged(false));
    }
  }, [pageChanged, criteria, dispatch]);

  useEffect(() => {
    dispatch(getAllInspectionRegions());
    dispatch(updateSortColumn("rciSection"));
    dispatch(updateSortDirection("desc"));
    dispatch(updateTotalItemCount(0));
  }, [dispatch]);

  useEffect(() => {
    if (id && criteria && initialLoad && criteria.sectionNumber) {
      setInitialLoad(false);
      dispatch(performSectionSearch(criteria as any));
    }
  }, [criteria, initialLoad, id, dispatch]);

  useEffect(() => {
    dispatch(getAllInspectionRegions());
  }, [dispatch]);

  const reset = () => {
    setSection(null);
    setSectionNumber(null as any);
    setCounty("");
    setRegion("");
  };

  return (
    <CustomCard collapsible={true} headerText="Section Search Criteria">
      <div className="row">
        <div className="col-lg-4">
          <label className="font-weight-bold" htmlFor="section" title="Section Contains">
            Section Number:
          </label>
          <SectionSelector
            selectedSection={section}
            id="section"
            onChange={sect => {
              setSection(sect);
              setSectionNumber(sect?.rciSection);
            }}></SectionSelector>
        </div>
        <div className="col">
          <label htmlFor="region" title="Region" className="font-weight-bold">
            Region:
          </label>
          <select
            title="Region"
            className="form-control"
            name="region"
            id="region"
            value={region}
            onChange={event => setRegion(event.target.value)}>
            <option value="">All</option>
            {Object.values(inspectionRegions)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((ir, i) => {
                return (
                  <option key={ir._id} value={ir._id}>
                    {ir.name}
                  </option>
                );
              })}
            );
          </select>
        </div>
        <div className="col-lg-3">
          <label className="font-weight-bold" htmlFor="county" title="County">
            County:
          </label>
          <select
            className="form-control"
            name="county"
            id="county"
            title="County"
            value={county}
            onChange={event => setCounty(event.target.value)}>
            <EnumToOptions enumeration={FloridaCounty}></EnumToOptions>
          </select>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col">
          <div className="text-center">
            <ButtonWithIcon buttonType="danger" icon="undo" buttonText="Reset" titleText="Reset" onClick={reset} />{" "}
            &nbsp;&nbsp;
            <ButtonWithIcon
              buttonType="primary"
              icon="search"
              buttonText="Search"
              onClick={() => {
                dispatch(updateCurrentPageIndex(0));
                dispatch(performSectionSearch({ ...criteria, currentPageIndex: 0 } as any));
              }}></ButtonWithIcon>
          </div>
        </div>
      </div>
    </CustomCard>
  );
};

export default SectionSearchCriteria;
