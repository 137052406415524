import axios from "axios";
import { ISectionAnnualData, ISection, SectionAnnualDataStatus } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/annualRecords`;

export const getSectionAnnualData = async (id: string): Promise<ISectionAnnualData> => {
  const response = await axios.get(`${baseUrl}/annualRecord/${id}`);
  return response.data;
};
export const createSectionAnnualData = async (
  annualRecord: ISectionAnnualData,
  section: ISection,
): Promise<ISection> => {
  const response = await axios.post(`${baseUrl}/annualRecord/${section?._id}`, annualRecord);
  return response.data;
};
export const updateSectionAnnualData = async (annualRecord: ISectionAnnualData): Promise<ISectionAnnualData> => {
  const response = await axios.patch(`${baseUrl}/annualRecord/${annualRecord._id}`, annualRecord);
  return response.data;
};

export const getSectionAnnualDataRecords = async (sectionId: string): Promise<ISectionAnnualData[]> => {
  const response = await axios.get(`${baseUrl}/ ${sectionId}`);
  return response.data;
};

export const changeSectionAnnualDataStatus = async (
  annualData: ISectionAnnualData,
  changeToStatus: SectionAnnualDataStatus,
) => {
  const response = await axios.patch(
    `${baseUrl}/annualRecord/changeSectionAnnualDataStatus/${annualData._id}/${changeToStatus}`,
    annualData,
  );
  return response.data;
};
