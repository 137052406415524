import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IViolation } from "oda-shared";
import * as violationApi from "../../apis/ViolationApi";
import { toast } from "react-toastify";

type SliceState = {
  currentViolation: IViolation | null;
};

const violationSlice = createSlice({
  name: "violation",
  initialState: {
    currentViolation: null,
  } as SliceState,
  reducers: {
    loadViolation: (state: SliceState, action: PayloadAction<IViolation>) => {
      state.currentViolation = action.payload;
    },
  },
});

export const { loadViolation } = violationSlice.actions;

export default violationSlice.reducer;

export const getViolation = (violationId: string) => async (dispatch: any) => {
  violationApi
    .getViolation(violationId)
    .then((violation: IViolation) => {
      dispatch(loadViolation(violation));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateViolation =
  (violation: IViolation, successCallback: (violation: IViolation) => void) => async (dispatch: any) => {
    const updatedViolation = await violationApi.updateViolation(violation);
    toast.success("Violation Saved!");
    dispatch(loadViolation(updatedViolation));
    successCallback(updatedViolation);
  };

export const removeDocumentFromViolationNotice =
  (violation: IViolation, successCallback: (violation: IViolation) => void) => async (dispatch: any) => {
    const updatedViolation = await violationApi.removeNoticeDocument(violation);
    toast.success("Document Removed!");
    dispatch(loadViolation(updatedViolation));
    successCallback(updatedViolation);
  };

export const addViolation =
  (violation: IViolation, successCallback: (violation: IViolation) => void) => async (dispatch: any) => {
    violationApi
      .addViolation(violation)
      .then((newViolation: IViolation) => {
        dispatch(loadViolation(newViolation));
        toast.success("Violation created!");
        successCallback(newViolation);
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
