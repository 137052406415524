import axios from "axios";
import { IReviewReminder } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/ReviewReminder`;

export const getReviewReminder = async (): Promise<IReviewReminder> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const updateReviewReminder = async (reviewReminder: IReviewReminder): Promise<IReviewReminder> => {
  const response = await axios.post(baseUrl, reviewReminder);
  return response.data;
};
