"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViolationStatus = void 0;
var ViolationStatus;
(function (ViolationStatus) {
    ViolationStatus["Active"] = "Active";
    ViolationStatus["InLitigation"] = "In Litigation";
    ViolationStatus["Resolved"] = "Resolved";
    ViolationStatus["Withdrawn"] = "Withdrawn";
    ViolationStatus["Inactive"] = "Inactive";
})(ViolationStatus = exports.ViolationStatus || (exports.ViolationStatus = {}));
