import React from "react";

const Footer = () => {
  return (
    <div className="container-fluid" style={{ bottom: "0", width: "100%" }}>
      <div className="row">
        <div className="col-lg text-center bg-light">
          Outdoor Advertising
          <br />
          Report technical problems to the&nbsp;Service Desk &nbsp;at 1-866-955-4357 (HELP) or e-mail:{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="mailto:FDOT.ServiceDesk@dot.state.fl.us?subject=Outdoor Advertising"
            title="Use this link to E-Mail concerns, problems, and/or comments">
            Service Desk
          </a>
          <br />
          <a
            target="_blank"
            style={{ textDecoration: "underline" }}
            rel="noopener noreferrer"
            title="Web Policies and Notices (Opens new browser window)"
            href="https://www.fdot.gov/agencyresources/webpoliciesandnotices.shtm">
            Web Policies and Notices
          </a>
          &nbsp; - &nbsp;
          <a
            target="_blank"
            style={{ textDecoration: "underline" }}
            rel="noopener noreferrer"
            href="https://www.fdot.gov/agencyresources/notices/accessibility.shtm"
            title="Accessibility Statement  (Opens new browser window)">
            Accessibility Statement
          </a>
          &nbsp; - &nbsp;
          <a
            target="_blank"
            style={{ textDecoration: "underline" }}
            rel="noopener noreferrer"
            href="https://www.myflorida.com"
            title="Accessibility Statement  (Opens new browser window)">
            My Florida
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
