import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccountCardRow, IAccountSearchCriteria } from "oda-shared";
import { SortDirection } from "react-data-grid";
import { getOdaAccountsCard } from "./../../apis/AccountApi";

type SliceState = {
  odaAccountCardResults: IAccountCardRow[];
  isLoadingResults: boolean;
  sortColumn: string;
  sortDirection: SortDirection;
  currentPageIndex: number;
  itemsPerPage: number;
  pageChanged: boolean;
  totalItemCount: number;
};

const odaAccountCardSlice = createSlice({
  name: "odaAccountCard",
  initialState: {
    currentPageIndex: 0,
    isLoadingResults: false,
    itemsPerPage: 10,
    odaAccountCardResults: [],
    pageChanged: false,
    sortColumn: "",
    sortDirection: "NONE" as any,
    totalItemCount: 0,
  } as SliceState,
  reducers: {
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoadingResults = action.payload;
      return state;
    },
    updateAccountResults: (state: SliceState, action: PayloadAction<IAccountCardRow[]>) => {
      state.odaAccountCardResults = action.payload;
      return state;
    },
    updateTotalItemCount: (state: SliceState, action: PayloadAction<number>) => {
      state.totalItemCount = action.payload;
      return state;
    },
    updateCurrentPageIndex: (state: SliceState, action: PayloadAction<number>) => {
      state.currentPageIndex = action.payload;
      return state;
    },
    updateItemsPerPage: (state: SliceState, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      return state;
    },
    updatePageChanged: (state: SliceState, action: PayloadAction<boolean>) => {
      state.pageChanged = action.payload;
      return state;
    },
    updateSortColumn: (state: SliceState, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
      return state;
    },
    updateSortDirection: (state: SliceState, action: PayloadAction<SortDirection>) => {
      state.sortDirection = action.payload;
      return state;
    },
  },
});

export const {
  updateIsLoading,
  updateAccountResults,
  updateTotalItemCount,
  updateCurrentPageIndex,
  updateItemsPerPage,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} = odaAccountCardSlice.actions;

export default odaAccountCardSlice.reducer;

export const getOdaAccounts = (criteria: IAccountSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  getOdaAccountsCard(criteria)
    .then((data: { totalCount: number; results: IAccountCardRow[] }) => {
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateAccountResults(data.results));
      dispatch(updateIsLoading(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(updateIsLoading(false));
    });
};
