"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermitStatus = void 0;
var PermitStatus;
(function (PermitStatus) {
    PermitStatus["Active"] = "Active";
    PermitStatus["Expired"] = "Expired";
    PermitStatus["Revoked"] = "Revoked";
    PermitStatus["NoticeOfIntentToRevoke"] = "Notice Of Intent To Revoke";
    PermitStatus["RequestCancellation"] = "Request Cancellation";
    PermitStatus["Canceled"] = "Cancelled";
    PermitStatus["RequestReinstatement"] = "Request Reinstatement";
    PermitStatus["AwaitingReinstatementPayment"] = "Awaiting Reinstatement Payment";
    PermitStatus["Void"] = "Void";
})(PermitStatus = exports.PermitStatus || (exports.PermitStatus = {}));
