"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenseStatus = void 0;
var LicenseStatus;
(function (LicenseStatus) {
    LicenseStatus["Saved"] = "Saved";
    LicenseStatus["Submitted"] = "Submitted";
    //InReview = "Under Review",
    LicenseStatus["Active"] = "Active";
    LicenseStatus["Returned"] = "Returned";
    LicenseStatus["Suspended"] = "Suspended";
    LicenseStatus["Expired"] = "Expired";
})(LicenseStatus = exports.LicenseStatus || (exports.LicenseStatus = {}));
