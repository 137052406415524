import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFinding, ITaskAssignment } from "oda-shared";
import * as findingApi from "../../apis/FindingApi";
import { toast } from "react-toastify";
import { loadFacing } from "./FacingSlice";

type SliceState = {
  currentFinding: IFinding;
  findingTasks: ITaskAssignment[];
};

const findingSlice = createSlice({
  name: "finding",
  initialState: {} as SliceState,
  reducers: {
    loadFinding: (state: SliceState, action: PayloadAction<IFinding>) => {
      state.currentFinding = action.payload;
      if (action.payload?.tasks){
        state.findingTasks = action.payload?.tasks;
      }
      else{
        state.findingTasks = [];
      }
      
    },    
    setFindingTasks: (state: SliceState, action: PayloadAction) =>{
      state.findingTasks = state.currentFinding?.tasks? [...state.currentFinding?.tasks] : [];
    }
  },
});

export const { loadFinding, setFindingTasks } = findingSlice.actions;

export default findingSlice.reducer;

export const getFinding = (findingId: string, successCallback?: () => void) => async (dispatch: any) => {
  findingApi
    .getFinding(findingId)
    .then((finding: IFinding) => {
      dispatch(loadFinding(finding));      
      if (successCallback) {
        successCallback();
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateFinding = (finding: IFinding) => async (dispatch: any) => {
  const updatedFacing = await findingApi.updateFinding(finding);
  toast.success("Facing Saved!");
  if (updatedFacing && updatedFacing.findings && updatedFacing.findings.length > 0) {
    dispatch(loadFinding(updatedFacing.findings[updatedFacing.findings.length - 1]));
  }
  dispatch(loadFacing(updatedFacing));
};

export const deleteFinding = (findingId: string, successCallback?: () => void) => async (dispatch: any) => {
  await findingApi.deleteFinding(findingId);
  toast.success("Finding Deleted!");
  dispatch(loadFinding({} as any));
  if (successCallback) {
    successCallback();
  }
};
