"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbeddedReports = void 0;
var EmbeddedReports;
(function (EmbeddedReports) {
    EmbeddedReports["ActiveAccounts"] = "ActiveAccounts";
    EmbeddedReports["ActivePermitCounts"] = "ActivePermitCounts";
    EmbeddedReports["SectionInventoryProgress"] = "SectionInventoryProgress";
    EmbeddedReports["PaidInvoiceLineItems"] = "PaidInvoiceLineItems";
    EmbeddedReports["InvoicePaymentExceptionsReport"] = "InvoicePaymentExceptionsReport";
    EmbeddedReports["AccountsWithUnallocatedLines"] = "AccountsWithUnallocatedLines";
    EmbeddedReports["AccountsWithUnpaidInvoices"] = "AccountsWithUnpaidInvoices";
    EmbeddedReports["ODAAccountingDeposits"] = "ODAAccountingDeposits";
    EmbeddedReports["InactivePermitsNoRemovalDate"] = "InactivePermitsNoRemovalDate";
    EmbeddedReports["PermitsBySection"] = "PermitsBySection";
    EmbeddedReports["PermitsIssuedIn90Days"] = "PermitsIssuedIn90Days";
    EmbeddedReports["ApplicationStatus"] = "ApplicationStatus";
    EmbeddedReports["MovedFacings"] = "MovedFacings";
    EmbeddedReports["MultipleFacingsOneSign"] = "MultipleFacingsOneSign";
    EmbeddedReports["MovedStructures"] = "MovedStructures";
    EmbeddedReports["IllegalSignsList"] = "IllegalSignsList";
    EmbeddedReports["IllegalSignsStatusSummary"] = "IllegalSignsStatusSummary";
    EmbeddedReports["IllegalSignsResolutionsSummary"] = "IllegalSignsResolutionsSummary";
    EmbeddedReports["IllegalSignsRemovalsSummary"] = "IllegalSignsRemovalsSummary";
    EmbeddedReports["IllegalSignsActivityByStatus"] = "IllegalSignsActivityByStatus";
    EmbeddedReports["IllegalSignsCostRecovery"] = "IllegalSignsCostRecovery";
    EmbeddedReports["ViolationsQualityControlReport"] = "ViolationsQualityControlReport";
    EmbeddedReports["ApplicationDenied"] = "ApplicationDenied";
    EmbeddedReports["ApplicationReturned"] = "ApplicationReturned";
    EmbeddedReports["PermitNotRequired"] = "PermitNotRequired";
    EmbeddedReports["ApplicatinUnderReview"] = "ApplicatinUnderReview";
    EmbeddedReports["TopAccounts"] = "TopAccounts";
    EmbeddedReports["PermitsForAccount"] = "PermitsForAccount";
    EmbeddedReports["PermitActivityByAccount"] = "PermitActivityByAccount";
    EmbeddedReports["AccountInformation"] = "AccountInformation";
    EmbeddedReports["RegionCountyReport"] = "RegionCountyReport";
    EmbeddedReports["SectionsByCounty"] = "SectionsByCounty";
    EmbeddedReports["StructureCountsByCounty"] = "StructureCountsByCounty";
    EmbeddedReports["Licensees"] = "Licensees";
    EmbeddedReports["SignDetails"] = "SignDetails";
    EmbeddedReports["TransferredPermits"] = "TransferredPermits";
    EmbeddedReports["StructuresOnSection"] = "StructuresOnSection";
    EmbeddedReports["SummaryReport"] = "SummaryReport";
})(EmbeddedReports = exports.EmbeddedReports || (exports.EmbeddedReports = {}));
