"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Direction = void 0;
var Direction;
(function (Direction) {
    Direction["North"] = "N";
    Direction["East"] = "E";
    Direction["West"] = "W";
    Direction["South"] = "S";
})(Direction = exports.Direction || (exports.Direction = {}));
