"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermitApplicationStatus = void 0;
var PermitApplicationStatus;
(function (PermitApplicationStatus) {
    PermitApplicationStatus["Saved"] = "Saved";
    PermitApplicationStatus["Received"] = "Received";
    PermitApplicationStatus["UnderReview"] = "Under Review";
    PermitApplicationStatus["ReturnedIncomplete"] = "Returned Incomplete";
    PermitApplicationStatus["ApplicationPending"] = "Application Pending";
    PermitApplicationStatus["Approved"] = "Approved";
    PermitApplicationStatus["ApprovedPendingPayment"] = "Approved - Pending Payment";
    PermitApplicationStatus["Denied"] = "Denied";
    PermitApplicationStatus["DeniedPendingCorrections"] = "Denied - Pending Corrections";
    PermitApplicationStatus["LitigationStatus"] = "In Litigation";
    PermitApplicationStatus["PermitNotRequired"] = "Permit Not Required";
    PermitApplicationStatus["Withdrawn"] = "Adminstratively Withdrawn";
})(PermitApplicationStatus = exports.PermitApplicationStatus || (exports.PermitApplicationStatus = {}));
