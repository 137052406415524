"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HearingCaseStatus = void 0;
var HearingCaseStatus;
(function (HearingCaseStatus) {
    HearingCaseStatus["DistrictCourtOfAppealsReferral"] = "District Court of Appeals Referral";
    HearingCaseStatus["DistrictCourtOfAppealsDecisionIssuedOverturnedFinalOrder"] = "District Court of Appeals Decision Issued \u2013 Overturned Final Order";
    HearingCaseStatus["DistrictCourtOfAppealsDecisionIssuedUpheldFinalOrder"] = "District Court of Appeals Decision Issued \u2013 Upheld Final Order";
    HearingCaseStatus["FinalOrderIssued"] = "Final Order Issued";
    HearingCaseStatus["HearingRequestReceived"] = "Hearing Request Received";
    HearingCaseStatus["OrderOfDismissalIssued"] = "Order of Dismissal Issued";
    HearingCaseStatus["SubsequentLitigationOverturnedFinalOrder"] = "Subsequent Litigation - Overturned Final Order";
    HearingCaseStatus["SubsequentLitigationUpheldFinalOrder"] = "Subsequent Litigation - Upheld Final Order";
    HearingCaseStatus["ClearkOrderOfDismissal"] = "Clerk\u2019s Order of Dismissal";
    //DepartmentWithdrewViolation = "Department Withdrew Violation",
    //PetitionerWithdrewHearingRequest = "Petitioner Withdrew Hearing Request",
    //InformalHearingScheduled = "Informal Hearing Scheduled",
    //RecommendedOrderIssued = "Recommended Order Issued",
    //Abatement = "Abatement",
    //ExceptionsFiled = "Exceptions Filed",
    //DOAHRelinquishedJurisdictionBackToFDOT = "DOAH Relinquished Jurisdiction Back to FDOT",
    //InformalHearingCompleted = "Informal Hearing Completed",
    //SubmittedRecommendedOrder = "Submitted Recommended Order",
    //FinalOrderSupported = "Final Order Supported",
})(HearingCaseStatus = exports.HearingCaseStatus || (exports.HearingCaseStatus = {}));
