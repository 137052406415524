"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeStatus = void 0;
var NoticeStatus;
(function (NoticeStatus) {
    NoticeStatus["CanceledOrExpiredTagCostLetterIssued"] = "Canceled or Expired Tag Cost Letter Issued";
    NoticeStatus["Collections"] = "Collections";
    NoticeStatus["ContractorInvoicePaid"] = "Contractor Invoice Paid";
    NoticeStatus["DeliveryConfirmationReceived"] = "Delivery Confirmation Received";
    NoticeStatus["FieldConsultant"] = "Field Consultant";
    NoticeStatus["FinalDemandBeforeCollections"] = "Final Demand Before Collections";
    NoticeStatus["InitialReportOfViolation"] = "Initial Report of Violation";
    NoticeStatus["InLitigation"] = "In Litigation";
    NoticeStatus["IssueWorkOrder"] = "Issue Work Order";
    NoticeStatus["LetterOfConcernIssued"] = "Letter of Concern Issued";
    NoticeStatus["NewJurisdictionNoticeIssued"] = "New Jurisdiction Notice Issued";
    NoticeStatus["NewJurisdictionSecondNoticeIssued"] = "New Jurisdiction Second Notice Issued";
    NoticeStatus["NoticePosted"] = "Notice Posted";
    NoticeStatus["NoticePosted10Day"] = "Notice Posted - 10 Day";
    NoticeStatus["NovCostLetterIssued"] = "NOV Cost Letter Issued";
    NoticeStatus["Nov10DayLetterIssued"] = "NOV 10 Day Letter Issued";
    NoticeStatus["OnHold"] = "On Hold";
    NoticeStatus["Paid"] = "Paid";
    NoticeStatus["PermitApplicationReceived"] = "Permit Application Received";
    NoticeStatus["Received"] = "Received";
    NoticeStatus["RemovalOfRemainingStructureRequired"] = "Removal of Remaining Structure Required";
    NoticeStatus["RemovalPackageUnderReview"] = "Removal Package Under Review";
    NoticeStatus["ResolutionRecommendationByInspector"] = "Resolution Recommendation by Inspector";
    NoticeStatus["Resolved"] = "Resolved";
    NoticeStatus["RevokedRemovalCostLetterIssued"] = "Revoked Removal Cost Letter Issued";
    NoticeStatus["SentToRegional"] = "Sent to Regional";
    NoticeStatus["SupplementalRequestCompleted"] = "Supplemental Request Completed";
    NoticeStatus["SupplementalRequestToRegionalInspector"] = "Supplemental Request to Regional Inspector";
    NoticeStatus["WarningPosted"] = "Warning Posted";
    NoticeStatus["WarningPostedCostLetter"] = "Warning Posted Cost Letter";
    NoticeStatus["WorkOrderComplete"] = "Work Order Complete";
    NoticeStatus["Uncollected"] = "Uncollected";
})(NoticeStatus = exports.NoticeStatus || (exports.NoticeStatus = {}));
