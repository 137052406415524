import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInspectionRegion, ISection, IFacing, IFacingRow } from "oda-shared";
import * as sectionApi from "../../apis/SectionApi";
import { toast } from "react-toastify";
import { SortDirection } from "react-data-grid";

type SliceState = {
  currentSection: ISection;
  sections: ISection[];
  allSections: ISection[];
  sectionsNotInventoried: ISection[];
  facings: IFacing[];
  facingRows: IFacingRow[];
  sortDirection: SortDirection;
  sortColumn: string;
};

const sectionSlice = createSlice({
  name: "section",
  initialState: {
    sortDirection: "NONE" as any,
    sortColumn: "",
  } as SliceState,
  reducers: {
    setCurrentSection: (state: SliceState, action: PayloadAction<ISection>) => {
      state.currentSection = action.payload;
    },
    loadSections: (state: SliceState, action: PayloadAction<ISection[]>) => {
      state.sections = action.payload;
    },
    loadAllSections: (state: SliceState, action: PayloadAction<ISection[]>) => {
      state.allSections = action.payload;
    },
    loadSectionsNotInventoried: (state: SliceState, action: PayloadAction<ISection[]>) => {
      state.sectionsNotInventoried = action.payload;
    },
    loadFacings: (state: SliceState, action: PayloadAction<IFacing[]>) => {
      state.facings = action.payload;
    },
    loadFacingRows: (state: SliceState, action: PayloadAction<IFacingRow[]>) => {
      state.facingRows = action.payload;
    },
    updateSortDirection: (state: SliceState, action: PayloadAction<SortDirection>) => {
      state.sortDirection = action.payload;
      return state;
    },
    updateSortColumn: (state: SliceState, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
      return state;
    },
  },
});

export const {
  setCurrentSection,
  loadSections,
  loadSectionsNotInventoried,
  loadFacings,
  loadFacingRows,
  loadAllSections,
  updateSortDirection,
  updateSortColumn,
} = sectionSlice.actions;

export default sectionSlice.reducer;

export const loadSection = (id: string) => async (dispatch: any) => {
  let section: ISection;
  const parsed = parseInt(id);

  if (Number.isInteger(parsed) && (id.length === 7 || id.length === 8)) {
    section = await sectionApi.getSectionByNumber(id);
  } else {
    section = await sectionApi.getSection(id);
  }

  dispatch(setCurrentSection(section));
};

export const getSections = () => async (dispatch: any) => {
  sectionApi
    .getSections()
    .then((sections: ISection[]) => {
      dispatch(loadSections(sections));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getAllSections = () => async (dispatch: any) => {
  sectionApi
    .getAllSections()
    .then((sections: ISection[]) => {
      dispatch(loadAllSections(sections));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getSectionsNotInventoried = (sinceDate: Date | null) => async (dispatch: any) => {
  sectionApi
    .getSectionsNotInventoried(sinceDate)
    .then((sections: ISection[]) => {
      dispatch(loadSectionsNotInventoried(sections));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const searchSections = (regionId: string, county: string, notPatrolledYear: string) => async (dispatch: any) => {
  //export const searchSections = (regionId: string, county: string) => async (dispatch: any) => {
  sectionApi
    .searchSections(regionId, county, notPatrolledYear)
    //.searchSections(regionId, county)
    .then((sections: ISection[]) => {
      dispatch(loadSections(sections));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const saveSection =
  (section: ISection, region: IInspectionRegion, successCallback?: (id: string) => void) => async (dispatch: any) => {
    if (!region) {
      toast.error("Region is required");
    }
    if (section._id) {
      sectionApi
        .updateSection(section, region)
        .then((section: ISection) => {
          dispatch(setCurrentSection(section));
          toast.success("Section saved!");
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    } else {
      sectionApi
        .createSection(section, region)
        .then((section: ISection) => {
          dispatch(setCurrentSection(section));
          toast.success("Section created!");
          if (successCallback) {
            successCallback(section._id.toString());
          }
        })
        .catch((error: any) => {
          console.log(error);
          if (error.response.status === 422) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

export const getFacingsForSection = (rciSection: string) => async (dispatch: any) => {
  sectionApi
    .getFacingsForSection(rciSection)
    .then((facings: IFacing[]) => {
      dispatch(loadFacings(facings));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const SearchFacings = (rciSection: string, showOnlyActive: boolean) => async (dispatch: any) => {
  sectionApi
    .searchFacings(rciSection, showOnlyActive)
    .then((facings: IFacingRow[]) => {
      dispatch(loadFacingRows(facings));
    })
    .catch((error: any) => {
      console.log(error);
    });
};
