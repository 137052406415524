"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTitleCase = exports.isNullOrWhitespace = void 0;
exports.isNullOrWhitespace = function (subject) {
    return subject === null || subject === undefined || subject.trim() === "";
};
exports.toTitleCase = function (val) {
    if (!val)
        return val;
    return val.split(' ')
        .map(function (w) { return w[0].toUpperCase() + w.substring(1).toLowerCase(); })
        .join(' ');
};
