import axios from "axios";
import { ITaskAssignment } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/taskAssignment`;

export const getNoticeTaskAssignments = async (id: string): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/notice/${id}`);
  return response.data;
};

export const getIllegalSignTaskAssignments = async (id: string): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/illegalSign/${id}`);
  return response.data;
};

export const getFindingTaskAssignments = async (id: string): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/finding/${id}`);
  return response.data;
};

export const getPermitTaskAssignments = async (id: string): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/permit/${id}`);
  return response.data;
};

export const getTaskAssignments = async (criteria: any): Promise<ITaskAssignment[]> => {
  const response = await axios.post(`${baseUrl}/search`, criteria);
  return response.data;
};

export const getTaskAssignment = async (id: string): Promise<ITaskAssignment> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const getViolationTaskAssignments = async (id: string): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/violation/${id}`);
  return response.data;
};

export const getLitigationTaskAssignments = async (id: string): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/litigation/${id}`);
  return response.data;
};

export const addTaskAssignment = async (taskAssignment: ITaskAssignment) => {
  const response = await axios.post(`${baseUrl}`, taskAssignment);
  return response.data;
};

export const updateTaskAssignment = async (taskAssignment: ITaskAssignment) => {
  const response = await axios.patch(`${baseUrl}/${taskAssignment._id}`, taskAssignment);
  return response.data;
};

export const deleteTask = async (task: ITaskAssignment) => {
  const response = await axios.delete(`${baseUrl}/${task._id}`);
  return response.data;
};
