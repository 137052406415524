import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOdaAccount,
  IAccountIdMap,
  IAccountUser,
  AccountRole,
  AccountUserStatus,
  IPermitApplication,
  IDocument,
  IViolation,
  IAccountLineItemRow,
} from "oda-shared";
import * as accountApi from "./../../apis/AccountApi";
import { toast } from "react-toastify";

export interface IAccountLineItemType {
  rows: IAccountLineItemRow[];
  total: { totalRenewalAmount: number; totalLateFees: number; totalReinFees: number; grandTotal: number };
}

type AccountState = {
  currentAccount: IOdaAccount;
  accountList: Array<IOdaAccount>;
  accountIdMaps: Array<IAccountIdMap>;
  currentAccountDocuments: Array<IDocument>;
  currentAccountDocumentsCount: number;
  currentAccountPermitApplications: Array<IPermitApplication>;
  currentAccountPermitApplicationsCount: number;
  status: "idle" | "working" | "load succeeded" | "create succeeded" | "update succeeded" | "failed";
  error: string | null;
  accountViolations: IViolation[];
  accountLineItems: IAccountLineItemType;
};

const odaAccountSlice = createSlice({
  name: "odaAccount",
  initialState: {
    status: "idle",
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    accountList: new Array(),
    currentAccountDocuments: new Array(),
    currentAccountPermitApplications: new Array(),
    currentAccountDocumentsCount: 0,
    currentAccountPermitApplicationsCount: 0,
    accountEmails: [],
    accountViolations: [],
    accountLineItems: { rows: [], total: { totalRenewalAmount: 0, totalLateFees: 0, totalReinFees: 0, grandTotal: 0 } },
  } as unknown as AccountState,
  reducers: {
    resetAccountState: (state: AccountState) => {
      state.error = "";
      state.status = "idle";
    },
    setError: (state: AccountState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setCurrentAccountPermitApplicationsCount: (state: AccountState, action: PayloadAction<number>) => {
      state.currentAccountPermitApplicationsCount = action.payload;
    },
    setCurrentAccountDocumentsCount: (state: AccountState, action: PayloadAction<number>) => {
      state.currentAccountDocumentsCount = action.payload;
    },
    setStatus: (
      state: AccountState,
      action: PayloadAction<"idle" | "working" | "load succeeded" | "create succeeded" | "update succeeded" | "failed">,
    ) => {
      if (action.payload !== "failed") {
        state.error = "";
      }
      state.status = action.payload;
    },
    storeAccount: (state: AccountState, action: PayloadAction<IOdaAccount>) => {
      state.currentAccount = action.payload;
      state.status = "load succeeded";
      state.currentAccountDocuments = [];
      state.currentAccountPermitApplications = [];
      state.currentAccountPermitApplicationsCount = 0;
      state.currentAccountDocumentsCount = 0;
    },
    setCurrent: (state: AccountState, action: PayloadAction<IOdaAccount>) => {
      state.currentAccount = action.payload;
      state.status = "load succeeded";
      state.currentAccountDocuments = [];
      state.currentAccountPermitApplications = [];
      state.currentAccountPermitApplicationsCount = 0;
      state.currentAccountDocumentsCount = 0;
    },
    storeCurrentAccountDocuments: (state: AccountState, action: PayloadAction<IDocument[]>) => {
      state.currentAccountDocuments = action.payload;
      state.status = "load succeeded";
    },
    storeCurrentAccountPermitApplications: (state: AccountState, action: PayloadAction<IPermitApplication[]>) => {
      state.currentAccountPermitApplications = action.payload;
      state.status = "load succeeded";
    },
    pushUserToAccount: (state: AccountState, action: PayloadAction<IAccountUser>) => {
      const accountUserIndex = state.currentAccount.accountUsers.findIndex(a => {
        return a._id === action.payload._id;
      });

      if (accountUserIndex > -1) {
        state.currentAccount.accountUsers[accountUserIndex] = action.payload;
      } else {
        state.currentAccount.accountUsers.push(action.payload);
      }
    },
    inactivateUserFromAccount: (state: AccountState, action: PayloadAction<string>) => {
      const accountUser = state.currentAccount.accountUsers.find(a => {
        return a._id === action.payload;
      });

      if (accountUser) {
        accountUser.status = AccountUserStatus.Inactivated;
      }
    },
    updateUserAccount: (state: AccountState, action: PayloadAction<IAccountUser>) => {
      const index = state.currentAccount.accountUsers.findIndex(a => {
        return a._id === action.payload._id;
      });

      if (index > -1) {
        state.currentAccount.accountUsers[index] = action.payload;
      }
    },
    storeAccountList: (state: AccountState, action: PayloadAction<Array<IOdaAccount>>) => {
      state.accountList = action.payload;
    },
    storeAccountMaps: (state: AccountState, action: PayloadAction<Array<IAccountIdMap>>) => {
      state.accountIdMaps = action.payload;
    },
    storeAccountViolations: (state: AccountState, action: PayloadAction<IViolation[]>) => {
      state.accountViolations = action.payload;
    },
    storeAccountLineItems: (state: AccountState, action: PayloadAction<IAccountLineItemType>) => {
      state.accountLineItems = action.payload;
    },
  },
});

export const {
  storeAccount,
  storeAccountList,
  resetAccountState,
  setStatus,
  setError,
  storeAccountMaps,
  pushUserToAccount,
  inactivateUserFromAccount,
  updateUserAccount,
  storeCurrentAccountDocuments,
  storeCurrentAccountPermitApplications,
  setCurrentAccountPermitApplicationsCount,
  setCurrentAccountDocumentsCount,
  storeAccountViolations,
  storeAccountLineItems,
} = odaAccountSlice.actions;

export default odaAccountSlice.reducer;

export const getAccountViolations = (id: string) => async (dispatch: any) => {
  accountApi
    .getAccountViolationsApi(id)
    .then((violations: IViolation[]) => {
      dispatch(storeAccountViolations(violations));
    })
    .catch((error: any) => {
      dispatch(setError(error));
      console.log(error);
    });
};

export const getAccountLineItems = (id: string) => async (dispatch: any) => {
  accountApi
    .getAccountLineItemsApi(id)
    .then((lineItems: IAccountLineItemType) => {
      dispatch(storeAccountLineItems(lineItems));
    })
    .catch((error: any) => {
      dispatch(setError(error));
      console.log(error);
    });
};

export const getAccountsForUser = () => async (dispatch: any) => {
  accountApi
    .getAccountsForUser()
    .then((accountList: Array<IOdaAccount>) => {
      dispatch(storeAccountList(accountList));
    })
    .catch((error: any) => {
      dispatch(setError(error));
      console.log(error);
    });
};

export const getAllAccounts = (filters: any) => async (dispatch: any) => {
  accountApi
    .getAllAccounts(filters)
    .then((accountList: Array<IOdaAccount>) => {
      dispatch(storeAccountList(accountList));
    })
    .catch((error: any) => {
      dispatch(setError(error));
      console.log(error);
    });
};

export const getAccountIdMaps = () => async (dispatch: any) => {
  accountApi
    .getAccountIdMaps()
    .then((accountMaps: Array<IAccountIdMap>) => {
      dispatch(storeAccountMaps(accountMaps));
    })
    .catch((error: any) => {
      dispatch(setError(error));
      console.log(error);
    });
};

export const getAccountIdMapsForUser = () => async (dispatch: any) => {
  accountApi
    .getAccountIdMapsForUser()
    .then((accountMaps: Array<IAccountIdMap>) => {
      dispatch(storeAccountMaps(accountMaps));
    })
    .catch((error: any) => {
      dispatch(setError(error));
      console.log(error);
    });
};

export const getAccount = (id: string, isPublic?: boolean) => async (dispatch: any) => {
  dispatch(setStatus("working"));
  accountApi
    .getAccount(id, isPublic)
    .then((account: IOdaAccount) => {
      dispatch(storeAccount(account));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(setError(error));
      dispatch(setStatus("failed"));
    });
};

export const getPermitApplications =
  (id: string, startIndex: number, limit: number, filter?: any, sort?: any) => async (dispatch: any) => {
    dispatch(setStatus("working"));
    accountApi
      .getAccountPermitApplications(id, startIndex, limit, filter, sort)
      .then((result: any) => {
        dispatch(storeCurrentAccountPermitApplications(result.results));
        dispatch(setCurrentAccountPermitApplicationsCount(result.count));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(setError(error));
        dispatch(setStatus("failed"));
      });
  };

export const getDocuments =
  (id: string, startIndex: number, limit: number, filter?: any, sort?: any) => async (dispatch: any) => {
    dispatch(setStatus("working"));
    accountApi
      .getAccountDocuments(id, startIndex, limit, filter, sort)
      .then((result: any) => {
        dispatch(storeCurrentAccountDocuments(result.results));
        dispatch(setCurrentAccountDocumentsCount(result.count));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(setError(error));
        dispatch(setStatus("failed"));
      });
  };

export const saveAccountUserRequest = (user: IAccountUser) => async (dispatch: any) => {
  dispatch(setStatus("working"));
  accountApi
    .requestAccountAccess(user)
    .then(results => {
      if (results.type === "success") {
        toast.success(results.message);
      } else {
        toast.error("message");
      }
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(setError(error));
      dispatch(setStatus("failed"));

      if (error.request.status === 422) {
        toast.error(error.response.data);
      } else {
        toast.error("Problem with user account request save.");
      }
    });
};

export const addUserToAccount = (accountId: string, userId: string, role: AccountRole) => async (dispatch: any) => {
  const accountRole = await accountApi.addUserToAccount(accountId, userId, role);
  dispatch(pushUserToAccount(accountRole));
  toast.success("User added to account");
};

export const inactivateUserFromOdaAccount = (accountUserId: string) => async (dispatch: any) => {
  await accountApi.inactivateUserFromAccount(accountUserId);
  dispatch(inactivateUserFromAccount(accountUserId));
  toast.success("User removed from account");
};

export const changeUserAccountStatus = (accountUserId: string, status: AccountUserStatus) => async (dispatch: any) => {
  const userAccount = await accountApi.changeUserAccountStatus(accountUserId, status);
  dispatch(updateUserAccount(userAccount));
  toast.success(`User status changed to ${status}`);
};

export const suspendLicense = (accountId: string) => async (dispatch: any) => {
  const account = await accountApi.suspendLicense(accountId);
  dispatch(storeAccount(account));
  toast.success(`License has been suspended`);
};

//please note the ID here is the guid, NOT the account number.
export const saveAccount =
  (account: IOdaAccount, successCallback?: (account?: IOdaAccount) => void) => async (dispatch: any) => {
    dispatch(setStatus("working"));
    if (account._id) {
      accountApi
        .updateAccount(account)
        .then((account: IOdaAccount) => {
          dispatch(storeAccount(account));
          getAllAccounts({});
          toast.success("Account saved.");
          console.log("successCallback", successCallback);
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(setError(error));
          dispatch(setStatus("failed"));
          toast.error("Problem with account save.");
        });
    } else {
      dispatch(setStatus("working"));
      accountApi
        .newAccount(account)
        .then((account: IOdaAccount) => {
          dispatch(storeAccount(account));
          getAllAccounts({});
          toast.success("Account created.");
          console.log("successCallback", successCallback);
          if (successCallback) {
            successCallback(account);
          }
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(setError(error));
          dispatch(setStatus("failed"));

          if (error.request.status === 422) {
            alert(error.response.data.message);
          } else {
            toast.error("Problem with user account request save.");
          }
        });
    }
  };
