"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatus = void 0;
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Initiated"] = "Online - Initiated";
    PaymentStatus["Successful"] = "Online - Success";
    PaymentStatus["Failed"] = "Online - Failed";
    PaymentStatus["Duplicate"] = "Online - Duplicate";
    PaymentStatus["Canceled"] = "Online - Cancelled";
    PaymentStatus["Received"] = "Manual - Received";
    PaymentStatus["Deposited"] = "Manual - Deposited";
    PaymentStatus["Returned"] = "Manual - Returned";
    PaymentStatus["OnlineRefunded"] = "Online - Refunded";
    PaymentStatus["OnlineRefundPrepared"] = "Online - Refund Prepared";
    PaymentStatus["ManualRefunded"] = "Manual - Refunded";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
