import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import SectionSearchCriteria from "./SectionSearchCriteria";
import SectionSearchResults from "./SectionSearchResults";

const SectionSearch: React.FC = () => {
  const results = useSelector((state: RootState) => state.searchReducer.sectionResults);

  return (
    <>
      <SectionSearchCriteria></SectionSearchCriteria>
      <SectionSearchResults results={results}></SectionSearchResults>
    </>
  );
};

export default SectionSearch;
