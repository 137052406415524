import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPermitCancellationRequest, IPermitTransferRequest } from "oda-shared";
import * as permitApi from "../../apis/PermitApi";
import { toast } from "react-toastify";

type SliceState = {
  transferRequests: Record<string, IPermitTransferRequest>;
  currentRequest?: IPermitTransferRequest;
  currentCancellationRequest?: IPermitCancellationRequest;
};

const permitTransferRequestSlice = createSlice({
  name: "transferRequests",
  initialState: {
    transferRequests: {},
    searchResults: [],
    isLoadingSearchResults: false,
  } as SliceState,
  reducers: {
    setCurrentRequest: (state: SliceState, action: PayloadAction<IPermitTransferRequest>) => {
      state.currentRequest = action.payload;
    },

    loadTransferRequest: (state: SliceState, action: PayloadAction<IPermitTransferRequest>) => {
      state.transferRequests[action.payload._id] = action.payload;
      state.currentRequest = action.payload;
    },

    setCurrentCancellationRequest: (state: SliceState, action: PayloadAction<IPermitCancellationRequest>) => {
      state.currentCancellationRequest = action.payload;
    },
  },
});

export const { setCurrentRequest, loadTransferRequest, setCurrentCancellationRequest } =
  permitTransferRequestSlice.actions;

export default permitTransferRequestSlice.reducer;

export const addNewPermitTransferRequest =
  (permitIds: [string], odaAccount: string, successCallback?: (id: string) => void) => async (dispatch: any) => {
    const newRequest = await permitApi.createPermitTransferRequest(permitIds, odaAccount);
    toast.success("Transfers Created for review!");
    dispatch(setCurrentRequest(newRequest));
    if (successCallback) {
      successCallback(newRequest._id);
    }
  };

export const getPermitTransferRequest = (requestId: string) => async (dispatch: any) => {
  permitApi
    .getPermitTransferRequest(requestId)
    .then((request: IPermitTransferRequest) => {
      dispatch(setCurrentRequest(request));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updatePermitTransferRequest = (transferRequest: IPermitTransferRequest) => async (dispatch: any) => {
  const request = await permitApi.updatePermitTransferRequest(transferRequest);
  toast.success("Transfer Request Updated!");
  dispatch(setCurrentRequest(request));
};

export const addNewPermitCancellationRequest =
  (request: IPermitCancellationRequest, permitIds: [string]) => async (dispatch: any) => {
    await permitApi.createPermitCancellationRequest(request, permitIds);
    toast.success("Cancellation Request Created!");
  };

export const getPermitCancellationRequest = (requestId: string) => async (dispatch: any) => {
  permitApi
    .getPermitCancellationRequest(requestId)
    .then((request: IPermitCancellationRequest) => {
      dispatch(setCurrentCancellationRequest(request));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateStatusOfPermitCancellationRequest =
  (cancellationRequest: IPermitCancellationRequest) => async (dispatch: any) => {
    permitApi
      .updateStatusOfPermitCancellationRequest(cancellationRequest)
      .then((request: IPermitCancellationRequest) => {
        dispatch(setCurrentCancellationRequest(request));
      });
    toast.success("Cancellation Request Updated!");
  };
