import axios from "axios";
import { IShoppingCart, IShoppingCartItem } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/shopping-cart`;

export const getShoppingCart = async (): Promise<IShoppingCart> => {
  const response = await axios.get(baseUrl);
  return response.data?.shoppingCart;
};

export const updateItem = async (item: IShoppingCartItem) => {
  const response = await axios.put(`${baseUrl}/items/${item._id}`, item);
  return response.data?.item;
};

export const addItem = async (item: IShoppingCartItem) => {
  console.log("addItem called");
  const response = await axios.post(`${baseUrl}/items`, item);
  return response.data?.item;
};

export const deleteItem = async (id: string) => {
  const response = await axios.delete(`${baseUrl}/items/${id}`);
  return response.data?.item;
};
