"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllegalSignMaterial = void 0;
var IllegalSignMaterial;
(function (IllegalSignMaterial) {
    IllegalSignMaterial["Banner"] = "Banner";
    IllegalSignMaterial["Masonry"] = "Masonry";
    IllegalSignMaterial["Metal"] = "Metal";
    IllegalSignMaterial["Monopole"] = "Monopole";
    IllegalSignMaterial["PaintOverSign"] = "Paint over Sign";
    IllegalSignMaterial["TowingOfATrailerHitchBallRequired"] = "Towing of a Trailer Hitch Ball Required";
    IllegalSignMaterial["TowingOfATrailerFifthWheelGooseneckHitchOrSemiTractorTrailerRequired"] = "Towing of a Trailer Fifth Wheel Gooseneck Hitch or Semi Tractor Trailer Required";
    IllegalSignMaterial["Unknown"] = "Unknown";
    IllegalSignMaterial["WoodOrVinyl"] = "Wood or Vinyl";
})(IllegalSignMaterial = exports.IllegalSignMaterial || (exports.IllegalSignMaterial = {}));
