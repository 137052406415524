"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeSearchType = void 0;
var NoticeSearchType;
(function (NoticeSearchType) {
    NoticeSearchType["NoticeNumber"] = "Notice Number";
    NoticeSearchType["Compliance"] = "Compliance";
    NoticeSearchType["Invoice"] = "Invoice";
    NoticeSearchType["Check"] = "Check";
})(NoticeSearchType = exports.NoticeSearchType || (exports.NoticeSearchType = {}));
