import axios from "axios";
import { IDropDownConfiguration } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/drop-down-configuration`;

export const getDropDownConfigs = async (): Promise<IDropDownConfiguration[]> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const updateDropDownConfigsOptions = async (
  dropDownConfigs: IDropDownConfiguration[],
): Promise<IDropDownConfiguration[]> => {
  const response = await axios.put(`${baseUrl}/group/${dropDownConfigs[0].groupName}`, dropDownConfigs);
  return response.data;
};
