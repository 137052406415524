import { ILitigationRow } from "oda-shared";
import { WorkSheet } from "xlsx/types";
import { ExcelExportService } from "../services/ExcelExportService";
import { inputFieldDateFormat } from "./DateUtils";

export const exportToExcel = (data: Array<any>, headers: Array<string>, excelFileName: string): void => {
  const excelExportService = new ExcelExportService();

  excelExportService.export(
    data,
    (worksheet: WorkSheet, complete: (worksheet: WorkSheet, excelFileName: string) => void) => {
      for (let i = 0; i < headers.length; i++) {
        const digit = i / 26;
        const modulo = i % 26;
        let columnIndex =
          digit >= 1
            ? Math.floor(digit + 9)
                .toString(36)
                .toUpperCase()
            : "";
        columnIndex += (modulo + 10).toString(36).toUpperCase();
        columnIndex += "1";
        worksheet[columnIndex].v = headers[i];
      }
      complete(worksheet, excelFileName);
    },
  );
};

export const caseManagementExcelData = (data: ILitigationRow[]) => {
  return data.map(x => {
    return {
      caseNumber: x.caseNumber,
      attorney: x.attorney,
      petitioner: x.petitioner,
      status: x.status,
      statusDate: inputFieldDateFormat(x.statusDate),
      resolution: x.resolution,
      deptPrevailed: x.departmentPrevailed,
    };
  });
};
