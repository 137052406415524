import React, { CSSProperties } from "react";
import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IButtonWithIconProps {
  /** The Bootstrap Class for the button */
  buttonType:
    | "primary"
    | "secondary"
    | "danger"
    | "success"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-danger"
    | "outline-success";
  icon: IconName;  
  buttonText?: string;
  titleText?: string;
  onClick?: () => void;
  additionalClass?: string;  
  disabled?: boolean;
  buttonAction?: "submit" | "button" | "reset";
  notTabAccessible?: boolean;
}

/** Uses font-awesome icons in conjunction with an Bootstrap button */
export const ButtonWithIcon: React.FC<IButtonWithIconProps> = (props: IButtonWithIconProps) => {
  const style: CSSProperties = {
    marginRight: props.buttonText !== undefined ? "10px" : "0px",
  };

  const getButtonText = (): JSX.Element | null => {
    return props.buttonText !== undefined ? <span>{props.buttonText}</span> : null;
  };

  const getIconAndText = (): JSX.Element => {
    return (
      <React.Fragment>
        <FontAwesomeIcon icon={props.icon} style={style} />
        {getButtonText()}
      </React.Fragment>
    );
  };

  const classText = props.additionalClass
    ? `btn btn-${props.buttonType} ${props.additionalClass}`
    : `btn btn-${props.buttonType}`;

  let content: JSX.Element;
  if (props.disabled) {
    if (props.titleText !== undefined) {
      if (props.notTabAccessible) {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            title={props.titleText ?? props.buttonText}
            aria-label={props.titleText}
            disabled={props.disabled}
            tabIndex={-1}>
            {getIconAndText()}
          </button>
        );
      } else {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            title={props.titleText ?? props.buttonText}
            aria-label={props.titleText}
            disabled={props.disabled}>
            {getIconAndText()}
          </button>
        );
      }
    } else {
      if (props.notTabAccessible) {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            title={props.titleText ?? props.buttonText}
            className={classText}
            aria-label={props.buttonText}
            disabled={props.disabled}
            tabIndex={-1}>
            {getIconAndText()}
          </button>
        );
      } else {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            title={props.titleText ?? props.buttonText}
            className={classText}
            aria-label={props.buttonText}
            disabled={props.disabled}>
            {getIconAndText()}
          </button>
        );
      }
    }
  } else if (props.onClick) {
    if (props.titleText !== undefined) {
      if (props.notTabAccessible) {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            aria-label={props.titleText}
            onClick={props.onClick}
            title={props.titleText ?? props.buttonText}
            tabIndex={-1}>
            {getIconAndText()}
          </button>
        );
      } else {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            aria-label={props.titleText}
            onClick={props.onClick}
            title={props.titleText ?? props.buttonText}>
            {getIconAndText()}
          </button>
        );
      }
    } else {
      if (props.notTabAccessible) {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            title={props.titleText ?? props.buttonText}
            aria-label={props.buttonText}
            onClick={props.onClick}
            tabIndex={-1}>
            {getIconAndText()}
          </button>
        );
      } else {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            title={props.titleText ?? props.buttonText}
            aria-label={props.buttonText}
            onClick={props.onClick}>
            {getIconAndText()}
          </button>
        );
      }
    }
  } else {
    if (props.titleText !== undefined) {
      if (props.notTabAccessible) {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            aria-label={props.titleText}
            title={props.titleText ?? props.buttonText}
            tabIndex={-1}>
            {getIconAndText()}
          </button>
        );
      } else {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            aria-label={props.titleText}
            title={props.titleText ?? props.buttonText}>
            {getIconAndText()}
          </button>
        );
      }
    } else {
      if (props.notTabAccessible) {
        content = (
          <button
            type={props.buttonAction ?? "button"}
            className={classText}
            aria-label={props.buttonText}
            tabIndex={-1}
            title={props.titleText ?? props.buttonText}>
            {getIconAndText()}
          </button>
        );
      } else {
        content = (
          <button title={props.titleText ?? props.buttonText} type={props.buttonAction ?? "button"} className={classText} aria-label={props.buttonText}>
            {getIconAndText()}
          </button>
        );
      }
    }
  }

  return content;
};
