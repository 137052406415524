import React, { Suspense, useEffect } from "react";
import Loading from "react-loading";
import { Routes, Route, Navigate } from "react-router-dom";
import IllegalSign from "../illegalSigns/IllegalSign";
import IllegalSigns from "../illegalSigns/IllegalSigns";
import PermitActivityForAccount from "../reports/PermitActivityForAccount";
import PublicOdaAccount from "../search/public/PublicOdaAccount";
import SignDetail from "../search/public/SignDetail";
import SectionSearch from "../search/section/SectionSearch";
import StructureSearch from "../search/structure/StructureSearch";
import SignsRemovedQuery from "../section/SignsRemovedQuery";
import PatrolSummary from "../section/PatrolSummary";
import CaseSearch from "../search/cases/CaseSearch";
import TransferSearch from "../search/transfer/TransferSearch";
import FacingSearch from "../search/facing/FacingSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { fetchIsUserOnAnyAccount } from "../../redux/slices/UserSlice";
import TaskPage from "../tasks/TaskPage";
import { InternalRole, Rights } from "oda-shared";
const NotAuthorized = React.lazy(() => import("../NotAuthorized"));
const NotAllowed = React.lazy(() => import ("../NotAllowed"));
const NoMatch = React.lazy(() => import("../NoMatch"));
const PaymentIssue = React.lazy(() => import("./../payment/PaymentIssue"));
const Staff = React.lazy(() => import("../staff/Staff"));
const Impersonate = React.lazy(() => import("../Impersonate"));
const LoginFailure = React.lazy(() => import("../LoginFailure"));
const OdaAccount = React.lazy(() => import("../accounts/OdaAccount"));
const SelectAccount = React.lazy(() => import("../accounts/SelectAccount"));
const AllAccounts = React.lazy(() => import("../accounts/AllAccounts"));
const Roles = React.lazy(() => import("../administration/roleManagement/Roles"));
const PermitApplication = React.lazy(() => import("../application/PermitApplication"));
const NewPermitApplication = React.lazy(() => import("../application/NewPermitApplication"));
const ViewApplications = React.lazy(() => import("../application/ViewMyApplicationsCard"));
const LicenseApplication = React.lazy(() => import("../application/LicenseApplication"));
const LicenseStatusHistory = React.lazy(() => import("../application/LicenseStatusHistory"));
const LicenseApplications = React.lazy(() => import("../application/LicenseApplications"));
const InspectionRegions = React.lazy(() => import("../administration/inspectionRegions/InspectionRegions"));
const InspectionRegion = React.lazy(() => import("../administration/inspectionRegions/InspectionRegion"));
const ShoppingCart = React.lazy(() => import("../payment/ShoppingCart"));
const Dashboard = React.lazy(() => import("../dashboard/Dashboard"));
const Search = React.lazy(() => import("../search/Search"));
const AccountSearch = React.lazy(() => import("../search/account/AccountSearch"));
const ActiveAccounts = React.lazy(() => import("../search/account/ActiveAccounts"));
const InvoiceSearch = React.lazy(() => import("../search/invoice/InvoiceSearch"));
const Invoice = React.lazy(() => import("../payment/Invoice"));
const InvoicePdfWrapper = React.lazy(() => import("../payment/InvoicePdfWrapper"));
const PermitApplicationSearch = React.lazy(() => import("../search/permitApplication/PermitApplicationSearch"));
const PermitSearch = React.lazy(() => import("../search/permit/PermitSearch"));
const PaymentSearch = React.lazy(() => import("../search/payment/PaymentSearch"));
const LitigationSearch = React.lazy(() => import("../search/litigation/LitigationSearch"));
const NoticeSearch = React.lazy(() => import("../search/notice/NoticeSearch"));
const IllegalSignSearch = React.lazy(() => import("../search/illegalSign/IllegalSignSearch"));
const ViolationSearch = React.lazy(() => import("../search/violation/ViolationSearch"));
const FindingSearch = React.lazy(() => import("../search/finding/FindingSearch"));
const DocumentSearch = React.lazy(() => import("../search/document/DocumentSearch"));
const Permit = React.lazy(() => import("../permit/Permit"));
const Payment = React.lazy(() => import("../payment/Payment"));
const PermitRenewalDate = React.lazy(() => import("../permit/PermitRenewalDate"));
const AnnualRenewals = React.lazy(() => import("../accounts/AnnualRenewals"));
const DateChanger = React.lazy(() => import("../administration/DateChanger"));
const OdaDataFile = React.lazy(() => import("../administration/OutdoorAdvertisingDataFile"));
const Litigation = React.lazy(() => import("../litigation/Litigations"));
const EditLitigation = React.lazy(() => import("../litigation/EditLitigation"));
const LitigationSearchResultsWrapper = React.lazy(() => import("../litigation/LitigationSearchResultsWrapper"));
const DropDownConfiguration = React.lazy(() => import("../administration/dropDownConfiguration/DropDownConfiguration"));
const Payments = React.lazy(() => import("../payment/Payments"));
const Invoices = React.lazy(() => import("../payment/Invoices"));
const NewPayment = React.lazy(() => import("../payment/NewPayment"));
const Sections = React.lazy(() => import("../section/SectionListForSectionInventory"));
const SectionPatrols = React.lazy(() => import("../section/SectionListForPatrols"));
const Section = React.lazy(() => import("../section/Section"));
const Patrols = React.lazy(() => import("../section/PatrolList"));
const Patrol = React.lazy(() => import("../section/Patrol"));
const Facing = React.lazy(() => import("../structure/Facing"));
const FacingViolationDetails = React.lazy(() => import("../violations/FacingViolationDetails"));
//const AnnualInventories = React.lazy(() => import("../administration/annualnventory/AnnualInventories"));
//const AnnualInventory = React.lazy(() => import("../administration/annualnventory/AnnualInventory"));
const SectionAnnualInventoryList = React.lazy(() => import("../section/SectionInventoryList"));
const SectionAnnualInventory = React.lazy(() => import("../section/SectionInventory"));
const SectionInventoryByFieldConsultantReport = React.lazy(
  () => import("../reports/SectionInventoryByFieldConsultant"),
);
const SectionInventoryReport = React.lazy(() => import("../reports/SectionInventoryReport"));

const Report270 = React.lazy(() => import("../reports/report270Day/Report270Day"));
const Report270Build = React.lazy(() => import("../reports/report270Build/Report270Build"));
const Rps = React.lazy(() => import("../reports/rps/Rps"));

const Notice = React.lazy(() => import("../notices/Notices"));
const EditNotice = React.lazy(() => import("../notices/EditNotice"));
const NoticeSearchResultsWrapper = React.lazy(() => import("../notices/NoticeSearchResultsWrapper"));
const ReportSummary = React.lazy(() => import("../reports/ReportSummary"));
const SectionsByCounty = React.lazy(() => import("../reports/SectionsByCounty"));
const LineItemReports = React.lazy(() => import("../reports/LineItemReport"));
const MileageList = React.lazy(() => import("../mileage/MileageList"));
const Mileage = React.lazy(() => import("../mileage/Mileage"));
const MileageQuery = React.lazy(() => import("../mileage/MileageQuery"));
const Help = React.lazy(() => import("../Help"));
const NinetyDayReport = React.lazy(() => import("../reports/NinetyDayReport"));
const RegionCountyReport = React.lazy(() => import("../reports/RegionCountyReport"));
const ApplicationsUnderReview = React.lazy(() => import("../reports/ApplicationsUnderReview"));
const PermitNinetyDayReport = React.lazy(() => import("../reports/PermitNinetyDayReport"));
const StructuresByCountyReport = React.lazy(() => import("../reports/StructuresByCountyReport"));
const PermitTransferRequest = React.lazy(() => import("../permit/PermitTransferRequest"));
const EmbeddedReport = React.lazy(() => import("../reports/EmbeddedReport"));
const PermitCancellationRequest = React.lazy(() => import("../permit/PermitCancellationRequest"));
const TaskList = React.lazy(() => import("../tasks/TaskList"));
const DevTools = React.lazy(() => import("../Devtools"));

const AuthenticatedRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const { user, isUserOnAnyAccount, isAuthenticated } = useSelector((state: RootState) => state.userReducer);
  const hasDashboard = Rights.hasDashboard(user);
  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(fetchIsUserOnAnyAccount());
    }
  }, [user, isAuthenticated, dispatch]);


  const ProtectedRoute = (children, condition) => {
    if (condition) {
      return children;
    } 
    else return <Navigate to="/NotAllowed" replace />;     
  };

  const InternalOnlyRoute = (children) => {
    return ProtectedRoute(children, (Rights.hasAnyFdotRole(user) || user?.isFieldConsultantInspector || user?.isFieldConsultantManager));      
  }


//  

const getDefaultRoute = () =>{
  
  if (user && user.isExternal){
    if (isUserOnAnyAccount){
      return <Route path="" element={ProtectedRoute(<AllAccounts />, Rights.canViewAccounts(user))} />
    }
    else return <Route path="" element={ProtectedRoute(<SelectAccount />, Rights.canViewAccounts(user))} />
  }
  else if (hasDashboard){    
    return <Route path="" element={ProtectedRoute(<Dashboard />, isAuthenticated)} />    
  }
  else if (Rights.hasOnlyThisRole(user, InternalRole.FdotLegal)){
    return <Route path="" element={ProtectedRoute(<CaseSearch />, Rights.canViewCases(user))} />
  }
  else if (Rights.hasOnlyThisRole(user, InternalRole.FdotInspector) || user?.isFieldConsultantInspector){
    return <Route path="" element={ProtectedRoute(<TaskList />, isAuthenticated)} />
  }
  return <Route path="" element={ProtectedRoute(<AllAccounts />, Rights.canViewAccounts(user))} />  
}

  return (
    <Suspense fallback={<Loading type="spinningBubbles"></Loading>}>
      <Routes>
        {getDefaultRoute()}     

        {/* Admin only routes */}        
        <Route path="DropDownConfiguration" element={ProtectedRoute(<DropDownConfiguration />, Rights.canManageConfiguration(user))} />
        <Route path="PermitRenewalDate" element={ProtectedRoute(<PermitRenewalDate />, Rights.canManageConfiguration(user))} />
        <Route path="InspectionRegions" element={ProtectedRoute(<InspectionRegions />, Rights.canManageConfiguration(user))} />
        <Route path="InspectionRegions/:id" element={ProtectedRoute(<InspectionRegion />, Rights.canManageConfiguration(user))} />

        {/* Role secured routes - accessible to one or more roles */}
        <Route path="Roles" element={ProtectedRoute(<Roles/>, Rights.canManageInternalRoles(user) || Rights.canManageConsultantRoles(user))} />
        <Route
          path="LicenseApplication/changeLicenseApplicationStatus/:id/:status"
          element={ProtectedRoute(<LicenseApplication />, Rights.canManageAllAccounts(user))}
          key="start-licenseReview"
        />
        <Route path="Notice/:id" element={ProtectedRoute(<EditNotice />, Rights.canViewNotices(user))} />
        <Route path="Notice" element={ProtectedRoute(<EditNotice />, Rights.canViewNotices(user))} />
        <Route path="Task/:id" element={ProtectedRoute(<TaskPage />, Rights.canViewTasks(user))} key="edit-task" />
        <Route path="Task/" element={ProtectedRoute(<TaskPage />, Rights.canViewTasks(user))} key="create-task" />
        <Route path="Litigation/:id/documents" element={ProtectedRoute(<EditLitigation />, Rights.canViewCases(user))} />
        <Route path="Litigation/:id" element={ProtectedRoute(<EditLitigation />, Rights.canViewCases(user))} />
        <Route path="Litigation" element={ProtectedRoute(<EditLitigation />, Rights.canViewCases(user))} />
        <Route path="SignDetail/:tagNumber" element={ProtectedRoute(<SignDetail />, Rights.hasAnyFdotRole(user))} />    
        <Route path="Mileage" element={ProtectedRoute(<MileageList />, Rights.canQueryMileage(user))} />
        <Route path="MileageQuery" element={ProtectedRoute(<MileageQuery />, Rights.canQueryMileage(user))} />
        <Route path="Payments" element={ProtectedRoute(<Payments />, Rights.canViewAccountingInfo(user))} />
        <Route path="Invoices" element={ProtectedRoute(<Invoices />, Rights.canViewAccountingInfo(user))} />
        <Route path="sections" element={ProtectedRoute(<Sections />, Rights.canCreateEditSections(user) || Rights.canEditSectionInventories(user))} />
        <Route path="sectionPatrols" element={ProtectedRoute(<SectionPatrols />, Rights.canAddEditSectionPatrols(user))} />
        <Route path="patrolSummary" element={ProtectedRoute(<PatrolSummary />, Rights.canViewPatrolSummary(user))} />
        <Route path="signsRemoved" element={ProtectedRoute(<SignsRemovedQuery />, Rights.canViewSectionReports(user))} />
        <Route path="sections/section" element={ProtectedRoute(<Section />, Rights.canCreateEditSections(user) || Rights.canEditSectionInventories(user))} key="create-section" />
        <Route path="sections/section/:id" element={ProtectedRoute(<Section />, Rights.canCreateEditSections(user) || Rights.canEditSectionInventories(user))} key="update-section" />
        <Route path="sections/section/:id/:tab" element={ProtectedRoute(<Section />, Rights.canCreateEditSections(user) || Rights.canEditSectionInventories(user))} />
        <Route path="patrols" element={ProtectedRoute(<Patrols />, Rights.canAddEditSectionPatrols(user))} />
        <Route path="patrols/patrol/:patrolId/section/:sectionId" element={ProtectedRoute(<Patrol />, Rights.canAddEditSectionPatrols(user))} />
        <Route path="patrols/patrol/:patrolId" element={ProtectedRoute(<Patrol />, Rights.canAddEditSectionPatrols(user))} />
        <Route path="patrols/patrol/" element={ProtectedRoute(<Patrol />, Rights.canAddEditSectionPatrols(user))} />        
        <Route path="patrols/patrol/section/:sectionId" element={ProtectedRoute(<Patrol />, Rights.canAddEditSectionPatrols(user))} />
        
        {/* Internal user only routes - accessible to any internal user, but no external ones */}
        <Route path="Reports/ActiveAccounts" element={InternalOnlyRoute(<ActiveAccounts />)} />
        <Route path="Search" element={InternalOnlyRoute(<Search />)}>
          <Route path="Account" element={InternalOnlyRoute(<AccountSearch />)} />
          <Route path="Case" element={ProtectedRoute(<CaseSearch />, Rights.canViewCases(user))} />
          <Route path="Document" element={InternalOnlyRoute(<DocumentSearch />)} />
          <Route path="Facing" element={InternalOnlyRoute(<FacingSearch />)} />
          <Route path="Invoice" element={ProtectedRoute(<InvoiceSearch />, Rights.canViewAccountingInfo(user))} />
          <Route path="PermitApplication" element={InternalOnlyRoute(<PermitApplicationSearch />)} />
          <Route path="Litigation" element={ProtectedRoute(<LitigationSearch />, Rights.canViewCases(user))} />
          <Route path="Notice" element={ProtectedRoute(<NoticeSearch />, Rights.canViewNotices(user))} />
          <Route path="Payment" element={ProtectedRoute(<PaymentSearch />, Rights.canViewAccountingInfo(user))} />
          <Route path="Permit" element={InternalOnlyRoute(<PermitSearch />)} />
          <Route path="Permit/:accountNumber" element={InternalOnlyRoute(<PermitSearch />)} />
          <Route path="Structure" element={InternalOnlyRoute(<StructureSearch />)} />
          <Route path="Section" element={InternalOnlyRoute(<SectionSearch />)} />
          <Route path="Section/:id" element={InternalOnlyRoute(<SectionSearch />)} />
          <Route path="OdaAccount/:id" element={InternalOnlyRoute(<PublicOdaAccount />)} />
          <Route path="Finding" element={InternalOnlyRoute(<FindingSearch />)} />
          <Route path="Violation" element={InternalOnlyRoute(<ViolationSearch />)} />
          <Route path="Transfer" element={InternalOnlyRoute(<TransferSearch />)} />          
          <Route path="IllegalSign" element={InternalOnlyRoute(<IllegalSignSearch />)} />
        </Route>
        <Route path="Litigations" element={ProtectedRoute(<Litigation />, Rights.canViewCases(user))}>
          <Route path="SearchResults" element={ProtectedRoute(<LitigationSearchResultsWrapper />, Rights.canViewCases(user))} />
          <Route path=":id" element={ProtectedRoute(<EditLitigation />, Rights.canCreateEditCases(user))} />
        </Route>
        <Route path="Notices" element={ProtectedRoute(<Notice />, Rights.canViewNotices(user))}>
          <Route path="SearchResults" element={ProtectedRoute(<NoticeSearchResultsWrapper />, Rights.canViewNotices(user))} />
          <Route path=":id" element={ProtectedRoute(<EditNotice />, Rights.canEditCreateNotices(user))} />
        </Route>
        <Route path="mileages/mileage" element={ProtectedRoute(<Mileage />, Rights.canQueryMileage(user))} key="create-mileage" />
        <Route path="mileages/mileage/:id" element={ProtectedRoute(<Mileage />, Rights.canQueryMileage(user))} key="update-mileage" />
        <Route path="illegalSigns/:id/Tab/Documents" element={ProtectedRoute(<IllegalSign />, Rights.canViewIllegalSigns(user))} />
        <Route path="illegalSigns/:id/:taskId" element={ProtectedRoute(<IllegalSign />, Rights.canViewIllegalSigns(user))} />
        <Route path="illegalSigns/:id" element={ProtectedRoute(<IllegalSign />, Rights.canViewIllegalSigns(user))} />
        <Route path="illegalSigns" element={ProtectedRoute(<IllegalSigns />, Rights.canViewIllegalSigns(user))} />
        <Route path="Accounts/annual-renewals" element={ProtectedRoute(<AnnualRenewals />, Rights.canManageAllAccounts(user))} />
        <Route path="Accounts/account/:id" element={ProtectedRoute(<OdaAccount />, Rights.canViewAccounts(user))} key="edit-account" />
        <Route path="Accounts/account/:id/:tab" element={ProtectedRoute(<OdaAccount />, Rights.canViewAccounts(user))} key="edit-account-tab" />
        <Route path="Accounts/account" element={ProtectedRoute(<OdaAccount />, Rights.canViewAccounts(user))} key="create-account" />
        <Route path="Accounts/selectAccount" element={ProtectedRoute(<SelectAccount />, Rights.canViewAccounts(user))} />
        <Route path="Accounts" element={ProtectedRoute(<AllAccounts />, Rights.canViewAccounts(user))} />        
        
        <Route path="PermitApplication/:id/:tab" element={ProtectedRoute(<PermitApplication />, Rights.canViewApplications(user))} />
        <Route path="PermitApplication/:id" element={ProtectedRoute(<PermitApplication />, Rights.canViewApplications(user))} />
        <Route path="PermitApplication" element={ProtectedRoute(<NewPermitApplication />, Rights.canViewApplications(user))} />     
        <Route path="PermitApplications" element={ProtectedRoute(<ViewApplications />, Rights.canViewApplications(user))} /> 
        <Route path="LicenseApplications" element={ProtectedRoute(<LicenseApplications />, Rights.canManageSomeAccounts(user))} />
        <Route path="LicenseApplication/:accountId" element={ProtectedRoute(<LicenseApplication />, Rights.canManageSomeAccounts(user))} />
        <Route path="LicenseApplication/:accountId/:licenseId" element={ProtectedRoute(<LicenseApplication />, Rights.canManageSomeAccounts(user))} />
        <Route path="LicenseApplication/LicenseStatusHistory/:licenseId" element={ProtectedRoute(<LicenseStatusHistory />, Rights.canManageSomeAccounts(user))} />
        
        <Route path="Permit/:id" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} />
        <Route path="Permit/:id/:tab" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} /> 
        <Route path="Permit/:id/:tab/:action" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/:tab1/finding/:findingId" element={ProtectedRoute(<Permit />, Rights.canViewFindings(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/:tab1/finding/:findingId/task/:taskId" element={ProtectedRoute(<Permit />, Rights.canViewFindings(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/:tab1/finding/:findingId/Notice/:noticeId" element={ProtectedRoute(<Permit />, Rights.canViewNotices(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/:tab1/violation/:violationId" element={ProtectedRoute(<Permit />, Rights.canViewViolations(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/face/:faceId" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/inventory/:inventoryId" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} />
        <Route path="Permit/:id/:tab/facing/:facingId/face/new" element={ProtectedRoute(<Permit />, Rights.canViewPermits(user))} />

        {/* //This one actually does not have rules.  */}
        <Route path="OdaDataFile" element={<OdaDataFile />}/>

        <Route path="ShoppingCart" element={ProtectedRoute(<ShoppingCart />, Rights.canViewShoppingCart(user))} />
        <Route path="Payment/New/:accountId" element={ProtectedRoute(<NewPayment />, Rights.canProcessPayments(user))} />
        <Route path="Payment/New" element={ProtectedRoute(<NewPayment />, Rights.canProcessPayments(user))} />
        <Route path="Payment/:id" element={ProtectedRoute(<Payment />, Rights.canViewPayments(user))} />
        <Route path="Invoice/:id/pdf" element={ProtectedRoute(<InvoicePdfWrapper />, Rights.canViewPayments(user))} />
        <Route path="Invoice/:id" element={ProtectedRoute(<Invoice />, Rights.canViewPayments(user))} />
    
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="facing/:id" element={ProtectedRoute(<Facing />, Rights.canViewPermits(user))} />
        <Route path="facing/:id/:tab" element={ProtectedRoute(<Facing />, Rights.canViewPermits(user))} />
        <Route path="facing/:id/:tab/finding/:findingId" element={ProtectedRoute(<Facing />, Rights.canViewFindings(user))} />
        <Route path="facing/:id/:tab/finding/:findingId/task/:taskId" element={ProtectedRoute(<Facing />, Rights.canViewFindings(user))} />
        <Route path="facing/:id/face/:faceId" element={ProtectedRoute(<Facing />, Rights.canViewPermits(user))} />

        <Route path="facing/:facingId/:tab/violation/:violationId" element={ProtectedRoute(<FacingViolationDetails />, Rights.canViewViolations(user))} />
        {/* <Route path="facing/:facingId/:tab/violation/:violationId" element={ProtectedRoute(<Facing />)} /> */}
        <Route path="facing/:facingId/:tab/finding/:findingId/Notice/:noticeId" element={ProtectedRoute(<Facing />, Rights.canViewNotices(user))} />
        <Route path="facing/:facingId/inventory/:inventoryId" element={ProtectedRoute(<Facing />, Rights.canViewPermits(user))} />
        <Route path="facing/:facingId/face/new" element={ProtectedRoute(<Facing />, Rights.canViewPermits(user))} />

        {/*<Route path="AnnualInventories" element={ProtectedRoute(<AnnualInventories />)} />
        <Route path="annualInventories/inventory/:inventoryId" element={ProtectedRoute(<AnnualInventory />)} />
        <Route path="annualInventories/inventory" element={ProtectedRoute(<AnnualInventory />)} />*/}
        <Route path="annualDataRecords" element={ProtectedRoute(<SectionAnnualInventoryList />, Rights.canEditSectionInventories(user))} />
        <Route path="annualDataRecords/annualData/:id" element={ProtectedRoute(<SectionAnnualInventory />, Rights.canEditSectionInventories(user))} />
        <Route path="annualDataRecords/annualData/section/:sectionId" element={ProtectedRoute(<SectionAnnualInventory />, Rights.canEditSectionInventories(user))} />

        <Route path="Reports/Report270" element={ProtectedRoute(<Report270 />, Rights.canViewInternalReports(user))} />
        <Route path="Reports/Report270Build" element={ProtectedRoute(<Report270Build />, Rights.canViewInternalReports(user))} />
        <Route path="reports/sectionInventoryByField" element={ProtectedRoute(<SectionInventoryByFieldConsultantReport />, Rights.canViewInternalReports(user))} />
        <Route path="reports/sectionInventoryReport" element={ProtectedRoute(<SectionInventoryReport />, Rights.canViewInternalReports(user))} />
        <Route path="reports/rps" element={ProtectedRoute(<Rps />, Rights.canViewInternalReports(user))} />
        <Route path="Report/ReportSummary" element={ProtectedRoute(<ReportSummary />, Rights.canViewInternalReports(user))} />
        <Route path="Reports/sectionsByCounty" element={ProtectedRoute(<SectionsByCounty />, Rights.canViewInternalReports(user))}></Route>
        <Route path="Reports/sectionsByCounty/:county" element={ProtectedRoute(<SectionsByCounty />, Rights.canViewInternalReports(user))}></Route>
        <Route path="Reports/LineItemReports" element={ProtectedRoute(<LineItemReports />, Rights.canViewInternalReports(user))} />
        <Route path="Reports/ApplicationsUnderReview" element={ProtectedRoute(<ApplicationsUnderReview />, Rights.canViewInternalReports(user))} />
        <Route path="RegionCountyReport" element={ProtectedRoute(<RegionCountyReport />, Rights.canViewInternalReports(user))} />
        <Route path="Permits90Day" element={ProtectedRoute(<PermitNinetyDayReport />, Rights.canViewInternalReports(user))} />
        <Route path="StructureCountsByCounty" element={ProtectedRoute(<StructuresByCountyReport />, Rights.canViewInternalReports(user))} />
        <Route path="PermitActivityForAccount" element={ProtectedRoute(<PermitActivityForAccount />, Rights.canViewInternalReports(user))} />
        <Route path="NinetyDayReport/:reportType" element={ProtectedRoute(<NinetyDayReport />, Rights.canViewInternalReports(user))} />



        <Route path="permitTransferRequest/:requestId" element={ProtectedRoute(<PermitTransferRequest />, Rights.canApplyForPermit(user))} />
 
        <Route path="permitCancellationRequest/:requestId" element={ProtectedRoute(<PermitCancellationRequest />, Rights.canApplyForPermit(user))} />

        {/* Any authenticated user routes */}
        {/* This includes routes that must be available during testing such as impersonate and staff. */}        
        <Route path="NotAuthorized" element={ProtectedRoute(<NotAuthorized />, isAuthenticated)} />
        <Route path="NotAllowed" element={ProtectedRoute(<NotAllowed />, isAuthenticated)} />
        <Route path="Help" element={ProtectedRoute(<Help />, isAuthenticated)} />
        <Route path="Tasks" element={ProtectedRoute(<TaskList />, isAuthenticated)} />
        <Route path="LoginFailure" element={ProtectedRoute(<LoginFailure />, isAuthenticated)} />
        <Route path="PaymentIssue" element={ProtectedRoute(<PaymentIssue />, isAuthenticated)} />
        <Route path="Impersonate" element={ProtectedRoute(<Impersonate />, isAuthenticated)} />        
        <Route path="DateChanger" element={ProtectedRoute(<DateChanger />, isAuthenticated)} />
        {/* //This route is not hit directly but it is used in staff pickers */}
        <Route path="Staff" element={ProtectedRoute(<Staff />, isAuthenticated)} />

        <Route path="EmbeddedReport/:report/:filter" element={<EmbeddedReport />} />
        <Route path="EmbeddedReport/:report" element={<EmbeddedReport />} />

        <Route path="devtools" element={<DevTools/>}/>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export default AuthenticatedRoutes;
