import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPatrol, IUserProfile, IPatrolCountRow } from "oda-shared";
import * as patrolApi from "../../apis/PatrolApi";
import { toast } from "react-toastify";

type SliceState = {
  currentPatrol: IPatrol;
  patrols: IPatrol[];
  allPatrols: IPatrol[];
  patrolCounts: IPatrolCountRow[];
  patrolSummaryCounts: any[];
};

const patrolSlice = createSlice({
  name: "patrol",
  initialState: {} as SliceState,
  reducers: {
    setCurrentPatrol: (state: SliceState, action: PayloadAction<IPatrol>) => {
      state.currentPatrol = action.payload;
    },
    loadPatrols: (state: SliceState, action: PayloadAction<IPatrol[]>) => {
      state.patrols = action.payload;
    },
    loadAllPatrols: (state: SliceState, action: PayloadAction<IPatrol[]>) => {
      state.allPatrols = action.payload;
    },
    loadPatrolCounts: (state: SliceState, action: PayloadAction<IPatrolCountRow[]>) => {
      state.patrolCounts = action.payload;
    },
    loadPatrolSummaryCounts: (state: SliceState, action: PayloadAction<any[]>) => {
      state.patrolSummaryCounts = action.payload;
    },
  },
});

export const { setCurrentPatrol, loadPatrols, loadAllPatrols, loadPatrolCounts, loadPatrolSummaryCounts } =
  patrolSlice.actions;

export default patrolSlice.reducer;

export const loadPatrol = (id: string) => async (dispatch: any) => {
  const patrol = await patrolApi.getPatrol(id);
  dispatch(setCurrentPatrol(patrol));
};

export const savePatrol =
  (patrol: IPatrol, inspector: IUserProfile, successCallback?: (id: string) => void) => async (dispatch: any) => {
    if (!inspector) {
      toast.error("Inspector is required!");
    }
    if (patrol?._id) {
      patrolApi
        .updatePatrol(patrol, inspector)
        .then((patrol: IPatrol) => {
          dispatch(setCurrentPatrol(patrol));
          toast.success("Patrol saved!");
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    } else {
      patrolApi
        .createPatrol(patrol, inspector)
        .then((patrol: IPatrol) => {
          dispatch(setCurrentPatrol(patrol));
          toast.success("Patrol created!");
          if (successCallback) {
            successCallback(patrol._id.toString());
          }
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    }
  };

export const getAllPatrols = () => async (dispatch: any) => {
  patrolApi
    .getAllPatrols()
    .then((patrols: IPatrol[]) => {
      dispatch(loadAllPatrols(patrols));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const searchPatrols = (county: string, year: string, inspectorId: string) => async (dispatch: any) => {
  patrolApi
    .searchPatrols(county, year, inspectorId)
    .then((patrols: IPatrol[]) => {
      dispatch(loadPatrols(patrols));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getSignsRemovedByRegion = (selectedYear: string) => async (dispatch: any) => {
  patrolApi
    .getSignsRemovedByRegion(selectedYear)
    .then((patrolCount: IPatrolCountRow[]) => {
      dispatch(loadPatrolCounts(patrolCount));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getPatrolSummary = (selectedYear: string) => async (dispatch: any) => {
  patrolApi
    .getPatrolSummary(selectedYear)
    .then((patrolCount: any[]) => {
      console.log("before Load");
      console.log(patrolCount);
      dispatch(loadPatrolSummaryCounts(patrolCount));
    })
    .catch((error: any) => {
      console.log(error);
    });
};
