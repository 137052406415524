import React, { useEffect, useState } from "react";
import { IAccountIdMap } from "oda-shared";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { AccountSelector } from "../common/AccountSelector";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  onRequestAccess: (accountSelected: IAccountIdMap | null) => void;
}
const AccountSearchModal = (props: IProps) => {
  const [selectedAccount, setSelectedAccount] = useState<IAccountIdMap | null>(null);

  useEffect(() => {
    if (props.isOpen) {
      setSelectedAccount(null);
    }
  }, [props.isOpen]);

  return (
    <>
      <div>
        <Modal isOpen={props.isOpen} toggle={props.toggle} name="AccountSearchModal">
          <ModalHeader toggle={props.toggle}>Find an ODA Account</ModalHeader>
          <ModalBody style={{ whiteSpace: "pre-line" }}>
            <div className="row m-1">
              <div className="col font-weight-bold">
                <label htmlFor="selectAccount" title="Select an Account">
                  Select an Account:{" "}
                </label>
              </div>
            </div>
            <div className="row m-1">
              <div className="col">
                <AccountSelector
                  id="selectAccount"
                  selectedAccount={selectedAccount}
                  onChange={(acct: IAccountIdMap | null) => setSelectedAccount(acct)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                props.onRequestAccess(selectedAccount);
              }}>
              Request Access
            </Button>
            <Button color="secondary" onClick={props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
export default AccountSearchModal;
