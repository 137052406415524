import axios from "axios";
import { ILitigation, ILitigationRow } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/litigation`;

export const fetchLitigation = async (id: string): Promise<ILitigation> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};
export const getLitigationsForExcel = async (): Promise<ILitigationRow[]> => {
  const response = await axios.get(`${baseUrl}/excel/all`);
  return response.data;
};

export const updateLitigation = async (litigation: ILitigation, id?: string): Promise<ILitigation> => {
  const response = await axios.post(`${baseUrl}/${id}`, litigation);
  return response.data;
};

export const createLitigation = async (litigation: ILitigation): Promise<ILitigation> => {
  const response = await axios.post(`${baseUrl}/`, litigation);
  return response.data;
};

export const searchByPartialCaseNumber = async (
  searchTerm: string,
  excludeCaseNumbers: string,
): Promise<ILitigation[]> => {
  const response = await axios.post(`${baseUrl}/search`, { searchTerm, excludeCaseNumbers });
  return response.data;
};
