import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserProfile } from "oda-shared";
import * as userApi from "./../../apis/UserApi";

type SliceState = {
  internalUsersWithRoles: IUserProfile[];
};

const userRoleSlice = createSlice({
  name: "userRole",
  initialState: {
    internalUsersWithRoles: new Array<IUserProfile>(),
  } as SliceState,
  reducers: {
    loadInternalUserRoles: (state: SliceState, action: PayloadAction<IUserProfile[]>) => {
      state.internalUsersWithRoles = action.payload;
    },
    loadInternalUserRole: (state: SliceState, action: PayloadAction<IUserProfile>) => {
      const index = state.internalUsersWithRoles.findIndex(p => {
        return action.payload.azureAdOid === p.azureAdOid;
      });

      if (index > -1) {
        state.internalUsersWithRoles[index] = action.payload;
      } else {
        state.internalUsersWithRoles.push(action.payload);
      }
    },
    removeInternalUser: (state: SliceState, action: PayloadAction<IUserProfile>) => {
      const index = state.internalUsersWithRoles.findIndex(p => {
        return action.payload.azureAdOid === p.azureAdOid;
      });

      if (index > -1) {
        state.internalUsersWithRoles.splice(index, 1);
      }
    },
  },
});

export const {
  loadInternalUserRoles: loadUserRoles,
  loadInternalUserRole: loadUserRole,
  removeInternalUser,
} = userRoleSlice.actions;

export default userRoleSlice.reducer;

export const getInternalUserRoles = () => async (dispatch: any) => {
  userApi
    .getInternalUsers()
    .then((profiles: IUserProfile[]) => {
      dispatch(loadUserRoles(profiles));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const addInternalUserRole = (azureAdOid: string, role: string, staff: IUserProfile) => async (dispatch: any) => {
  userApi
    .addInternalUserRole(azureAdOid, role, staff)
    .then(profile => {
      dispatch(loadUserRole(profile));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const removeRoleFromInternalUser = (azureAdOid: string, role: string) => async (dispatch: any) => {
  userApi
    .removeInternalUserRole(azureAdOid, role)
    .then(profile => {
      if (!profile.internalRoles || profile.internalRoles.length === 0) {
        dispatch(removeInternalUser(profile));
      } else {
        dispatch(loadUserRole(profile));
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};
