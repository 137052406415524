"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentTypeName = void 0;
var DocumentTypeName;
(function (DocumentTypeName) {
    DocumentTypeName["CombinedAnnualNonPaymentNotices"] = "Combined Annual Non-Payment Notices";
    DocumentTypeName["AlternateViewZoneAgreement"] = "Alternate View Zone Agreement";
    DocumentTypeName["ApplicationPendingActionLetter"] = "Application Pending Action Letter";
    DocumentTypeName["ArchivedViolations"] = "Archived Violations";
    DocumentTypeName["CeaseWorkOrder"] = "Cease Work Order";
    DocumentTypeName["CertificateofTagPosting"] = "Certificate of Tag Posting";
    DocumentTypeName["CertifiedMailSlip"] = "Certified Mail Slip";
    DocumentTypeName["ClerksOrderofDismissal"] = "Clerks Order of Dismissal";
    DocumentTypeName["CollectionsReferralPacket"] = "Collections-Referral Packet";
    DocumentTypeName["ConformityReviewSummary"] = "Conformity Review Summary";
    DocumentTypeName["ConsultantReport"] = "Consultant Report";
    DocumentTypeName["ComplianceSheet"] = "Compliance Sheet";
    DocumentTypeName["CopyofCaseFile"] = "Copy of Case File";
    DocumentTypeName["Correspondence"] = "Correspondence";
    DocumentTypeName["CorporationDetail"] = "Corporation Detail";
    DocumentTypeName["DeliveryConfirmation"] = "Delivery Confirmation";
    DocumentTypeName["DeliveryNotConfirmedReturnedMail"] = "Delivery Not Confirmed-Returned Mail";
    DocumentTypeName["DemoInvoiceContractor"] = "Demo Invoice-Contractor";
    DocumentTypeName["DeniedApplicationNotice"] = "Denied Application Notice";
    DocumentTypeName["DeterminationofLandUse"] = "Determination of Land Use";
    DocumentTypeName["DiscoveryRequest"] = "Discovery Request";
    DocumentTypeName["DiscoveryResponse"] = "Discovery Response";
    DocumentTypeName["EmailAttachment"] = "Email Attachment";
    DocumentTypeName["ExemptionReviewSummary"] = "Exemption Review Summary";
    DocumentTypeName["FinalDemandBeforeCollections"] = "Final Demand Before Collections";
    DocumentTypeName["FinalNoticeofSignRemoval"] = "Final Notice of Sign Removal";
    DocumentTypeName["FinalOrder"] = "Final Order";
    DocumentTypeName["HearingRequest"] = "Hearing Request";
    DocumentTypeName["HearingRequestWithdrawn"] = "Hearing Request-Withdrawn";
    DocumentTypeName["IllegalSignWarningCostLetter"] = "Illegal Sign Warning Cost Letter";
    DocumentTypeName["IncompleteApplicationNotice"] = "Incomplete Application Notice";
    DocumentTypeName["InvoiceSignRemoved"] = "Invoice-Sign Removed";
    DocumentTypeName["LandownerPermission"] = "Landowner Permission";
    DocumentTypeName["LetterofConcern"] = "Letter of Concern";
    DocumentTypeName["LicenseApplication"] = "License Application";
    DocumentTypeName["LicenseSuspendedRevokedLetter"] = "License Suspended/Revoked Letter";
    DocumentTypeName["LocalGovernmentPermissionBuildingPermit"] = "Local Government Permission Or Building Permit";
    DocumentTypeName["LocationSketch"] = "Location Sketch";
    DocumentTypeName["Map"] = "Map";
    DocumentTypeName["NewJurisdictionNotice"] = "New Jurisdiction Notice";
    DocumentTypeName["NewJurisdictionSecondNotice"] = "New Jurisdiction Second Notice";
    DocumentTypeName["NoticeofIntenttoRevoke"] = "Notice of Intent to Revoke";
    DocumentTypeName["NoticeofIntenttoSuspendRevokeLicense"] = "Notice of Intent to Suspend/Revoke License";
    DocumentTypeName["NoticeofNonCompliance"] = "Notice of Non Compliance";
    DocumentTypeName["NoticeofPermitVoid"] = "Notice of Permit Void";
    DocumentTypeName["NoticeOfUnauthorizedRemoval"] = "Notice of Unauthorized Removal";
    DocumentTypeName["NoticeofViolation10DayLetter"] = "Notice of Violation 10-day letter";
    DocumentTypeName["NoticeofViolationandOrdertoShowCauseforNonPayment"] = "Notice of Violation and Order to Show Cause for Non-Payment.";
    DocumentTypeName["NoticeofViolationIllegalSignintheRightofWay"] = "Notice of Violation-Illegal Sign in the Right of Way";
    DocumentTypeName["NOVIllegalVegetationCutting"] = "NOV-Illegal Vegetation Cutting";
    DocumentTypeName["NOVCostLetter"] = "NOV-Cost Letter";
    DocumentTypeName["OrderofDismissal"] = "Order of Dismissal";
    DocumentTypeName["PaymentRecord"] = "Payment Record";
    DocumentTypeName["PermitApplication"] = "Permit Application";
    DocumentTypeName["PermitCancellationCertification"] = "Permit Cancellation Certification";
    DocumentTypeName["PermitNotRequiredLetter"] = "Permit Not Required Letter";
    DocumentTypeName["PermitTransferRequest"] = "Permit Transfer Request";
    DocumentTypeName["PhotoAerial"] = "Photo-Aerial";
    DocumentTypeName["PhotoCeaseWorkOrderPosted"] = "Photo-Cease Work Order Posted";
    DocumentTypeName["PhotoFacing"] = "Photo-Facing";
    DocumentTypeName["PhotoFacingHistorical"] = "Photo-Historical Facing";
    DocumentTypeName["PhotoNonconformingRuleReminderPosted"] = "Photo-Nonconforming Rule Reminder Posted";
    DocumentTypeName["PhotoVOC"] = "Photo-VOC";
    DocumentTypeName["PhotoGeneral"] = "Photo-General";
    DocumentTypeName["PhotoIllegalSignNoticePosted"] = "Photo-Illegal Sign Notice Posted";
    DocumentTypeName["PhotoIllegalSignInRightOfWayNoticePosted"] = "Photo-Illegal Sign in Right of Way Notice Posted";
    DocumentTypeName["PhotoNoticeofViolationPosted"] = "Photo-Notice of Violation";
    DocumentTypeName["PhotoSignLocation"] = "Photo-Sign Location";
    DocumentTypeName["PhotoSignRemoved"] = "Photo-Sign Removed";
    DocumentTypeName["PhotoSiteMarkings"] = "Photo-Site Markings";
    DocumentTypeName["PhotoTag"] = "Photo-Tag";
    DocumentTypeName["PhotoTagHistorical"] = "Photo-Historical Tag";
    DocumentTypeName["PhotoWarningPosted"] = "Photo-Warning Posted";
    DocumentTypeName["PropertyDetail"] = "Property Detail";
    DocumentTypeName["RCIReport"] = "RCI Report";
    DocumentTypeName["Receipt"] = "Receipt";
    DocumentTypeName["RecommendedOrder"] = "Recommended Order";
    DocumentTypeName["ReducedSpacingAuthorization"] = "Reduced Spacing Authorization";
    DocumentTypeName["RemovalCostLetterCancelledPermit"] = "Removal Cost Letter-Cancelled Permit";
    DocumentTypeName["RemovalCostLetterExpiredPermit"] = "Removal Cost Letter-Expired Permit";
    DocumentTypeName["RemovalCostLetterRevokedPermit"] = "Removal Cost Letter-Revoked Permit";
    DocumentTypeName["RemovalofRemainingStructureRequired"] = "Removal of Remaining Structure Required";
    DocumentTypeName["RenewalSummary"] = "Annual Renewal Summary";
    DocumentTypeName["RenewalSupplement"] = "Annual Renewal Supplement";
    DocumentTypeName["ReplacementTagRequest"] = "Replacement Tag Request";
    DocumentTypeName["ResearchDocument"] = "Research Document";
    DocumentTypeName["RightOfWayBoundaryReview"] = "Right of Way Boundary Review";
    DocumentTypeName["SectionAttachment"] = "Section Attachment";
    DocumentTypeName["SettlementAgreement"] = "Settlement Agreement";
    DocumentTypeName["SevenYearRuleReview"] = "7 Year Rule Review";
    DocumentTypeName["SignRemovalPackage"] = "Sign Removal Package";
    DocumentTypeName["Survey"] = "Survey";
    DocumentTypeName["TaskWorkOrder"] = "Task Work Order";
    DocumentTypeName["TaskWorkOrderPackage"] = "Task Work Order Package";
    DocumentTypeName["VegetationManagementPermit"] = "Vegetation Management Permit";
    DocumentTypeName["VerificationOfLocalGovernmentSignOff"] = "Verification of Local Government Sign Off";
    DocumentTypeName["VerificationOfScenicHighway"] = "Verification of Scenic Highway";
    DocumentTypeName["VerificationOfSpacingSummary"] = "Verification of Spacing Summary";
    DocumentTypeName["ViolationWithdrawn"] = "Violation-Withdrawn";
    DocumentTypeName["VoidofCopyWarning"] = "Void of Copy Warning";
    DocumentTypeName["VoidedOrRevokedPermit"] = "Voided or Revoked Permit";
    DocumentTypeName["WorkOrder"] = "Work Order";
})(DocumentTypeName = exports.DocumentTypeName || (exports.DocumentTypeName = {}));
