"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HasRoles = exports.HasRole = void 0;
exports.HasRole = function (user, role) {
    if (user && user.internalRoles) {
        var index = user.internalRoles.findIndex(function (r) {
            return role === r;
        });
        if (index > -1) {
            return true;
        }
    }
    return false;
};
exports.HasRoles = function (user, roles) {
    if (user && user.internalRoles) {
        var index = user.internalRoles.findIndex(function (r) {
            return (roles.findIndex(function (role) {
                return role === r;
            }) > -1);
        });
        if (index > -1) {
            return true;
        }
    }
    return false;
};
