import axios from "axios";
import { IViolation } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/violation`;

export const getViolation = async (id: string): Promise<IViolation> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const addViolation = async (violation: IViolation) => {
  const response = await axios.post(`${baseUrl}`, violation);
  return response.data;
};

export const updateViolation = async (violation: IViolation) => {
  const response = await axios.patch(`${baseUrl}/${violation._id}`, violation);
  return response.data;
};

export const removeNoticeDocument = async (violation: IViolation) => {
  const response = await axios.post(`${baseUrl}/${violation._id}/RemoveNoticeDocument`, null);
  return response.data;
};
