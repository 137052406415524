"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionAnnualDataStatus = void 0;
var SectionAnnualDataStatus;
(function (SectionAnnualDataStatus) {
    SectionAnnualDataStatus["InProgress"] = "In Progress";
    SectionAnnualDataStatus["Submitted"] = "Submitted";
    SectionAnnualDataStatus["Completed"] = "Completed";
    SectionAnnualDataStatus["RequestInfo"] = "Additional Info Requested";
})(SectionAnnualDataStatus = exports.SectionAnnualDataStatus || (exports.SectionAnnualDataStatus = {}));
