import React, { useCallback, useEffect } from "react";
import { CustomCard } from "../../common/CustomCard";
import { ITransferRow, pagingOptions } from "oda-shared";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import {
  gridServerPageable,
  customCellRender,
  customHeaderRender,
  gridDataState,
  gridLink,
  gridDate,
} from "../../common/grid/customCellRender";
import { State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  updateCurrentPageIndex,
  updateItemsPerPage,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";
import { LoadingImage } from "../../common/LoadingImage";

interface IProps {
  results: Array<ITransferRow>;
}

const PermitSearchResults: React.FC<IProps> = (props: IProps) => {
  const [dataState, setDataState] = React.useState<State>(gridDataState("invoiceSearchResults"));

  const user = useSelector((state: RootState) => state.userReducer.user);
  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, totalItemCount, isLoadingSearchResults } =
    useSelector((state: RootState) => state.searchReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCurrentPageIndex(0));
    dispatch(updateItemsPerPage(pagingOptions.PermitApplicationPageSize));
  }, [dispatch]);

  const onPageChange = (e: GridPageChangeEvent) => {
    console.log("Page Change", e.page);
    dispatch(updateCurrentPageIndex(e.page.skip / e.page.take));
    dispatch(updateItemsPerPage(e.page.take));
    // Triggers a post to server with the new paging info
    dispatch(updatePageChanged(true));
  };

  const handleSort = useCallback(
    (event: GridSortChangeEvent) => {
      dispatch(updateSortColumn(event.sort[0]?.field));
      dispatch(updateSortDirection(event.sort[0]?.dir as any));
      // Triggers a post to server with the new sort info
      dispatch(updatePageChanged(true));
    },
    [dispatch],
  );
  if (isLoadingSearchResults) {
    return (
      <CustomCard collapsible={true} headerText="Search Results">
        <LoadingImage></LoadingImage>
      </CustomCard>
    );
  }

  const getPermitNumberCell = (e: GridCellProps) => {
    return gridLink(e, `/Permit/${e.dataItem.id}`, e.dataItem.permitNumber);
  };

  const getTagNumberCell = (e: GridCellProps) => {
    return gridLink(e, `/Permit/${e.dataItem.id}`, e.dataItem.currentTagNumber);
  };

  return (
    <CustomCard collapsible={true} headerText="Search Results">
      <Grid
        {...dataState}
        sortable
        skip={itemsPerPage * currentPageIndex}
        take={itemsPerPage}
        total={totalItemCount}
        sort={[{ field: sortColumn as any, dir: sortDirection as any }]}
        data={props.results}
        onDataStateChange={e => setDataState(e.dataState)}
        pageable={gridServerPageable(itemsPerPage)}
        onPageChange={onPageChange}
        onSortChange={handleSort as any}
        cellRender={customCellRender}
        headerCellRender={customHeaderRender}>
        <GridColumn field="permitNumber" title="Permit #" cell={getPermitNumberCell} />
        <GridColumn field="currentTagNumber" title="Tag #" cell={getTagNumberCell} />
        <GridColumn field="status" title="Status" sortable={false} />
        <GridColumn
          field="transferDate"
          title="Transferred Date"
          sortable={false}
          cell={e => gridDate(e.dataItem.transferDate)}
        />
        <GridColumn field="seller" title="Seller Name" sortable={false} />
        <GridColumn field="buyer" title="Buyer (Current Owner)" sortable={false} />
        <GridColumn field="county" title="County" />
        <GridColumn field="comments" title="Comments" sortable={false} />
      </Grid>
    </CustomCard>
  );
};

export default PermitSearchResults;
