"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloridaCounty = void 0;
var FloridaCounty;
(function (FloridaCounty) {
    FloridaCounty["Alachua"] = "Alachua";
    FloridaCounty["Baker"] = "Baker";
    FloridaCounty["Bay"] = "Bay";
    FloridaCounty["Bradford"] = "Bradford";
    FloridaCounty["Brevard"] = "Brevard";
    FloridaCounty["Broward"] = "Broward";
    FloridaCounty["Calhoun"] = "Calhoun";
    FloridaCounty["Charlotte"] = "Charlotte";
    FloridaCounty["Citrus"] = "Citrus";
    FloridaCounty["Clay"] = "Clay";
    FloridaCounty["Collier"] = "Collier";
    FloridaCounty["Columbia"] = "Columbia";
    FloridaCounty["DeSoto"] = "DeSoto";
    FloridaCounty["Dixie"] = "Dixie";
    FloridaCounty["Duval"] = "Duval";
    FloridaCounty["Escambia"] = "Escambia";
    FloridaCounty["Flagler"] = "Flagler";
    FloridaCounty["Franklin"] = "Franklin";
    FloridaCounty["Gadsden"] = "Gadsden";
    FloridaCounty["Gilchrist"] = "Gilchrist";
    FloridaCounty["Glades"] = "Glades";
    FloridaCounty["Gulf"] = "Gulf";
    FloridaCounty["Hamilton"] = "Hamilton";
    FloridaCounty["Hardee"] = "Hardee";
    FloridaCounty["Hendry"] = "Hendry";
    FloridaCounty["Hernando"] = "Hernando";
    FloridaCounty["Highlands"] = "Highlands";
    FloridaCounty["Hillsborough"] = "Hillsborough";
    FloridaCounty["Holmes"] = "Holmes";
    FloridaCounty["IndianRiver"] = "Indian River";
    FloridaCounty["Jackson"] = "Jackson";
    FloridaCounty["Jefferson"] = "Jefferson";
    FloridaCounty["Lafayette"] = "Lafayette";
    FloridaCounty["Lake"] = "Lake";
    FloridaCounty["Lee"] = "Lee";
    FloridaCounty["Leon"] = "Leon";
    FloridaCounty["Levy"] = "Levy";
    FloridaCounty["Liberty"] = "Liberty";
    FloridaCounty["Madison"] = "Madison";
    FloridaCounty["Manatee"] = "Manatee";
    FloridaCounty["Marion"] = "Marion";
    FloridaCounty["Martin"] = "Martin";
    FloridaCounty["MiamiDade"] = "Miami-Dade";
    FloridaCounty["Monroe"] = "Monroe";
    FloridaCounty["Nassau"] = "Nassau";
    FloridaCounty["Okaloosa"] = "Okaloosa";
    FloridaCounty["Okeechobee"] = "Okeechobee";
    FloridaCounty["Orange"] = "Orange";
    FloridaCounty["Osceola"] = "Osceola";
    FloridaCounty["PalmBeach"] = "Palm Beach";
    FloridaCounty["Pasco"] = "Pasco";
    FloridaCounty["Pinellas"] = "Pinellas";
    FloridaCounty["Polk"] = "Polk";
    FloridaCounty["Putnam"] = "Putnam";
    FloridaCounty["SantaRosa"] = "Santa Rosa";
    FloridaCounty["Sarasota"] = "Sarasota";
    FloridaCounty["Seminole"] = "Seminole";
    FloridaCounty["StJohns"] = "St.Johns";
    FloridaCounty["StLucie"] = "St.Lucie";
    FloridaCounty["Sumter"] = "Sumter";
    FloridaCounty["Suwannee"] = "Suwannee";
    FloridaCounty["Taylor"] = "Taylor";
    FloridaCounty["Union"] = "Union";
    FloridaCounty["Volusia"] = "Volusia";
    FloridaCounty["Wakulla"] = "Wakulla";
    FloridaCounty["Walton"] = "Walton";
    FloridaCounty["Washington"] = "Washington";
})(FloridaCounty = exports.FloridaCounty || (exports.FloridaCounty = {}));
