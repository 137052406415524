"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExemptSignReason = void 0;
var ExemptSignReason;
(function (ExemptSignReason) {
    ExemptSignReason["NotApplicable"] = "Not Applicable";
    ExemptSignReason["OnPremise"] = "479.16(1) On Premise";
    ExemptSignReason["Farm"] = "479.16(2) Farm";
    ExemptSignReason["ForSaleOrRent"] = "479.16(3) For Sale or Rent";
    ExemptSignReason["PropertyLessThan8SquareFeet"] = "479.16(8) On Property Less than 8 Square Feet";
    ExemptSignReason["LessThan8SquareFeetNonProfit"] = "479.16(12) Less than 8 Square Feet Non-Profit";
    ExemptSignReason["LessThan16SquareFeetDirectional"] = "479.16(15) Less than 16 Square Feet Directional";
})(ExemptSignReason = exports.ExemptSignReason || (exports.ExemptSignReason = {}));
