import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInspectionRegion, IMileage, IUserProfile, IMileageCountRow } from "oda-shared";
import * as mileageApi from "../../apis/MileageApi";
import { toast } from "react-toastify";

type SliceState = {
  currentMileage: IMileage;
  allMileages: IMileage[];
  mileageCounts: IMileageCountRow[];
};

const mileageSlice = createSlice({
  name: "mileage",
  initialState: {} as SliceState,
  reducers: {
    setCurrentMileage: (state: SliceState, action: PayloadAction<IMileage>) => {
      state.currentMileage = action.payload;
    },
    loadAllMileages: (state: SliceState, action: PayloadAction<IMileage[]>) => {
      state.allMileages = action.payload;
    },
    loadMileageCounts: (state: SliceState, action: PayloadAction<IMileageCountRow[]>) => {
      state.mileageCounts = action.payload;
    },
    removeMileage: (state: SliceState, action: PayloadAction<string>) => {
      if (!state.allMileages) {
        return state;
      }
      state.allMileages = state.allMileages.filter(i => i._id.toString() !== action.payload);
      return state;
    },
  },
});

export const { setCurrentMileage, loadAllMileages, removeMileage, loadMileageCounts } = mileageSlice.actions;

export default mileageSlice.reducer;

export const loadMileage = (id: string) => async (dispatch: any) => {
  const mileage = await mileageApi.getMileage(id);
  dispatch(setCurrentMileage(mileage));
};

export const deleteMileage =
  (mileageId: string, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    mileageApi
      .deleteMileage(mileageId)
      .then(() => {
        dispatch(removeMileage(mileageId));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        failureCallback();
      });
  };

export const getAllMileages = () => async (dispatch: any) => {
  mileageApi
    .getAllMileages(0)
    .then((data: { results: Array<IMileage>; totalCount: number }) => {
      dispatch(loadAllMileages(data.results));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getMileageCountByRegion = (selectedYear: string, selectedQuarter: string) => async (dispatch: any) => {
  mileageApi
    .getMileageCountByRegion(selectedYear, selectedQuarter)
    .then((mileageCount: IMileageCountRow[]) => {
      dispatch(loadMileageCounts(mileageCount));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const saveMileage =
  (mileage: IMileage, region: IInspectionRegion, inspector: IUserProfile, successCallback?: () => void) =>
  async (dispatch: any) => {
    if (!region) {
      toast.error("Region is required");
    }
    if (mileage._id) {
      mileageApi
        .updateMileage(mileage, region, inspector)
        .then((mileage: IMileage) => {
          dispatch(getAllMileages());
          toast.success("Mileage saved!");
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    } else {
      mileageApi
        .createMileage(mileage, region, inspector)
        .then((mileage: IMileage) => {
          dispatch(getAllMileages());
          toast.success("Mileage created!");
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    }
  };
