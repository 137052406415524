import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadSignDetails } from "../../../redux/slices/SearchSlice";
import { RootState } from "../../../redux/RootReducer";
import { CustomCard } from "../../common/CustomCard";
import { format } from "date-fns";
import { documentPermitPictureUrl } from "../../../apis/DocumentApi";

const SignDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { tagNumber } = useParams();
  const sign = useSelector((state: RootState) => state.searchReducer.currentSignDetail);

  const mapLink =
    sign && sign.latitude && sign.longitude
      ? `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${sign.latitude},${sign.longitude}&heading=-90`
      : "";
  useEffect(() => {
    if (tagNumber) {
      dispatch(loadSignDetails(tagNumber));
    }
  }, [tagNumber, dispatch]);

  if (!sign) {
    return <></>;
  }
  return (
    <div>
      <br />
      <CustomCard headerText={`Sign Detail - ${tagNumber}`}>
        <CustomCard headerText="Location Information">
          <div className="row">
            <div className="col-lg font-weight-bold">Region:</div>
            <div className="col-lg">{sign.region}</div>
            <div className="col-lg font-weight-bold">County:</div>
            <div className="col-lg">{sign.county}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">State Road:</div>
            <div className="col-lg">{sign.stateRoad}</div>
            <div className="col-lg font-weight-bold">Local Name:</div>
            <div className="col-lg">{sign.localName}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">US Route:</div>
            <div className="col-lg">{sign.usRoute}</div>
            <div className="col-lg font-weight-bold">Class:</div>
            <div className="col-lg">{sign.class}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">RCI Section:</div>
            <div className="col-lg">{sign.rciSection}</div>
            <div className="col-lg font-weight-bold">Direction:</div>
            <div className="col-lg">{sign.direction}</div>
          </div>
        </CustomCard>
        <CustomCard headerText="Structure Information">
          <div className="row">
            <div className="col-lg font-weight-bold">Milepost:</div>
            <div className="col-lg">{sign.milepost}</div>
            <div className="col-lg font-weight-bold">Conforming:</div>
            <div className="col-lg">{sign.conforming}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">Section Side:</div>
            <div className="col-lg">{sign.sectionSide}</div>
            <div className="col-lg font-weight-bold">Date Built:</div>
            <div className="col-lg">{sign.dateBuilt ? format(new Date(sign.dateBuilt), "MM/dd/yyyy") : ""}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">Latitude:</div>
            <div className="col-lg">{sign.latitude}</div>
            <div className="col-lg font-weight-bold">Longitude:</div>
            <div className="col-lg">{sign.longitude}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">Lights:</div>
            <div className="col-lg">{sign.lights}</div>
            <div className="col-lg font-weight-bold">Material:</div>
            <div className="col-lg">{sign.material}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">Height:</div>
            <div className="col-lg">{sign.height}</div>
            <div className="col-lg font-weight-bold"># of Supportss:</div>
            <div className="col-lg">{sign.numberOfSupports}</div>
          </div>
          <div className="row">
            <div className="col-lg font-weight-bold">Date Removed:</div>
            <div className="col-lg">{sign.dateRemoved ? format(new Date(sign.dateRemoved), "MM/dd/yyyy") : ""}</div>
            <div className="col-lg font-weight-bold">Configuration:</div>
            <div className="col-lg">{sign.configuration}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 font-weight-bold">Structure is:</div>
            <div className="col-lg">{sign.structureLocation}</div>
          </div>
          {mapLink !== "" ? (
            <div className="row">
              <div className="col-lg font-weight-bold">
                <a
                  className="btn btn-link"
                  title="Link to Google Map Street View - Opens in a new window"
                  href={mapLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  Link to Google Map Street View
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </CustomCard>
        <CustomCard headerText="Permit Information">
          <div className="row">
            <div className="col font-weight-bold">Issue Date:</div>
            <div className="col">{format(new Date(sign.permitIssueDate), "MM/dd/yyyy")}</div>
            <div className="col font-weight-bold">Tag #:</div>
            <div className="col">{sign.tagNumber}</div>
          </div>
          <div className="row">
            <div className="col font-weight-bold">Permit Status:</div>
            <div className="col">{sign.permitStatus}</div>
            <div className="col font-weight-bold">Square Feet:</div>
            <div className="col">{sign.squareFeet}</div>
          </div>
          <div className="row">
            <div className="col font-weight-bold">Permitee:</div>
            <div className="col">
              <Link to={`/Search/OdaAccount/${sign.permiteeId}`}>{sign.permitteeName}</Link>
            </div>
            <div className="col font-weight-bold">HAGL:</div>
            <div className="col">{sign.heightAboveGroundLevel}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 font-weight-bold">Sign Reads:</div>
            <div className="col-lg">{sign.signReads}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 font-weight-bold">View Zone:</div>
            <div className="col-lg">{sign.viewZone}</div>
          </div>
          {sign.pictureId ? (
            <>
              <div className="row">
                <div className="col-lg-3 font-weight-bold">Picture:</div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <img alt="Tag" src={documentPermitPictureUrl(sign.permitId)} height="400"></img>
                </div>
              </div>
            </>
          ) : (
            <>Sign Picture is Currently Missing</>
          )}
          <div className="row">
            <div className="col">
              <CustomCard headerText="Previous Tag Numbers">
                <div className="row">
                  <div className="col table-responsive-lg">
                    <table className="table table-sm table-bordered table-striped">
                      <tbody>
                        {sign.previousTagNumbers.map((t, i) => {
                          return (
                            <tr key={i}>
                              <td>{t}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CustomCard>
            </div>
          </div>
        </CustomCard>
      </CustomCard>
    </div>
  );
};

export default SignDetail;
