import { IDocument } from "oda-shared";
import React, { useEffect, useState } from "react";
import { getSizeInKb } from "../../utils/DocumentUtilities";
import { getUserDisplayName } from "../../apis/UserApi";

interface IProps {
  doc: IDocument;
}

const DocumentLink: React.FC<IProps> = (props: IProps) => {
  const [displayName, setDisplayName] = useState<string | null>(null);

  useEffect(() => {  
    if (props.doc && props.doc.addedById && props.doc.addedById._id) {
      
      getUserDisplayName(props.doc.addedById._id)
        .then(name => {
          setDisplayName(name);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [props.doc]);

  return (
    <span>
      {props.doc?.originalname} {props.doc?.documentType ? props.doc?.documentType : ""}{" "}
      {getSizeInKb(props.doc?.blobSize)} {props.doc?.added ? new Date(props.doc?.added).toLocaleDateString() : ""}{" "}
      {displayName}
    </span>
  );
};

export default DocumentLink;
