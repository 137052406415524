import axios from "axios";
import {
  ApplicationReviewStatus,
  FieldReviewType,
  IDenialLetter,
  IIncompleteLetter,
  IPermitApplication,
  PaymentQuarter,
  PermitApplicationStatus,
} from "oda-shared";
import { toast } from "react-toastify";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/permitApplication`;

export const sendPermitStatusEmail = async (id: string, status: PermitApplicationStatus | undefined, applyToThisFacingOnly:boolean): Promise<boolean> => {
  const response = await axios.post(`${baseUrl}/sendApplicationStatusEmail`, {id: id, status: status, applyToThisFacingOnly: applyToThisFacingOnly});
  return response.data;
}

export const getPermitApplication = async (id: string): Promise<IPermitApplication> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const addPermitApplication = async (permitApplication: IPermitApplication) => {
  const response = await axios.post(`${baseUrl}`, permitApplication);
  return response.data;
};

export const updatePermitApplication = async (permitApplication: IPermitApplication) => {
  const response = await axios.patch(`${baseUrl}/${permitApplication._id}`, permitApplication);
  return response.data;
};

export const updatePermitApplicationLetter = async (id: string, denialInfo: any, incompleteInfo: any,) => {  
  const response = await axios.patch(`${baseUrl}/${id}/updateLetterInfo`, {
    denialLetter: denialInfo, 
    incompleteLetter: incompleteInfo}
  );
  return response.data;
}

export const updatePermitApplicationReview = async (permitApplication: IPermitApplication, applyToThisFacingOnly: boolean, reviewType: FieldReviewType) => {
  const response = await axios.patch(`${baseUrl}/${permitApplication._id}/updateReview/${applyToThisFacingOnly? 'false' : 'true' }/${reviewType}`, permitApplication);
  return response.data;
};

export const resubmitDeniedPermitApplication = async (id: string) => {
  const response = await axios.patch(`${baseUrl}/${id}/resubmitDenied`);
  return response.data;
};

export const updatePermitApplicationStatus = async (
  id: string,
  status: PermitApplicationStatus,
  comment: string,
  reason?: string,
  denialLetter?: IDenialLetter,
  incompleteLetter?: IIncompleteLetter,
  applyToThisFacingOnly?: boolean,
) => {
  let url = `${baseUrl}/${id}/ChangeStatus/${status}`;

  if (applyToThisFacingOnly === true) {
    url += "/false";
  } else {
    url += "/true";
  }
  const response = await axios.patch(url, {
    comment: comment,
    reason: reason,
    denialLetter,
    incompleteLetter,
  });
  return response.data;
};

export const updatePermitApplicationReviewStatus = async (
  id: string,
  status: ApplicationReviewStatus,
  comment: string,
  recommendation: string,
  reason: string,
  denialLetter?: IDenialLetter,
  incompleteLetter?: IIncompleteLetter,
  applyToThisFacingOnly?: boolean,
) => {
  let url = `${baseUrl}/${id}/ChangeReviewStatus/${status}`;

  if (applyToThisFacingOnly === true) {
    url += "/false";
  } else {
    url += "/true";
  }

  const response = await axios.patch(url, {
    comment: comment,
    recommendation: recommendation,
    reason: reason,
    denialLetter,
    incompleteLetter,
  });
  return response.data;
};

export const splitApplication = async (id: string, facingCount: number) => {
  const response = await axios.patch(`${baseUrl}/${id}/split/${facingCount}`);
  return response.data;
};

export const getPermitApplications = async () => {
  const response = await axios.get(`${baseUrl}/`);
  return response.data;
};

export const markReceived = async (id: string, paymentQuarter: PaymentQuarter) => {
  const response = await axios.post(`${baseUrl}/${id}/mark-received?paymentQuarter=${encodeURI(paymentQuarter)}`);
  return response.data;
};

export const assignInspectorToApplication = async (id: string, inspectorId: string) => {
  const response = await axios.patch(`${baseUrl}/${id}/AssignInspector/${inspectorId}`);
  return response.data;
};

export const validatePermitAppNumber = async (searchString: number) => {
  const response = await axios.get(`${baseUrl}/validate-permit-app-number/${searchString}`);
  const { valid, permitApp } = response.data;
  return { valid, permitApp };
};
