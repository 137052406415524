"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DepartmentPrevailed = void 0;
var DepartmentPrevailed;
(function (DepartmentPrevailed) {
    DepartmentPrevailed["NotDetermined"] = "Not Determined";
    DepartmentPrevailed["No"] = "No";
    DepartmentPrevailed["Yes"] = "Yes";
    DepartmentPrevailed["SettlementAgreement"] = "Settlement Agreement";
    DepartmentPrevailed["IssueResolved"] = "Issue Resolved";
})(DepartmentPrevailed = exports.DepartmentPrevailed || (exports.DepartmentPrevailed = {}));
