import React, { useEffect, useMemo, useState } from "react";
import { ButtonWithIcon } from "../../common/ButtonWithIcon";
import { CustomCard } from "../../common/CustomCard";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  performFacingSearch,
  updateCurrentPageIndex,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";

const FacingSearchCriteria = () => {
  const [facingNumber, setFacingNumber] = useState("");

  const dispatch = useDispatch();

  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, pageChanged } = useSelector(
    (state: RootState) => state.searchReducer,
  );

  const criteria = useMemo(() => {
    return {
      facingNumber,
      sortColumn,
      sortDirection,
      currentPageIndex,
      itemsPerPage,
      pageChanged,
    };
  }, [facingNumber, sortColumn, sortDirection, currentPageIndex, itemsPerPage, pageChanged]);

  useEffect(() => {
    if (pageChanged) {
      dispatch(performFacingSearch(criteria as any));
      dispatch(updatePageChanged(false));
    }
  }, [criteria, dispatch, pageChanged]);

  useEffect(() => {
    dispatch(updateSortColumn("facingNumber"));
    dispatch(updateSortDirection("desc"));
  }, [dispatch]);

  const reset = () => {
    setFacingNumber("");
  };

  return (
    <>
      <CustomCard headerText="Facing Search Criteria">
        <div className="row">
          <div className="col-lg-4">
            <label htmlFor="nameContains" title="Name Contains" className="font-weight-bold">
              Facing ID:
            </label>
            <input
              type="number"
              name="nameContains"
              id="nameContains"
              title="Name Contains"
              className="form-control"
              value={facingNumber}
              onChange={e => setFacingNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <ButtonWithIcon
                buttonType="danger"
                icon="undo"
                buttonText="Reset"
                onClick={reset}
                titleText="Reset Search"
              />
              &nbsp;&nbsp;
              <ButtonWithIcon
                buttonType="primary"
                icon="search"
                buttonText="Search"
                titleText="Search"
                onClick={() => {
                  dispatch(updateCurrentPageIndex(0));
                  dispatch(performFacingSearch({ ...criteria, currentPageIndex: 0 } as any));
                }}
              />
            </div>
          </div>
        </div>
      </CustomCard>
    </>
  );
};

export default FacingSearchCriteria;
