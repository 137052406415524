"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentQuarter = void 0;
var PaymentQuarter;
(function (PaymentQuarter) {
    PaymentQuarter["FirstQuarter"] = "1st Quarter";
    PaymentQuarter["SecondQuarter"] = "2nd Quarter";
    PaymentQuarter["ThirdQuarter"] = "3rd Quarter";
    PaymentQuarter["FourthQuarter"] = "4th Quarter";
})(PaymentQuarter = exports.PaymentQuarter || (exports.PaymentQuarter = {}));
