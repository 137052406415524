import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  performStructureSearch,
  updateCurrentPageIndex,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";
import { ButtonWithIcon } from "../../common/ButtonWithIcon";
import { CustomCard } from "../../common/CustomCard";
import EnumToOptions from "../../common/EnumOptions";
import { ISection, SectionSide } from "oda-shared";
import { SectionSelector } from "../../common/SectionSelector";

const StructureSearchCriteria: React.FC = () => {
  const [sectionId, setSectionId] = useState<string>("");
  const [section, setSection] = useState<ISection>(null);
  const [facingNumber, setFacingNumber] = useState<string>("");
  const [side, setSide] = useState<string>("");
  const [distance, setDistance] = useState<string>("");
  const [milepost, setMilepost] = useState<string>("");
  const [exists, setExists] = useState<string>("");
  const [structureNumber, setStructureNumber] = useState("");
  const [activeOnly, setActiveOnly] = useState(false);
  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, pageChanged } = useSelector(
    (state: RootState) => state.searchReducer,
  );
  const dispatch = useDispatch();

  console.log("Sort Column", sortColumn);
  const reset = () => {
    setSectionId("");
    setSection(null);
    setSide("");
    setDistance("");
    setMilepost("");
    setStructureNumber("");
    setActiveOnly(false);
    setFacingNumber("");
  };

  const criteria = useMemo(() => {
    return {
      sectionId,
      side,
      milepost,
      distance,
      exists,
      activeOnly,
      facingNumber,
      sortColumn,
      sortDirection,
      structureNumber,
      currentPageIndex,
      itemsPerPage,
    };
  }, [
    sectionId,
    side,
    milepost,
    distance,
    exists,
    activeOnly,
    facingNumber,
    sortColumn,
    sortDirection,
    structureNumber,
    currentPageIndex,
    itemsPerPage,
  ]);

  useEffect(() => {
    console.log("Sort");
    dispatch(updateSortColumn("structureNumber"));
    dispatch(updateSortDirection("desc"));
  }, [dispatch]);

  useEffect(() => {
    if (pageChanged) {
      dispatch(performStructureSearch(criteria));
      dispatch(updatePageChanged(false));
    }
  }, [pageChanged, criteria, dispatch]);

  return (
    <CustomCard collapsible={true} headerText="Structure Search Criteria">
      <div className="row">
        <div className="col-lg">
          <label className="font-weight-bold" htmlFor="structureNumber" title="Structure Number">
            Structure Number:
          </label>
          <input
            className="form-control"
            type="number"
            name="structureNumber"
            id="structureNumber"
            title="Structure Number"
            value={structureNumber}
            onChange={event => setStructureNumber(event.target.value)}></input>
        </div>
        <div className="col-lg">
          <label className="font-weight-bold" htmlFor="facingNumber" title="Facing Number">
            Facing Number:
          </label>
          <input
            type="number"
            className="form-control"
            name="facingNumber"
            id="facingNumber"
            title="Facing Number"
            value={facingNumber}
            onChange={event => setFacingNumber(event.target.value)}></input>
        </div>
        <div className="col-lg">
          <label className="font-weight-bold" htmlFor="section" title="Name Contains">
            Section Number:
          </label>
          <SectionSelector
            selectedSection={section}
            id="section"
            onChange={sect => {
              setSection(sect);
              sect !== null ? setSectionId(sect.rciSection) : setSectionId("");
            }}
          />
        </div>
        <div className="col-lg">
          <label className="font-weight-bold" htmlFor="side" title="Side">
            Side:
          </label>
          <select
            className="form-control"
            name="side"
            id="side"
            title="Side"
            value={side}
            onChange={event => setSide(event.target.value)}>
            <EnumToOptions enumeration={SectionSide}></EnumToOptions>
          </select>
        </div>
        <div className="col-lg-3">
          <label className="font-weight-bold" htmlFor="milepost" title="Milepost">
            Section Milepost:
          </label>
          <input
            type="number"
            className="form-control"
            name="milepost"
            id="milepost"
            disabled={!sectionId}
            title="Milepost"
            value={milepost}
            onChange={event => setMilepost(event.target.value)}></input>
        </div>
        <div className="col-lg-3">
          <label className="font-weight-bold" htmlFor="distance" title="Distance">
            Within Distance of Milepost (Miles):
          </label>
          <input
            type="number"
            className="form-control"
            disabled={!milepost}
            name="distance"
            id="distance"
            title="Distance"
            value={distance}
            onChange={event => setDistance(event.target.value)}></input>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2">
          <label className="font-weight-bold" htmlFor="activeOnly" title="Active Only">
            Active Only:
          </label>
        </div>
        <div className="col-lg-1 text-left">
          <input
            className="form-control"
            type="checkbox"
            name="activeOnly"
            id="activeOnly"
            title="Active Only"
            checked={activeOnly}
            onChange={event => setActiveOnly(event.target.checked)}></input>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg">
          <div className="text-center">
            <ButtonWithIcon buttonType="danger" icon="undo" buttonText="Reset" titleText="Reset" onClick={reset} />
            &nbsp;&nbsp;
            <ButtonWithIcon
              buttonType="primary"
              icon="search"
              buttonText="Search"
              onClick={() => {
                dispatch(updateCurrentPageIndex(0));
                dispatch(performStructureSearch({ ...criteria, currentPageIndex: 0 } as any));
              }}></ButtonWithIcon>
          </div>
        </div>
      </div>
    </CustomCard>
  );
};

export default StructureSearchCriteria;
