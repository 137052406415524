"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryStatus = void 0;
var InventoryStatus;
(function (InventoryStatus) {
    InventoryStatus["Requested"] = "Requested";
    InventoryStatus["InProgress"] = "In Progress";
    InventoryStatus["Submitted"] = "Submitted";
    InventoryStatus["Completed"] = "Completed";
})(InventoryStatus = exports.InventoryStatus || (exports.InventoryStatus = {}));
