import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPermit } from "oda-shared";
import { RootState } from "../../redux/RootReducer";
import { searchPermitByNumber } from "../../redux/slices/PermitSlice";
import * as permitApi from "../../apis/PermitApi";

interface IProps {
  id: string;
  selectedPermitId: string | null;
  onChange: (selected: IPermit | null) => void;
  disabled?: boolean;
  clearAfterSelect?: boolean;
  required?: boolean;
}

export const PermitSelector: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { searchResults, isLoadingSearchResults } = useSelector((state: RootState) => state.permitReducer);
  //const [options, setOptions] = useState<IPermit[]>([]);
  const [selectedPermit, setSelectedPermit] = useState<IPermit[]>([]);

  useEffect(() => {
    if (props.selectedPermitId) {
      //const matchingPermit = options.find(i => i._id.toString() === props.selectedPermitId);
      const matchingPermit = searchResults.find(i => i._id.toString() === props.selectedPermitId);
      setSelectedPermit(matchingPermit ? [matchingPermit] : []);
    }
  }, [props.selectedPermitId, searchResults]);

  useEffect(() => {
    if (props.selectedPermitId) {
      permitApi.getPermit(props.selectedPermitId).then(permit => {
        setSelectedPermit([permit]);
      });
    }
  }, [props.selectedPermitId]);

  // useEffect(() => {
  //   setOptions(searchResults);
  // }, [searchResults]);

  const onChange = (selected: Array<IPermit>) => {
    let returnVal: IPermit | null = null;
    if (selected && selected.length > 0) {
      returnVal = selected[0]; //._id.toString();
    }
    setSelectedPermit(selected);
    if (props.onChange) {
      props.onChange(returnVal);
    }
    if (props.clearAfterSelect) {
      setSelectedPermit([]);
    }
  };

  const handleSearch = async (searchString: string) => {
    dispatch(searchPermitByNumber(searchString));
  };

  const getLabel = (permit: IPermit | null | undefined): string => {
    return permit
      ? `Permit # ${permit.permitNumber} ${permit.currentTagNumber ? ` - ${permit.currentTagNumber}` : ""}`
      : "";
  };

  return (
    <AsyncTypeahead
      id={props.id}
      inputProps={{
        "aria-label": props.id,
        title: "Enter Permit or Tag Number",
        id: props.id,
        required: props.required ?? false,
      }}
      allowNew={false}
      isLoading={isLoadingSearchResults}
      multiple={false}
      minLength={2}
      options={searchResults}
      selected={selectedPermit}
      onSearch={handleSearch}
      onChange={onChange}
      placeholder="Enter Permit or Tag Number to search"
      labelKey={(option: any) => getLabel(option)}
      disabled={props.disabled ?? false}
    />
  );
};
