import * as reportApi from "../../apis/ReportApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RpsRow,
  INinetyDayReportRow,
  NinetyDayReportType,
  PermitReportRow,
  StructureCountByCountyRow,
  PermitActivityRange,
} from "oda-shared";

type SliceState = {
  rpsResults: RpsRow[];
  isLoading: boolean;
  ninetyDayReportResults: INinetyDayReportRow[];
  applicationsUnderResults: INinetyDayReportRow[];
  permitNinetyDayResults: PermitReportRow[];
  permitActivityByAccountResults: PermitReportRow[];
  structureCountsByCountyResults: StructureCountByCountyRow[];
};

const reportSlice = createSlice({
  name: "report",
  initialState: {
    rpsResults: [],
    isLoading: false,
    ninetyDayReportResults: [],
    applicationsUnderResults: [],
    permitNinetyDayResults: [],
    structureCountsByCountyResults: [],
    permitActivityByAccountResults: [],
  } as SliceState,
  reducers: {
    updateRpsResults: (state: SliceState, action: PayloadAction<RpsRow[]>) => {
      state.rpsResults = action.payload;
      return state;
    },
    updateNinetyDayResults: (state: SliceState, action: PayloadAction<INinetyDayReportRow[]>) => {
      state.ninetyDayReportResults = action.payload;
      return state;
    },
    updateApplicationsUnderResults: (state: SliceState, action: PayloadAction<INinetyDayReportRow[]>) => {
      state.applicationsUnderResults = action.payload;
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    updatePermitNinetyDayResults: (state: SliceState, action: PayloadAction<PermitReportRow[]>) => {
      state.permitNinetyDayResults = action.payload;
      return state;
    },
    updateStructureCountsByCountyResults: (state: SliceState, action: PayloadAction<StructureCountByCountyRow[]>) => {
      state.structureCountsByCountyResults = action.payload;
      return state;
    },
    updatePermitActivityByAccountResults: (state: SliceState, action: PayloadAction<PermitReportRow[]>) => {
      state.permitActivityByAccountResults = action.payload;
      return state;
    },
  },
});

export const {
  updateRpsResults,
  updateIsLoading,
  updateNinetyDayResults,
  updateApplicationsUnderResults,
  updatePermitNinetyDayResults,
  updateStructureCountsByCountyResults,
  updatePermitActivityByAccountResults,
} = reportSlice.actions;
export default reportSlice.reducer;

export const fetchPermitActivityForAccountData =
  (accountId: string, reportRange: PermitActivityRange) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    reportApi
      .fetchPermitActivityForAccountData(accountId, reportRange)
      .then((results: PermitReportRow[]) => {
        dispatch(updatePermitActivityByAccountResults(results));
        dispatch(updateIsLoading(false));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
      });
  };

export const fetchNinetyDayReportData = (reportType: NinetyDayReportType) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  reportApi
    .fetchNinetyDayReportData(reportType)
    .then((results: INinetyDayReportRow[]) => {
      dispatch(updateNinetyDayResults(results));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchApplicationsUnderReviewData = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  reportApi
    .fetchApplicationsUnderReviewData()
    .then((results: INinetyDayReportRow[]) => {
      dispatch(updateApplicationsUnderResults(results));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchRpsData = (startDate: Date, endDate: Date) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  reportApi
    .fetchRpsReportData(startDate, endDate)
    .then((results: RpsRow[]) => {
      dispatch(updateRpsResults(results));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchPermitNinetyDayData = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  reportApi
    .fetchPermitNinetyDayData()
    .then((results: PermitReportRow[]) => {
      dispatch(updatePermitNinetyDayResults(results));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchStructureCountsByCountyData = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  reportApi
    .fetchStructureCountsByCountyData()
    .then((results: StructureCountByCountyRow[]) => {
      dispatch(updateStructureCountsByCountyResults(results));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};
