import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IGridSearchObject, IPermitApplicationRow } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/search`;

export const searchApis = createApi({
  reducerPath: "SearchApis",
  baseQuery: fetchBaseQuery({ baseUrl, credentials: "include" }),
  tagTypes: ["report-270-day-search"],
  endpoints: builder => ({
    report270daySearch: builder.mutation<
      { totalCount: number; results: IPermitApplicationRow[] },
      { criteria: any; gridState: IGridSearchObject }
    >({
      query: body => ({ url: `/report-270-day`, body, method: "POST" }),
      invalidatesTags: ["report-270-day-search"],
    }),
  }),
});

export const { useReport270daySearchMutation } = searchApis;
