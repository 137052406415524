import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { CustomCard } from "../common/CustomCard";
import { stringUtils, yearUtils } from "oda-shared";
import { getSignsRemovedByRegion } from "../../redux/slices/PatrolSlice";
import { getAllInspectionRegions } from "../../redux/slices/InspectionRegionsSlice";

const SignsRemovedQuery: React.FC = () => {
  const dispatch = useDispatch();
  const patrolCounts = useSelector((state: RootState) => state.patrolReducer.patrolCounts);
  const allInspectionRegions = useSelector((state: RootState) => state.inspectionRegionsReducer.inspectionRegions);
  const [selectedYear, setYear] = useState("");

  useEffect(() => {
    dispatch(getAllInspectionRegions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSignsRemovedByRegion(
        stringUtils.isNullOrWhitespace(selectedYear) ? yearUtils.getCurrentFiscalYear() : selectedYear,
      ),
    );
  }, [dispatch, selectedYear]);

  return (
    <div className="container-fluid">
      <br />
      <div className="row">
        <div className="col-lg-2">
          <label htmlFor="year" title="Region" className="font-weight-bold">
            Year:
          </label>
          <select
            className="form-control"
            id="year"
            onChange={e => {
              setYear(e.target.value);
            }}
            defaultValue={yearUtils.getCurrentFiscalYear()}>
            <>
              {Object.values(yearUtils.getFiscalYearList(2020))
                .sort((b, a) => a.localeCompare(b))
                .map((item, i) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
            </>
          </select>
        </div>
      </div>
      <CustomCard headerText="Signs Removed Query" collapsible={false}>
        <div className="row">
          <div className="col-lg-10">
            <div className="row text-center">
              <div className="col-lg-12 text-center">
                <label className="font-weight-bold">Total Signs Removed:</label>&nbsp;
                {patrolCounts?.reduce((prev, next) => prev + next.totalSignsRemoved, 0).toLocaleString()}
              </div>
            </div>
            <br />
            <div className="row">
              {Object.values(allInspectionRegions)?.map((r, i) => {
                const countRecord = patrolCounts?.find(c => c.region === r.name);
                return (
                  <>
                    <div className="col-lg">
                      <div className="text-center">
                        <label className="font-weight-bold">{r.name}:</label>&nbsp;
                        {countRecord ? countRecord?.totalSignsRemoved.toLocaleString() : 0}
                      </div>
                      <table className="table-striped table-bordered table">
                        <thead>
                          <tr>
                            <th>County</th>
                            <th>Sign Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...r?.counties]
                            .sort((a, b) => {
                              return a.localeCompare(b);
                            })
                            ?.map((rc, j) => {
                              const countyCountRecord = countRecord?.counties.find(c => c.county === rc);
                              return (
                                <tr key={j}>
                                  <td>{rc}</td>
                                  <td>{countyCountRecord ? countyCountRecord?.count.toLocaleString() : 0}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default SignsRemovedQuery;
