"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalRole = void 0;
var InternalRole;
(function (InternalRole) {
    InternalRole["ApplicationReviewer"] = "Application Reviewer";
    InternalRole["FdotAdmin"] = "FDOT Admin";
    InternalRole["FdotInspector"] = "FDOT Inspector";
    InternalRole["FdotLegal"] = "FDOT Legal";
    InternalRole["FieldSupervisor"] = "Field Supervisor";
    InternalRole["ManagementReviewer"] = "Management Reviewer";
    InternalRole["OfficeStaff"] = "Office Staff";
    InternalRole["OfficeSupervisor"] = "Office Supervisor";
})(InternalRole = exports.InternalRole || (exports.InternalRole = {}));
