import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "./RootReducer";
import { odaAccountApi } from "../apis/RTKQueries/OdaAccountSearchApi";
import { searchApis } from "../apis/RTKQueries/SearchApis";

const middleware = [...getDefaultMiddleware()];

const store = configureStore({
  reducer: rootReducer,
  devTools: fdot.process.env.APP_ENVIRONMENT === "development",
  // middleware,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(odaAccountApi.middleware, searchApis.middleware),
});

if (fdot.process.env.APP_ENVIRONMENT === "development" && (module as any).hot) {
  (module as any).hot.accept("./RootReducer", () => {
    const newRootReducer = require("./RootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
