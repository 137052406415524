import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import FacingSearchCriteria from "./FacingSearchCriteria";
import FacingSearchResults from "./FacingSearchResults";

const FacingSearch: React.FC = () => {
  const results = useSelector((state: RootState) => state.searchReducer.facingResults);

  return (
    <>
      <FacingSearchCriteria />
      <FacingSearchResults results={results} />
    </>
  );
};

export default FacingSearch;
