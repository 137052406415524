import React, { useCallback, useEffect } from "react";
import { CustomCard } from "../../common/CustomCard";
import { pagingOptions, IStructureRow } from "oda-shared";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import {
  gridServerPageable,
  customCellRender,
  customHeaderRender,
  gridDataState,
  gridLink,
} from "../../common/grid/customCellRender";
import { State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  updateCurrentPageIndex,
  updateItemsPerPage,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";
import { LoadingImage } from "../../common/LoadingImage";

interface IProps {
  results: Array<IStructureRow>;
  appendHeader: string;
}

const StructureSearchResults: React.FC<IProps> = (props: IProps) => {
  const user = useSelector((state: RootState) => state.userReducer.user);
  const [dataState, setDataState] = React.useState<State>(gridDataState("documentSearchResults"));

  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, totalItemCount, isLoadingSearchResults } =
    useSelector((state: RootState) => state.searchReducer);
  const dispatch = useDispatch();

  const onPageChange = (e: GridPageChangeEvent) => {
    console.log("Page Change", e.page);
    dispatch(updateCurrentPageIndex(e.page.skip / e.page.take));
    dispatch(updateItemsPerPage(e.page.take));
    // Triggers a post to server with the new paging info
    dispatch(updatePageChanged(true));
  };

  const handleSort = useCallback(
    (event: GridSortChangeEvent) => {
      dispatch(updateCurrentPageIndex(0));
      dispatch(updateSortColumn(event.sort[0]?.field));
      dispatch(updateSortDirection(event.sort[0]?.dir as any));
      // Triggers a post to server with the new sort info
      dispatch(updatePageChanged(true));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(updateCurrentPageIndex(0));
    dispatch(updateItemsPerPage(pagingOptions.PermitApplicationPageSize));
  }, [dispatch]);

  if (isLoadingSearchResults) {
    return (
      <CustomCard collapsible={true} headerText={"Search Results"}>
        <LoadingImage></LoadingImage>
      </CustomCard>
    );
  }

  const getAccountCell = (e: GridCellProps) => {
    if (user && user._id && !user.isExternal) {
      return gridLink(e, `/Accounts/account/${e.dataItem.accountId}`, e.dataItem.accountName);
    } else {
      return gridLink(e, `/Search/OdaAccount/${e.dataItem.accountId}`, e.dataItem.accountName);
    }
  };

  const getStructureCell = (e: GridCellProps) => {
    if (user && user._id && !user.isExternal) {
      return gridLink(e, `/Permit/${e.dataItem.permitId}/structure`, e.dataItem.structureNumber);
    } else {
      return <td>{e.dataItem.structureNumber}</td>;
    }
  };

  return (
    <CustomCard collapsible={true} headerText={"Search Results"}>
      <Grid
        {...dataState}
        sortable
        skip={itemsPerPage * currentPageIndex}
        take={itemsPerPage}
        total={totalItemCount}
        sort={[{ field: sortColumn as any, dir: sortDirection as any }]}
        data={props.results}
        onDataStateChange={e => setDataState(e.dataState)}
        pageable={gridServerPageable(itemsPerPage)}
        onPageChange={onPageChange}
        onSortChange={handleSort as any}
        cellRender={customCellRender}
        headerCellRender={customHeaderRender}>
        <GridColumn field="structureNumber" title="Structure Number" cell={getStructureCell} />
        <GridColumn field="sectionNumber" title="Section Number" />
        <GridColumn field="side" title="Side" />
        <GridColumn field="milepost" title="Milepost" />
        <GridColumn field="status" title="Status" sortable={false} />
        <GridColumn field="conforming" title="Conforming" sortable={false} />
        <GridColumn field="accountName" title="Account Name" sortable={false} cell={getAccountCell} />
      </Grid>
    </CustomCard>
  );
};

export default StructureSearchResults;
