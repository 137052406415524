import axios from "axios";
import {
  //IAnnualInventory,
  ILicenseApplication,
  INotice,
  IPermit,
  IPermitApplication,
  IPermitCancellationRequest,
  IPermitTransferRequest,
  ISectionAnnualData,
  ITag,
  ITaskAssignment,
} from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/dashboard`;

export const getConsultantManagerApplications = async (): Promise<IPermitApplication[]> => {
  const response = await axios.get(`${baseUrl}/applications/consultantManager`);
  return response.data;
};
export const getConsultantManagerInspections = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/inspections/consultantManager`);
  return response.data;
};

export const getConsultantFieldInspectorsApplications = async (): Promise<IPermitApplication[]> => {
  const response = await axios.get(`${baseUrl}/applications/consultantFieldInspectors`);
  return response.data;
};
export const getConsultantFieldInspectorsInspections = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/inspections/consultantFieldInspectors`);
  return response.data;
};

export const getConsultantFieldInspectorsForTags = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/inspections/consultantFieldInspectorsTags`);
  return response.data;
};

export const getApplicationReviewerApplications = async (): Promise<IPermitApplication[]> => {
  const response = await axios.get(`${baseUrl}/applications/applicationReviewer`);
  return response.data;
};
export const getApplicationReviewerInspections = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/inspections/applicationReviewer`);
  return response.data;
};

export const getManagementReviewerApplications = async (): Promise<IPermitApplication[]> => {
  const response = await axios.get(`${baseUrl}/applications/managementReviewer`);
  return response.data;
};

export const getExternalUserApplications = async (): Promise<IPermitApplication[]> => {
  const response = await axios.get(`${baseUrl}/applications/externalUser`);
  return response.data;
};

export const getTransferApplications = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/applications/transferPermits`);
  return response.data;
};

export const getTransferRequests = async (): Promise<IPermitTransferRequest[]> => {
  const response = await axios.get(`${baseUrl}/applications/transferRequests`);
  return response.data;
};

export const getApplicationReviewerTransferApplications = async (
  includeDraft: boolean,
): Promise<IPermitTransferRequest[]> => {
  const response = await axios.get(`${baseUrl}/permits/applicationReviewer/transfers/${includeDraft}`);
  return response.data;
};
export const getApplicationReviewerTransfersAwaitingPayment = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/permits/applicationReviewer/transfersAwaitingPayment`);
  return response.data;
};

/*export const getAnnualInventoriesInProgress = async (): Promise<IAnnualInventory[]> => {
  const response = await axios.get(`${baseUrl}/inventories/inProgress`);
  return response.data;
};*/

export const getSectionAnnualDataInProgress = async (): Promise<ISectionAnnualData[]> => {
  const response = await axios.get(`${baseUrl}/sectionAnnualDataInProgress/inProgress`);
  return response.data;
};

export const getApprovedPermitsAwaitingTag = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/approvedPermitsAwaitingTags`);
  return response.data;
};

export const getApprovedPermitsAwaitingPayment = async (): Promise<IPermitApplication[]> => {
  const response = await axios.get(`${baseUrl}/approvedPermitsAwaitingPayment`);
  return response.data;
};

export const getPendingCancellations = async (): Promise<IPermit[]> => {
  const response = await axios.get(`${baseUrl}/pendingCancellations`);
  return response.data;
};

export const getPendingTagReplacementRequests = async (): Promise<ITag[]> => {
  const response = await axios.get(`${baseUrl}/pendingTagReplacements`);
  return response.data;
};

export const getPendingLicenseApplications = async (): Promise<ILicenseApplication[]> => {
  const response = await axios.get(`${baseUrl}/pendingLicenseApplications`);
  return response.data;
};

export const getVoidOfCopyInspections = async (): Promise<INotice[]> => {
  const response = await axios.get(`${baseUrl}/voidOfCopyInspections`);
  return response.data;
};

export const getTaskAssignments = async (): Promise<ITaskAssignment[]> => {
  const response = await axios.get(`${baseUrl}/taskAssignments`);
  return response.data;
};

export const getApplicationCancellationRequests = async (): Promise<IPermitCancellationRequest[]> => {
  const response = await axios.get(`${baseUrl}/cancellationRequests`);
  return response.data;
};
