"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumFromStringValue = exports.enumToNumberArray = exports.enumToArray = void 0;
exports.enumToArray = function (enumeration, initialEntry) {
    var array = Object.keys(enumeration).map(function (k) { return enumeration[k]; });
    array.sort();
    if (initialEntry !== undefined && initialEntry !== null) {
        array.unshift(initialEntry);
    }
    return array;
};
exports.enumToNumberArray = function (enumeration, initialEntry) {
    var enumValues = [];
    for (var value in enumeration) {
        if (typeof enumeration[value] === "number") {
            enumValues.push(enumeration[value]);
        }
    }
    enumValues.sort();
    if (initialEntry !== undefined && initialEntry !== null) {
        enumValues.unshift(initialEntry);
    }
    return enumValues;
};
//If this enum type includes the passed in value as one of its values,
//cast it to enum type and return it, otherwise return undefined
function enumFromStringValue(enumType, value) {
    return Object.values(enumType).includes(value)
        ? value
        : undefined;
}
exports.enumFromStringValue = enumFromStringValue;
