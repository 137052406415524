import React, { useEffect, CSSProperties } from "react";
import { fetchConfig } from "../../redux/slices/ConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { LoadingImage } from "../common/LoadingImage";
import { fetchUser } from "../../redux/slices/UserSlice";
import Header from "../Header";
import Footer from "../Footer";
import { initializeApp } from "../../utils/Initializer";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import AnonymousRoutes from "./AnonymousRoutes";
import { ThemeStyle } from "../theme/ThemeStyle";

const hiddenButScreenReaderAccessibleStyle: CSSProperties = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
};

const Routes: React.FC = () => {
  const dispatch = useDispatch();

  const { loadingConfig, config } = useSelector((state: RootState) => state.configReducer);

  const { isUserLoaded, isAuthenticated, user } = useSelector((state: RootState) => state.userReducer);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchConfig());
    }
  }, [dispatch, isAuthenticated, user]);

  useEffect(() => {
    if (config && user) {
      let authenticatedUserId = `${user.firstName} ${user.lastName}`;
      if (user.emailAddress) {
        authenticatedUserId += ` (${user.emailAddress})`;
      }
      if (user.srsId) {
        authenticatedUserId += ` [${user.srsId.toString()}]`;
      } else {
        authenticatedUserId += ` [${user.azureAdOid}]`;
      }

      initializeApp(config, authenticatedUserId);
    }
  }, [config, user]);

  if (!isUserLoaded || loadingConfig || (!config && isAuthenticated === true)) {
    return <LoadingImage></LoadingImage>;
  }

  return (
    <>
      <ThemeStyle></ThemeStyle>
      <a href="#maincontent" style={hiddenButScreenReaderAccessibleStyle}>
        Skip to main content
      </a>
      <Header></Header>
      <div id="maincontent" style={{ paddingBottom: "100px" }}>
        <div className="container-fluid">
          <div className="row">
            <div role="main" className="col">
              {isAuthenticated ? <AuthenticatedRoutes></AuthenticatedRoutes> : <AnonymousRoutes></AnonymousRoutes>}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Routes;
