"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllegalSignStatus = void 0;
var IllegalSignStatus;
(function (IllegalSignStatus) {
    IllegalSignStatus["CancelledOrExpiredTagCostLetterIssued"] = "Canceled or Expired Tag Cost Letter Issued";
    IllegalSignStatus["Collections"] = "Collections";
    IllegalSignStatus["ContractorInvoicePaid"] = "Contractor Invoice Paid";
    IllegalSignStatus["DeliveryConfirmationReceived"] = "Delivery Confirmation Received";
    IllegalSignStatus["FieldConsultant"] = "Field Consultant";
    IllegalSignStatus["FinalDemandBeforeCollections"] = "Final Demand Before Collections";
    IllegalSignStatus["InLitigation"] = "In Litigation";
    IllegalSignStatus["InitialReportOfViolationConsultant"] = "Initial Report of Violation - Consultant";
    IllegalSignStatus["InitialReportOfViolationFDOT"] = "Initial Report of Violation -  FDOT";
    IllegalSignStatus["WorkOrderIssued"] = "Work Order Issued";
    IllegalSignStatus["LetterOfConcernIssued"] = "Letter of Concern Issued";
    IllegalSignStatus["NewJurisdictionNoticeIssued"] = "New Jurisdiction Notice Issued";
    IllegalSignStatus["NewJurisdictionSecondNoticeIssued"] = "New Jurisdiction Second Notice Issued";
    IllegalSignStatus["NoticePosted30Day"] = "Notice Posted - 30 Day";
    IllegalSignStatus["NoticePostedROW10Day"] = "Notice Posted - ROW 10 Day";
    IllegalSignStatus["NOVCostLetterIssued"] = "NOV Cost Letter Issued";
    //NOV10DayLetterIssued = "NOV 10 Day Letter Issued",
    IllegalSignStatus["OnHold"] = "On Hold";
    IllegalSignStatus["Paid"] = "Paid";
    IllegalSignStatus["PermitApplicationReceived"] = "Permit Application Received";
    IllegalSignStatus["ReceivedInOffice"] = "Received in Office";
    IllegalSignStatus["RemovalOfRemainingStructureRequired"] = "Removal of Remaining Structure Required";
    IllegalSignStatus["RemovalPackageUnderReview"] = "Removal Package Under Review";
    IllegalSignStatus["ResolutionRecommendationByInspector"] = "Resolution Recommendation by Inspector";
    IllegalSignStatus["ResolutionRecommendationByConsultant"] = "Resolution Recommendation by Consultant";
    IllegalSignStatus["Resolved"] = "Resolved";
    IllegalSignStatus["RevokedRemovalCostLetterIssued"] = "Revoked Removal Cost Letter Issued";
    IllegalSignStatus["SentToRegional"] = "Sent to Regional";
    IllegalSignStatus["SupplementalRequestCompleted"] = "Supplemental Request Completed";
    IllegalSignStatus["SupplementalRequestToRegionalInspector"] = "Supplemental Request to Regional Inspector";
    IllegalSignStatus["WriteOffUncollectible"] = "Write Off Uncollectible";
    IllegalSignStatus["WarningPosted"] = "Warning Posted";
    IllegalSignStatus["WorkOrderCompleted"] = "Work Order Completed";
    IllegalSignStatus["PreviouslyPermitted"] = "Previously Permitted";
    IllegalSignStatus["NOVForSignOnROW"] = "NOV for Sign on ROW";
    IllegalSignStatus["RemovalInvoiceIssued"] = "Removal Invoice Issued";
    //OnPremise = "On Premise",
})(IllegalSignStatus = exports.IllegalSignStatus || (exports.IllegalSignStatus = {}));
