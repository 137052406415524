"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeResolution = void 0;
var NoticeResolution;
(function (NoticeResolution) {
    NoticeResolution["Adverse"] = "Adverse";
    NoticeResolution["Dismissed"] = "Dismissed";
    NoticeResolution["Duplicate"] = "Duplicate";
    NoticeResolution["Exemption"] = "Exemption";
    NoticeResolution["NoViolationFound"] = "No Violation Found";
    NoticeResolution["NotApplicable"] = "Not Applicable";
    NoticeResolution["OnPremise"] = "On-Premise";
    NoticeResolution["Paid"] = "Paid";
    NoticeResolution["Permitted"] = "Permitted";
    NoticeResolution["Removed"] = "Removed";
    NoticeResolution["Upheld"] = "Upheld";
    NoticeResolution["WrittenOff"] = "Written Off";
})(NoticeResolution = exports.NoticeResolution || (exports.NoticeResolution = {}));
