"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReasonForViolationType = void 0;
var ViolationType_1 = require("../models/enums/ViolationType");
exports.getReasonForViolationType = function (violationType) {
    if (violationType === ViolationType_1.ViolationType["07A"]) {
        return "Failure to Display Permit Tag. Permit tags must be securely attached to the upper 50 percent of the sign structure and attached in such a manner as to be plainly visible from the main traveled way pursuant to s. 479.07(5)(a), Florida Statutes.";
    }
    if (violationType === ViolationType_1.ViolationType["07D"]) {
        return "Has Intermittent Lights Not Embodied in Display Rotating or Flashing Lights < 100 ft. from ROW";
    }
    if (violationType === ViolationType_1.ViolationType["07E"]) {
        return "A sign may not exceed 50 feet in height above the crown of the road outside an incorporated area or exceed 65 feet in height above the crown of the road inside an incorporated area, pursuant to s. 479.07(9)(b), Florida Statutes.";
    }
    if (violationType === ViolationType_1.ViolationType["07H"]) {
        return "Is <= 15 ft. from ROW";
    }
    if (violationType === ViolationType_1.ViolationType["07I"]) {
        return "Signs may not contain the words “stop” or “danger,” or present or imply the need or requirement of stopping or the existence of danger, or which is a copy or imitation of official signs, and which is adjacent to the right-of-way of any highway on the State Highway System, interstate highway system, or federal aid primary highway system, pursuant to s. 479.11(6), Florida Statutes.";
    }
    if (violationType === ViolationType_1.ViolationType["07M"]) {
        return "Permittee no longer has permission from the owner or other person in lawful control of the sign site to have and maintain a sign at the permitted location, pursuant to s. 479.07(7), Florida Statutes.";
    }
    if (violationType === ViolationType_1.ViolationType["07N"]) {
        return "Other";
    }
    if (violationType === ViolationType_1.ViolationType["08A"]) {
        return "Failure to Pay License Renewal Fees";
    }
    if (violationType === ViolationType_1.ViolationType["08B"]) {
        return "Failure to Pay Permit Renewal Fees";
    }
    if (violationType === ViolationType_1.ViolationType["08C"]) {
        return "Submission of dishonored check";
    }
    if (violationType === ViolationType_1.ViolationType["09S"]) {
        return "This noconforming sign has modifications provides that enhances the visibility of the sign’s message, or the period of time that the sign’s message is visible. Section 14-10.007(2)(c), Florida Administrative Code.";
    }
    if (violationType === ViolationType_1.ViolationType["09T"]) {
        return "This nonconforming sign is no longer substantially the same as it was on the date it became nonconforming.  s. 14-10.007(1), Florida Administrative Code. ";
    }
    if (violationType === ViolationType_1.ViolationType["09X"]) {
        return "This nonconforming sign no longer exists at the permitted location and is deemed abandoned by the Department, pursuant to s. 14-10.007(6)(b), Florida Administrative Code. ";
    }
    if (violationType === ViolationType_1.ViolationType["09Y"]) {
        return "Nonconforming sign may not continue to exist if it has been destroyed.  This sign has been destroyed pursuant to s. 14-10.007(6)(a), Florida Administrative Code. ";
    }
    if (violationType === ViolationType_1.ViolationType["09Z"]) {
        return "This nonconforming sign has not displayed advertising copy for a period of 12 months or more, and is deemed abandoned, pursuant to s. 14-10.007(6)(b), Florida Administrative Code. ";
    }
    if (violationType === ViolationType_1.ViolationType["16B"]) {
        return "This nonconforming sign no longer exists at the permitted location and is deemed abandoned by the Department, pursuant to s. 14-10.007(6)(b), Florida Administrative Code.";
    }
    if (violationType === ViolationType_1.ViolationType["New"]) {
        return "Exceeds 950 square feet of sign facing including all embellishments pursuant to s. 479.09(b), Florida States ";
    }
    if (violationType === ViolationType_1.ViolationType["11B"]) {
        return "COMPLETED SIGN NOT BUILT:  You did not erect your completed outdoor advertising sign at the proposed site within 270 days of the date of permit issuance by the Department. You are hereby notified to CEASE WORK. The outdoor advertising sign(s) permitted under the above-referenced permit tag number(s) is now considered to be an illegal sign.  All materials which have been used to date for the purposes of erection of this billboard must be removed within thirty (30) days from the date of this Notice or they will be removed by the Department and the cost assessed against you.";
    }
    if (violationType === ViolationType_1.ViolationType["12A"]) {
        return "Illegal vegetation removal.";
    }
    if (violationType === ViolationType_1.ViolationType["27A"]) {
        return "On _______, you were notified that your outdoor advertising permit renewal fees for " + new Date().getFullYear() + " _______ had not been received by the Department. You were given until ________ to submit the required fees along with a ten percent delinquency fee to prevent the expiration of your permits, or, alternatively, to request an administrative hearing.  Your payment was not received within that period, nor did you request an administrative hearing. The affected permits, by tag numbers, are:";
    }
    if (violationType === ViolationType_1.ViolationType["27B"]) {
        return "On ________ the Department received a Cancellation Certification from you (copy attached) authorizing cancellation of outdoor advertising permits, tag numbers: ______ ";
    }
    return "";
};
