"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPhoneNumber = void 0;
var LogService_1 = require("../services/LogService");
exports.formatPhoneNumber = function (phoneNumber, extension) {
    if (phoneNumber === undefined) {
        return '';
    }
    if (phoneNumber.length === 10) {
        var formatted = "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6);
        return extension !== undefined && extension.length > 0
            ? formatted + " (" + extension + ")"
            : formatted;
    }
    else if (phoneNumber.length === 7) {
        var formatted = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 4);
        return extension !== undefined && extension.length > 0
            ? formatted + " (" + extension + ")"
            : formatted;
    }
    else {
        LogService_1.logException("Implement formatPhoneNumber logic for: " + phoneNumber);
        return phoneNumber;
    }
};
