import axios from "axios";
import { IFacing, IFinding } from "oda-shared";
import { toast } from "react-toastify";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/finding`;

export const getFinding = async (findingId: string): Promise<IFinding> => {
  const response = await axios.get(`${baseUrl}/${findingId}`);
  return response.data;
};

export const updateFinding = async (finding: IFinding): Promise<IFacing> => {
  const response = await axios.patch(`${baseUrl}/${finding._id}`, finding);
  return response.data;
};

export const deleteFinding = async (findingId: string): Promise<any> => {
  const response = await axios.delete(`${baseUrl}/${findingId}`);
  return response.data;
};

export const addFinding = async (facing: IFacing): Promise<IFinding> => {
  const response = await axios.post(`${baseUrl}/`, {
    facing: facing,
  });

  return response.data;
};

export const generateNoticeOfNonCompliancePdfUri = (permitId: string, noticeId: string) =>
  `${baseUrl}/noticeOfNonCompliance/${permitId}/${noticeId}`;

export const generateRemovalCostLetterUri = (permitId: string, letterType: string) =>
  `${baseUrl}/removalCostLetter/${permitId}/${letterType}`;

//2024-01-05 KNATCSS Removing this for now as it does not seem used.
// export const emailNoticeOfNoncompliance = async (permitId: string, noticeId: string) => {
//   const response = await axios.get(`${baseUrl}/noticeOfNonComplianceEmail/${permitId}/${noticeId}`);
//   if (response.data === "OK") {
//     toast.success("Email Sent!");
//   } else {
//     toast.error("Something went wrong while sending email");
//   }
//   return response.data;
//};
