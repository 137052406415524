"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFieldReview = exports.isFieldSheetFdotComplete = exports.getNextStatuses = exports.validateSubmit = exports.isDocumentsComplete = exports.canEditFieldReviewSheet = exports.getAmountPaid = exports.canEditFdotFieldReviewSheet = exports.getFacingConformity = exports.canEditApplication = exports.canAddDocsToApplication = exports.canSplitApplication = exports.hasFieldReviewSheet = exports.hasFdotReviewSheet = exports.hasSignInfoTab = exports.isInFinalStatus = exports.getButtonVerbForFutureReviewStatus = exports.getButtonVerbForFutureStatus = exports.hasRelatedApplications = void 0;
var PermitApplicationStatus_1 = require("../models/enums/PermitApplicationStatus");
var ApplicationReviewStatus_1 = require("../models/enums/ApplicationReviewStatus");
var UserService_1 = require("./UserService");
var InternalRole_1 = require("../models/enums/InternalRole");
var DocumentTypeName_1 = require("../models/enums/DocumentTypeName");
var YesNo_1 = require("../models/enums/YesNo");
var OwnedOrLeased_1 = require("../models/enums/OwnedOrLeased");
var Index_1 = require("../Index");
exports.hasRelatedApplications = function (app) {
    if (!app) {
        return false;
    }
    if (app.parentFacingApplication) {
        return true;
    }
    if (app.additionalFacingApplications && app.additionalFacingApplications.length > 0) {
        return true;
    }
    return false;
};
exports.getButtonVerbForFutureStatus = function (futureStatus, currentStatus) {
    switch (futureStatus) {
        case PermitApplicationStatus_1.PermitApplicationStatus.Saved:
            //we should never have this as a future status...this is an initial status
            return "";
        case PermitApplicationStatus_1.PermitApplicationStatus.Received:
            return 'Submit';
        case PermitApplicationStatus_1.PermitApplicationStatus.UnderReview:
            if (currentStatus === PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections) {
                return 'Start Review of Corrections';
            }
            return 'Start Review';
        case PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete:
            return 'Return Incomplete Application';
        case PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending:
            return 'Set Application Pending';
        case PermitApplicationStatus_1.PermitApplicationStatus.Approved:
            return 'Approve Permit';
        case PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment:
            return 'Approve - Pending Payment';
        case PermitApplicationStatus_1.PermitApplicationStatus.Denied:
            return 'Deny Permit';
        case PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections:
            return 'Deny Permit - Pending Corrections';
        case PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus:
            return 'Set Application In Litigation';
        case PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired:
            return 'Return - Permit Not Required';
        case PermitApplicationStatus_1.PermitApplicationStatus.Withdrawn:
            return 'Withdraw (Administrative Purposes Only)';
    }
};
exports.getButtonVerbForFutureReviewStatus = function (futureReviewStatus) {
    //These are roughly in workflow order
    switch (futureReviewStatus) {
        //APPLICATION REVIEWER STEPS
        case ApplicationReviewStatus_1.ApplicationReviewStatus.UnderReview:
            return 'Start Review';
        //After starting review the Application Reviewer has two choices - 
        //send to management with their approval (initial review complete)
        //or send to management with the note this application is missing something (ManagementIncompleteReview)
        case ApplicationReviewStatus_1.ApplicationReviewStatus.InitialReviewComplete:
            return 'Send for 2nd Completeness Check';
        case ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementIncompleteReview:
            return 'Send to Management for Incomplete Review';
        //MANAGEMENT REVIEWER STEPS
        //Management reviewer has two choices - return to the customer as incomplete, or send for field review by consultant
        case ApplicationReviewStatus_1.ApplicationReviewStatus.ApplicationReviewIncomplete:
            return 'Send for Incomplete Letter';
        case ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementIncompleteReviewComplete:
            //2024-04-16 This is currently part of the workflow for incomplete apps that were returned...probably will be removed. Leaving here for now.
            return "Management Incomplete Review Completed";
        //Manager has approved for field review.      
        case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewToBeAssigned:
            return 'Send for Field Review';
        //CONSULTANT FIELD REVIEW STEPS
        //This one actually has no button, app moves to this review status when the Consultant manager selects a consultant field inspector.
        case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress:
            return '';
        //Field consultant inspector completes review, submits to consultant manager to confirm
        case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted:
            return 'Submit Field Review to Manager';
        //Consultant manager has two choices - 
        //send back to inspector to add missing info
        //or if complete, send to fdot
        case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete:
            return 'Return Field Review to Inspector';
        case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete:
            return 'Submit Field Review to FDOT';
        //APPLICATION REVIEWER STEPS
        //Application reviewer does final check. Has two choices -
        //send back for field review if something is missing
        //or make a recommendation and send to management final review
        case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation:
            return 'Return Field Review as Incomplete';
        case ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending:
            return 'Send for Management Final Review';
        //MANAGEMENT REVIEWER STEPS
        //Manager clicks this button to signify they are doing their final review
        case ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress:
            return 'Start Management Final Review';
        case ApplicationReviewStatus_1.ApplicationReviewStatus.ReturnedIncomplete:
            //...I don't think this one is used?, the main status signifies this.
            return 'Return Incomplete Application';
        case ApplicationReviewStatus_1.ApplicationReviewStatus.Completed:
            //Not used as part of the status workflow but I'm giving this a button text anyway just in case
            return "Complete Application Review";
    }
    return "";
};
exports.isInFinalStatus = function (app) {
    if (!app) {
        return false;
    }
    if (app.status === PermitApplicationStatus_1.PermitApplicationStatus.Approved ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.Denied ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired) {
        return true;
    }
    return false;
};
exports.hasSignInfoTab = function (app) {
    if (!app) {
        return false;
    }
    if (app.status === PermitApplicationStatus_1.PermitApplicationStatus.Approved ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.Denied ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired) {
        return true;
    }
    return false;
};
exports.hasFdotReviewSheet = function (app) {
    if (!app) {
        return false;
    }
    //If this app is in a final status, or under review and has gone through field review already:
    if (app.status === PermitApplicationStatus_1.PermitApplicationStatus.Approved ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.Denied ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired ||
        (app.status === PermitApplicationStatus_1.PermitApplicationStatus.UnderReview &&
            (app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.Resubmitted ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress))) {
        return true;
    }
    return false;
};
exports.hasFieldReviewSheet = function (app) {
    if (!app) {
        return false;
    }
    //Basically, if this app is in a status where it has undergone review or is about to
    if (app.status === PermitApplicationStatus_1.PermitApplicationStatus.Approved ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.Denied ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus ||
        app.status === PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired ||
        (app.status === PermitApplicationStatus_1.PermitApplicationStatus.UnderReview &&
            (app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.Resubmitted ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending ||
                app.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress))) {
        return true;
    }
    return false;
};
//The user has to be able to create applications, and if external the user has to be able to create apps
//for the account this permit was originally on.
exports.canSplitApplication = function (application, user) {
    var _a, _b, _c;
    if (!Index_1.Rights.canApplyForPermit(user)) {
        return false;
    }
    var isAccountUser = false;
    if (user.isExternal && user.externalAccounts) {
        var index = (_a = user.externalAccounts) === null || _a === void 0 ? void 0 : _a.findIndex(function (f) {
            var _a;
            return f.odaAccount === application.odaAccount || f.odaAccount === ((_a = application.odaAccount) === null || _a === void 0 ? void 0 : _a._id);
        });
        isAccountUser = index > -1;
    }
    var appCreateUserId = (_c = (_b = application.createUser) === null || _b === void 0 ? void 0 : _b._id) === null || _c === void 0 ? void 0 : _c.toString();
    if (appCreateUserId === user._id.toString() || isAccountUser) {
        return true;
    }
    return false;
};
exports.canAddDocsToApplication = function (application, user) {
    if (!application || !user) {
        return false;
    }
    if (application.isManual) {
        if (Index_1.Rights.canManagePermitDetails(user)) {
            return true;
        }
        return false;
    }
    //Online app. Customers can only manage docs when the entire thing is editable for them
    if (user.isExternal) {
        return exports.canEditApplication(application, user);
    }
    //Internal users can manage docs in any status except saved.
    return application.status !== PermitApplicationStatus_1.PermitApplicationStatus.Saved && Index_1.Rights.canManagePermitDetails(user);
};
exports.canEditApplication = function (application, user) {
    var _a, _b;
    if (!application || !user) {
        return false;
    }
    //Saved and denied pending corrections are the only two statuses that can be edited.
    if (!(application.status === PermitApplicationStatus_1.PermitApplicationStatus.Saved ||
        application.status === PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections)) {
        return false;
    }
    //Manual applications can only be edited by authorized internal users
    if (application.isManual) {
        if (Index_1.Rights.canManagePermitDetails(user)) {
            return true;
        }
        return false;
    }
    //Online applications can only be edited by an account user
    //or by the user who created it
    var isAccountUser = false;
    if (user.isExternal && user.externalAccounts) {
        var index = (_a = user.externalAccounts) === null || _a === void 0 ? void 0 : _a.findIndex(function (f) {
            var _a;
            return f.odaAccount === application.odaAccount || f.odaAccount === ((_a = application.odaAccount) === null || _a === void 0 ? void 0 : _a._id);
        });
        isAccountUser = index > -1;
    }
    var isCreateUser = user._id.toString() == application.createUser || user._id.toString() == ((_b = application.createUser) === null || _b === void 0 ? void 0 : _b._id);
    if (isCreateUser || isAccountUser) {
        return true;
    }
    return false;
};
exports.getFacingConformity = function (facing) {
    var _a;
    if (facing) {
        return facing.isConforming && ((_a = facing.structure) === null || _a === void 0 ? void 0 : _a.isConforming);
    }
    return false;
};
exports.canEditFdotFieldReviewSheet = function (application, user) {
    if (!application || !user || !application.reviewStatus) {
        return false;
    }
    if (application.status !== PermitApplicationStatus_1.PermitApplicationStatus.UnderReview) {
        return false;
    }
    //Can be edited any time after it has been through field review once. 
    var reviewStatuses = [ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete, ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation,
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted, ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete,
        ApplicationReviewStatus_1.ApplicationReviewStatus.Resubmitted, ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending,
        ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress];
    var hasBeenFieldReviewed = application.statusHistory.findIndex(function (sh) { return sh.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete; }) > -1;
    return (hasBeenFieldReviewed && reviewStatuses.includes(application.reviewStatus) && Index_1.Rights.canEditFdotFieldReviewSheet(user));
};
exports.getAmountPaid = function (application) {
    if (!application || !application.receivedDate) {
        return 0;
    }
    if (application.amountPaid != undefined) {
        return application.amountPaid;
    }
    return Index_1.getPermitCostByDate(new Date(application.receivedDate));
};
exports.canEditFieldReviewSheet = function (application, user) {
    if (!application || !user || application.status !== PermitApplicationStatus_1.PermitApplicationStatus.UnderReview) {
        return false;
    }
    if (!Index_1.Rights.canEditConsultantFieldReviewSheet(user))
        return false;
    //Check consultant rights
    if (user.isFieldConsultantInspector || user.isFieldConsultantManager) {
        var consultantStatuses = [ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress, ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete,
            ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation, ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted];
        if (user.isFieldConsultantInspector && (application.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress || application.reviewStatus === ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete)) {
            return true;
        }
        if (user.isFieldConsultantManager && application.reviewStatus && consultantStatuses.includes(application.reviewStatus)) {
            return true;
        }
        return false;
    }
    //Check internal rights
    //Converted apps can have their field app review updated in any part of the review cycle.
    if (application.isConverted && application.status === PermitApplicationStatus_1.PermitApplicationStatus.UnderReview) {
        return true;
    }
    //All other apps can have their field app review updated by the allowed internal users 
    //once it's been past the initial completeness review.
    var internalReviewStatuses = [
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewToBeAssigned,
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress,
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete,
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation,
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted,
        ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete,
        ApplicationReviewStatus_1.ApplicationReviewStatus.Resubmitted,
        ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending,
        ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress
    ];
    return (application.reviewStatus && internalReviewStatuses.includes(application.reviewStatus));
};
exports.isDocumentsComplete = function (application, additionalDocuments) {
    if (additionalDocuments === void 0) { additionalDocuments = []; }
    if (!application) {
        return false;
    }
    var availableDocuments = additionalDocuments.length === 0 ? additionalDocuments : application.additionalDocuments;
    var landUseDocument = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.DeterminationofLandUse; });
    if (!landUseDocument && !application.isManual) {
        return false;
    }
    var localGovernmentDocument = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.LocalGovernmentPermissionBuildingPermit; });
    var landOwnerDocument = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.LandownerPermission; });
    if (
    //!application.localGovernmentDocument &&
    //!application.localGovernmentBuildingPermitDocument &&
    //!application.landOwnerDocument &&
    !localGovernmentDocument &&
        !landOwnerDocument &&
        !application.isManual) {
        return false;
    }
    if (!application.isManual &&
        //&& !application.landOwnerDocument
        application.signSiteLeased === OwnedOrLeased_1.OwnedOrLeased.Leased &&
        !landOwnerDocument) {
        return false;
    }
    var cancelationCertification = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.PermitCancellationCertification; });
    if (!application.isManual &&
        application.isExistingPermitBeingCanceled === YesNo_1.YesNo.Yes &&
        //!application.cancelationCertification
        !cancelationCertification) {
        return false;
    }
    var locationSketch = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.LocationSketch; });
    if (
    //!application.locationSketch
    !locationSketch &&
        !application.isManual) {
        return false;
    }
    var sitePhotograph = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.PhotoSiteMarkings; });
    if (
    //!application.sitePhotograph
    !sitePhotograph &&
        !application.isManual) {
        return false;
    }
    var applicationDocument = availableDocuments === null || availableDocuments === void 0 ? void 0 : availableDocuments.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.PermitApplication; });
    if (application.isManual &&
        //&& !application.applicationDocument
        !applicationDocument) {
        return false;
    }
    return true;
};
exports.validateSubmit = function (application, user) {
    var _a, _b, _c, _d, _e, _f, _g;
    var result = { success: true, messages: [] };
    if (!application) {
        result.success = false;
        result.messages.push("Application is missing.");
        return result;
    }
    //There are a few converted apps that came in as under review, but some required fields were missing because old ODA does not store them.
    //For these, once approved and awaiting payment, they could not be added to cart from the certification page
    //Unless we short-circuit the validation.
    if (application.isManual && application.isConverted && application.status === PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment) {
        return result;
    }
    if (!application.isApplicationInformationComplete) {
        result.success = false;
        result.messages.push("Application Information tab is not complete.");
    }
    if (!application.isSignInformationComplete) {
        result.success = false;
        result.messages.push("Sign Information tab is not complete.");
    }
    if (!application.usRouteNumber && !application.stateRoadNumber && !application.localNameNumber && !application.isManual) {
        result.success = false;
        result.messages.push("US Route Number, State Road Number, or Local Road Name is required on the Sign Information Tab.");
    }
    if (!result.success) {
        return result;
    }
    var landUseDocument = (_a = application.additionalDocuments) === null || _a === void 0 ? void 0 : _a.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.DeterminationofLandUse; });
    if (
    //!application.landUseDocument
    !landUseDocument &&
        !application.isManual) {
        result.success = false;
        result.messages.push("Determination of Land Use Document Required on the Documents Tab.");
    }
    var landOwnerDocument = (_b = application.additionalDocuments) === null || _b === void 0 ? void 0 : _b.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.LandownerPermission; });
    if (
    //!application.landOwnerDocument
    !landOwnerDocument &&
        application.signSiteLeased === OwnedOrLeased_1.OwnedOrLeased.Leased &&
        !application.isManual) {
        result.success = false;
        result.messages.push("Land Owner Authorization document Required on the Documents Tab.");
    }
    var applicationDocument = (_c = application.additionalDocuments) === null || _c === void 0 ? void 0 : _c.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.PermitApplication; });
    if (application.isManual && //!application.applicationDocument
        !applicationDocument) {
        result.success = false;
        result.messages.push("Application PDF is required on the Documents Tab.");
    }
    var localGovernmentDocument = (_d = application.additionalDocuments) === null || _d === void 0 ? void 0 : _d.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.LocalGovernmentPermissionBuildingPermit; });
    if (
    //!application.localGovernmentDocument &&
    //!application.localGovernmentBuildingPermitDocument &&
    !localGovernmentDocument &&
        !application.isManual) {
        result.success = false;
        result.messages.push("Local Government Document or Local Government Building Permit Document Required on the Documents Tab.");
    }
    var locationSketch = (_e = application.additionalDocuments) === null || _e === void 0 ? void 0 : _e.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.LocationSketch; });
    if (
    //!application.locationSketch
    !locationSketch &&
        !application.isManual) {
        result.success = false;
        result.messages.push("Location Sketch Document Required on the Documents Tab.");
    }
    var sitePhotograph = (_f = application.additionalDocuments) === null || _f === void 0 ? void 0 : _f.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.PhotoSiteMarkings; });
    if (
    //!application.sitePhotograph
    !sitePhotograph &&
        !application.isManual) {
        result.success = false;
        result.messages.push("Site Photograph Document Required on the Documents Tab.");
    }
    var cancelationCertification = (_g = application.additionalDocuments) === null || _g === void 0 ? void 0 : _g.find(function (d) { return d.documentType === DocumentTypeName_1.DocumentTypeName.PermitCancellationCertification; });
    if (!application.isManual &&
        application.isExistingPermitBeingCanceled === YesNo_1.YesNo.Yes &&
        //!application.cancelationCertification
        !cancelationCertification) {
        result.success = false;
        result.messages.push("Cancellation Certification Document Required on the Documents Tab.");
    }
    if (!user.isExternal) {
        result.success = true;
    }
    return result;
};
var canDoAppReview = function (user) {
    return UserService_1.HasRoles(user, [InternalRole_1.InternalRole.ApplicationReviewer, InternalRole_1.InternalRole.FdotAdmin, InternalRole_1.InternalRole.ManagementReviewer]);
};
var canDoMgmtReview = function (user) {
    return UserService_1.HasRoles(user, [InternalRole_1.InternalRole.FdotAdmin, InternalRole_1.InternalRole.ManagementReviewer]);
};
var canDoConsultantReview = function (user) {
    return user.isFieldConsultantInspector || user.isFieldConsultantManager || canDoAppReview(user);
};
exports.getNextStatuses = function (application, user) {
    if (!application || !user) {
        return { statusOptions: [], reviewStatusOptions: [] };
    }
    switch (application.status) {
        //Received - app reviewer can move main status to under review. 
        case PermitApplicationStatus_1.PermitApplicationStatus.Received:
            if (canDoAppReview(user)) {
                return { statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.UnderReview], reviewStatusOptions: [] };
            }
            break;
        //Under review - a bunch here.    
        case PermitApplicationStatus_1.PermitApplicationStatus.UnderReview:
            switch (application.reviewStatus) {
                //App reviewer round one choices
                case ApplicationReviewStatus_1.ApplicationReviewStatus.UnderReview:
                    if (canDoAppReview(user)) {
                        //App reviewer can set initial review to complete or send to management for incomplete
                        return { statusOptions: [], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.InitialReviewComplete] };
                    }
                    break;
                //Field consultant choices
                case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress:
                case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete:
                    if (canDoConsultantReview(user)) {
                        return { statusOptions: [], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted] };
                    }
                    break;
                //Field consultant manager choices
                case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted:
                case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation:
                    if (user.isFieldConsultantManager || canDoAppReview(user)) {
                        return { statusOptions: [], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete, ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewReturnedIncomplete] };
                    }
                    break;
                //Back to the app reviewer        
                case ApplicationReviewStatus_1.ApplicationReviewStatus.ApplicationReviewIncomplete:
                    if (canDoAppReview(user)) {
                        return { statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.InitialReviewComplete] };
                    }
                    break;
                case ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete:
                case ApplicationReviewStatus_1.ApplicationReviewStatus.Resubmitted:
                    if (canDoAppReview(user)) {
                        return { statusOptions: [], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewNeedsAdditionalInformation, ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending] };
                    }
                    break;
                //Management reviewer steps
                case ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewPending:
                    if (canDoMgmtReview(user)) {
                        return { statusOptions: [], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress] };
                    }
                    break;
                case ApplicationReviewStatus_1.ApplicationReviewStatus.ManagementFinalReviewInProgress:
                    if (canDoMgmtReview(user)) {
                        console.log("App " + application.tempNumber + " already paid is " + application.alreadyPaid + ", manualPayment is " + application.manualPayment + ", and q19 is " + application.isApplicationResultOfFdotProject);
                        if ((application.manualPayment && application.isApplicationResultOfFdotProject !== YesNo_1.YesNo.Yes && !application.alreadyPaid)) {
                            return {
                                statusOptions: [
                                    PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment,
                                    PermitApplicationStatus_1.PermitApplicationStatus.Denied,
                                    PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections,
                                    PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired,
                                    PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending,
                                    PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete
                                ], reviewStatusOptions: []
                            };
                        }
                        else {
                            return {
                                statusOptions: [
                                    PermitApplicationStatus_1.PermitApplicationStatus.Approved,
                                    PermitApplicationStatus_1.PermitApplicationStatus.Denied,
                                    PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections,
                                    PermitApplicationStatus_1.PermitApplicationStatus.PermitNotRequired,
                                    PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending,
                                    PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete
                                ], reviewStatusOptions: []
                            };
                        }
                        break;
                    }
                case ApplicationReviewStatus_1.ApplicationReviewStatus.InitialReviewComplete:
                    if (canDoMgmtReview(user)) {
                        return { statusOptions: [], reviewStatusOptions: [ApplicationReviewStatus_1.ApplicationReviewStatus.ApplicationReviewIncomplete, ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewToBeAssigned] };
                    }
                    break;
            }
            //End huge case for Under Review sub-statuses
            break;
        case PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending:
            if (canDoMgmtReview(user)) {
                if (application.manualPayment && application.isApplicationResultOfFdotProject !== YesNo_1.YesNo.Yes) {
                    return {
                        statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment, PermitApplicationStatus_1.PermitApplicationStatus.Denied, PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections, PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus],
                        reviewStatusOptions: []
                    };
                }
                else {
                    return {
                        statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.Approved, PermitApplicationStatus_1.PermitApplicationStatus.Denied, PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections, PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus],
                        reviewStatusOptions: []
                    };
                }
            }
            break;
        case PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment:
            return {
                statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.Approved, PermitApplicationStatus_1.PermitApplicationStatus.Denied, PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections, PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus],
                reviewStatusOptions: []
            };
        //These two are FINAL and cannot be changed.
        case PermitApplicationStatus_1.PermitApplicationStatus.Approved:
        case PermitApplicationStatus_1.PermitApplicationStatus.ReturnedIncomplete:
            return {
                statusOptions: [],
                reviewStatusOptions: []
            };
        //Denied can go into litigation or denied pending correction statuses, that's it
        case PermitApplicationStatus_1.PermitApplicationStatus.Denied:
            if (canDoAppReview(user)) {
                return {
                    statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus, PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections],
                    reviewStatusOptions: []
                };
            }
            break;
        case PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections: {
            if (canDoAppReview(user)) {
                return {
                    statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus, PermitApplicationStatus_1.PermitApplicationStatus.Denied,
                        PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending, PermitApplicationStatus_1.PermitApplicationStatus.UnderReview],
                    reviewStatusOptions: []
                };
            }
        }
        //In litigation it can be moved back to pending, under review, denied, denied pending corrections, or it can be approved
        //Pick the right approved based on whether it's paid for or not
        case PermitApplicationStatus_1.PermitApplicationStatus.LitigationStatus:
            if (application.manualPayment && application.isApplicationResultOfFdotProject !== YesNo_1.YesNo.Yes) {
                return {
                    statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending, PermitApplicationStatus_1.PermitApplicationStatus.UnderReview,
                        PermitApplicationStatus_1.PermitApplicationStatus.ApprovedPendingPayment, PermitApplicationStatus_1.PermitApplicationStatus.Denied,
                        PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections], reviewStatusOptions: []
                };
            }
            else {
                return { statusOptions: [PermitApplicationStatus_1.PermitApplicationStatus.ApplicationPending, PermitApplicationStatus_1.PermitApplicationStatus.UnderReview,
                        PermitApplicationStatus_1.PermitApplicationStatus.Approved, PermitApplicationStatus_1.PermitApplicationStatus.Denied,
                        PermitApplicationStatus_1.PermitApplicationStatus.DeniedPendingCorrections], reviewStatusOptions: [] };
            }
    }
    return { statusOptions: [], reviewStatusOptions: [] };
};
exports.isFieldSheetFdotComplete = function (application) {
    if (!application.fdotIncorporated) {
        return false;
    }
    else if (application.fdotIncorporated === YesNo_1.YesNo.Yes && !application.fdotIncorporatedCity) {
        return false;
    }
    if (!application.fdotUrbanArea ||
        !application.fdotHighwayTypes ||
        !application.fdotOutsideUrbanArea ||
        !application.fdotScenicHwy ||
        !application.fdotLandUse) {
        return false;
    }
    if (!application.fdotNonconfirming) {
        return false;
    }
    else if (application.fdotNonconfirming === YesNo_1.YesNo.Yes && !application.fdotReasons) {
        return false;
    }
    if (!application.fdotPermitCancelled) {
        return false;
    }
    else if (application.fdotPermitCancelled === YesNo_1.YesNo.Yes &&
        (!application.fdotForVegetation || !application.fdotCancelledTags)) {
        return false;
    }
    if (!application.fdotCompetingApplication) {
        return false;
    }
    else if (application.fdotCompetingApplication === YesNo_1.YesNo.Yes && !application.fdotCompetingApplNos) {
        return false;
    }
    if (!application.fdotNoticeOfViolationNewJurisdictionAppl) {
        return false;
    }
    else if (application.fdotNoticeOfViolationNewJurisdictionAppl === YesNo_1.YesNo.Yes &&
        !application.fdotNoticeOfViolationNewJurisdictionNoticeNumber) {
        return false;
    }
    if (!application.fdotPending) {
        return false;
    }
    else if (application.fdotPending === YesNo_1.YesNo.Yes && !application.fdotPendingApplNumber) {
        return false;
    }
    return true;
};
exports.validateFieldReview = function (application) {
    var result = { success: true, messages: [] };
    if (!application)
        return result;
    if ((application.reviewStatus == ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewSubmitted || application.reviewStatus == ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewInProgress)
        && !application.isFieldReviewComplete) {
        result.success = false;
        result.messages.push("Field Review for application " + application.tempNumber + " is not complete.");
    }
    else if (application.reviewStatus == ApplicationReviewStatus_1.ApplicationReviewStatus.FieldReviewComplete && !application.isFieldFdotReviewComplete) {
        result.success = false;
        result.messages.push("Field Sheet (Application Reviewer) for application " + application.tempNumber + " is not complete.");
    }
    if (!result.success) {
        return result;
    }
    return result;
};
