"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IApplicableSection = void 0;
var IApplicableSection;
(function (IApplicableSection) {
    IApplicableSection["Accounts"] = "Accounts";
    IApplicableSection["PermitApplication"] = "Permit Application";
    IApplicableSection["FieldReview"] = "Field Review";
    IApplicableSection["Permits"] = "Permits";
    IApplicableSection["IllegalSigns"] = "IllegalSigns";
    IApplicableSection["Violations"] = "Violations";
    IApplicableSection["Hearings"] = "Hearings";
    IApplicableSection["VegMgt"] = "Veg Mgt";
    IApplicableSection["Findings"] = "Findings";
    IApplicableSection["Tasks"] = "Tasks";
    IApplicableSection["Structures"] = "Structures";
})(IApplicableSection = exports.IApplicableSection || (exports.IApplicableSection = {}));
