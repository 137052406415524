import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ILoggingService, logService, IAppConfig } from "oda-shared";
import { staffService, IStaffServiceOptions, Environment } from "@fdot/arculus-staff-service";

export var appInsights: ApplicationInsights;

export const initializeApp = (config: IAppConfig, authenticatedUserName: string) => {
  loadAppInsights(config.APPINSIGHTS_INSTRUMENTATIONKEY);

  // It isn't necessary to use the user's actual sign-in name.
  // It only has to be an ID that is unique to that user.
  // It must not include spaces or any of the characters ,;=|.
  const userNameToUse = authenticatedUserName.replace(/[,;=| ]+/g, "_");

  appInsights.setAuthenticatedUserContext(userNameToUse);

  initializeSharedServices(config);
  //initializeIcons();
};

const loadAppInsights = (applicationInsightsKey?: string) => {
  if (appInsights) {
    return;
  }

  if (applicationInsightsKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: applicationInsightsKey,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
      },
    });
    appInsights.loadAppInsights();
  } else {
    appInsights = {
      trackEvent: () => {},
      trackException: () => {},
      setAuthenticatedUserContext: () => {},
    } as any as ApplicationInsights;
  }
};

const initializeSharedServices = (config: IAppConfig) => {
  const cachingEnabled = fdot.process.env.STAFF_CACHE_ENABLED;
  let cacheDurationInMinutes = 0;
  if (cachingEnabled) {
    if (fdot.process.env.STAFF_CACHE_DURATION_IN_MINUTES) {
      cacheDurationInMinutes = fdot.process.env.STAFF_CACHE_DURATION_IN_MINUTES;
    }
  }

  if (config.ARCULUS_API_KEY) {
    const staffServiceOptions: IStaffServiceOptions = {
      arculusApiKey: config.ARCULUS_API_KEY,
      environment: fdot.process.env.APP_ENVIRONMENT === "production" ? Environment.Production : Environment.Test,
      cacheOptions: {
        cacheDurationInMinutes,
        cachingEnabled,
        slidingExpirationEnabled: true,
      },
    };

    staffService.initialize(staffServiceOptions);
  }

  const loggingService: ILoggingService = {
    log: (item: any) => console.log(item),

    logException: (error: any) => {
      const exceptionDetails = {
        error,
        id: "Exception",
        severityLevel: SeverityLevel.Critical,
      };
      appInsights.trackException(exceptionDetails);
    },
  };

  logService.initialize(loggingService);
};
