import { FloridaCounty } from "oda-shared";

export const getCountyPropertyAppraisalLink = (county: FloridaCounty) => {
  let href = "";
  switch (county) {
    case FloridaCounty.Alachua:
      href = "https://www.acpafl.org/";
      break;
    case FloridaCounty.Baker:
      href = "http://www.bakerpa.com/";
      break;
    case FloridaCounty.Bay:
      href = "https://www.baypa.net/index.html"; //https://www.qpublic.net/bay/index.html redirected to https://www.baypa.net/index.html
      break;
    case FloridaCounty.Bradford:
      href = "http://www.bradfordappraiser.com/";
      break;
    case FloridaCounty.Brevard:
      href = "https://www.bcpao.us/"; 
      break;
    case FloridaCounty.Broward:
      href = "https://www.bcpa.net/";
      break;
    case FloridaCounty.Calhoun:
      href = "https://calhounpa.net/#"; //https://www.qpublic.net/calhoun/ redirected to https://calhounpa.net/#
      break;
    case FloridaCounty.Charlotte:
      href = "http://www.ccappraiser.com";
      break;
    case FloridaCounty.Citrus:
      href = "https://www.citruspa.org/";
      break;
    case FloridaCounty.Clay:
      href = "https://ccpao.com/"; //https://www.ccpao.com/newsite/ redirected to https://ccpao.com/
      break;
    case FloridaCounty.Collier:
      href = "https://www.collierappraiser.com/";
      break;
    case FloridaCounty.Columbia:
      href = "http://g2.columbia.floridapa.com/index.asp?";
      break;
    case FloridaCounty.DeSoto:
      href = "http://www.desotopa.com/";
      break;
    case FloridaCounty.Dixie:
      href = "https://www.qpublic.net/dixie/";
      break;
    case FloridaCounty.Duval:
      href = "https://www.coj.net/departments/property-appraiser.aspx";
      break;
    case FloridaCounty.Escambia:
      href = "http://www.escpa.org/";
      break;
    case FloridaCounty.Flagler:
      href = "https://qpublic.net/fl/flagler/"; //https://www.flaglerpa.com/ redirected to https://qpublic.net/fl/flagler/
      break;
    case FloridaCounty.Franklin:
      href = "https://franklincountypa.net/#"; //https://www.qpublic.net/franklin/ redirected to https://franklincountypa.net/#
      break;
    case FloridaCounty.Gadsden:
      href = "https://gadsdenpa.com/";
      break;
    case FloridaCounty.Gilchrist:
      href = "https://www.qpublic.net/fl/gilchrist/";
      break;
    case FloridaCounty.Glades:
      href = "https://qpublic.net/fl/glades/";
      break;
    case FloridaCounty.Gulf:
      href = "https://gulfpa.com/#";
      break;
    case FloridaCounty.Hamilton:
      href = "https://hamiltonpa.com/";
      break;
    case FloridaCounty.Hardee:
      href = "https://hardeepa.com/"; //http://www.qpublic.net/hardee/ redirected to https://hardeepa.com/
      break;
    case FloridaCounty.Hendry:
      href = "https://hendryprop.com/";
      break;
    case FloridaCounty.Hernando:
      href = "http://www.hernandopa-fl.us/PAWebsite/(S(b0my2y55ewjrh5js2gfam555))/Default.aspx";
      break;
    case FloridaCounty.Highlands:
      href = "https://www.hcpao.org/"; // http://www.appraiser.co.highlands.fl.us/index.shtml redirected to https://www.hcpao.org/
      break;
    case FloridaCounty.Hillsborough:
      href = "https://www.hcpafl.org/";
      break;
    case FloridaCounty.Holmes:
      href = "https://www.qpublic.net/fl/holmes/";
      break;
    case FloridaCounty.IndianRiver:
      href = "https://www.ircpa.org/";
      break;
    case FloridaCounty.Jackson:
      href = "https://www.qpublic.net/fl/jackson/";
      break;
    case FloridaCounty.Jefferson:
      href = "https://jeffersonpa.net/";
      break;
    case FloridaCounty.Lafayette:
      href = "http://www.lafayettepa.com/";
      break;
    case FloridaCounty.Lake:
      href = "https://www.lakecopropappr.com/";
      break;
    case FloridaCounty.Lee:
      href = "https://www.leepa.org/"; //spinning for decades, but working
      break;
    case FloridaCounty.Leon:
      href = "https://www.leonpa.org/_dnn/";
      break;
    case FloridaCounty.Levy:
      href = "https://www.qpublic.net/fl/levy/";
      break;
    case FloridaCounty.Liberty:
      href = "https://libertypa.org/#";
      break;
    case FloridaCounty.Madison:
      href = "https://madisonpa.com/";
      break;
    case FloridaCounty.Manatee:
      href = "https://www.manateepao.com/";
      break;
    case FloridaCounty.Marion:
      href = "http://www.pa.marion.fl.us/";
      break;
    case FloridaCounty.Martin:
      href = "https://www.pa.martin.fl.us/";
      break;
    case FloridaCounty.MiamiDade:
      href = "https://www.miamidade.gov/pa/home.asp";
      break;
    case FloridaCounty.Monroe:
      href = "https://qpublic.net/fl/Monroe/";
      break;
    case FloridaCounty.Nassau:
      href = "https://www.nassauflpa.com/";
      break;
    case FloridaCounty.Okaloosa:
      href = "https://okaloosapa.com/";
      break;
    case FloridaCounty.Okeechobee:
      href = "http://www.okeechobeepa.com/";
      break;
    case FloridaCounty.Orange:
      href = "https://www.ocpafl.org/";
      break;
    case FloridaCounty.Osceola:
      href = "https://www.property-appraiser.org/";
      break;
    case FloridaCounty.PalmBeach:
      href = "https://www.pbcgov.org/papa/index.htm"; //http://www.co.palm-beach.fl.us/papa/index.htm redirected to https://www.pbcgov.org/papa/index.htm
      break;
    case FloridaCounty.Pasco:
      href = "https://www.pascopa.com/"; // http://www.appraiser.pascogov.com/ redirected to https://www.pascopa.com/
      break;
    case FloridaCounty.Pinellas:
      href = "https://www.pcpao.org/";
      break;
    case FloridaCounty.Polk:
      href = "https://www.polkpa.org/";
      break;
    case FloridaCounty.Putnam:
      href = "https://pa.putnam-fl.com/"; //http://www.putnam-fl.com/app/ redirected to https://pa.putnam-fl.com/
      break;
    case FloridaCounty.SantaRosa:
      href = "https://www.srcpa.org/";
      break;
    case FloridaCounty.Sarasota:
      href = "http://www.sc-pa.com/";
      break;
    case FloridaCounty.Seminole:
      href = "https://www.scpafl.org/";
      break;
    case FloridaCounty.StJohns:
      href = "https://www.sjcpa.us/";
      break;
    case FloridaCounty.StLucie:
      href = "http://www.paslc.org/";
      break;
    case FloridaCounty.Sumter:
      href = "https://www.sumterpa.com/";
      break;
    case FloridaCounty.Suwannee:
      href = "http://www.suwanneepa.com/";
      break;
    case FloridaCounty.Taylor:
      href = "https://qpublic.net/fl/taylor/"; //http://www.taylorcountypa.com/ redirected to https://qpublic.net/fl/taylor/
      break;
    case FloridaCounty.Union:
      href = "http://www.unionpa.com/";
      break;
    case FloridaCounty.Volusia:
      href = "https://vcpa.vcgov.org/#gsc.tab=0"; //http://webserver.vcgov.org/index.html redirected to https://vcpa.vcgov.org/#gsc.tab=0
      break;
    case FloridaCounty.Wakulla:
      href = "https://www.qpublic.net/fl/wakullapa/";
      break;
    case FloridaCounty.Walton:
      href = "https://waltonpa.com/";
      break;
    case FloridaCounty.Washington:
      href = "https://www.qpublic.net/fl/washington/propertyappraiser.html"; //page unavailable by domain
      break;
  }

  return href;
};
