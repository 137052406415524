"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessObjectDocumentAssociation = void 0;
var BusinessObjectDocumentAssociation;
(function (BusinessObjectDocumentAssociation) {
    BusinessObjectDocumentAssociation["Account"] = "Account";
    BusinessObjectDocumentAssociation["Application"] = "Application";
    BusinessObjectDocumentAssociation["Facing"] = "Facing";
    BusinessObjectDocumentAssociation["Finding"] = "Finding";
    BusinessObjectDocumentAssociation["IllegalSign"] = "IllegalSign";
    BusinessObjectDocumentAssociation["License"] = "License";
    BusinessObjectDocumentAssociation["Litigation"] = "Litigation";
    BusinessObjectDocumentAssociation["Notice"] = "Notice";
    BusinessObjectDocumentAssociation["Permit"] = "Permit";
    BusinessObjectDocumentAssociation["Tag"] = "Tag";
    BusinessObjectDocumentAssociation["TaskAssignment"] = "TaskAssignment";
    BusinessObjectDocumentAssociation["Violation"] = "Violation";
})(BusinessObjectDocumentAssociation = exports.BusinessObjectDocumentAssociation || (exports.BusinessObjectDocumentAssociation = {}));
;
