import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faFileExcel,
  faSun,
  faMoon,
  faMinusCircle,
  faPlusCircle,
  faPlus,
  faTrash,
  faEdit,
  faChevronDown,
  faChevronUp,
  faCloudDownloadAlt,
  faDownload,
  faUpload,
  faCloudUploadAlt,
  faSave,
  faBan,
  faQuestionCircle,
  faSearch,
  faCheck,
  faTimes,
  faShoppingCart,
  faMoneyBillWave,
  faFileInvoiceDollar,
  faUndo,
  faFilePdf,
  faAngleDoubleRight,
  faPrint,
  faTasks,
  faEnvelope,
  faTag,
  faRoad,
  faSquare
} from "@fortawesome/free-solid-svg-icons";
// If you need any regular icons use something like this to import:
// import { faSquare } from '@fortawesome/free-regular-svg-icons';

export const initializeIcons = () => {
  library.add(faExclamationTriangle);
  library.add(faFileExcel);
  library.add(faSun);
  library.add(faMoon);
  library.add(faMinusCircle);
  library.add(faPlusCircle);
  library.add(faPlus);
  library.add(faTrash);
  library.add(faEdit);
  library.add(faChevronDown);
  library.add(faChevronUp);
  library.add(faCloudDownloadAlt);
  library.add(faDownload);
  library.add(faCloudUploadAlt);
  library.add(faUpload);
  library.add(faSave);
  library.add(faBan);
  library.add(faQuestionCircle);
  library.add(faSearch);
  library.add(faCheck);
  library.add(faTimes);
  library.add(faShoppingCart);
  library.add(faMoneyBillWave);
  library.add(faFileInvoiceDollar);
  library.add(faUndo);
  library.add(faFilePdf);
  library.add(faAngleDoubleRight);
  library.add(faPrint);
  library.add(faTasks);
  library.add(faEnvelope);
  library.add(faTag);
  library.add(faRoad);
  library.add(faSquare);
};
