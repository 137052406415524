import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export class ExcelExportService {
  excelType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  excelExtension = ".xlsx";

  export = (
    json: any[],
    setHeadersCallback: (
      worksheet: XLSX.WorkSheet,
      complete: (worksheet: XLSX.WorkSheet, excelFileName: string) => void,
    ) => void,
  ): void => {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const completed = (ws: XLSX.WorkSheet, excelFileName: string): void => {
      const workbook: XLSX.WorkBook = {
        Sheets: { data: ws },
        SheetNames: ["data"],
      };
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.save(excelBuffer, excelFileName);
    };
    setHeadersCallback(worksheet, completed);
  };

  private save = (buffer: any, fileName: string): void => {
    const data: Blob = new Blob([buffer], { type: this.excelType });
    const dt = new Date();
    const timeString: string = `${dt.getFullYear()}_${
      dt.getMonth() + 1
    }_${dt.getDate()}_${dt.getHours().toString()}_${dt.getMinutes().toString()}_${dt.getSeconds().toString()}`;
    saveAs(data, fileName + "_" + timeString + this.excelExtension);
  };
}
