import * as dropDownConfigApi from "../../apis/DropDownConfigApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDropDownConfiguration } from "oda-shared";

type SliceState = {
  dropDownConfigs: IDropDownConfiguration[];
  isLoading: boolean;
};

const DropDownConfigurationSlice = createSlice({
  name: "dropDownConfiguration",
  initialState: {
    dropDownConfigs: [],
    selectedGroupName: "",
    selectedDropDownConfig: null,
    isLoading: false,
  } as SliceState,
  reducers: {
    updateDropDownConfigs: (state: SliceState, action: PayloadAction<IDropDownConfiguration[]>) => {
      state.dropDownConfigs = action.payload;
      return state;
    },
    updateDropDownGroup: (state: SliceState, action: PayloadAction<IDropDownConfiguration[]>) => {
      const groupName = action.payload[0].groupName;
      const copy = state.dropDownConfigs.filter(i => i.groupName !== groupName);
      for (const item of action.payload) {
        copy.push(item);
      }
      state.dropDownConfigs = copy;
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

export const { updateDropDownConfigs, updateDropDownGroup, updateIsLoading } = DropDownConfigurationSlice.actions;
export default DropDownConfigurationSlice.reducer;

export const fetchAllDropDownConfigurations = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  dropDownConfigApi
    .getDropDownConfigs()
    .then((dropDownConfigurations: IDropDownConfiguration[]) => {
      dispatch(updateDropDownConfigs(dropDownConfigurations));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const updateGroupOptions = (
  dropDownConfigs: IDropDownConfiguration[],
  successCallback: () => void,
  failureCallback: () => void,
) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  dropDownConfigApi
    .updateDropDownConfigsOptions(dropDownConfigs)
    .then((updatedGroup: IDropDownConfiguration[]) => {
      dispatch(updateDropDownGroup(updatedGroup));
      dispatch(updateIsLoading(false));
      successCallback();
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
      failureCallback();
    });
};
