import React from "react";
import {  
  GridColumnMenuWrapper,
  GridColumnProps
} from '@progress/kendo-react-grid';



export const CustomTitledHeaderCell = (props: GridColumnProps) => {  
  return (
    <React.Fragment>
      <span className="k-cell-inner" title={props.title}>
        <span className="k-link" onClick={props.onClick} title={props.title}>
          <span className="k-column-title font-weight-bold" title={props.title}>
            {props.title}
            {props.children}
          </span>
        </span>        
      </span>
    </React.Fragment>
  );
}
  
