"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountUserStatus = void 0;
var AccountUserStatus;
(function (AccountUserStatus) {
    AccountUserStatus["Requested"] = "Requested";
    AccountUserStatus["Approved"] = "Approved";
    AccountUserStatus["Denied"] = "Denied";
    AccountUserStatus["Inactivated"] = "Inactivated";
})(AccountUserStatus = exports.AccountUserStatus || (exports.AccountUserStatus = {}));
