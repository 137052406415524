import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import CaseSearchCriteria from "./CaseSearchCriteria";
import CaseSearchResults from "./CaseSearchResults";

const CaseSearch: React.FC = () => {
  const results = useSelector((state: RootState) => state.searchReducer.litigationResults);

  return (
    <>
      <CaseSearchCriteria />
      <CaseSearchResults results={results} />
    </>
  );
};

export default CaseSearch;
