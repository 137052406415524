"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyTypes = void 0;
var PropertyTypes;
(function (PropertyTypes) {
    PropertyTypes["NA"] = "N/A";
    PropertyTypes["Church"] = "Church";
    PropertyTypes["School"] = "School";
    PropertyTypes["Cemetery"] = "Cemetery";
    PropertyTypes["PublicPark"] = "Public Park";
    PropertyTypes["PublicReservation"] = "Public Reservation";
    PropertyTypes["PublicPlayground"] = "Public Playground";
    PropertyTypes["StateNationalPark"] = "State Or National Park";
})(PropertyTypes = exports.PropertyTypes || (exports.PropertyTypes = {}));
