import { combineReducers } from "@reduxjs/toolkit";
import configReducer from "./slices/ConfigSlice";
import userReducer from "./slices/UserSlice";
import userRoleReducer from "./slices/UserRolesSlice";
import odaAccountReducer from "./slices/OdaAccountSlice";
import inspectionRegionsReducer from "./slices/InspectionRegionsSlice";
import licenseApplicationReducer from "./slices/LicenseApplicationSlice";
import addressReducer from "./slices/AddressSlice";
import permitApplicationReducer from "./slices/PermitApplicationSlice";
import shoppingCartReducer from "./slices/ShoppingCartSlice";
import tagReducer from "./slices/TagSlice";
import fieldConsultantsReducer from "./slices/FieldConsultantsSlice";
import dashboardReducer from "./slices/DashboardSlice";
import searchReducer from "./slices/SearchSlice";
import permitReducer from "./slices/PermitSlice";
import paymentReducer from "./slices/PaymentSlice";
import permitRenewalDateReducer from "./slices/PermitRenewalDateSlice";
import litigationReducer from "./slices/LitigationSlice";
import dropDownConfigReducer from "./slices/DropDownConfigSlice";
import facingReducer from "./slices/FacingSlice";
import noticeReducer from "./slices/NoticeSlice";
import sectionReducer from "./slices/SectionSlice";
import patrolReducer from "./slices/PatrolSlice";
//import inventoryReducer from "./slices/AnnualInventorySlice";
import sectionInventoryReducer from "./slices/SectionInventorySlice";
import reportSummaryReducer from "./slices/ReportSummarySlice";
import reportReducer from "./slices/ReportSlice";
import faceReducer from "./slices/FaceSlice";
import facingInventoryReducer from "./slices/FacingInventorySlice";
import findingReducer from "./slices/FindingSlice";
import mileageReducer from "./slices/MileageSlice";
import reviewReminderReducer from "./slices/ReviewReminderSlice";
import violationReducer from "./slices/ViolationSlice";
import taskAssignmentReducer from "./slices/TaskAssignmentSlice";
import odaAccountCardReducer from "./slices/OdaAccountCardSlice";
import illegalSignReducer from "./slices/IllegalSignSlice";
import permitTransferReducer from "./slices/PermitTransferRequestSlice";

import { odaAccountApi } from "../apis/RTKQueries/OdaAccountSearchApi";
import { searchApis } from "../apis/RTKQueries/SearchApis";

const rootReducer = combineReducers({
  configReducer,
  userReducer,
  userRoleReducer,
  odaAccountReducer,
  odaAccountCardReducer,
  inspectionRegionsReducer,
  licenseApplicationReducer,
  addressReducer,
  permitApplicationReducer,
  shoppingCartReducer,
  tagReducer,
  fieldConsultantsReducer,  
  dashboardReducer,
  searchReducer,
  permitReducer,
  paymentReducer,
  permitRenewalDateReducer,
  litigationReducer,
  dropDownConfigReducer,
  facingReducer,
  faceReducer,
  facingInventoryReducer,
  noticeReducer,
  sectionReducer,
  patrolReducer,
  //inventoryReducer,
  sectionInventoryReducer,
  reportSummaryReducer,
  reportReducer,
  findingReducer,
  mileageReducer,
  reviewReminderReducer,
  violationReducer,
  taskAssignmentReducer,
  illegalSignReducer,
  permitTransferReducer,
  [odaAccountApi.reducerPath]: odaAccountApi.reducer,
  [searchApis.reducerPath]: searchApis.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
