import axios from "axios";
import { IPatrol, IUserProfile, IPatrolCountRow, stringUtils } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/patrols`;

export const getPatrol = async (id: string): Promise<IPatrol> => {
  const response = await axios.get(`${baseUrl}/patrol/${id}`);
  return response.data;
};
export const createPatrol = async (patrol: IPatrol, inspector: IUserProfile): Promise<IPatrol> => {
  const response = await axios.post(`${baseUrl}/patrol/${inspector?._id}`, patrol);
  return response.data;
};
export const updatePatrol = async (patrol: IPatrol, inspector: IUserProfile): Promise<IPatrol> => {
  const response = await axios.patch(`${baseUrl}/patrol/${patrol._id}/${inspector?._id}`, patrol);
  return response.data;
};

export const getAllPatrols = async (): Promise<IPatrol[]> => {
  const response = await axios.get(`${baseUrl}`);
  return response.data;
};

export const searchPatrols = async (county: string, year: string, inspectorId: string): Promise<IPatrol[]> => {
  const countyParam = stringUtils.isNullOrWhitespace(county) ? "-" : county;
  const response = await axios.get(`${baseUrl}/county/${countyParam}/${year}/${inspectorId}`);
  return response.data;
};

export const getSignsRemovedByRegion = async (selectedYear: string): Promise<IPatrolCountRow[]> => {
  const response = await axios.get(`${baseUrl}/patrol/signsRemovedCount/${selectedYear}`);
  return response.data;
};
export const getPatrolSummary = async (selectedYear: string): Promise<any[]> => {
  const response = await axios.get(`${baseUrl}/patrol/patrolSummary/${selectedYear}`);
  return response.data;
};
