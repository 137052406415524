import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import TransferSearchCriteria from "./TransferSearchCriteria";
import TransferSearchResults from "./TransferSearchResults";

const TransferSearch: React.FC = () => {
  const results = useSelector((state: RootState) => state.searchReducer.transferResults);

  return (
    <>
      <TransferSearchCriteria></TransferSearchCriteria>
      <TransferSearchResults results={results}></TransferSearchResults>
    </>
  );
};

export default TransferSearch;
