import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  performPermitSearch,
  performTransferSearch,
  updateCurrentPageIndex,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";
import { ButtonWithIcon } from "../../common/ButtonWithIcon";
import { CustomCard } from "../../common/CustomCard";
import EnumToOptions from "../../common/EnumOptions";
import { PermitStatus, FloridaCounty } from "oda-shared";

const PermitSearchCriteria: React.FC = () => {
  const [transferDateFrom, setTransferDateFrom] = useState("");
  const [transferDateTo, setTransferDateTo] = useState("");
  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, pageChanged } = useSelector(
    (state: RootState) => state.searchReducer,
  );
  const user = useSelector((state: RootState) => state.userReducer.user);
  const dispatch = useDispatch();

  const reset = () => {
    setTransferDateFrom("");
    setTransferDateTo("");
  };

  const criteria = useMemo(() => {
    return {
      transferDateTo,
      transferDateFrom,
      sortColumn,
      sortDirection,
      currentPageIndex,
      itemsPerPage,
    };
  }, [transferDateTo, transferDateFrom, sortColumn, sortDirection, currentPageIndex, itemsPerPage]);

  useEffect(() => {
    if (pageChanged) {
      dispatch(performTransferSearch(criteria as any));
      dispatch(updatePageChanged(false));
    }
  }, [pageChanged, criteria, dispatch]);

  useEffect(() => {
    dispatch(updateSortColumn("permitNumber"));
    dispatch(updateSortDirection("desc"));
  }, [dispatch]);

  return (
    <CustomCard collapsible={true} headerText="Permit Transfer Search Criteria">
      <div className="row">
        <div className="col-lg-3">
          <label className="font-weight-bold" htmlFor="transferDateFrom">
            Transfer Date From:
          </label>
          <input
            className="form-control"
            type="date"
            name="transferDateFrom"
            id="transferDateFrom"
            title="transferDateFrom"
            value={transferDateFrom}
            onChange={event => setTransferDateFrom(event.target.value)}></input>
        </div>
        <div className="col-lg-3">
          <label className="font-weight-bold" htmlFor="transferDateTo">
            Transfer Date To:
          </label>
          <input
            className="form-control"
            type="date"
            name="transferDateTo"
            id="transferDateTo"
            title="transferDateTo"
            value={transferDateTo}
            onChange={event => setTransferDateTo(event.target.value)}></input>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg">
          <div className="text-center">
            <ButtonWithIcon buttonType="danger" icon="undo" buttonText="Reset" titleText="Reset" onClick={reset} />
            &nbsp;&nbsp;
            <ButtonWithIcon
              buttonType="primary"
              icon="search"
              buttonText="Search"
              onClick={() => {
                dispatch(updateCurrentPageIndex(0));
                dispatch(performTransferSearch({ ...criteria, currentPageIndex: 0 }));
              }}></ButtonWithIcon>
          </div>
        </div>
      </div>
    </CustomCard>
  );
};

export default PermitSearchCriteria;
