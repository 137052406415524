import mileageApi from "axios";
import axios from "axios";
import { IInspectionRegion, IMileage, IUserProfile, IMileageCountRow } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/mileages`;

export const getMileage = async (id: string): Promise<IMileage> => {
  const response = await mileageApi.get(`${baseUrl}/mileage/${id}`);
  return response.data;
};

export const createMileage = async (
  mileage: IMileage,
  region: IInspectionRegion,
  inspector: IUserProfile,
): Promise<IMileage> => {
  const response = await mileageApi.post(`${baseUrl}/mileage/${region?._id}/${inspector?._id}`, mileage);
  return response.data;
};
export const updateMileage = async (
  mileage: IMileage,
  region: IInspectionRegion,
  inspector: IUserProfile,
): Promise<IMileage> => {
  const response = await mileageApi.patch(
    `${baseUrl}/mileage/${mileage?._id}/${region?._id}/${inspector?._id}`,
    mileage,
  );
  return response.data;
};

export const getAllMileages = async (pageIndex: number): Promise<{ totalCount: number; results: Array<IMileage> }> => {
  const response = await mileageApi.get(`${baseUrl}/${pageIndex}`);
  return response.data;
};

export const deleteMileage = async (id: string): Promise<void> => {
  const response = await mileageApi.delete(`${baseUrl}/mileage/${id}`);
  return response.data;
};

export const getMileageCountByRegion = async (
  selectedYear: string,
  selectedQuarter: string,
): Promise<IMileageCountRow[]> => {
  const response = await axios.get(`${baseUrl}/mileageCount/${selectedYear}/${selectedQuarter}`);
  return response.data;
};
