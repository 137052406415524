"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailSignatureType = void 0;
var EmailSignatureType;
(function (EmailSignatureType) {
    EmailSignatureType["PermitsAccounts"] = "Permits and Account Services";
    EmailSignatureType["Applications"] = "Applications";
    EmailSignatureType["IllegalSigns"] = "Illegal Signs";
    EmailSignatureType["Compliance"] = "Compliance";
})(EmailSignatureType = exports.EmailSignatureType || (exports.EmailSignatureType = {}));
