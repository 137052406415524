import * as paymentApi from "../../apis/payment/PaymentApi";
import * as accountApi from "../../apis/AccountApi";
import * as invoiceApi from "../../apis/payment/InvoiceApi";
import * as lineItemApi from "../../apis/payment/LineItemApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAddLineItemResult,
  ICreateInvoiceAndUpdateShoppingCartResult,
  IInvoice,
  ILineItem,
  IOdaAccount,
  IPayment,
  IShoppingCart,
  PaymentStatus,
} from "oda-shared";

type SliceState = {
  payment: IPayment | null;
  invoice: IInvoice | null;
  isLoading: boolean;
  account: IOdaAccount | null;
  receivedPayments: IPayment[];
  unpaidInvoicesOnAccount: IInvoice[];
  unpaidInvoicesNotOnAccount: IInvoice[];
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payment: null,
    invoice: null,
    isLoading: false,
    account: null,
    receivedPayments: [],
    unpaidInvoicesOnAccount: [],
    unpaidInvoicesNotOnAccount: [],
  } as SliceState,
  reducers: {
    updatePayment: (state: SliceState, action: PayloadAction<IPayment>) => {
      state.payment = action.payload;
      return state;
    },
    updateInvoice: (state: SliceState, action: PayloadAction<IInvoice | null>) => {
      state.invoice = action.payload;
      return state;
    },
    updateAccount: (state: SliceState, action: PayloadAction<IOdaAccount>) => {
      state.account = action.payload;
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    updateReceivedPayments: (state: SliceState, action: PayloadAction<IPayment[]>) => {
      state.receivedPayments = action.payload;
      return state;
    },
    updateUnpaidInvoicesOnAccount: (state: SliceState, action: PayloadAction<IInvoice[]>) => {
      state.unpaidInvoicesOnAccount = action.payload;
      return state;
    },
    updateUnpaidInvoicesNotOnAccount: (state: SliceState, action: PayloadAction<IInvoice[]>) => {
      state.unpaidInvoicesNotOnAccount = action.payload;
      return state;
    },
    removeLineItemFromInvoice: (state: SliceState, action: PayloadAction<string>) => {
      if (!state.invoice || !state.invoice.lineItems) {
        return state;
      }
      state.invoice.lineItems = state.invoice.lineItems.filter(i => i._id.toString() !== action.payload);
      return state;
    },
    updateLineItemFromInvoice: (state: SliceState, action: PayloadAction<ILineItem>) => {
      if (!state.invoice || !state.invoice.lineItems) {
        return state;
      }
      var copy = [];
      for (const lineItem of state.invoice.lineItems) {
        if (lineItem._id.toString() === action.payload._id.toString()) {
          copy.push(action.payload);
        } else {
          copy.push(lineItem);
        }
      }
      state.invoice.lineItems = copy;
      return state;
    },
    addLineItemInternal: (state: SliceState, action: PayloadAction<ILineItem>) => {
      if (state.invoice && state.invoice._id.toString() === action.payload.invoiceId?.toString()) {
        state.invoice.lineItems.push(action.payload);
      }
      return state;
    },
  },
});

export const {
  updatePayment,
  updateInvoice,
  updateAccount,
  updateIsLoading,
  updateReceivedPayments,
  updateUnpaidInvoicesOnAccount,
  updateUnpaidInvoicesNotOnAccount,
  removeLineItemFromInvoice,
  updateLineItemFromInvoice,
  addLineItemInternal,
} = paymentSlice.actions;
export default paymentSlice.reducer;

export const fetchPayment = (id: string | number) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  paymentApi
    .getPayment(id)
    .then((payment: IPayment) => {
      dispatch(updatePayment(payment));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const editPayment =
  (payment: IPayment, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    paymentApi
      .updatePayment(payment._id.toString(), payment)
      .then((updatedPayment: IPayment) => {
        dispatch(updatePayment(updatedPayment));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

  export const createPayment =
  (payment: IPayment, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    
    dispatch(updateIsLoading(true));
    paymentApi
      .createNewPayment( payment)
      .then((updatedPayment: IPayment) => {
        dispatch(updatePayment(updatedPayment));
        dispatch(updateIsLoading(false));       
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));     
        failureCallback();
      });
    return payment;
  };

export const fetchInvoice = (id: string | number) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  invoiceApi
    .getInvoice(id)
    .then((invoice: IInvoice) => {
      dispatch(updateInvoice(invoice));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchAccount = (id: string) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  accountApi
    .getAccount(id)
    .then((account: IOdaAccount) => {
      dispatch(updateAccount(account));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchAccountPayments = (accountId: string) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  paymentApi
    .fetchPaymentsByAccount(accountId)
    .then((payments: IPayment[]) => {
      dispatch(updateReceivedPayments(payments.filter(i => i.paymentStatus === PaymentStatus.Received)));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchAllReceivedPayments = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  paymentApi
    .fetchReceivedPayments()
    .then((payments: IPayment[]) => {
      dispatch(updateReceivedPayments(payments));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchUnpaidInvoicesNotOnAccount = (accountId: string, paymentId: string) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  invoiceApi
    .fetchUnpaidAndPaymentInvoices(paymentId)
    .then((invoices: IInvoice[]) => {
      dispatch(updateUnpaidInvoicesNotOnAccount(invoices.filter(i => i.accountId !== accountId)));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const fetchUnpaidInvoicesOnAccount = (accountId: string, paymentId: string) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  invoiceApi
    .fetchAccountInvoices(accountId)
    .then((invoices: IInvoice[]) => {
      dispatch(
        updateUnpaidInvoicesOnAccount(invoices.filter(i => !i.datePaid || i.paymentIds?.indexOf(paymentId) !== -1)),
      );
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const updateInvoiceComment =
  (id: string | number, comment: string, successCallback: () => void, failureCallback: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    invoiceApi
      .updateComment(id, comment)
      .then((invoice: IInvoice) => {
        dispatch(updateInvoice(invoice));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const unPayInvoice =
  (id: string | number, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    invoiceApi
      .unPay(id)
      .then((invoice: IInvoice) => {
        dispatch(updateInvoice(invoice));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const deleteInvoice =
  (id: string | number, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    invoiceApi
      .deleteInvoice(id)
      .then(() => {
        dispatch(updateInvoice(null));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const refundInvoice =
  (id: string | number, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    invoiceApi
      .refundInvoice(id)
      .then((invoice: IInvoice) => {
        dispatch(updateInvoice(invoice));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const associateInvoices =
  (paymentId: string, invoiceIds: string[], successCallback: () => void, failureCallback: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    paymentApi
      .associateInvoices(paymentId, invoiceIds)
      .then((payment: IPayment) => {
        dispatch(updatePayment(payment));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const deleteLineItem =
  (lineItemId: string, invoiceId: string, successCallback: () => void, failureCallback: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    lineItemApi
      .deleteLineItem(lineItemId)
      .then(updatedInvoice => {
        dispatch(removeLineItemFromInvoice(lineItemId));
        dispatch(fetchInvoice(invoiceId));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const refundLineItem =
  (lineItemId: string, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    lineItemApi
      .refundLineItem(lineItemId)
      .then(updatedLineItem => {
        dispatch(updateLineItemFromInvoice(updatedLineItem));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const undoRefundLineItem =
  (lineItemId: string, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    lineItemApi
      .undoRefundLineItem(lineItemId)
      .then(updatedLineItem => {
        dispatch(updateLineItemFromInvoice(updatedLineItem));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const updateLineItem =
  (lineItem: ILineItem, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    lineItemApi
      .updateLineItem(lineItem)
      .then(updatedLineItem => {
        dispatch(updateLineItemFromInvoice(updatedLineItem));
        if (lineItem.invoiceId) {
          dispatch(fetchInvoice(lineItem.invoiceId));
        }
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const addLineItems =
  (
    invoiceId: string,
    shoppingCartItemIds: string[],
    successCallback: (shoppingCart: IShoppingCart) => void,
    failureCallback: () => void,
  ) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    invoiceApi
      .addLineItems(invoiceId, shoppingCartItemIds)
      .then((result: IAddLineItemResult) => {
        dispatch(updateIsLoading(false));
        dispatch(fetchInvoice(invoiceId));
        successCallback(result.shoppingCart);
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const associatePayments =
  (invoiceId: string, paymentIds: string[], successCallback: () => void, failureCallback: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    invoiceApi
      .associatePayments(invoiceId, paymentIds)
      .then((invoice: IInvoice) => {
        dispatch(updateInvoice(invoice));
        dispatch(updateIsLoading(false));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const depositPaymentsAndCreateInvoice =
  (
    paymentIds: string[],
    successCallback: (itemResult: ICreateInvoiceAndUpdateShoppingCartResult) => void,
    failureCallback: (validationIssues: string[]) => void,
  ) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    paymentApi
      .depositPaymentsAndCreateInvoice(paymentIds)
      .then((result: ICreateInvoiceAndUpdateShoppingCartResult) => {
        const wasSuccessful = result && (!result.validationIssues || result.validationIssues.length === 0);
        dispatch(updateIsLoading(false));
        if (wasSuccessful) {
          successCallback(result);
        } else {
          failureCallback(result.validationIssues);
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback(["An Unknown Error Occurred!"]);
      });
  };

export const addLineItem =
  (lineItem: ILineItem, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    lineItemApi
      .createLineItem(lineItem)
      .then((updatedLineItem: ILineItem) => {
        dispatch(updateIsLoading(false));
        dispatch(addLineItemInternal(updatedLineItem));
        if (lineItem.invoiceId) {
          dispatch(fetchInvoice(lineItem.invoiceId));
        }
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };
