import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITag } from "oda-shared";
import * as TagApi from "../../apis/TagApi";

type SliceState = {
  accountTags: Record<string, Record<string, ITag>>;
  tags: ITag[];
};

const tagSlice = createSlice({
  name: "tag",
  initialState: {
    accountTags: {},
    tags: [],
  } as SliceState,
  reducers: {
    setAccountTag: (state: SliceState, action: PayloadAction<[string, ITag[]]>) => {
      state.accountTags[action.payload[0]] = {};
      action.payload[1].forEach(ir => {
        state.accountTags[action.payload[0]][ir._id] = ir;
      });

      return state;
    },
    setTags: (state: SliceState, action: PayloadAction<ITag[]>) => {
      state.tags = action.payload;
      return state;
    },
  },
});

export const { setAccountTag, setTags } = tagSlice.actions;
export default tagSlice.reducer;

export const loadTags = () => async (dispatch: any) => {
  const tags = await TagApi.getTags();
  dispatch(setTags(tags));
};

export const loadTagsForOdaAccount = (accountId: string) => async (dispatch: any) => {
  const tags = await TagApi.getTagsForOdaAccount(accountId);
  dispatch(setTags(tags));
};
