import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ISection, stringUtils } from "oda-shared";
import { getAllSections } from "../../redux/slices/SectionSlice";
import { RootState } from "../../redux/RootReducer";
import { searchSectionByCounty } from "../../redux/slices/SearchSlice";

interface IProps {
  id: string;
  county?: string;
  selectedSection: ISection | null;
  disabled?: boolean;
  onChange: (selected: ISection | null) => void;
}

export const SectionSelector: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const allSections = useSelector((state: RootState) => state.sectionReducer.allSections);
  const sectionsByCounty = useSelector((state: RootState) => state.searchReducer.sectionByCountyResults);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Array<ISection>>([]);
  const [selectedSection, setSelectedSection] = useState<Array<ISection>>([]);
  const [sections, setSections] = useState<Array<ISection>>([]);

  useEffect(() => {
    if (props.county) {
      dispatch(searchSectionByCounty(props.county));
    }
    if (!allSections || allSections.length === 0) {
      dispatch(getAllSections());
    }
  }, [dispatch, props.county]);

  useEffect(() => {
    if (
      props.county &&
      !stringUtils.isNullOrWhitespace(props.county) &&
      sectionsByCounty &&
      sectionsByCounty.length > 0
    ) {
      setSections(sectionsByCounty);
    } else {
      if (allSections && allSections.length > 0) {
        setSections(allSections);
      }
    }
  }, [dispatch, sectionsByCounty, allSections, props.county]);

  useEffect(() => {
    const selectedAccount = props.selectedSection === null ? [] : [props.selectedSection];
    setSelectedSection(selectedAccount);
  }, [props.selectedSection]);

  const onChange = (selected: Array<ISection>) => {
    let returnVal: ISection | null = null;
    if (selected && selected.length > 0) {
      returnVal = selected[0];
    }
    setSelectedSection(selected);
    props.onChange(returnVal);
  };

  const handleSearch = async (searchString: string) => {
    if (!sections || sections.length < 1) {
      return;
    }
    setIsLoading(true);
    const matches = sections.filter(item =>
      item.rciSection.toString().toUpperCase().startsWith(searchString.toUpperCase()),
    );
    setOptions(matches);
    setIsLoading(false);
  };

  const getLabel = (section: ISection | null | undefined): string => {
    return section && section.rciSection ? `${section.rciSection}` : "";
  };

  return (
    <AsyncTypeahead
      id={props.id}
      inputProps={{
        "aria-label": props.id,
        id: props.id,
        title: "Enter Section Number",
        maxLength: 8,
        type: "tel",
        pattern: "[0-9]*",
      }}
      useCache={false} //keep useCache as false so old values does not show up
      allowNew={false}
      isLoading={isLoading}
      multiple={false}
      onInputChange={(input, e) => (input = input.slice(0, 8))}
      onKeyDown={e => {}}
      minLength={3}
      options={options}
      selected={selectedSection}
      onSearch={handleSearch}
      onChange={onChange}
      placeholder="Search Section Number (8 Digits)"
      disabled={props.disabled}
      labelKey={(option: any) => getLabel(option)}
    />
  );
};
