import * as reportSummaryApi from "../../apis/ReportSummaryApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISummary } from "oda-shared";

type SliceState = {
  summary: ISummary;
  selectedEmail: ISummary;
  isLoading: boolean;
};

const reportSummarySlice = createSlice({
  name: "summary",
  initialState: {
    summary : {
  },
  
    isLoading: false,
  } as SliceState,
  reducers: {
    updateReportSummary: (state: SliceState, action: PayloadAction<ISummary>) => {
      state.summary = action.payload;
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

export const { updateReportSummary, updateIsLoading } = reportSummarySlice.actions;
export default reportSummarySlice.reducer;

export const fetchReprotSummaryData = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  reportSummaryApi
    .getReportSummary()
    .then((summary: ISummary) => {
      dispatch(updateReportSummary(summary));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

