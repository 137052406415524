import axios from "axios";
import {
  NinetyDayReportType,
  RpsRow,
  INinetyDayReportRow,
  PermitReportRow,
  StructureCountByCountyRow,
  PermitActivityRange,
} from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/reports`;

export const fetchRpsReportData = async (startDate: Date, endDate: Date): Promise<RpsRow[]> => {
  const response = await axios.get(
    `${baseUrl}/rps?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
  );
  return response.data;
};

export const fetchNinetyDayReportData = async (reportType: NinetyDayReportType): Promise<INinetyDayReportRow[]> => {
  const response = await axios.get(`${baseUrl}/ninety-day-report/${reportType}`);
  return response.data;
};

export const fetchPermitActivityForAccountData = async (
  accountId: string,
  reportRange: PermitActivityRange,
): Promise<PermitReportRow[]> => {
  const response = await axios.get(`${baseUrl}/PermitActivityForAccount/${accountId}/${reportRange}`);
  return response.data;
};

export const fetchApplicationsUnderReviewData = async (): Promise<INinetyDayReportRow[]> => {
  const response = await axios.get(`${baseUrl}/applicationsUnderReview`);
  return response.data;
};

export const fetchPermitNinetyDayData = async (): Promise<PermitReportRow[]> => {
  const response = await axios.get(`${baseUrl}/permitNinetyDay`);
  return response.data;
};

export const fetchStructureCountsByCountyData = async (): Promise<StructureCountByCountyRow[]> => {
  const response = await axios.get(`${baseUrl}/structureCountsByCounty`);
  return response.data;
};
