"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagCertificationStatus = void 0;
var TagCertificationStatus;
(function (TagCertificationStatus) {
    TagCertificationStatus["SelfCertified"] = "Self Certified";
    TagCertificationStatus["Certified"] = "Certified";
    TagCertificationStatus["NotPostedOrVisible"] = "Not Posted Or Visible";
    //Added for conversion
    TagCertificationStatus["Unknown"] = "Unknown";
})(TagCertificationStatus = exports.TagCertificationStatus || (exports.TagCertificationStatus = {}));
