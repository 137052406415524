export const setEmptyStringsAsNull = (value: any): any => {
  if (value.trim() === "") {
    return null;
  }
  return value;
};

export const roundUpCoordinates = (coordinate: string) => {
  let roundedCoordinate = coordinate;
  if (coordinate.includes(".")) {
    const decimalValue = coordinate.substring(coordinate.indexOf(".") + 1);
    if (decimalValue.length > 8) {
      roundedCoordinate = `${coordinate.substring(0, coordinate.indexOf("."))}.${decimalValue.substring(0, 8)}`;
    }
  }

  return roundedCoordinate;
};

// export const roundUpMilepost = (milepost: string) => {
//   let roundedMilepost = milepost.toString();
//   console.log("milepost", milepost);
//   if (milepost && roundedMilepost.includes(".")) {
//     const decimalValue = roundedMilepost.substring(roundedMilepost.indexOf(".") + 1);

//     console.log("decimalValue", decimalValue);
//     if (decimalValue.length === 0) {
//       roundedMilepost = `${roundedMilepost.substring(0, roundedMilepost.indexOf("."))}.000`;
//     }

//     if (decimalValue.length === 1) {
//       roundedMilepost = `${roundedMilepost.substring(0, roundedMilepost.indexOf("."))}.${decimalValue}00`;
//     }

//     if (decimalValue.length === 2) {
//       roundedMilepost = `${roundedMilepost.substring(0, roundedMilepost.indexOf("."))}.${decimalValue}0`;
//     }

//     if (decimalValue.length > 3) {
//       roundedMilepost = `${roundedMilepost.substring(0, roundedMilepost.indexOf("."))}.${decimalValue.substring(0, 3)}`;
//     }
//   }

//   return roundedMilepost;
// };

export const getCurrencyFormat = (cost: number) => {
  const val = cost ?? 0;
  return val.toLocaleString("en-US", { style: "currency", currency: "USD" })
};
