import React, { useEffect, useState } from "react";
import { FloridaCounty } from "oda-shared";
import { getCountyPropertyAppraisalLink } from "../../utils/CountyAppraisalLinkHelper";

interface IProps {
  county: FloridaCounty;
  label?: string;
}

const CountyPropertyAppraisalLink = (props: IProps) => {
  const [validCounty, setValidCounty] = useState(false);

  useEffect(() => {
    const countyExists: boolean = Object.values(FloridaCounty).includes(props.county);
    props.county !== null && props.county !== undefined && countyExists ? setValidCounty(true) : setValidCounty(false);
  }, [props.county, props.label]);

  return (
    <>
      {validCounty ? (
        <a
          href={getCountyPropertyAppraisalLink(props.county)}
          title={`${props.county} Property Appraisal Link - Opens in a new Browser Window`}
          rel="noopener noreferrer"
          target="_blank">
          {props.label ? props.label : `${props.county} Property Appraisal Link`}
        </a>
      ) : (
        <span>{props.label ? props.label : ``}</span>
      )}
    </>
  );
};

export default CountyPropertyAppraisalLink;
