"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigurationOption = void 0;
var ConfigurationOption;
(function (ConfigurationOption) {
    ConfigurationOption["VShaped"] = "V-Shaped";
    ConfigurationOption["BackToBack"] = "Back to back";
    ConfigurationOption["SingleFacing"] = "Single Facing";
    ConfigurationOption["SideBySide"] = "Side By Side";
    ConfigurationOption["Monumental"] = "Monumental";
    ConfigurationOption["Stacked"] = "Stacked";
    ConfigurationOption["Unknown"] = "Unknown";
    ConfigurationOption["Other"] = "Other";
})(ConfigurationOption = exports.ConfigurationOption || (exports.ConfigurationOption = {}));
