import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAccount } from "../../../redux/slices/OdaAccountSlice";
import { RootState } from "../../../redux/RootReducer";
import { CustomCard } from "../../common/CustomCard";
import { format } from "date-fns";
import { formatDateWithNoTimeZone } from "../../../utils/DateUtils";
import { documentPermitPictureUrl } from "../../../apis/DocumentApi";
import SafeLink from "../../common/SafeLink";

const PublicOdaAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentAccount = useSelector((state: RootState) => state.odaAccountReducer.currentAccount);

  useEffect(() => {
    if (id) {
      dispatch(getAccount(id, true));
    }
  }, [id, dispatch]);

  if (!currentAccount) {
    return <></>;
  }
  return (
    <div>
      <CustomCard headerText="Account Information">
        <div className="row">
          <div className="col font-weight-bold">Account Name:</div>
          <div className="col">{currentAccount.accountName}</div>
          <div className="col font-weight-bold">Contact Name:</div>
          <div className="col">{currentAccount.accountRep}</div>
        </div>
        <div className="row">
          <div className="col font-weight-bold">Address Line 1:</div>
          <div className="col">{currentAccount.addressLine1}</div>
          <div className="col font-weight-bold">Address Line 2:</div>
          <div className="col">{currentAccount.addressLine2}</div>
        </div>
        <div className="row">
          <div className="col font-weight-bold">City:</div>
          <div className="col">{currentAccount.city}</div>
          <div className="col font-weight-bold">States</div>
          <div className="col">{currentAccount.addressState}</div>
        </div>
        <div className="row">
          <div className="col font-weight-bold">Zip Code:</div>
          <div className="col">{currentAccount.zip}</div>
          <div className="col font-weight-bold">Phone</div>
          <div className="col">{currentAccount.phone}</div>
        </div>
        <div className="row">
          <div className="col font-weight-bold">License Required:</div>
          <div className="col">{currentAccount.licenseRequired}</div>
          <div className="col font-weight-bold">License #</div>
          <div className="col">{currentAccount.licenseNumber}</div>
        </div>
        <div className="row">
          <div className="col font-weight-bold">License Issued:</div>
          <div className="col">{format(new Date(currentAccount.licenseIssueDate), "MM/dd/yyyy")}</div>
          <div className="col font-weight-bold">License Expires</div>
          <div className="col">{formatDateWithNoTimeZone(currentAccount.licenseExpiryDate)}</div>
        </div>
        <div className="row">
          <div className="col">
            <CustomCard headerText="Permits">
              <table className="table table-bordered table-striped table-responsive-lg">
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th>Status</th>
                    <th>Date Issued</th>
                    <th>Expiration Date</th>
                    <th>Section Number</th>
                    <th>Milepost</th>
                    <th>RCI Class</th>
                    <th>Side Of Section</th>
                    <th>Picture</th>
                  </tr>
                </thead>
                <tbody>
                  {currentAccount.permits?.map((p, i) => {
                    return (
                      <tr key={p._id}>
                        <td>
                          <Link to={`/SignDetail/${p.currentTagNumber}`}>{p.currentTagNumber}</Link>
                        </td>
                        <td>{p.status}</td>
                        <td>{format(new Date(p.dateIssued), "MM/dd/yyyy")}</td>
                        <td>{formatDateWithNoTimeZone(p.expirationDate)}</td>
                        <td>{p.structure.sectionNumber}</td>
                        <td>{p.structure.milepost}</td>
                        <td>{p.structure.rciClass}</td>
                        <td>{p.structure.sideOfSection}</td>
                        <td>
                          <SafeLink url={documentPermitPictureUrl(p._id)} linkText="View"></SafeLink>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CustomCard>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default PublicOdaAccount;
