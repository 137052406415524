import React, { Suspense } from "react";
import Loading from "react-loading";
import { Routes, Route } from "react-router-dom";
import PublicOdaAccount from "../search/public/PublicOdaAccount";

const SignDetail = React.lazy(() => import("../search/public/SignDetail"));
const LandingPage = React.lazy(() => import("../LandingPage"));
const NotAuthorized = React.lazy(() => import("../NotAuthorized"));
const NoMatch = React.lazy(() => import("../NoMatch"));
const LoginFailure = React.lazy(() => import("../LoginFailure"));
const PaymentIssue = React.lazy(() => import("./../payment/PaymentIssue"));
const Search = React.lazy(() => import("../search/Search"));
const AccountSearch = React.lazy(() => import("../search/account/AccountSearch"));
const ActiveAccounts = React.lazy(() => import("../search/account/ActiveAccounts"));
const PermitApplicationSearch = React.lazy(() => import("../search/permitApplication/PermitApplicationSearch"));
const PermitSearch = React.lazy(() => import("../search/permit/PermitSearch"));
const StructureSearch = React.lazy(() => import("../search/structure/StructureSearch"));
const SectionSearch = React.lazy(() => import("../search/section/SectionSearch"));
const SectionsByCounty = React.lazy(() => import("../reports/SectionsByCounty"));
const ViolationSearch = React.lazy(() => import("../search/violation/ViolationSearch"));
const OdaDataFile = React.lazy(() => import("../administration/OutdoorAdvertisingDataFile"));
const Help = React.lazy(() => import("../Help"));
const NinetyDayReport = React.lazy(() => import("../reports/NinetyDayReport"));
const RegionCountyReport = React.lazy(() => import("../reports/RegionCountyReport"));
const ApplicationsUnderReview = React.lazy(() => import("../reports/ApplicationsUnderReview"));
const PermitNinetyDayReport = React.lazy(() => import("../reports/PermitNinetyDayReport"));
const StructuresByCountyReport = React.lazy(() => import("../reports/StructuresByCountyReport"));
const PermitActivityForAccount = React.lazy(() => import("../reports/PermitActivityForAccount"));
const EmbeddedReport = React.lazy(() => import("../reports/EmbeddedReport"));

const AnonymousRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loading></Loading>}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="NotAuthorized" element={<NotAuthorized />} />
        <Route path="Help" element={<Help />} />
        <Route path="LoginFailure" element={<LoginFailure />} />
        <Route path="PaymentIssue" element={<PaymentIssue />} />
        <Route path="Reports/sectionsByCounty" element={<SectionsByCounty />}></Route>
        <Route path="Reports/sectionsByCounty/:county" element={<SectionsByCounty />}></Route>
        <Route path="SignDetail/:tagNumber" element={<SignDetail />} />
        <Route path="OdaDataFile" element={<OdaDataFile />} />
        <Route path="RegionCountyReport" element={<RegionCountyReport />} />
        <Route path="Reports/ApplicationsUnderReview" element={<ApplicationsUnderReview />} />
        <Route path="NinetyDayReport/:reportType" element={<NinetyDayReport />} />
        <Route path="Permits90Day" element={<PermitNinetyDayReport />} />
        <Route path="StructureCountsByCounty" element={<StructuresByCountyReport />} />
        <Route path="PermitActivityForAccount" element={<PermitActivityForAccount />} />
        <Route path="Reports/ActiveAccounts" element={<ActiveAccounts />} />
        <Route path="EmbeddedReport/:report/:filter" element={<EmbeddedReport />} />
        <Route path="EmbeddedReport/:report" element={<EmbeddedReport />} />
        <Route path="Search" element={<Search />}>
          <Route path="Account" element={<AccountSearch />} />
          <Route path="PermitApplication" element={<PermitApplicationSearch />} />
          <Route path="Permit" element={<PermitSearch />} />
          <Route path="Permit/:accountNumber" element={<PermitSearch />} />
          <Route path="Structure" element={<StructureSearch />} />
          <Route path="Section" element={<SectionSearch />} />
          <Route path="Section/:id" element={<SectionSearch />} />
          <Route path="OdaAccount/:id" element={<PublicOdaAccount />} />
          <Route path="Violation" element={<ViolationSearch />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export default AnonymousRoutes;
