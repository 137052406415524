import axios from "axios";
import { ILicenseApplication, IOdaAccount, LicenseStatus } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/LicenseApplication`;

export const getLicenseApplications = async (): Promise<ILicenseApplication[]> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const addLicenseApplication = async (accountId: string) => {
  const response = await axios.post(`${baseUrl}/${accountId}`);
  return response.data;
};

export const updateLicenseApplication = async (licenseApp: ILicenseApplication) => {
  const response = await axios.patch(`${baseUrl}/${licenseApp._id}`, licenseApp);
  return response.data;
};
export const confirmLicenseApplicationSignature = async (licenseApp: ILicenseApplication) => {
  const response = await axios.patch(`${baseUrl}/ConfirmSignature/${licenseApp._id}`, licenseApp);
  return response.data;
};

export const changeLicenseApplicationStatus = async (license: ILicenseApplication, changeToStatus: LicenseStatus) => {
  const response = await axios.patch(`${baseUrl}/changeLicenseApplicationStatus/${license._id}/${changeToStatus}`);
  return response.data;
};

export const getLicenseApplication = async (licenseId: string): Promise<ILicenseApplication> => {
  const response = await axios.get(`${baseUrl}/LicenseStatusHistory/${licenseId}`);
  return response.data;
};

export const searchLicenseByNumber = async (searchString: string): Promise<ILicenseApplication[]> => {
  const response = await axios.get(`${baseUrl}/license-number-search/${searchString}`);
  return response.data;
};

export const getAccountByLicense = async (licenseId: string): Promise<IOdaAccount | null> => {
  const response = await axios.get(`${baseUrl}/${licenseId}/account`);
  return response.data;
};

export const getLicenseDocumentUri = (odaAccountId: string) => `${baseUrl}/${odaAccountId}/licenseDoc`;
