"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionReviewStatus = void 0;
var InspectionReviewStatus;
(function (InspectionReviewStatus) {
    InspectionReviewStatus["UnderReview"] = "Under Review";
    InspectionReviewStatus["ReturnedIncomplete"] = "Returned Incomplete";
    InspectionReviewStatus["FieldReviewToBeAssigned"] = "Field Review To Be Assigned";
    InspectionReviewStatus["FieldReviewInProgress"] = "Field Review - In Progress";
    InspectionReviewStatus["FieldReviewSubmitted"] = "Field Review - Submitted";
    InspectionReviewStatus["FieldReviewReturnedIncomplete"] = "Field Review - Returned Incomplete";
    InspectionReviewStatus["FieldReviewComplete"] = "Field Review - Complete";
    InspectionReviewStatus["Completed"] = "Completed";
})(InspectionReviewStatus = exports.InspectionReviewStatus || (exports.InspectionReviewStatus = {}));
