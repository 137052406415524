"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DenialReason = void 0;
var DenialReason;
(function (DenialReason) {
    // commenting NA as per users request
    // NA = "Not Applicable",
    DenialReason["Other"] = "Other";
    DenialReason["Spacing"] = "Spacing";
    DenialReason["LandUse"] = "Land Use";
    DenialReason["SpacingAndLandUse"] = "Spacing and Land Use";
})(DenialReason = exports.DenialReason || (exports.DenialReason = {}));
