"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineItemCode = void 0;
var LineItemCode;
(function (LineItemCode) {
    LineItemCode["DelinquentFees"] = "Delinquent Fees";
    LineItemCode["IllegalVegetationCutting"] = "Illegal Vegetation Cutting";
    LineItemCode["NewLicense"] = "New License";
    LineItemCode["NewPermit"] = "New Permit";
    LineItemCode["NewWallMural"] = "New Wall Mural";
    LineItemCode["Other"] = "Other";
    LineItemCode["OverPayment"] = "Over Payment";
    LineItemCode["PermitRenewal"] = "Permit Renewal";
    LineItemCode["Refund"] = "Refund";
    LineItemCode["Reinstatement"] = "Reinstatement";
    LineItemCode["RenewalLicense"] = "Renewal License";
    LineItemCode["ReplaceTag"] = "Replace Tag";
    LineItemCode["SignRemoval"] = "Sign Removal";
    LineItemCode["Transfer"] = "Transfer";
    LineItemCode["Transfers"] = "Transfers (over 20)";
})(LineItemCode = exports.LineItemCode || (exports.LineItemCode = {}));
