"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentType = void 0;
var PaymentType;
(function (PaymentType) {
    PaymentType["Cash"] = "Cash";
    PaymentType["Check"] = "Check";
    PaymentType["MoneyOrder"] = "Money Order";
    PaymentType["Online"] = "Online";
    PaymentType["OnlineRefund"] = "Online Refund";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
