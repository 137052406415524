import axios from "axios";
import { IInvoice, ILineItem } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/line-item`;

export const deleteLineItem = async (id: string): Promise<IInvoice> => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};

export const refundLineItem = async (id: string): Promise<ILineItem> => {
  const response = await axios.post(`${baseUrl}/${id}/refund`);
  return response.data;
};

export const undoRefundLineItem = async (id: string): Promise<ILineItem> => {
  const response = await axios.post(`${baseUrl}/${id}/undo-refund`);
  return response.data;
};

export const updateLineItem = async (lineItem: ILineItem): Promise<ILineItem> => {
  const response = await axios.patch(`${baseUrl}/${lineItem._id}`, lineItem);
  return response.data;
};

export const createLineItem = async (lineItem: ILineItem): Promise<ILineItem> => {
  const response = await axios.post(`${baseUrl}`, lineItem);
  return response.data;
};

export const downloadMonthlyLineItemPdf = (startDate: string, endDate: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/line-item/line-item-pdf/${startDate}/${endDate}`;

export const downloadMonthlyLineItemExcel = (startDate: string, endDate: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/line-item/line-item-excel/${startDate}/${endDate}`;

export const downloadMonthlyNewLineItemPdf = (newStartDate: string, newEndDate: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/line-item/line-item-summary-pdf/${newStartDate}/${newEndDate}`;

export const downloadMonthlyNewLineItemExcel = (newStartDate: string, newEndDate: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/line-item/line-item-summary-excel/${newStartDate}/${newEndDate}`;
