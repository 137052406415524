import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/Store";
import App from "./App";

const render = () => {
  // const App = require("./App").default;

  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );

  // ReactDOM.render(
  //   <Provider store={store}>
  //     <App />
  //   </Provider>,
  //   document.getElementById("root"),
  // );
};

render();

if (fdot.process.env.APP_ENVIRONMENT === "development" && (module as any).hot) {
  (module as any).hot.accept("./App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
