import axios from "axios";
import { IAddLineItemResult, ICreateInvoiceAndUpdateShoppingCartResult, IInvoice } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/invoice`;

export const createInvoice = async (): Promise<ICreateInvoiceAndUpdateShoppingCartResult> => {
  const response = await axios.post(`${baseUrl}`);
  return response.data;
};

export const createManualInvoice = async (amount: number, accountId: string): Promise<IInvoice> => {
  const response = await axios.post(`${baseUrl}/manual`, { amount, accountId });
  return response.data;
};

export const getInvoice = async (id: string | number): Promise<IInvoice> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const generateInvoicePdfUri = (invoiceId: string | number) => `${baseUrl}/pdf/${invoiceId}`;
export const generateInvoicePaymentPdfUri = (invoiceId: string | number) => `${baseUrl}/receipt/pdf/${invoiceId}`;

export const updateComment = async (id: string | number, comment: string): Promise<IInvoice> => {
  const response = await axios.put(`${baseUrl}/${id}/comment`, { comment });
  return response.data;
};

export const unPay = async (id: string | number): Promise<IInvoice> => {
  const response = await axios.put(`${baseUrl}/${id}/un-pay`);
  return response.data;
};

export const deleteInvoice = async (id: string | number): Promise<IInvoice> => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};

export const refundInvoice = async (id: string | number): Promise<IInvoice> => {
  const response = await axios.post(`${baseUrl}/${id}/refund`);
  return response.data;
};

export const fetchAccountInvoices = async (accountId: string): Promise<IInvoice[]> => {
  const response = await axios.get(`${baseUrl}/account/${accountId}`);
  return response.data;
};

export const fetchUnpaidInvoices = async (): Promise<IInvoice[]> => {
  const response = await axios.get(`${baseUrl}/un-paid`);
  return response.data;
};

export const fetchUnpaidAndPaymentInvoices = async(id: string | number): Promise<IInvoice[]> => {
  const response = await axios.get(`${baseUrl}/un-paid/${id}`);
  return response.data;
};

export const addLineItems = async (invoiceId: string, shoppingCartItemIds: string[]): Promise<IAddLineItemResult> => {
  const response = await axios.post(`${baseUrl}/${invoiceId}/line-items`, { shoppingCartItemIds });
  return response.data;
};

export const associatePayments = async (invoiceId: string, paymentIds: string[]): Promise<IInvoice> => {
  const response = await axios.post(`${baseUrl}/${invoiceId}/associate-payments`, { paymentIds });
  return response.data;
};
