"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeRemovedBy = void 0;
var NoticeRemovedBy;
(function (NoticeRemovedBy) {
    NoticeRemovedBy["Contractor"] = "Contractor";
    NoticeRemovedBy["DepartmentPersonnel"] = "Department Personnel";
    NoticeRemovedBy["VoluntaryByOwner"] = "Voluntary by Owner";
    NoticeRemovedBy["NotApplicable"] = "Not Applicable";
})(NoticeRemovedBy = exports.NoticeRemovedBy || (exports.NoticeRemovedBy = {}));
