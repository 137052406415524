import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { stringUtils, yearUtils } from "oda-shared";
import { getPatrolSummary } from "../../redux/slices/PatrolSlice";
import { CustomCard } from "../common/CustomCard";

const PatrolSummary: React.FC = () => {
  const dispatch = useDispatch();
  const patrolSummaryCounts = useSelector((state: RootState) => state.patrolReducer.patrolSummaryCounts);
  const [selectedYear, setYear] = useState("");  
  const [totalSectionPatrolled, setTotalSectionPatrolled] = useState("");
  const [totalSignRemoved, setTotalSignRemoved] = useState("");  

  useEffect(() => {
    dispatch(
      getPatrolSummary(stringUtils.isNullOrWhitespace(selectedYear) ? yearUtils.getCurrentFiscalYear() : selectedYear),
    );
  }, [dispatch, selectedYear]);

  useEffect(() => {
        
    setTotalSectionPatrolled(patrolSummaryCounts?.reduce((total, next) => total + next.sections ?? 0, 0));
    setTotalSignRemoved(patrolSummaryCounts?.reduce((total, next) => total + next.signsRemoved ?? 0, 0));
  }, [dispatch, patrolSummaryCounts]);

  return (
    <div className="container-fluid">
      <br />
      <div className="row">
        <div className="col-lg-2">
          <label htmlFor="year" title="Region" className="font-weight-bold">
            Year:
          </label>
          <select
            className="form-control"
            id="year"
            onChange={e => {
              setYear(e.target.value);
            }}
            defaultValue={yearUtils.getCurrentFiscalYear()}>
            <>
              {Object.values(yearUtils.getFiscalYearList(2021))
                .sort((b, a) => a.localeCompare(b))
                .map((item, i) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
            </>
          </select>
        </div>
      </div>
      <CustomCard headerText="Patrol Summary" collapsible={false}>
        <div className="row">
          <div className="col-lg-5">
            <div className="row">
              <table className="table-striped table-bordered table">
                <thead>
                  <tr>
                    <th>Region</th>                    
                    <th>Sections Patrolled</th>                    
                    <th>Sign Removed</th>
                  </tr>
                </thead>
                {patrolSummaryCounts
                  ? patrolSummaryCounts.map((m, i) => {
                      return (
                        <>
                          <tbody>
                            <tr key={i}>
                              <td>{m.regionName}</td>                              
                              <td>{m.sections}</td>
                              <td>{m.signsRemoved}</td>
                            </tr>
                          </tbody>
                        </>
                      );
                    })
                  : patrolSummaryCounts}
                <tr>
                  <td className="font-weight-bold">Totals</td>                  
                  <td className="font-weight-bold">{totalSectionPatrolled?.toLocaleString()}</td>                  
                  <td className="font-weight-bold">{totalSignRemoved?.toLocaleString()}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default PatrolSummary;
