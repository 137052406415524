import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IPermit,
  IStructure,
  ITag,
  ReinstateTagStatus,
  InspectionStatus,
  InspectionReviewStatus,
  IInspectionRegion,
} from "oda-shared";
import * as permitApi from "../../apis/PermitApi";
import { toast } from "react-toastify";

type SliceState = {
  currentPermit?: IPermit;
  accountPermits: IPermit[];
  searchResults: IPermit[];
  canVoidCurrentPermit: boolean;
  isLoadingSearchResults: boolean;
};
const permitSlice = createSlice({
  name: "permit",
  initialState: {
    currentPermit: undefined,
    accountPermits: [],
    searchResults: [],
    canVoidCurrentPermit: false,
    isLoadingSearchResults: false,
  } as SliceState,
  reducers: {
    setCurrentPermit: (state: SliceState, action: PayloadAction<IPermit | undefined>) => {
      state.currentPermit = action.payload;
    },
    updateTag: (state: SliceState, action: PayloadAction<ITag>) => {
      if (state.currentPermit) {
        const index = state.currentPermit?.tags.findIndex(t => {
          return t._id === action.payload._id;
        });

        if (index > -1) {
          state.currentPermit.tags[index] = action.payload;
        }
      }
    },
    updateTags: (state: SliceState, action: PayloadAction<ITag[]>) => {
      for (const tag of action.payload) {
        const index = state.currentPermit?.tags.findIndex(t => {
          return t._id === tag._id;
        });

        if (state.currentPermit && index && index > -1) {
          state.currentPermit.tags[index] = tag;
        }
      }
    },
    updatePermitStructure: (state: SliceState, action: PayloadAction<IStructure>) => {
      if (state.currentPermit) {
        state.currentPermit.structure = action.payload;
      }
    },
    setPermits: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.accountPermits = action.payload;
      return state;
    },
    setSearchResults: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.searchResults = action.payload;
      return state;
    },
    updateIsLoadingSearchResults: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoadingSearchResults = action.payload;
      return state;
    },
    updateCanVoidCurrentPermit: (state: SliceState, action: PayloadAction<boolean>) => {
      state.canVoidCurrentPermit = action.payload;
      return state;
    },
  },
});

export const {
  setCurrentPermit,
  updateTag,
  updateTags,
  updatePermitStructure,
  setPermits,
  setSearchResults,
  updateIsLoadingSearchResults,
  updateCanVoidCurrentPermit,
} = permitSlice.actions;

export default permitSlice.reducer;

export const clearPermit =() =>async (dispatch: any) =>{
  dispatch(setCurrentPermit(undefined));
}

export const loadPermit = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.getPermit(id);
  dispatch(setCurrentPermit(permitApp));
};

export const getCanVoidPermit = (id: string) => async (dispatch: any) => {
  const canVoid = await permitApi.canVoid(id);
  dispatch(updateCanVoidCurrentPermit(canVoid));
};

export const updatePermit = (permit: IPermit) => async (dispatch: any) => {
  const permitApp = await permitApi.updatePermit(permit);
  toast.success("Permit Saved!");
  dispatch(setCurrentPermit(permitApp));
};

export const issuePermitTag = (permit: IPermit) => async (dispatch: any) => {
  const permitApp = await permitApi.issuePermitTag(permit);
  toast.success("Tag Issued!");
  dispatch(setCurrentPermit(permitApp));
};

export const updateReplacementTagReason = (tag: ITag, reason: string) => async (dispatch: any) => {
  const permitApp = await permitApi.updateReplacementTagReason(tag, reason);
  dispatch(updateTag(permitApp));
};

export const issueReplacementTag = (tag: ITag) => async (dispatch: any) => {
  const permitApp = await permitApi.issueReplacementTag(tag);
  toast.success("Tag Issued!");
  dispatch(updateTags(permitApp));
};

export const denyReplacementTag = (tag: ITag) => async (dispatch: any) => {
  const permitApp = await permitApi.denyReplacementTag(tag);
  toast.success("Tag Denied!");
  dispatch(updateTag(permitApp));
};

export const selfCertifyTag = (tag: ITag) => async (dispatch: any) => {
  const permitApp = await permitApi.selfCertifyTag(tag);
  toast.success("Tag Self Certified!");
  dispatch(updateTag(permitApp));
};

export const certifyTag = (tag: ITag) => async (dispatch: any) => {
  const permitApp = await permitApi.certifyTag(tag);
  toast.success("Tag Certified!");
  dispatch(updateTag(permitApp));
};

export const notPostedTag = (tag: ITag) => async (dispatch: any) => {
  const updatedTag = await permitApi.notPostedTag(tag);
  toast.success("Tag Not Posted or Visible!");
  dispatch(updateTag(updatedTag));
};

export const assignConsultantInspectorToPermit = (id: string, inspectorId: string) => async (dispatch: any) => {
  const permitApp = await permitApi.assignConsultantInspectorToApplication(id, inspectorId);
  dispatch(setCurrentPermit(permitApp));
};

export const assignFdotInspectorToPermit = (id: string, inspectorId: string) => async (dispatch: any) => {
  const permitApp = await permitApi.assignFdotInspectorToApplication(id, inspectorId);
  dispatch(setCurrentPermit(permitApp));
};

export const noticeToRevokePermit = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.noticeToRevokePermit(id);
  dispatch(setCurrentPermit(permitApp));
};

export const revokePermit = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.revokePermit(id);
  dispatch(setCurrentPermit(permitApp));
};

export const manualPermitCancellation = (id: string, signRemovedDate: Date | null) => async (dispatch: any) => {
  const permitApp = await permitApi.manualPermitCancellation(id, signRemovedDate);
  dispatch(setCurrentPermit(permitApp));
};

export const cancel = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.cancel(id);
  dispatch(setCurrentPermit(permitApp));
};

export const requestReinstatement =
  (id: string, reinstateStatusOfPermitTag: ReinstateTagStatus) => async (dispatch: any) => {
    const permitApp = await permitApi.requestReinstatement(id, reinstateStatusOfPermitTag);
    dispatch(setCurrentPermit(permitApp));
  };

export const approveReinstatement = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.approveReinstatement(id);
  dispatch(setCurrentPermit(permitApp));
};
export const assignBuildInspectionRequired =
  (id: string, buildInspectionRequired: boolean) => async (dispatch: any) => {
    const permitApp = await permitApi.assignBuildInspectionRequired(id, buildInspectionRequired);
    dispatch(setCurrentPermit(permitApp));
  };

export const updateStructure = (structure: IStructure, region: IInspectionRegion) => async (dispatch: any) => {
  if (!region) {
    toast.error("Region is required");
  }
  const struct = await permitApi
    .updateStructure(structure, region)
    .then((structure: IStructure) => {
      dispatch(updatePermitStructure(structure));
      toast.success("Structure Saved!");
    })
    .catch((error: any) => {
      console.log(error);
      toast.error("Something went wrong");
    });
};

export const moveStructure = (structure: IStructure, comment: string) => async (dispatch: any) => {
  await permitApi.createLocationHistory(structure, comment);
  const struct = await permitApi.moveStructure(structure);
  toast.success("Structure Moved!");
  dispatch(updatePermitStructure(struct));
};

export const acceptTransfer = (id: number) => async (dispatch: any) => {
  const permitApp = await permitApi.acceptTransfer(id);
  toast.success("Transfer Accepted!");
  dispatch(setCurrentPermit(permitApp));
};

export const approveTransfer = (id: number) => async (dispatch: any) => {
  const permitApp = await permitApi.approveTransfer(id);
  toast.success("Transfer Approved!");
  dispatch(setCurrentPermit(permitApp));
};

export const initiateTransfer = (permitIds: [string], odaAccount: string) => async (dispatch: any) => {
  await permitApi.initiateTransfer(permitIds, odaAccount);
  toast.success("Transfers Initiated!");
};

export const denyTransfer = (id: number) => async (dispatch: any) => {
  const permitApp = await permitApi.denyTransfer(id);
  toast.success("Transfer Incomplete-Pending!");
  dispatch(setCurrentPermit(permitApp));
};

export const assignInspectorToPermitInspection = (id: string, inspectorId: string) => async (dispatch: any) => {
  const permit = await permitApi.assignInspectorToPermitInspection(id, inspectorId);
  dispatch(setCurrentPermit(permit));
};

export const updatePermitInspectionStatus =
  (id: string, status: InspectionStatus, comment: string) => async (dispatch: any) => {
    const permit = await permitApi.updatePermitInspectionStatus(id, status, comment);
    toast.success("Permit Inspection Status Updated");
    dispatch(setCurrentPermit(permit));
  };

export const updatePermitInspectionReviewStatus =
  (id: string, status: InspectionReviewStatus, comment: string) => async (dispatch: any) => {
    const permit = await permitApi.updatePermitInspectionReviewStatus(id, status, comment);
    toast.success("Permit Inspection Status Updated");
    dispatch(setCurrentPermit(permit));
  };

export const loadPermitsForOdaAccount = (accountId: string) => async (dispatch: any) => {
  const permits = await permitApi.getPermitsForOdaAccount(accountId);
  dispatch(setPermits(permits));
};

export const voidPermit = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.voidPermit(id);
  toast.success("Permit Voided!");
  dispatch(setCurrentPermit(permitApp));
};

export const reinstatePermit = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.reinstatePermit(id);
  toast.success("Permit Reinstated!");
  dispatch(setCurrentPermit(permitApp));
};

export const reactivatePermit = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApi.reactivatePermit(id);
  toast.success("Permit Reactivated!");
  dispatch(setCurrentPermit(permitApp));
};

export const searchPermitByNumber = (searchString: string) => async (dispatch: any) => {
  dispatch(updateIsLoadingSearchResults(true));
  permitApi
    .searchPermitByNumber(searchString)
    .then((matches: IPermit[]) => {
      dispatch(setSearchResults(matches));
      dispatch(updateIsLoadingSearchResults(false));
    })
    .catch(error => {
      console.log(error);
      dispatch(updateIsLoadingSearchResults(false));
    });
};
