"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationReviewStatus = void 0;
var ApplicationReviewStatus;
(function (ApplicationReviewStatus) {
    ApplicationReviewStatus["Completed"] = "Completed";
    ApplicationReviewStatus["ManagementIncompleteReview"] = "Management Incomplete Review";
    ApplicationReviewStatus["ManagementIncompleteReviewComplete"] = "Management Incomplete Review - Complete";
    ApplicationReviewStatus["ManagementFinalReviewPending"] = "Management Final Review - Pending";
    ApplicationReviewStatus["ManagementFinalReviewInProgress"] = "Management Final Review - In Progress";
    ApplicationReviewStatus["ReturnedIncomplete"] = "Returned Incomplete";
    ApplicationReviewStatus["FieldReviewToBeAssigned"] = "Field Review Requested";
    ApplicationReviewStatus["FieldReviewInProgress"] = "Field Review - In Progress";
    ApplicationReviewStatus["FieldReviewSubmitted"] = "Field Review - Submitted";
    ApplicationReviewStatus["FieldReviewReturnedIncomplete"] = "Field Review - Returned Incomplete";
    ApplicationReviewStatus["FieldReviewComplete"] = "Field Review - Complete";
    //This status is ONLY for apps that get resubmitted. It's about the same as Field Review Complete.
    ApplicationReviewStatus["Resubmitted"] = "Denied Pending Correction - Resubmitted";
    ApplicationReviewStatus["FieldReviewNeedsAdditionalInformation"] = "Field Review - Needs Additional Information";
    ApplicationReviewStatus["UnderReview"] = "Under Review";
    //ApplicationReviewerComplete = "Application Review Complete", // changed to InitialReviewComplete
    ApplicationReviewStatus["ApplicationReviewIncomplete"] = "Application Found Incomplete";
    ApplicationReviewStatus["InitialReviewComplete"] = "Initial Review Complete";
    //FieldReviewRequested = "Field Review Requested", //OLD COnverted Status
})(ApplicationReviewStatus = exports.ApplicationReviewStatus || (exports.ApplicationReviewStatus = {}));
