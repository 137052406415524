import React from 'react';

interface IProps {
    url: string;
    linkText: string;
}

// Prevent unsafe cross origin destinations
// rel="noopener" Prevents the new page from being able to access the window.opener property and ensures it runs in a separate process.
// rel="noreferrer" Has the same effect but also prevents the Referer header from being sent to the new page.
const SafeLink: React.FC<IProps> = (props: IProps) => {
    return (
        <a href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            title={`${props.linkText} (Opens new browser window)`}>
            {props.linkText}
        </a>
    );
};

export default SafeLink;