import React, { useCallback, useEffect } from "react";
import { CustomCard } from "../../common/CustomCard";
import { pagingOptions, ISection } from "oda-shared";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import {
  gridServerPageable,
  customCellRender,
  customHeaderRender,
  gridDataState,
  gridLink,
} from "../../common/grid/customCellRender";
import { State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  updateCurrentPageIndex,
  updateItemsPerPage,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";
import { LoadingImage } from "../../common/LoadingImage";

interface IProps {
  results: Array<ISection>;
}

const SectionSearchResults: React.FC<IProps> = (props: IProps) => {
  const [dataState, setDataState] = React.useState<State>(gridDataState("sectionSearchResults"));

  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, totalItemCount, isLoadingSearchResults } =
    useSelector((state: RootState) => state.searchReducer);
  const { theme } = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch();
  const currentSection = useSelector((state: RootState) => {
    return state.searchReducer.currentSection;
  });
  const sectionNotFound = useSelector((state: RootState) => {
    return state.searchReducer.sectionNotFound;
  });
  const user = useSelector((state: RootState) => state.userReducer.user);

  useEffect(() => {
    dispatch(updateCurrentPageIndex(0));
    dispatch(updateItemsPerPage(pagingOptions.PermitApplicationPageSize));
  }, [dispatch]);

  const onPageChange = (e: GridPageChangeEvent) => {
    console.log("Page Change", e.page);
    dispatch(updateCurrentPageIndex(e.page.skip / e.page.take));
    dispatch(updateItemsPerPage(e.page.take));
    // Triggers a post to server with the new paging info
    dispatch(updatePageChanged(true));
  };

  const handleSort = useCallback(
    (event: GridSortChangeEvent) => {
      dispatch(updateSortColumn(event.sort[0]?.field));
      dispatch(updateSortDirection(event.sort[0]?.dir as any));
      // Triggers a post to server with the new sort info
      dispatch(updatePageChanged(true));
    },
    [dispatch],
  );

  if (isLoadingSearchResults) {
    return (
      <CustomCard collapsible={true} headerText="Search Results">
        <LoadingImage></LoadingImage>
      </CustomCard>
    );
  }

  const getSectionCell = (e: GridCellProps) => {
    return gridLink(e, `/sections/section/${e.dataItem.id}`, e.dataItem.rciSection);
  };

  return (
    <CustomCard collapsible={true} headerText="Search Results">
      <Grid
        {...dataState}
        sortable
        skip={itemsPerPage * currentPageIndex}
        take={itemsPerPage}
        total={totalItemCount}
        sort={[{ field: sortColumn as any, dir: sortDirection as any }]}
        data={props.results}
        onDataStateChange={e => setDataState(e.dataState)}
        pageable={gridServerPageable(itemsPerPage)}
        onPageChange={onPageChange}
        onSortChange={handleSort as any}
        cellRender={customCellRender}
        headerCellRender={customHeaderRender}>
        <GridColumn field="rciSection" title="Section" cell={getSectionCell} />
        <GridColumn field="localName" title="Local Name" />
        <GridColumn field="stateRdNumber" title="SR#" />
        <GridColumn field="usRoute" title="US Route#" />
        <GridColumn field="class" title="Class" />
        <GridColumn field="startMilePost" title="Start Mile" />
        <GridColumn field="endMilePost" title="End Mile" />
        <GridColumn field="length" title="Length" />
        <GridColumn field="lastInventoryDate" title="Last Inv" />
        <GridColumn field="regionName" title="Region" sortable={false} />
        <GridColumn field="county" title="County" />
      </Grid>
    </CustomCard>
  );
};

export default SectionSearchResults;
