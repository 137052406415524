"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentItemType = void 0;
var PaymentItemType;
(function (PaymentItemType) {
    PaymentItemType["PermitApplication"] = "Permit Application";
    PaymentItemType["License"] = "License";
    PaymentItemType["PermitRenewal"] = "Permit Renewal";
    PaymentItemType["LicenseRenewal"] = "License Renewal";
    PaymentItemType["TagReplacement"] = "Tag Replacement";
    PaymentItemType["ReinstatePermit"] = "Reinstate Permit";
    PaymentItemType["TransferPermits"] = "Permit Transfers";
    //TransferPermitsOver20 = "Transfer Permits (Over 20)",
    PaymentItemType["DelinquentFees"] = "Delinquent Fees";
    PaymentItemType["VegetationCutting"] = "Vegetation Cutting";
    PaymentItemType["SignRemoval"] = "Sign Removal";
    PaymentItemType["NewTag"] = "New Tag";
    PaymentItemType["Other"] = "Other";
    PaymentItemType["Invoice"] = "Invoice";
    PaymentItemType["Refund"] = "Refund";
    PaymentItemType["WallMural"] = "Wall Mural";
})(PaymentItemType = exports.PaymentItemType || (exports.PaymentItemType = {}));
