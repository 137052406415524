import React, { useCallback, useEffect } from "react";
import { CustomCard } from "../../common/CustomCard";
import { pagingOptions, ILitigationRow, Rights } from "oda-shared";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import {
  gridServerPageable,
  customCellRender,
  customHeaderRender,
  gridDataState,
  gridLink,
  gridDate,
} from "../../common/grid/customCellRender";
import { State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  updateCurrentPageIndex,
  updateItemsPerPage,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
  performExportLitigationSearch,
} from "../../../redux/slices/SearchSlice";
import { LoadingImage } from "../../common/LoadingImage";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { caseManagementExcelData, exportToExcel } from "../../../utils/ExcelExportUtils";

interface IProps {
  results: Array<ILitigationRow>;
}

const CaseSearchResults = (props: IProps) => {
  const [dataState, setDataState] = React.useState<State>(gridDataState("documentSearchResults"));

  const {
    litigationExportResults,
    lastCriteriaUsed,
    sortColumn,
    sortDirection,
    currentPageIndex,
    itemsPerPage,
    totalItemCount,
    isLoadingSearchResults,
  } = useSelector((state: RootState) => state.searchReducer);
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.userReducer);

  const onPageChange = (e: GridPageChangeEvent) => {
    console.log("Page Change", e.page);
    dispatch(updateCurrentPageIndex(e.page.skip / e.page.take));
    dispatch(updateItemsPerPage(e.page.take));
    // Triggers a post to server with the new paging info
    dispatch(updatePageChanged(true));
  };

  const handleSort = useCallback(
    (event: GridSortChangeEvent) => {
      dispatch(updateSortColumn(event.sort[0]?.field));
      dispatch(updateSortDirection(event.sort[0]?.dir as any));
      console.log("SORT", event.sort[0]?.field, event.sort[0]?.dir);
      // Triggers a post to server with the new sort info
      dispatch(updatePageChanged(true));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(updateCurrentPageIndex(0));
    dispatch(updateItemsPerPage(pagingOptions.PermitApplicationPageSize));
  }, [dispatch]);

  if (isLoadingSearchResults) {
    return (
      <CustomCard collapsible={true} headerText="Search Results">
        <LoadingImage></LoadingImage>
      </CustomCard>
    );
  }

  const getCaseNumberCell = (e: GridCellProps) => {
    if (!Rights.canViewCases(user)){
      return <>{e.dataItem.caseNumber}</>;
    }
    return gridLink(e, `/Litigation/${e.dataItem.id}`, e.dataItem.caseNumber);
  };

  const getDocumentsCell = (e: GridCellProps) => {
    if (!Rights.canViewCases(user)){
      return <></>;
    }
    return gridLink(e, `/Litigation/${e.dataItem.id}/documents`, "View");
  };

  return (
    <CustomCard collapsible={true} headerText="Search Results">
      <div className="row">
        <div className="col text-right">
          <button
            type="button"
            className="btn btn-success mx-1"
            disabled={props.results.length === 0}
            onClick={() => {
              console.log("CLICK");
              dispatch(
                performExportLitigationSearch(
                  { ...lastCriteriaUsed, itemsPerPage: 10000, currentPageIndex: 0 } as any,
                  () => {
                    console.log(litigationExportResults);
                    exportToExcel(
                      caseManagementExcelData(litigationExportResults as any),
                      [
                        "Case Number",
                        "Attorney",
                        "Petitioner",
                        "Status",
                        "Status Date",
                        "Resolution",
                        "Department Prevailed",
                      ],
                      "Case Management Extract",
                    );
                  },
                ),
              );
            }}>
            <FontAwesomeIcon icon={faFileExcel} />
            &nbsp;&nbsp; Export to Excel
          </button>
        </div>
      </div>
      <Grid
        {...dataState}
        sortable
        skip={itemsPerPage * currentPageIndex}
        take={itemsPerPage}
        total={totalItemCount}
        sort={[{ field: sortColumn as any, dir: sortDirection as any }]}
        data={props.results}
        pageable={gridServerPageable(itemsPerPage)}
        onPageChange={onPageChange}
        onSortChange={handleSort as any}
        cellRender={customCellRender}
        headerCellRender={customHeaderRender}>
        <GridColumn field="caseNumber" title="Case Number" cell={getCaseNumberCell} />
        <GridColumn field="petitionFiledOn" title="Case Added" cell={e => gridDate(e.dataItem.petitionFiledOn)} />
        <GridColumn field="attorney" title="FDOT Attorney" />
        <GridColumn field="petitioner" title="Petitioner" />
        <GridColumn field="status" title="Status" />
        <GridColumn field="statusDate" title="Status Date" cell={e => gridDate(e.dataItem.statusDate)} />
        <GridColumn field="resolution" title="Resolution" />
        <GridColumn field="departmentPrevailed" title="Dept Prevailed" />
        <GridColumn field="id" title="Docs" cell={getDocumentsCell} />
      </Grid>
    </CustomCard>
  );
};

export default CaseSearchResults;
