import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFacing, IFacingAnnualInventory } from "oda-shared";
import * as facingApi from "../../apis/FacingApi";
import { toast } from "react-toastify";
import { loadFacing } from "./FacingSlice";

type SliceState = {
  currentFacingInventory: IFacingAnnualInventory;
};

const facingInventorySlice = createSlice({
  name: "facingInventory",
  initialState: {} as SliceState,
  reducers: {
    loadFacingInventory: (state: SliceState, action: PayloadAction<IFacingAnnualInventory>) => {
      state.currentFacingInventory = action.payload;
    },
  },
});

export const { loadFacingInventory } = facingInventorySlice.actions;

export default facingInventorySlice.reducer;

export const getFacingInventory = (facingInventoryId: string) => async (dispatch: any) => {
  facingApi
    .getFacingInventory(facingInventoryId)
    .then((facingInventory: IFacingAnnualInventory) => {
      dispatch(loadFacingInventory(facingInventory));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateFacingInventory = (facingInventory: IFacingAnnualInventory) => async (dispatch: any) => {
  if (facingInventory._id) {
    facingApi
      .updateFacingInventory(facingInventory)
      .then((facing: IFacing) => {
        dispatch(loadFacing(facing));
        toast.success("Facing Inventory updated!");
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  } else {
    facingApi
      .createFacingInventory(facingInventory)
      .then((facing: IFacing) => {
        dispatch(loadFacing(facing));
        toast.success("Facing Inventory created!");
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  }
};
