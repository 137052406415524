import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILicenseApplication, LicenseStatus } from "oda-shared";
import * as licenseApplicationApi from "../../apis/LicenseApplicationApi";
import { toast } from "react-toastify";

type SliceState = {
  licenseApplications: Record<string, ILicenseApplication>;
  currentApplication?: ILicenseApplication;
  searchResults: ILicenseApplication[];
  isLoadingSearchResults: boolean;
};

const licenseApplicationSlice = createSlice({
  name: "licenseApplications",
  initialState: {
    licenseApplications: {},
    searchResults: [],
    isLoadingSearchResults: false,
  } as SliceState,
  reducers: {
    setCurrentApplication: (state: SliceState, action: PayloadAction<ILicenseApplication>) => {
      state.currentApplication = action.payload;
    },
    loadLicenseApplications: (state: SliceState, action: PayloadAction<ILicenseApplication[]>) => {
      action.payload.forEach(ir => {
        state.licenseApplications[ir._id] = ir;
      });
    },
    loadLicenseApplication: (state: SliceState, action: PayloadAction<ILicenseApplication>) => {
      state.licenseApplications[action.payload._id] = action.payload;
      state.currentApplication = action.payload;
    },
    setSearchResults: (state: SliceState, action: PayloadAction<ILicenseApplication[]>) => {
      state.searchResults = action.payload;
      return state;
    },
    updateIsLoadingSearchResults: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoadingSearchResults = action.payload;
      return state;
    },
  },
});

export const {
  setCurrentApplication,
  loadLicenseApplication,
  loadLicenseApplications,
  setSearchResults,
  updateIsLoadingSearchResults,
} = licenseApplicationSlice.actions;

export default licenseApplicationSlice.reducer;

export const getAllLicenseApplications = () => async (dispatch: any) => {
  licenseApplicationApi
    .getLicenseApplications()
    .then((licenseApplications: ILicenseApplication[]) => {
      dispatch(loadLicenseApplications(licenseApplications));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const addNewLicenseApplication =
  (accountId: string, successCallback?: (id: string) => void) => async (dispatch: any) => {
    licenseApplicationApi
      .addLicenseApplication(accountId)
      .then((licenseApplication: ILicenseApplication) => {
        dispatch(loadLicenseApplication(licenseApplication));
        if (successCallback) {
          successCallback(licenseApplication._id.toString());
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

export const changeLicenseApplicationStatus =
  (
    license: ILicenseApplication,
    changeToStatus: LicenseStatus,
    successCallback: () => void,
    failureCallback: () => void,
  ) =>
  async (dispatch: any) => {
    if (license) {
      licenseApplicationApi
        .changeLicenseApplicationStatus(license, changeToStatus)
        .then((licenseApplication: ILicenseApplication) => {
          dispatch(loadLicenseApplication(licenseApplication));
          successCallback();
        })
        .catch((error: any) => {
          console.log(error);
          failureCallback();
        });
    }
  };

export const getLicenseApplication = (licenseId: string) => async (dispatch: any) => {
  licenseApplicationApi
    .getLicenseApplication(licenseId)
    .then((licenseApplication: ILicenseApplication) => {
      dispatch(setCurrentApplication(licenseApplication));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const searchLicenseByNumber = (searchString: string) => async (dispatch: any) => {
  dispatch(updateIsLoadingSearchResults(true));
  licenseApplicationApi
    .searchLicenseByNumber(searchString)
    .then((matches: ILicenseApplication[]) => {
      dispatch(setSearchResults(matches));
      dispatch(updateIsLoadingSearchResults(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoadingSearchResults(false));
    });
};

export const updateLicenseApplication = (licenseApplication: ILicenseApplication) => async (dispatch: any) => {
  const licenseApp = await licenseApplicationApi.updateLicenseApplication(licenseApplication);
  toast.success("License Application Saved!");
  dispatch(setCurrentApplication(licenseApp));
};

export const confirmLicenseApplicationSignature =
  (licenseApplication: ILicenseApplication) => async (dispatch: any) => {
    //this sets user who clicked confirm checkbox
    const licenseApp = await licenseApplicationApi.confirmLicenseApplicationSignature(licenseApplication);
    toast.success("Confirmation Saved");
    dispatch(setCurrentApplication(licenseApp));
  };
