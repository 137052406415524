import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserProfile } from "oda-shared";
import * as fieldConsultantApi from "./../../apis/FieldConsultantApi";

type SliceState = {
  fieldConsultantInspectors: IUserProfile[];
  fieldConsultantManager: IUserProfile;
};

const userRoleSlice = createSlice({
  name: "userRole",
  initialState: {
    fieldConsultantInspectors: new Array<IUserProfile>(),
  } as SliceState,
  reducers: {
    loadFieldConsultantInspectors: (state: SliceState, action: PayloadAction<IUserProfile[]>) => {
      state.fieldConsultantInspectors = action.payload;
    },
    loadFieldConsultantManager: (state: SliceState, action: PayloadAction<IUserProfile>) => {
      state.fieldConsultantManager = action.payload;
    },
    loadFieldConsultantInspector: (state: SliceState, action: PayloadAction<IUserProfile>) => {
      const index = state.fieldConsultantInspectors.findIndex(p => {
        return action.payload.azureAdOid === p.azureAdOid;
      });

      if (index > -1) {
        state.fieldConsultantInspectors[index] = action.payload;
      } else {
        state.fieldConsultantInspectors.push(action.payload);
      }
    },
    deleteFieldConsultantInspector: (state: SliceState, action: PayloadAction<IUserProfile>) => {
      const index = state.fieldConsultantInspectors.findIndex(p => {
        return action.payload.azureAdOid === p.azureAdOid;
      });

      if (index > -1) {
        state.fieldConsultantInspectors.splice(index, 1);
      }
    },
  },
});

export const {
  loadFieldConsultantInspectors,
  loadFieldConsultantInspector,
  deleteFieldConsultantInspector,
  loadFieldConsultantManager,
} = userRoleSlice.actions;

export default userRoleSlice.reducer;

export const getFieldConsultantInspectors = () => async (dispatch: any) => {
  fieldConsultantApi
    .getFieldConsultantInspectors()
    .then((profiles: IUserProfile[]) => {
      dispatch(loadFieldConsultantInspectors(profiles));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const addFieldConsultantInspector = (id: string) => async (dispatch: any) => {
  fieldConsultantApi
    .addFieldConsultantInspector(id)
    .then(profile => {
      dispatch(loadFieldConsultantInspector(profile));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const addOrCreateFieldConsultantInspector = (azureAdOid: string, staff: IUserProfile) => async (dispatch: any) => {
  fieldConsultantApi
    .addOrCreateFieldConsultantInspector(azureAdOid, staff)
    .then(profile => {
      dispatch(loadFieldConsultantInspector(profile));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const removeFieldConsultantInspector = (id: string) => async (dispatch: any) => {
  fieldConsultantApi
    .removeFieldConsultantInspector(id)
    .then(profile => {
      dispatch(deleteFieldConsultantInspector(profile));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getFieldConsultantManager = () => async (dispatch: any) => {
  fieldConsultantApi
    .getFieldConsultantManager()
    .then((profiles: IUserProfile) => {
      dispatch(loadFieldConsultantManager(profiles));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateFieldConsultantManager = (id: string) => async (dispatch: any) => {
  fieldConsultantApi
    .addFieldConsultantManager(id)
    .then(profile => {
      dispatch(loadFieldConsultantManager(profile));
    })
    .catch((error: any) => {
      console.log(error);
    });
};
