"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateLicenseSubmit = void 0;
var LicenseStatus_1 = require("../models/enums/LicenseStatus");
exports.validateLicenseSubmit = function (account, user) {
    var result = { success: true, messages: [] };
    if (!user || !user._id) {
        result.success = false;
        result.messages.push("User not found.");
        return result;
    }
    if (account.licenseApplications &&
        account.licenseApplications.length >= 1 &&
        account.licenseApplications.some(function (l) { return l.status === LicenseStatus_1.LicenseStatus.Active || l.status === LicenseStatus_1.LicenseStatus.Submitted; })) {
        result.success = false;
        result.messages.push("Account already has active License Application");
    }
    if (!(user === null || user === void 0 ? void 0 : user.isExternal)) {
        result.success = true;
    }
    return result;
};
