import axios from "axios";
import { ICreateInvoiceAndUpdateShoppingCartResult, IPayment, NicPaymentPreparedModel } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/payment`;

export const getPermitApplicationFees = async (permitApplicationId: string): Promise<number> => {
  const response = await axios.get(`${baseUrl}/PermitApplication/${permitApplicationId}`);
  return response.data;
};

export const processPayment = async (paymentDetails: IPayment): Promise<IPayment> => {
  const response = await axios.post(baseUrl, paymentDetails);
  return response.data?.payment;
};

export const initiateNicPayment = async (paymentDetails: IPayment): Promise<NicPaymentPreparedModel> => {
  const response = await axios.post(`${baseUrl}/nic`, paymentDetails);
  return response.data;
};

// You can use payment ID sequence or the mongo ID of the document
export const getPayment = async (id: string | number): Promise<IPayment> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createNewPayment = async (payment: IPayment): Promise<IPayment> => {
  
  const response = await axios.post(`${baseUrl}`, payment);
  return response.data;
};

export const updatePayment = async (id: string | number, payment: IPayment): Promise<IPayment> => {
  
  const response = await axios.patch(`${baseUrl}/${id}`, payment);
  return response.data;
};

export const fetchPaymentsByAccount = async (accountId: string): Promise<IPayment[]> => {
  const response = await axios.get(`${baseUrl}/account/${accountId}/`);
  return response.data;
};

export const fetchReceivedPayments = async (): Promise<IPayment[]> => {
  const response = await axios.get(`${baseUrl}/received-payments/`);
  return response.data;
};

export const depositPaymentsAndCreateInvoice = async (
  paymentIds: string[],
): Promise<ICreateInvoiceAndUpdateShoppingCartResult> => {
  const response = await axios.post(`${baseUrl}/deposit`, paymentIds);
  return response.data;
};

export const generatePaymentReceiptPdfUri = (paymentId: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/receipt/pdf/${paymentId}`;

export const associateInvoices = async (paymentId: string, invoiceIds: string[]): Promise<IPayment> => {
  const response = await axios.put(`${baseUrl}/${paymentId}/associate-invoices`, { invoiceIds });
  return response.data;
};
