import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SliceState = {
    isValidating: Record<string, boolean>
    isValid: Record<string, boolean>
}

const addressSlice = createSlice({
    name: 'address',
    initialState: {
        isValidating: {},
        isValid: {}
    } as SliceState,
    reducers: {
        setIsValidating: (state: SliceState, action: PayloadAction<[string, boolean]>) => {
            state.isValidating[action.payload[0]] = action.payload[1];
            return state;
        },
        setIsValid: (state: SliceState, action: PayloadAction<[string, boolean]>) => {
            state.isValid[action.payload[0]] = action.payload[1];
            return state;
        }
    }
})

export const { setIsValidating, setIsValid } = addressSlice.actions;
export default addressSlice.reducer
