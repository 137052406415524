"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeType = void 0;
var NoticeType;
(function (NoticeType) {
    NoticeType["NoticeOfIntentToRevoke"] = "Notice of Intent to Revoke";
    NoticeType["NoticeOfNonCompliance"] = "Notice of Non-Compliance";
    NoticeType["NoticeOfPermitVoid"] = "Notice of Permit Void";
    NoticeType["VoidOfCopyLetterOfConcern"] = "Void of Copy - Letter of Concern";
    NoticeType["FinalNoticeOfSignRemoval"] = "Final Notice of Sign Removal";
    NoticeType["NoticeOfIntentToSuspendLicense"] = "Notice of Intent to Suspend License";
})(NoticeType = exports.NoticeType || (exports.NoticeType = {}));
