import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFacing, IFinding, IViolation } from "oda-shared";
import * as facingApi from "../../apis/FacingApi";
import { toast } from "react-toastify";
import { loadFinding } from "./FindingSlice";

type SliceState = {
  facings: IFacing[];
  currentFacing: IFacing;
};

const facingSlice = createSlice({
  name: "facings",
  initialState: {
    facings: {},
  } as SliceState,
  reducers: {
    loadFacings: (state: SliceState, action: PayloadAction<IFacing[]>) => {
      state.facings = action.payload;
    },
    loadFacing: (state: SliceState, action: PayloadAction<IFacing>) => {
      state.currentFacing = action.payload;
    },
    setCurrentFacing: (state: SliceState, action: PayloadAction<string>) => {
      const foundFacing = state.facings.find(f => f._id === action.payload);
      if (foundFacing) {
        state.currentFacing = foundFacing;
      } else {
        state.currentFacing = {} as IFacing;
      }
    },
    addViolationToFacing: (state: SliceState, action: PayloadAction<IViolation>) => {
      if (!state.currentFacing.violations) {
        state.currentFacing.violations = [] as any;
      } else {
        state.currentFacing.violations.push(action.payload);
      }
    },
    updateViolationToFacing: (state: SliceState, action: PayloadAction<IViolation>) => {
      if (state.currentFacing.violations) {
        const index = state.currentFacing.violations.findIndex(v => v._id === action.payload._id);
        if (index !== -1) {
          state.currentFacing.violations[index] = action.payload;
        }
      }
    },
  },
});

export const { loadFacings, loadFacing, addViolationToFacing, updateViolationToFacing, setCurrentFacing } =
  facingSlice.actions;

export default facingSlice.reducer;

export const getFacing = (facingId: string) => async (dispatch: any) => {
  facingApi
    .getFacing(facingId)
    .then((facing: IFacing) => {
      dispatch(loadFacing(facing));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getFacingsForStructure = (structureId: string) => async (dispatch: any) => {
  facingApi
    .getFacingsForStructure(structureId)
    .then((facings: Array<IFacing>) => {
      dispatch(loadFacings(facings));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateFacing = (facing: IFacing) => async (dispatch: any) => {
  facingApi
    .updateFacing(facing)
    .then((facing: IFacing) => {
      dispatch(loadFacing(facing));
      toast.success("Facing saved!");
    })
    .catch((error: any) => {
      console.log(error);
      toast.error("Something went wrong");
    });
};

export const createFinding = (finding: IFinding) => async (dispatch: any) => {
  facingApi
    .createFinding(finding)
    .then((facing: IFacing) => {
      dispatch(loadFacing(facing));
      if (facing && facing.findings && facing.findings.length > 0) {
        dispatch(loadFinding(facing.findings[facing.findings.length - 1]));
      }
      toast.success("Finding created!");
    })
    .catch((error: any) => {
      console.log(error);
      toast.error("Something went wrong");
    });
};
