import { AllDocumentTypes, IApplicableSection } from "oda-shared";

export const getSizeInKb = (size?: number): string => {
  if (!size) {
    return "";
  }

  const kb = size / 1024;
  if (isNaN(kb)) {
    return "";
  }
  if (kb < 1) {
    return `${size} bytes`;
  }

  return `${Math.round(size / 1024)} KB`;
};

export const getDocumentTypesBySection = (
  applicableSections: IApplicableSection[],
  isExternalUser: boolean,
  isInternalUser: boolean,
): string[] => {
  const uniqueOptions: string[] = [];
  applicableSections.forEach(x => {
    const filteredBySection = AllDocumentTypes.filter(
      d => (d.externalUser === isExternalUser || d.internalUser === isInternalUser) && d.applicableSections.includes(x),
    );
    filteredBySection.forEach(x => {
      if (uniqueOptions.findIndex(i => i === x.documentTypeName) === -1) {
        uniqueOptions.push(x.documentTypeName);
      }
    });
  });
  return uniqueOptions;
};
