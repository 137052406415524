"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllDocumentTypes = void 0;
var IApplicableSection_1 = require("../models/IApplicableSection");
var DocumentTypeName_1 = require("../models/enums/DocumentTypeName");
exports.AllDocumentTypes = [
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.AlternateViewZoneAgreement,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.VegMgt],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ApplicationPendingActionLetter,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ArchivedViolations,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.CeaseWorkOrder,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.CertificateofTagPosting,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.CertifiedMailSlip,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ClerksOrderofDismissal,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.CollectionsReferralPacket,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.CopyofCaseFile,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.Correspondence,
        internalUser: true,
        externalUser: false,
        applicableSections: [
            IApplicableSection_1.IApplicableSection.Accounts,
            IApplicableSection_1.IApplicableSection.PermitApplication,
            IApplicableSection_1.IApplicableSection.Permits,
            IApplicableSection_1.IApplicableSection.IllegalSigns,
            IApplicableSection_1.IApplicableSection.Hearings,
            IApplicableSection_1.IApplicableSection.Violations,
        ],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DeliveryConfirmation,
        internalUser: true,
        externalUser: false,
        applicableSections: [
            IApplicableSection_1.IApplicableSection.PermitApplication,
            IApplicableSection_1.IApplicableSection.IllegalSigns,
            IApplicableSection_1.IApplicableSection.Hearings,
            IApplicableSection_1.IApplicableSection.Violations,
        ],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DeliveryNotConfirmedReturnedMail,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings, IApplicableSection_1.IApplicableSection.Violations],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DemoInvoiceContractor,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DeniedApplicationNotice,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DeterminationofLandUse,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DiscoveryRequest,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.DiscoveryResponse,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.EmailAttachment,
        internalUser: true,
        externalUser: false,
        applicableSections: [],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.FinalDemandBeforeCollections,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.FinalNoticeofSignRemoval,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.FinalOrder,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.HearingRequest,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.HearingRequestWithdrawn,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.IncompleteApplicationNotice,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.InvoiceSignRemoved,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LandownerPermission,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LetterofConcern,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LicenseApplication,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LicenseSuspendedRevokedLetter,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LocalGovernmentPermissionBuildingPermit,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LocationSketch,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.FieldReview],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.LocationSketch,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.Map,
        internalUser: true,
        externalUser: true,
        applicableSections: [
            IApplicableSection_1.IApplicableSection.PermitApplication,
            IApplicableSection_1.IApplicableSection.Permits,
            IApplicableSection_1.IApplicableSection.IllegalSigns,
            IApplicableSection_1.IApplicableSection.Hearings,
            IApplicableSection_1.IApplicableSection.FieldReview,
        ],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NewJurisdictionNotice,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NewJurisdictionSecondNotice,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeofIntenttoRevoke,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Violations],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeofIntenttoSuspendRevokeLicense,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeofNonCompliance,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeofPermitVoid,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Violations],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeofViolationandOrdertoShowCauseforNonPayment,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeofViolationIllegalSignintheRightofWay,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NOVIllegalVegetationCutting,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NOVCostLetter,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.OrderofDismissal,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PaymentRecord,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PermitApplication,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication, IApplicableSection_1.IApplicableSection.Permits],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PermitCancellationCertification,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.Permits],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PermitCancellationCertification,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PermitNotRequiredLetter,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PermitTransferRequest,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.Permits],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoCeaseWorkOrderPosted,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoFacing,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Structures],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoNonconformingRuleReminderPosted,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoVOC,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings, IApplicableSection_1.IApplicableSection.Findings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoGeneral,
        internalUser: true,
        externalUser: true,
        applicableSections: [
            IApplicableSection_1.IApplicableSection.PermitApplication,
            IApplicableSection_1.IApplicableSection.Permits,
            IApplicableSection_1.IApplicableSection.IllegalSigns,
            IApplicableSection_1.IApplicableSection.Hearings,
            IApplicableSection_1.IApplicableSection.Findings,
            IApplicableSection_1.IApplicableSection.Tasks
        ],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoGeneral,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.FieldReview, IApplicableSection_1.IApplicableSection.Tasks],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoIllegalSignNoticePosted,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoIllegalSignInRightOfWayNoticePosted,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoNoticeofViolationPosted,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoSignLocation,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoSignRemoved,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoSiteMarkings,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.PhotoWarningPosted,
        internalUser: true,
        externalUser: true,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns, IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.RecommendedOrder,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ReducedSpacingAuthorization,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.PermitApplication],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.RemovalCostLetterCancelledPermit,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.RemovalCostLetterExpiredPermit,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Permits, IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.RemovalCostLetterRevokedPermit,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.NoticeOfUnauthorizedRemoval,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.RemovalofRemainingStructureRequired,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ReplacementTagRequest,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Accounts, IApplicableSection_1.IApplicableSection.Permits],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ResearchDocument,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.SectionAttachment,
        internalUser: true,
        externalUser: true,
        applicableSections: [],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.SettlementAgreement,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.SignRemovalPackage,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.TaskWorkOrder,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.TaskWorkOrderPackage,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.VegetationManagementPermit,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.VegMgt],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.ViolationWithdrawn,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.Hearings],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.VoidofCopyWarning,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
    {
        documentTypeName: DocumentTypeName_1.DocumentTypeName.WorkOrder,
        internalUser: true,
        externalUser: false,
        applicableSections: [IApplicableSection_1.IApplicableSection.IllegalSigns],
    },
];
