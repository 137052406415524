import * as noticeApi from "../../apis/NoticeApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotice } from "oda-shared";
import { toast } from "react-toastify";

type SliceState = {
  notice: INotice | null;
  isLoading: boolean;
  searchResults: INotice[];
  isLoadingSearchResults: boolean;
};

const noticeSlice = createSlice({
  name: "notice",
  initialState: {
    notice: null,
    isLoading: false,
    searchResults: [],
    isLoadingSearchResults: false,
  } as SliceState,
  reducers: {
    updateSelectedNotice: (state: SliceState, action: PayloadAction<INotice>) => {
      state.notice = action.payload;
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    setSearchResults: (state: SliceState, action: PayloadAction<INotice[]>) => {
      state.searchResults = action.payload;
      return state;
    },
    updateIsLoadingSearchResults: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoadingSearchResults = action.payload;
      return state;
    },
  },
});

export const { updateSelectedNotice, updateIsLoading, setSearchResults, updateIsLoadingSearchResults } =
  noticeSlice.actions;
export default noticeSlice.reducer;

export const fetchNotice = (id: string) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  noticeApi
    .getNotice(id)
    .then((notice: INotice) => {
      dispatch(updateSelectedNotice(notice));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const createNotice =
  (notice: INotice, successCallback: (notice: INotice) => void, failureCallback: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    noticeApi
      .createNotice(notice)
      .then((newNotice: INotice) => {
        dispatch(updateSelectedNotice(newNotice));
        dispatch(updateIsLoading(false));
        successCallback(newNotice);
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback();
      });
  };

export const updateNotice =
  (notice: INotice, successCallback: (notice: INotice) => void, failureCallback: (e: any) => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    noticeApi
      .updateNotice(notice)
      .then((updatedNotice: INotice) => {
        dispatch(updateSelectedNotice(updatedNotice));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          console.log(updatedNotice);
          console.log("successCallback", successCallback);
          successCallback(updateNotice as any);
        }
        toast.success("Notice has been successfully saved.");
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        failureCallback(error);
      });
  };

export const searchNoticeByNumber = (searchString: string) => async (dispatch: any) => {
  dispatch(updateIsLoadingSearchResults(true));
  noticeApi
    .searchNoticeByNumber(searchString)
    .then((matches: INotice[]) => {
      dispatch(setSearchResults(matches));
      dispatch(updateIsLoadingSearchResults(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoadingSearchResults(false));
    });
};
