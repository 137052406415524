"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getYearList = exports.getMonthList = exports.getCurrentFiscalYear = exports.getFiscalYearList = void 0;
exports.getFiscalYearList = function (startYear) {
    var currentYear = new Date().getFullYear();
    var yearList = new Array();
    while (startYear <= currentYear) {
        var fiscalYear = startYear + "-" + (startYear + 1);
        yearList === null || yearList === void 0 ? void 0 : yearList.push(fiscalYear);
        startYear++;
    }
    return yearList;
};
exports.getCurrentFiscalYear = function () {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    if (currentMonth > 6) {
        return currentYear + "-" + (currentYear + 1);
    }
    return currentYear - 1 + "-" + currentYear;
};
exports.getMonthList = function () {
    var startMonth = 1;
    var monthList = new Array();
    while (startMonth <= 12) {
        monthList === null || monthList === void 0 ? void 0 : monthList.push(startMonth <= 9 ? "0" + startMonth : "" + startMonth);
        startMonth++;
    }
    return monthList;
};
exports.getYearList = function () {
    var currentYear = new Date().getFullYear();
    var startYear = currentYear - 2;
    var yearList = new Array();
    while (startYear <= currentYear) {
        yearList === null || yearList === void 0 ? void 0 : yearList.push("" + startYear);
        startYear++;
    }
    return yearList;
};
