import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReviewReminder } from "oda-shared";
import * as ReviewReminderApi from "../../apis/ReviewReminderApi";

type SliceState = {
  reviewReminder: IReviewReminder;
};

const reviewReminderSlice = createSlice({
  name: "reviewReminder",
  initialState: {
    reviewReminder: {},
  } as SliceState,
  reducers: {
    setReviewReminder: (state: SliceState, action: PayloadAction<IReviewReminder>) => {
      state.reviewReminder = action.payload;
      return state;
    },
  },
});

export const { setReviewReminder } = reviewReminderSlice.actions;
export default reviewReminderSlice.reducer;

export const loadReviewReminder = () => async (dispatch: any) => {
  const reviewReminder = await ReviewReminderApi.getReviewReminder();
  dispatch(setReviewReminder(reviewReminder));
};

export const updateReviewReminder = (days: number) => async (dispatch: any) => {
  const reviewReminder = await ReviewReminderApi.updateReviewReminder({
    reminderDays: days,
  });

  dispatch(setReviewReminder(reviewReminder));
};
