import React from "react";
import { enumUtils } from "oda-shared";

interface IProps {
  hideSelect?: boolean;
  enumeration: any;
  excludeList?: any[];
  noValueSelectedText?: string;
}

const EnumToOptions: React.FC<IProps> = (props: IProps) => {
  const nothingSelectedText = props.noValueSelectedText ? props.noValueSelectedText : "Select";
  let enumArray = enumUtils.enumToArray(props.enumeration, props.hideSelect ? null : nothingSelectedText);
  if (props.excludeList && props.excludeList.length > 0) {
    enumArray = enumArray.filter(i => props.excludeList && props.excludeList.indexOf(i) === -1);
  }
  return (
    <>
      {enumArray.map((item, i) => {
        if (item === nothingSelectedText) {
          return (
            <option key={item} value="">
              {item}
            </option>
          );
        }
        return (
          <option key={item} value={item}>
            {item}
          </option>
        );
      })}
    </>
  );
};

export default EnumToOptions;
