import axios from "axios";
import { ITag } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/tag`;

export const getTag = async (id: string): Promise<ITag> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const addTag = async (tag: ITag) => {
  const response = await axios.post(`${baseUrl}`, tag);
  return response.data;
};

export const updateTag = async (tag: ITag) => {
  const response = await axios.patch(`${baseUrl}/${tag._id}`, tag);
  return response.data;
};

export const getTagsForOdaAccount = async (odaAccountId: string): Promise<ITag[]> => {
  const response = await axios.get(`${baseUrl}/odaAccount/${odaAccountId}`);
  return response.data;
};

export const getTags = async (): Promise<ITag[]> => {
  const response = await axios.get(`${baseUrl}/`);
  return response.data;
};
