import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  //IAnnualInventory,
  ILicenseApplication,
  INotice,
  IPermit,
  IPermitApplication,
  IPermitCancellationRequest,
  IPermitTransferRequest,
  ISectionAnnualData,
  ITag,
  ITaskAssignment,
} from "oda-shared";
import * as DashboardApi from "../../apis/DashboardApi";

type SliceState = {
  consultantManagerApplications: IPermitApplication[];
  consultantFieldInspectorsApplications: IPermitApplication[];
  applicationReviewerApplications: IPermitApplication[];
  managementReviewerApplications: IPermitApplication[];
  externalApplications: IPermitApplication[];
  transferPermits: IPermit[];
  transferPermitsAwaitingPayment: IPermit[];
  consultantManagerInspections: IPermit[];
  consultantFieldInspectorsInspections: IPermit[];
  consultantFieldInspectorsForTags: IPermit[];
  applicationReviewerInspections: IPermit[];
  applicationReviewerTransferApplications: IPermitTransferRequest[];
  transferRequests: IPermitTransferRequest[];
  //inventoriesInProgress: IAnnualInventory[];
  sectionAnnualDataInProgress: ISectionAnnualData[];
  approvedPermitsAwaitingTag: IPermit[];
  approvedPermitsAwaitingPayment: IPermitApplication[];
  pendingCancellation: IPermit[];
  pendingTagReplacementRequests: ITag[];
  pendingLicenseApplications: ILicenseApplication[];
  taskAssignments: ITaskAssignment[];
  voidOfCopyInspections: INotice[];
  permitCancellationRequests: IPermitCancellationRequest[];
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    consultantManagerApplications: [],
    consultantFieldInspectorsApplications: [],
    applicationReviewerApplications: [],
    managementReviewerApplications: [],
    externalApplications: [],
    transferPermits: [],
    consultantManagerInspections: [],
    consultantFieldInspectorsInspections: [],
    applicationReviewerInspections: [],
    applicationReviewerTransferApplications: [],
    transferRequests: [],
    consultantFieldInspectorsForTags: [],
    inventoriesInProgress: [],
    sectionAnnualDataInProgress: [],
    approvedPermitsAwaitingTag: [],
    approvedPermitsAwaitingPayment: [],
    pendingCancellation: [],
    pendingTagReplacementRequests: [],
    pendingLicenseApplications: [],
    transferPermitsAwaitingPayment: [],
    taskAssignments: [],
    voidOfCopyInspections: [],
    permitCancellationRequests: [],
  } as SliceState,
  reducers: {
    setConsultantManagerApplication: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.consultantManagerApplications = action.payload;
    },
    setTransferPermitsAwaitingPayment: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.transferPermitsAwaitingPayment = action.payload;
    },
    setConsultantFieldInspectorsApplications: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.consultantFieldInspectorsApplications = action.payload;
    },
    setApplicationReviewerApplications: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.applicationReviewerApplications = action.payload;
    },
    setManagementReviewerApplications: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.managementReviewerApplications = action.payload;
    },
    setExternalApplications: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.externalApplications = action.payload;
    },
    setTransferPermits: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.transferPermits = action.payload;
    },
    setConsultantManagerInspection: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.consultantManagerInspections = action.payload;
    },
    setConsultantFieldInspectorsInspections: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.consultantFieldInspectorsInspections = action.payload;
    },
    setConsultantFieldInspectorsTags: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.consultantFieldInspectorsForTags = action.payload;
    },
    setApplicationReviewerInspections: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.applicationReviewerInspections = action.payload;
    },
    setApplicationReviewerTransferApplications: (
      state: SliceState,
      action: PayloadAction<IPermitTransferRequest[]>,
    ) => {
      state.applicationReviewerTransferApplications = action.payload;
    },
    /*setAnnualInventoriesInProgress: (state: SliceState, action: PayloadAction<IAnnualInventory[]>) => {
      state.inventoriesInProgress = action.payload;
    },*/
    setSectionAnnualDataInProgress: (state: SliceState, action: PayloadAction<ISectionAnnualData[]>) => {
      state.sectionAnnualDataInProgress = action.payload;
    },
    setApprovedPermitsAwaitingTag: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.approvedPermitsAwaitingTag = action.payload;
    },

    setApprovedPermitsAwaitingPayment: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.approvedPermitsAwaitingPayment = action.payload;
    },

    setPendingCancellations: (state: SliceState, action: PayloadAction<IPermit[]>) => {
      state.pendingCancellation = action.payload;
    },
    setPendingTagReplacementRequests: (state: SliceState, action: PayloadAction<ITag[]>) => {
      state.pendingTagReplacementRequests = action.payload;
    },
    setPendingLicenseApplications: (state: SliceState, action: PayloadAction<ILicenseApplication[]>) => {
      state.pendingLicenseApplications = action.payload;
    },
    setVoidedOfCopyInspections: (state: SliceState, action: PayloadAction<INotice[]>) => {
      state.voidOfCopyInspections = action.payload;
    },
    setTaskAssignments: (state: SliceState, action: PayloadAction<ITaskAssignment[]>) => {
      state.taskAssignments = action.payload;
    },
    setTransferRequests: (state: SliceState, action: PayloadAction<IPermitTransferRequest[]>) => {
      state.transferRequests = action.payload;
    },
    setApplicationReviewerCancellationRequests: (
      state: SliceState,
      action: PayloadAction<IPermitCancellationRequest[]>,
    ) => {
      state.permitCancellationRequests = action.payload;
    },
  },
});

export const {
  setConsultantManagerApplication,
  setConsultantFieldInspectorsApplications,
  setApplicationReviewerApplications,
  setManagementReviewerApplications,
  setTransferPermits,
  setExternalApplications,
  setConsultantManagerInspection,
  setConsultantFieldInspectorsInspections,
  setApplicationReviewerInspections,
  setApplicationReviewerTransferApplications,
  //setAnnualInventoriesInProgress,
  setSectionAnnualDataInProgress,
  setConsultantFieldInspectorsTags,
  setApprovedPermitsAwaitingTag,
  setApprovedPermitsAwaitingPayment,
  setPendingCancellations,
  setPendingLicenseApplications,
  setPendingTagReplacementRequests,
  setTransferPermitsAwaitingPayment,
  setVoidedOfCopyInspections: setVoidOfCopyInspections,
  setTaskAssignments,
  setTransferRequests,
  setApplicationReviewerCancellationRequests,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const loadConsultantManagerApplication = () => async (dispatch: any) => {
  const applications = await DashboardApi.getConsultantManagerApplications();
  dispatch(setConsultantManagerApplication(applications));
};

export const loadTransferPermitsAwaitingPayment = () => async (dispatch: any) => {
  const permits = await DashboardApi.getApplicationReviewerTransfersAwaitingPayment();
  dispatch(setTransferPermitsAwaitingPayment(permits));
};

export const loadConsultantFieldInspectorsApplications = () => async (dispatch: any) => {
  const applications = await DashboardApi.getConsultantFieldInspectorsApplications();
  dispatch(setConsultantFieldInspectorsApplications(applications));
};

export const loadApplicationReviewerApplications = () => async (dispatch: any) => {
  const applications = await DashboardApi.getApplicationReviewerApplications();
  dispatch(setApplicationReviewerApplications(applications));
};

export const loadManagementReviewerApplications = () => async (dispatch: any) => {
  const applications = await DashboardApi.getManagementReviewerApplications();
  dispatch(setManagementReviewerApplications(applications));
};

export const getExternalUserApplications = () => async (dispatch: any) => {
  const applications = await DashboardApi.getExternalUserApplications();
  dispatch(setExternalApplications(applications));
};

export const getTransferApplications = () => async (dispatch: any) => {
  const applications = await DashboardApi.getTransferApplications();
  dispatch(setTransferPermits(applications));
};

export const getTransferRequests = () => async (dispatch: any) => {
  const requests = await DashboardApi.getTransferRequests();
  dispatch(setTransferRequests(requests));
};

export const loadConsultantManagerInspection = () => async (dispatch: any) => {
  const inspections = await DashboardApi.getConsultantManagerInspections();
  dispatch(setConsultantManagerInspection(inspections));
};

export const loadConsultantFieldInspectorsInspections = () => async (dispatch: any) => {
  const inspections = await DashboardApi.getConsultantFieldInspectorsInspections();
  dispatch(setConsultantFieldInspectorsInspections(inspections));
};

export const loadConsultantFieldInspectorsTags = () => async (dispatch: any) => {
  const inspections = await DashboardApi.getConsultantFieldInspectorsForTags();
  dispatch(setConsultantFieldInspectorsTags(inspections));
};

export const loadApplicationReviewerInspections = () => async (dispatch: any) => {
  const inspections = await DashboardApi.getApplicationReviewerInspections();
  dispatch(setApplicationReviewerInspections(inspections));
};

export const loadApplicationReviewerTransferApplications = (includeDraft: boolean) => async (dispatch: any) => {
  const inspections = await DashboardApi.getApplicationReviewerTransferApplications(includeDraft);
  dispatch(setApplicationReviewerTransferApplications(inspections));
};
/*export const loadAnnualInventoriesInProgress = () => async (dispatch: any) => {
  const inventories = await DashboardApi.getAnnualInventoriesInProgress();
  dispatch(setAnnualInventoriesInProgress(inventories));
};*/
export const loadSectionAnnualDataInProgress = () => async (dispatch: any) => {
  const inventories = await DashboardApi.getSectionAnnualDataInProgress();
  dispatch(setSectionAnnualDataInProgress(inventories));
};

export const loadApprovedPermitsAwaitingTag = () => async (dispatch: any) => {
  const data = await DashboardApi.getApprovedPermitsAwaitingTag();
  dispatch(setApprovedPermitsAwaitingTag(data));
};

export const loadApprovedPermitsAwaitingPayment = () => async (dispatch: any) => {
  const data = await DashboardApi.getApprovedPermitsAwaitingPayment();
  dispatch(setApprovedPermitsAwaitingPayment(data));
};

export const loadPendingCancellations = () => async (dispatch: any) => {
  const data = await DashboardApi.getPendingCancellations();
  dispatch(setPendingCancellations(data));
};

export const loadPendingLicenseApplications = () => async (dispatch: any) => {
  const data = await DashboardApi.getPendingLicenseApplications();
  dispatch(setPendingLicenseApplications(data));
};

export const loadPendingTagReplacementRequests = () => async (dispatch: any) => {
  const data = await DashboardApi.getPendingTagReplacementRequests();
  dispatch(setPendingTagReplacementRequests(data));
};

export const loadVoidOfCopyInspections = () => async (dispatch: any) => {
  const data = await DashboardApi.getVoidOfCopyInspections();
  dispatch(setVoidOfCopyInspections(data));
};

export const loadTaskAssignments = () => async (dispatch: any) => {
  const data = await DashboardApi.getTaskAssignments();
  dispatch(setTaskAssignments(data));
};

export const loadApplicationReviewerCancellationRequests = () => async (dispatch: any) => {
  const requests = await DashboardApi.getApplicationCancellationRequests();
  dispatch(setApplicationReviewerCancellationRequests(requests));
};
