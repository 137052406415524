"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanceledTagStatus = void 0;
var CanceledTagStatus;
(function (CanceledTagStatus) {
    CanceledTagStatus["Enclosed"] = "Enclosed";
    CanceledTagStatus["Lost"] = "Lost";
    CanceledTagStatus["Destroyed"] = "Destroyed";
    CanceledTagStatus["Stolen"] = "Stolen";
})(CanceledTagStatus = exports.CanceledTagStatus || (exports.CanceledTagStatus = {}));
