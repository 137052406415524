import React from "react";
import { AllDocumentTypes, IApplicableSection, IDocumentType } from "oda-shared";
import { getDocumentTypesBySection } from "../../utils/DocumentUtilities";

interface IProps {
  idSuffix: string;
  selectedValue: string; //document type name
  isInternalUser: boolean;
  isExternalUser: boolean;
  applicableSections: IApplicableSection[];
  onChange: (selectedOption: string) => void;
  key?: string;
}
const DocumentTypeSelector = (props: IProps) => {
//The list of allowable doc types that will show up here is mapped in AllDocumentTypes.ts and based on props.applicableSections.
  return (
    <>
      <select
        key={props.key ?? "documentType"}
        name="documentType"
        title="Select Document Type"
        id={`documentType${props.idSuffix}`}
        className="form-control"
        value={props.selectedValue}
        onChange={e => {
          props.onChange(e.target.value);
        }}>
        <option value="">Select</option>
        {getDocumentTypesBySection(props.applicableSections, props.isExternalUser, props.isInternalUser).map(x => {
          return (
            <option value={x} key={x}>
              {x}
            </option>
          );
        })}
      </select>
    </>
  );
};
export default DocumentTypeSelector;
