import axios from "axios";
import { IInspectionRegion } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/InspectionRegion`;

export const getInspectionRegions = async (): Promise<IInspectionRegion[]> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const addInspectionRegion = async (inspectionRegionName: string) => {
  const response = await axios.post(`${baseUrl}`, { name: inspectionRegionName, counties: [], inspectors: [] });
  return response.data;
};

export const updateInspectionRegion = async (inspectionRegion: IInspectionRegion) => {
  const response = await axios.patch(`${baseUrl}/${inspectionRegion._id}`, inspectionRegion);
  return response.data;
};

export const getUserCounties = async () => {
  const response = await axios.get(`${baseUrl}/GetUserCounties`);
  return response.data;
};
