"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstNameLastName = exports.getUserInitials = exports.staffMap = void 0;
var arculus_staff_service_1 = require("@fdot/arculus-staff-service");
var Theme_1 = require("../models/enums/Theme");
exports.staffMap = function (staff) {
    return {
        azureAdOid: staff.azureAdOid ? staff.azureAdOid : "",
        displayName: staff.lastName + ", " + staff.firstName,
        emailAddress: staff.emailAddress ? staff.emailAddress : "",
        firstName: staff.firstName,
        internalRoles: [],
        isExternal: false,
        isFieldConsultantInspector: false,
        isFieldConsultantManager: false,
        lastName: staff.lastName,
        phoneNumber: arculus_staff_service_1.phoneNumberUtils.formatPhoneNumber(staff.phone, staff.phoneExtension),
        srsId: staff.id,
        theme: Theme_1.Theme.Standard,
    };
};
exports.getUserInitials = function (user) {
    if (user === null || user === undefined) {
        return "?";
    }
    var initials = "";
    if (user.firstName && user.firstName.length > 0) {
        initials += user.firstName[0];
    }
    if (user.lastName && user.lastName.length > 0) {
        initials += user.lastName[0];
    }
    return initials;
};
exports.getFirstNameLastName = function (user) {
    if (user === undefined || user === null) {
        return "";
    }
    return user.firstName + " " + user.lastName;
};
