"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllegalSignNoticeType = void 0;
var IllegalSignNoticeType;
(function (IllegalSignNoticeType) {
    IllegalSignNoticeType["LetterOfConcern"] = "Letter of Concern";
    IllegalSignNoticeType["NoticeOfViolationIllegalSignInTheRightOfWay"] = "Notice of Violation - Illegal Sign in the Right of Way";
    IllegalSignNoticeType["TaskWorkOrder"] = "Task Work Order";
    IllegalSignNoticeType["SignRemovalInvoice"] = "Invoice of Sign Removal";
    IllegalSignNoticeType["FinalDemandLetter"] = "Final Demand Before Collections";
    IllegalSignNoticeType["NewJurisdictionNoticeIssued"] = "New Jurisdiction Notice";
    IllegalSignNoticeType["RemovalOfRemainingStructureLetter"] = "Removal of Remaining Structure Letter";
    IllegalSignNoticeType["NewJurisdictionSecondNoticeIssued"] = "New Jurisdiction Second Notice";
    IllegalSignNoticeType["FinalNoticeOfSignRemoval"] = "Final Notice of Sign Removal";
    IllegalSignNoticeType["NoticeOfViolation"] = "Notice of Violation Cost Letter";
    IllegalSignNoticeType["TaskWorkOrderPackage"] = "Task Work Order Package";
    IllegalSignNoticeType["RemovalCostLetterForCancelled"] = "Removal Cost Letter for Cancelled Permit";
    IllegalSignNoticeType["RemovalCostLetterForExpired"] = "Removal Cost Letter for Expired Permit";
    IllegalSignNoticeType["RemovalCostLetterForRevoked"] = "Removal Cost Letter for Revoked Permit";
    IllegalSignNoticeType["OrderToCeaseWork"] = "Order to Cease Work";
    IllegalSignNoticeType["NoticeOfUnauthorizedRemoval"] = "Notice Of Unauthorized Removal, Cutting Or Trimming Of Vegetation";
})(IllegalSignNoticeType = exports.IllegalSignNoticeType || (exports.IllegalSignNoticeType = {}));
