import axios from "axios";
import { IFacing, IFace, IFinding, IFacingAnnualInventory } from "oda-shared";
import { toast } from "react-toastify";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/facings`;

export const getFacing = async (facingId: string): Promise<IFacing> => {
  const response = await axios.get(`${baseUrl}/facing/${facingId}`);
  return response.data;
};

export const getFacingsForStructure = async (structureId: string): Promise<Array<IFacing>> => {
  const response = await axios.get(`${baseUrl}/structure/${structureId}`);
  return response.data;
};

export const updateFacing = async (facing: IFacing): Promise<IFacing> => {
  const response = await axios.patch(`${baseUrl}/facing/${facing._id}`, facing);
  return response.data;
};

const baseFaceUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/face`;

export const getFace = async (faceId: string): Promise<IFace> => {
  const response = await axios.get(`${baseFaceUrl}/${faceId}`);
  return response.data;
};

export const updateFace = async (face: IFace): Promise<IFacing> => {
  const response = await axios.patch(`${baseFaceUrl}/${face._id}`, face);
  return response.data;
};
export const createFace = async (face: IFace): Promise<IFacing> => {
  const response = await axios.post(`${baseFaceUrl}`, face);
  return response.data;
};

const baseFacingInventoryUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/facingInventory`;

export const getFacingInventory = async (facingInventoryId: string): Promise<IFacingAnnualInventory> => {
  const response = await axios.get(`${baseFacingInventoryUrl}/${facingInventoryId}`);
  return response.data;
};

export const updateFacingInventory = async (facingInventory: IFacingAnnualInventory): Promise<IFacing> => {
  const response = await axios.patch(`${baseFacingInventoryUrl}/${facingInventory._id}`, facingInventory);
  return response.data;
};
export const createFacingInventory = async (facingInventory: IFacingAnnualInventory): Promise<IFacing> => {
  const response = await axios.post(`${baseFacingInventoryUrl}`, facingInventory);
  return response.data;
};

const baseFindingUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/finding`;
export const createFinding = async (finding: IFinding): Promise<IFacing> => {
  const response = await axios.post(`${baseFindingUrl}`, finding);
  return response.data;
};

export const generateNoticeOfIntentToRevokePdfUri = (permitId: string, noticeId: string, violationId: string) =>
  `${baseFaceUrl}/noticeOfIntentToRevoke/${permitId}/${noticeId}/${violationId}`;

export const emailNoticeOfIntentToRevoke = async (permitId: string, noticeId: string) => {
  const response = await axios.get(`${baseFaceUrl}/noticeOfIntentToRevokeEmail/${permitId}/${noticeId}`);
  if (response.data === "OK") {
    toast.success("Email Sent!");
  } else {
    toast.error("Something went wrong while sending email");
  }
  return response.data;
};
