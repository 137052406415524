"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermitActivityRange = void 0;
var PermitActivityRange;
(function (PermitActivityRange) {
    PermitActivityRange["ThirtyDays"] = "30 Days";
    PermitActivityRange["NinetyDays"] = "90 Days";
    PermitActivityRange["OneHundredEightyDays"] = "180 Days";
    PermitActivityRange["TwoHundredSeventyDays"] = "270 Days";
    PermitActivityRange["OneYear"] = "1 Year";
    PermitActivityRange["SinceLastAnnualBilling"] = "Since Last Annual Billing";
})(PermitActivityRange = exports.PermitActivityRange || (exports.PermitActivityRange = {}));
