import { useEffect, useMemo } from "react";
import { InternalRole } from "oda-shared";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/RootReducer";
import { getInternalUserRoles } from "../redux/slices/UserRolesSlice";

export default function useInspectorProfiles() {
  const dispatch = useDispatch();
  const { internalUsersWithRoles } = useSelector((state: RootState) => state.userRoleReducer);

  useEffect(() => {
    if (internalUsersWithRoles?.length === 0) {
      dispatch(getInternalUserRoles());
    }
  }, [internalUsersWithRoles, dispatch]);

  const inspectorsUserProfiles = useMemo(() => {
    return internalUsersWithRoles
      .filter(i => {
        return (
          i.internalRoles!.findIndex(role => {
            return role === InternalRole.FdotInspector;
          }) > -1
        );
      })
      .sort((a, b) => {
        if (a.displayName === undefined) return -1;
        if (b.displayName === undefined) return 1;

        if (a.displayName < b.displayName) {
          return -1;
        }
        if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      });
  }, [internalUsersWithRoles]);

  return inspectorsUserProfiles;
}
