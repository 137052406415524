"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialType = void 0;
var MaterialType;
(function (MaterialType) {
    MaterialType["Concrete"] = "Concrete";
    MaterialType["Wood"] = "Wood";
    MaterialType["Steel"] = "Steel";
    MaterialType["Other"] = "Other";
    MaterialType["WallMural"] = "Wall Mural";
})(MaterialType = exports.MaterialType || (exports.MaterialType = {}));
