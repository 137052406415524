"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PdfDocumentNames = void 0;
var PdfDocumentNames;
(function (PdfDocumentNames) {
    PdfDocumentNames["OdaLicensePdf"] = "ODA_License.pdf";
    PdfDocumentNames["OdaAppPdf"] = "ODA_App.pdf";
    PdfDocumentNames["OdaDeterminationOfLandUsePdf"] = "ODA_Land_Use.pdf";
    PdfDocumentNames["OdaLocalGovernmentPermissionPdf"] = "ODA_Local_Government_Permission.pdf";
    PdfDocumentNames["OdaPermitCancellationPdf"] = "ODA_Permit_Cancellation.pdf";
    PdfDocumentNames["OdaPermitRevokedRemovalPdf"] = "ODA_Revoked_Removal.pdf";
    PdfDocumentNames["OdaAnnualSummaryPdf"] = "ODA_Annual_Summary_Year_Act_AccNum.pdf";
    PdfDocumentNames["OdaNoticeOfNonPayment"] = "ODA_Notice_NonPayment.pdf";
    PdfDocumentNames["OdaDoveRemovalOfRemainingStructure"] = "ODA_DOVE_RemovalOf_Remaining_Structure.pdf";
    PdfDocumentNames["OdaDoveCancelledOrExpiredTagCostLetter"] = "ODA_DOVE_Canceled_Or_Expired_Tag_Cost_Letter.pdf";
    PdfDocumentNames["OdaDoveNoticeOfJurisdictionalChange"] = "ODA_DOVE_NoticeOf_Jurisdictional_Change.pdf";
    PdfDocumentNames["OdaDoveSecondNoticeOfJurisdictionalChange"] = "ODA_DOVE_Second_NoticeOf_Jurisdictional_Change.pdf";
    PdfDocumentNames["OdaDoveLetterOfConcernPropertyOwner"] = "ODA_DOVE_LetterOf_Concern_Property_Owner.pdf";
    //OdaDoveLetterOfConcernSignOwner = "ODA_DOVE_LetterOf_Concern_Sign_Owner.pdf",
    PdfDocumentNames["OdaDoveIllegallyErectedSignInRightOfWay"] = "ODA_DOVE_Illegally_Erected_Sign_In_RightOf_Way.pdf";
    PdfDocumentNames["OdaDoveIllegallyErectedSignPropertyOwner"] = "ODA_DOVE_Illegally_Erected_Sign_PropertyOwner.pdf";
    PdfDocumentNames["OdaDoveIllegallyErectedSignSignOwner"] = "ODA_DOVE_Illegally_Erected_Sign_SignOwner.pdf";
    PdfDocumentNames["OdaRightOfWayExcelDataFile"] = "Outdoor Advertising Data File.xlsx";
    PdfDocumentNames["OdaIncompleteApplicationNotice"] = "ODA_Incomplete_Application_Notice.pdf";
    PdfDocumentNames["OdaDeniedApplicationNotice"] = "ODA_Denied_Application_Notice.pdf";
    PdfDocumentNames["OdaTaskOrder"] = "ODA_Task_Order.pdf";
    PdfDocumentNames["OdaSignRemovalInvoice"] = "ODA_Sign_Removal_Invoice.pdf";
    PdfDocumentNames["OdaFinalDemandLetter"] = "ODA_Final_Demand_Letter.pdf";
    PdfDocumentNames["OdaPermitTransferRequest"] = "Permit_Transfer_Request.pdf";
    PdfDocumentNames["OdaPermitCancellationRequest"] = "ODA_Permit_Cancellation.pdf";
    PdfDocumentNames["OdaOrderToCeaseWork"] = "ODA_Order_To_Cease_Work.pdf";
    PdfDocumentNames["OdaNoticeOfUnauthorizedRemoval"] = "ODA_Notice_Unauthorized_Removal_Of_Vegetation.pdf";
    PdfDocumentNames["OdaPermitNotRequired"] = "Permit_Not_Required.pdf";
    PdfDocumentNames["OdaFinalNoticeOfSignRemoval"] = "Final Notice of Sign Removal.pdf";
    PdfDocumentNames["Billy_Benson_Signature"] = "Billy_Benson_Signature.jpg";
    PdfDocumentNames["Michael_Green_Signature"] = "Michael_Green_Signature.jpg";
    PdfDocumentNames["Florida_Seal"] = "Florida_Seal.jpg";
    PdfDocumentNames["No_Image"] = "no-image-available.jpg";
    PdfDocumentNames["Dummy_BillBoard"] = "Dummy_Billboard.jpg";
    PdfDocumentNames["FDOTLogo"] = "FDOTLogo.png";
    PdfDocumentNames["Robbie_Collins_Signature"] = "Robbie_Collins_Signature.png";
})(PdfDocumentNames = exports.PdfDocumentNames || (exports.PdfDocumentNames = {}));
