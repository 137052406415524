"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskType = void 0;
var TaskType;
(function (TaskType) {
    TaskType["InventoryCheck"] = "Inventory Check";
    TaskType["LocationCheck"] = "Location Check";
    TaskType["ReportedIllegalSign"] = "Reported Illegal Sign";
    TaskType["Inspection270Day"] = "270 Day Build";
    TaskType["IllegalSign"] = "Illegal Sign";
    TaskType["Violation"] = "Violation";
    TaskType["Notice"] = "Notice";
    TaskType["FieldReview"] = "Field Review";
    TaskType["VOC"] = "VOC";
    TaskType["PermittedSignRemoval"] = "Permitted Sign Removal";
    TaskType["NonConformingSign"] = "Non-Conforming Sign";
    TaskType["LitigationSupport"] = "Litigation Support";
    TaskType["SignRemovalWarningSticker"] = "Sign Removal / Warning Sticker";
    //This is a historical type only for data conversion of the sharepoint lists. 
    TaskType["TagPost"] = "Tag Posting / 30DTP";
})(TaskType = exports.TaskType || (exports.TaskType = {}));
