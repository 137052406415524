"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNextInspectionStatuses = exports.userCanEditConformity = exports.internalUserCanEditStructure = exports.consultantUserCanEditStructure = exports.canEditInspection = void 0;
var UserService_1 = require("./UserService");
var InternalRole_1 = require("../models/enums/InternalRole");
var InspectionStatus_1 = require("../models/enums/InspectionStatus");
var InspectionReviewStatus_1 = require("../models/enums/InspectionReviewStatus");
var UserRightsService_1 = require("./UserRightsService");
exports.canEditInspection = function (permit, user) {
    if (permit.inspectionStatus === InspectionStatus_1.InspectionStatus.UnderReview &&
        (permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewInProgress ||
            permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewReturnedIncomplete ||
            permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewSubmitted) &&
        user.isExternal) {
        if (user.isFieldConsultantManager) {
            return true;
        }
        if (user.isFieldConsultantInspector &&
            permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewInProgress) {
            return true;
        }
    }
    if (UserService_1.HasRole(user, InternalRole_1.InternalRole.ApplicationReviewer) &&
        permit.inspectionStatus !== InspectionStatus_1.InspectionStatus.UnderReview &&
        permit.inspectionStatus !== InspectionStatus_1.InspectionStatus.Requested) {
        return true;
    }
    return false;
};
//Moved to UserRoleService
// export const canEditAnnualInventory = (permit: IPermit, user: IUser) => {
//   if (user.isExternal && (user.isFieldConsultantManager || user.isFieldConsultantInspector)) {
//     return true;
//   }
//   if (HasRoles(user, [InternalRole.FdotAdmin, InternalRole.OfficeStaff])) {
//     return true;
//   }
//   return false;
// };
//Consultants can only edit structure details if the structure is conforming
//or if it's been permitted as nonconforming and some 270 day inspection data is not filled out
exports.consultantUserCanEditStructure = function (structure, user) {
    if (!structure || !UserRightsService_1.canEditStructure(user)) {
        return false;
    }
    if (structure.dateRemoved) {
        return false;
    }
    if (!structure.isConforming) {
        return !structure.distanceEdgeOfPavement || !structure.latitude || !structure.longitude || !structure.dateErected ||
            !structure.material || !structure.configuration || !structure.supportNumber || !structure.constructionHeight || !structure.tagDisplayed;
    }
    return true;
};
exports.internalUserCanEditStructure = function (structure, user) {
    if (!structure || !UserRightsService_1.canEditStructure(user)) {
        return false;
    }
    return UserRightsService_1.hasAnyFdotRole(user);
};
exports.userCanEditConformity = function (permit, user) {
    if (!permit || !user || !user._id) {
        return false;
    }
    if (UserService_1.HasRoles(user, [
        InternalRole_1.InternalRole.FdotAdmin,
        InternalRole_1.InternalRole.OfficeSupervisor,
        InternalRole_1.InternalRole.FieldSupervisor,
        InternalRole_1.InternalRole.ManagementReviewer,
    ])) {
        return true;
    }
    if (UserService_1.HasRole(user, InternalRole_1.InternalRole.ApplicationReviewer) &&
        permit.inspectionStatus !== InspectionStatus_1.InspectionStatus.UnderReview &&
        permit.inspectionStatus !== InspectionStatus_1.InspectionStatus.Requested) {
        return true;
    }
    return false;
};
exports.getNextInspectionStatuses = function (permit, user) {
    var _a, _b, _c, _d, _e;
    var results = [[], []];
    switch (permit.inspectionStatus) {
        case InspectionStatus_1.InspectionStatus.UnderReview:
            if ((permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewInProgress ||
                permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewReturnedIncomplete) &&
                (((_a = user._id) === null || _a === void 0 ? void 0 : _a.toString()) == ((_c = (_b = permit.inspectionFieldConsultantInspector) === null || _b === void 0 ? void 0 : _b._id) === null || _c === void 0 ? void 0 : _c.toString()) ||
                    ((_d = user._id) === null || _d === void 0 ? void 0 : _d.toString()) == ((_e = permit.inspectionFieldConsultantInspector) === null || _e === void 0 ? void 0 : _e.toString()))) {
                results[1] = [InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewSubmitted];
            }
            else if (permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewSubmitted &&
                user.isFieldConsultantManager) {
                results[1] = [InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewComplete, InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewReturnedIncomplete];
            }
            else if (permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewReturnedIncomplete) {
                results[1] = [InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewSubmitted];
            }
            else if (permit.inspectionReviewStatus === InspectionReviewStatus_1.InspectionReviewStatus.FieldReviewComplete &&
                UserService_1.HasRole(user, InternalRole_1.InternalRole.ApplicationReviewer)) {
            }
            break;
        case InspectionStatus_1.InspectionStatus.Completed:
            if (UserService_1.HasRole(user, InternalRole_1.InternalRole.ApplicationReviewer)) {
                results[0] = [InspectionStatus_1.InspectionStatus.Compliant, InspectionStatus_1.InspectionStatus.NotCompliant];
                results[1] = [];
            }
            break;
    }
    return results;
};
