import axios from "axios";
import { IUserProfile, IUser } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/user`;
const profileUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/profile`;

export const getIsUserOnAnyAccount = async (): Promise<boolean> => {
  const response = await axios.get(`${baseUrl}/is-any-account-user`);
  return response.data;
};
export const getUser = async (): Promise<IUser> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const impersonateFdot = async (srsId: number): Promise<IUser> => {
  const response = await axios.post(`${baseUrl}/impersonate`, { srsId });
  return response.data;
};

export const impersonateB2c = async (azureAdOid: string): Promise<IUser> => {
  const response = await axios.post(`${baseUrl}/b2c/impersonate`, { azureAdOid });
  return response.data;
};

export const updateProfile = async (profile: IUserProfile): Promise<IUserProfile> => {
  const response = await axios.patch(`${profileUrl}/${profile.azureAdOid}`, profile);
  return response.data;
};

export const fetchProfile = async (azureAdOid: string): Promise<IUserProfile> => {
  const response = await axios.get(`${profileUrl}/${azureAdOid}`);
  return response.data;
};

export const getUserPhotoUri = async (azureAdOid: string | null | undefined): Promise<string | null> => {
  if (azureAdOid === undefined || azureAdOid === null) {
    return null;
  }
  const response = await axios.get(`${profileUrl}/${azureAdOid}/photo`);
  return response.data;
};

export const getInternalUsers = async () => {
  const response = await axios.get(`${baseUrl}/roles`);
  return response.data;
};

export const addInternalUserRole = async (azureAdOid: string, role: string, staff: IUserProfile) => {
  const response = await axios.post(`${baseUrl}/${azureAdOid}/role/${role}`, staff);
  return response.data;
};

export const removeInternalUserRole = async (azureAdOid: string, role: string) => {
  const response = await axios.delete(`${baseUrl}/${azureAdOid}/role/${role}`);
  return response.data;
};

export const searchExternalUsers = async (searchTerm: string) => {
  const response = await axios.get(`${baseUrl}/externalUser/${searchTerm}`);
  return response.data;
};

export const searchInternalUsers = async (searchTerm: string) => {
  const response = await axios.get(`${baseUrl}/internalUser/${searchTerm}`);
  return response.data;
};

export const updateDateChanger = async (newDate: string) => {
  const response = await axios.patch(`${baseUrl}/updateDateChanger`, { newDate });
  return response.data;
};

export const getUserDisplayName = async (id: string) => {
  const response = await axios.get(`${profileUrl}/getDisplayName/${id}`);
  return response.data;
};
