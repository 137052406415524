import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAccountCardRow, IDocument, IGridSearchObject, IPermitApplication, IStructure } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api`;

export const odaAccountApi = createApi({
  reducerPath: "odaAccountSliceApi",
  baseQuery: fetchBaseQuery({ baseUrl, credentials: "include" }),
  tagTypes: ["account-search", "permit-app-search", "doc-search", "structure-number", "email-search"],
  endpoints: builder => ({
    search: builder.mutation<{ totalCount: number; results: IAccountCardRow[] }, IGridSearchObject>({
      query: searchParams => ({ url: `/search/oda-account-search/`, body: searchParams, method: "POST" }),
      invalidatesTags: ["account-search"],
    }),
    accountPermitAppsSearch: builder.mutation<
      { totalCount: number; results: IPermitApplication[] },
      { accountId: string; searchParams: IGridSearchObject }
    >({
      query: body => ({
        url: `/account/permit-app-search/${body.accountId}`,
        body: body.searchParams,
        method: "POST",
      }),
      invalidatesTags: ["permit-app-search"],
    }),
    accountDocSearch: builder.mutation<
      { totalCount: number; results: IDocument[] },
      { accountId: string; searchParams: IGridSearchObject; filterType: string }
    >({
      query: body => ({
        url: `/account/doc-search/${body.accountId}/${body.filterType}`,
        body: body.searchParams,
        method: "POST",
      }),
      invalidatesTags: ["doc-search"],
    }),
    getStructureByNumber: builder.query<IStructure, number>({
      query: structureNumber => `/structure/number/${structureNumber}`,
      providesTags: ["structure-number"],
    }),
  }),
});

export const {
  useSearchMutation,
  useAccountPermitAppsSearchMutation,
  useAccountDocSearchMutation,
  useGetStructureByNumberQuery,
} = odaAccountApi;
