import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicationReviewStatus,
  FieldReviewType,
  IAddress,
  IDenialLetter,
  IIncompleteLetter,
  IOdaAccount,
  IPermitApplication,
  PaymentQuarter,
  PermitApplicationStatus,
} from "oda-shared";
import * as permitApplicationApi from "../../apis/PermitApplicationApi";
import * as paymentApi from "../../apis/payment/PaymentApi";
import { toast } from "react-toastify";

type SliceState = {
  currentApplication?: IPermitApplication;
  applications?: IPermitApplication[];
  newApplicationCreated?: boolean;
  applicationFees?: number;
};

const permitApplicationSlice = createSlice({
  name: "permitApplication",
  initialState: { newApplicationCreated: false } as SliceState,
  reducers: {
    setCurrentApplication: (state: SliceState, action: PayloadAction<IPermitApplication>) => {
      state.currentApplication = action.payload;
    },
    setApplications: (state: SliceState, action: PayloadAction<IPermitApplication[]>) => {
      state.applications = action.payload;
    },
    setNewApplicationCreated: (state: SliceState, action: PayloadAction<boolean>) => {
      state.newApplicationCreated = action.payload;
    },
    setApplicationFees: (state: SliceState, action: PayloadAction<number>) => {
      state.applicationFees = action.payload;
    },
  },
});

export const { setCurrentApplication, setApplications, setNewApplicationCreated: setNewApplicationCreated, setApplicationFees } =
  permitApplicationSlice.actions;

export default permitApplicationSlice.reducer;

export const loadPermitApplication = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApplicationApi.getPermitApplication(id);
  dispatch(setCurrentApplication(permitApp));
};

export const loadPermitApplicationFees = (id: string) => async (dispatch: any) => {
  const permitAppFees = await paymentApi.getPermitApplicationFees(id);
  dispatch(setApplicationFees(permitAppFees));
};

export const updateNewApplicationCreated = (isNew: boolean) => (dispatch: any) => dispatch(setNewApplicationCreated(isNew));

export const addPermitApplication =
  (odaAccount?: IOdaAccount, successCallback?: (id: string) => void) => async (dispatch: any) => {
    const addr: IAddress = {
      addressLine1: "",
      city: "",
      state: "",
      zipCode1: "",
    };

    const permitApplication: IPermitApplication = {
      isManual: false,
      statusChangeDate: new Date(),
      statusHistory: [] as any,
      mailingAddress: addr,
      applicationAddress: addr,
      status: PermitApplicationStatus.Saved,
      odaAccount: odaAccount,
      emailAddress: odaAccount?.emailOfRecord,
      phoneNumber: odaAccount?.phone,
    };

    const permitApp = await permitApplicationApi.addPermitApplication(permitApplication);
    dispatch(setNewApplicationCreated(true));
    dispatch(setCurrentApplication(permitApp));    
    if (successCallback) {
      successCallback(permitApp._id.toString());
    }
  };

  export const updatePermitApplicationLetter = (id: string, denialLetter?: IDenialLetter,    
    incompleteLetter?: IIncompleteLetter) => async (dispatch: any) => {                
    const permitApp = await permitApplicationApi.updatePermitApplicationLetter(id, denialLetter, incompleteLetter);
    toast.success(`Permit Application ${denialLetter? "Denial Letter Info " : "Incomplete Letter Info "}Saved!`);    
    dispatch(setCurrentApplication(permitApp));
  };

export const updatePermitApplication = (permitApplication: IPermitApplication) => async (dispatch: any) => {
  const permitApp = await permitApplicationApi.updatePermitApplication(permitApplication);
  toast.success("Permit Application Saved!");
  dispatch(setCurrentApplication(permitApp));
};

export const updatePermitApplicationReview = (permitApplication: IPermitApplication, applyToThisFacingOnly: boolean, reviewType: FieldReviewType) => async (dispatch: any) => {
  const permitApp = await permitApplicationApi.updatePermitApplicationReview(permitApplication, applyToThisFacingOnly, reviewType);
  toast.success("Permit Application Saved!");
  dispatch(setCurrentApplication(permitApp));
};

export const resubmitDeniedPermitApplication = (id: string) => async (dispatch: any) => {
  const permitApp = await permitApplicationApi.resubmitDeniedPermitApplication(id);
  dispatch(setCurrentApplication(permitApp));
};

// export const submitPermitApplication = (id: string) => async (dispatch: any) => {
//   const permitApp = await permitApplicationApi.submitPermitApplication(id);
//   dispatch(setCurrentApplication(permitApp));
// };

export const assignInspectorToApplication = (id: string, inspectorId: string) => async (dispatch: any) => {
  const permitApp = await permitApplicationApi.assignInspectorToApplication(id, inspectorId);
  dispatch(setCurrentApplication(permitApp));
};

export const updatePermitApplicationStatus =
  (
    id: string,
    status: PermitApplicationStatus,
    comment: string,        
    reason?: string,
    denialLetter?: IDenialLetter,
    incompleteLetter?: IIncompleteLetter,
    applyToThisFacingOnly?: boolean,    
  ) =>
  async (dispatch: any) => {
    const permitApp = await permitApplicationApi.updatePermitApplicationStatus(
      id,
      status,
      comment,     
      reason,
      denialLetter,
      incompleteLetter,
      applyToThisFacingOnly,
    );
    toast.success("Permit Application Status Updated");
    dispatch(loadPermitApplication(permitApp._id));
  };

export const updatePermitApplicationReviewStatus =
  (
    id: string,
    status: ApplicationReviewStatus,
    comment: string,
    recommendation: string,
    reason: string,
    denialLetter?: IDenialLetter,
    incompleteLetter?: IIncompleteLetter,
    applyToThisFacingOnly?: boolean,
  ) =>
  async (dispatch: any) => {
    const permitApp = await permitApplicationApi.updatePermitApplicationReviewStatus(
      id,
      status,
      comment,
      recommendation,
      reason,
      denialLetter,
      incompleteLetter,
      applyToThisFacingOnly,
    );
    toast.success("Permit Application Status Updated");
    dispatch(setCurrentApplication(permitApp));
  };

export const splitApplication = (id: string, facingCount: number) => async (dispatch: any) => {
  const permitApp = await permitApplicationApi.splitApplication(id, facingCount);
  dispatch(setCurrentApplication(permitApp));
};

export const getPermitApplications = () => async (dispatch: any) => {
  const apps = await permitApplicationApi.getPermitApplications();
  dispatch(setApplications(apps));
};

export const markReceived =
  (id: string, paymentQuarter: PaymentQuarter, successCallback: () => void, failureCallback: () => void) =>
  async (dispatch: any) => {
    try {
      const permitApp = await permitApplicationApi.markReceived(id, paymentQuarter);
      dispatch(setCurrentApplication(permitApp));
      successCallback();
    } catch (error) {
      console.log(error);
      failureCallback();
    }
  };
