"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendingReason = void 0;
var PendingReason;
(function (PendingReason) {
    PendingReason["Litigation"] = "Litigation";
    PendingReason["NcSignRemoval"] = "NC Sign Removal";
    PendingReason["NA"] = "Not Applicable";
    PendingReason["Other"] = "Other";
    PendingReason["VegetationManagementPermit"] = "Vegetation Management Permit";
})(PendingReason = exports.PendingReason || (exports.PendingReason = {}));
