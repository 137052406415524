"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermitTransferStatus = void 0;
var PermitTransferStatus;
(function (PermitTransferStatus) {
    //Request is created but not submitted
    PermitTransferStatus["Draft"] = "Draft";
    //Request has been submitted by buyer, waiting for the seller to accept it
    PermitTransferStatus["AwaitingTransferAcceptance"] = "Awaiting Transfer Acceptance";
    //Buyer has accepted but not paid yet
    PermitTransferStatus["AwaitingTransferPayment"] = "Awaiting Payment";
    //Buyer has paid, waiting for ODA office to approve
    PermitTransferStatus["AwaitingTransferApproval"] = "Awaiting Transfer Approval";
    //ODA office reviewing 
    PermitTransferStatus["UnderReview"] = "Under Review";
    //Admin status, working on it
    PermitTransferStatus["IncompletePending"] = "Incomplete-Pending";
    //Admins denied the transfer
    PermitTransferStatus["TransferDenied"] = "Transfer Denied";
    //Admin has approved the transfer
    PermitTransferStatus["TransferComplete"] = "Transfer Complete";
})(PermitTransferStatus = exports.PermitTransferStatus || (exports.PermitTransferStatus = {}));
