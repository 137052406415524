import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFace, IFacing } from "oda-shared";
import * as facingApi from "../../apis/FacingApi";
import { toast } from "react-toastify";
import { loadFacing } from "./FacingSlice";

type SliceState = {
  currentFace: IFace;
};

const faceSlice = createSlice({
  name: "face",
  initialState: {} as SliceState,
  reducers: {
    loadFace: (state: SliceState, action: PayloadAction<IFace>) => {
      state.currentFace = action.payload;
    },
  },
});

export const { loadFace } = faceSlice.actions;

export default faceSlice.reducer;

export const getFace = (faceId: string) => async (dispatch: any) => {
  facingApi
    .getFace(faceId)
    .then((face: IFace) => {
      dispatch(loadFace(face));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateFace = (face: IFace) => async (dispatch: any) => {
  if (face._id) {
    facingApi
      .updateFace(face)
      .then((facing: IFacing) => {
        dispatch(loadFacing(facing));
        toast.success("Face updated!");
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  } else {
    facingApi
      .createFace(face)
      .then((facing: IFacing) => {
        dispatch(loadFacing(facing));
        toast.success("Face created!");
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  }
};
