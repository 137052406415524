"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTaskUrl = void 0;
//Generates URLs for various objects
exports.getTaskUrl = function (task) {
    var _a, _b;
    var url = "";
    if (task.noticeId) {
        url = "/notice/" + task.noticeId;
    }
    else if (task.violationId && task.violation && task.violation.facingId) {
        url = "/facing/" + task.violation.facingId + "/violations/violation/" + task.violationId;
    }
    else if (task.findingId && ((_a = task.finding) === null || _a === void 0 ? void 0 : _a.facing)) {
        url = "/Facing/" + ((_b = task.finding) === null || _b === void 0 ? void 0 : _b.facing) + "/finding/finding/" + task.findingId + "/task/" + task._id;
    }
    else if (task.illegalSignId) {
        url = "/illegalSigns/" + task.illegalSignId + "/" + task._id;
    }
    else if (task.litigationId) {
        url = "/Litigation/" + task.litigationId;
    }
    else if (task.permitId) {
        url = "/Permit/" + task.permitId + "/tasks";
    }
    else if (task.applicationId) {
        url = "/PermitApplication/" + task.applicationId + "/fieldSheet";
    }
    else {
        url = "/Task/" + task._id;
    }
    return url;
};
