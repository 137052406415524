import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIlegalSign } from "oda-shared";
import * as illegalSignApi from "../../apis/IllegalSignApi";
import { toast } from "react-toastify";

type SliceState = {
  currentIllegalSign: IIlegalSign;
  illegalSigns: IIlegalSign[];
  searchResults: IIlegalSign[];
  isLoadingSearchResults: boolean;
};

const illegalSignSlice = createSlice({
  name: "illegalSign",
  initialState: {
    illegalSigns: [] as IIlegalSign[],
    currentIllegalSign: {} as IIlegalSign,
    searchResults: [],
    isLoadingSearchResults: false,
  } as SliceState,
  reducers: {
    loadIllegalSign: (state: SliceState, action: PayloadAction<IIlegalSign>) => {
      state.currentIllegalSign = action.payload;
    },
    loadIllegalSigns: (state: SliceState, action: PayloadAction<IIlegalSign[]>) => {
      state.illegalSigns = action.payload;
      for (var sign of state.illegalSigns){
        
      }
    },
    setSearchResults: (state: SliceState, action: PayloadAction<IIlegalSign[]>) => {
      state.searchResults = action.payload;
    },
    updateIsLoadingSearchResults: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoadingSearchResults = action.payload;
      return state;
    },
  },
});

export const { loadIllegalSign, loadIllegalSigns, setSearchResults, updateIsLoadingSearchResults } =
  illegalSignSlice.actions;

export default illegalSignSlice.reducer;

export const getIllegalSign = (id: string, successCallback?: () => void) => async (dispatch: any) => {
  illegalSignApi
    .getIllegalSign(id)
    .then((illegalSign: IIlegalSign) => {
      dispatch(loadIllegalSign(illegalSign));
      if (successCallback) {
        successCallback();
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const deleteIllegalSign = (id: string, successCallback?: () => void) => async (dispatch: any) => {
  illegalSignApi
    .deleteIllegalSign(id)
    .then(() => {
      if (successCallback) {
        successCallback();
      }
      dispatch(loadIllegalSign({} as any));
      toast.error("Illegal Sign has been deleted");
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getIllegalSigns = (filterToMe?: boolean) => async (dispatch: any) => {
  if (filterToMe) {
    illegalSignApi
      .getMyIllegalSigns()
      .then((illegalSigns: IIlegalSign[]) => {
        dispatch(loadIllegalSigns(illegalSigns));
      })
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    illegalSignApi
      .getAllIllegalSign()
      .then((illegalSigns: IIlegalSign[]) => {
        dispatch(loadIllegalSigns(illegalSigns));
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};

export const searchIllegalSignsByNumber = (number: string) => async (dispatch: any) => {
  dispatch(updateIsLoadingSearchResults(true));
  illegalSignApi
    .searchIllegalSignsByNumber(number)
    .then((results: IIlegalSign[]) => {
      dispatch(updateIsLoadingSearchResults(false));
      dispatch(setSearchResults(results));
    })
    .catch((error: any) => {
      dispatch(updateIsLoadingSearchResults(false));
      console.log(error);
    });
};

export const updateIllegalSign = (illegalSign: IIlegalSign) => async (dispatch: any) => {
  const updatedIllegalSign = await illegalSignApi.updateIllegalSign(illegalSign);
  toast.success("Illegal Sign Saved!");  
  dispatch(loadIllegalSign(updatedIllegalSign));
};

export const createIllegalSign =
  (illegalSign: IIlegalSign, successCallback?: (id: string) => void) => async (dispatch: any) => {
    const createdIllegalSign = await illegalSignApi.addIllegalSign(illegalSign);
    toast.success("Illegal Sign Created!");
    dispatch(loadIllegalSign(createdIllegalSign));
    if (successCallback) {
      successCallback(createdIllegalSign._id);
    }
  };

export const createIllegalSignFromPermit =
  (permitId: string, successCallback?: (id: string) => void) => async (dispatch: any) => {
    const createdIllegalSign = await illegalSignApi.addIllegalSignFromPermit(permitId);
    toast.success("Illegal Sign Created!");
    dispatch(loadIllegalSign(createdIllegalSign));
    if (successCallback) {
      successCallback(createdIllegalSign._id);
    }
  };

export const getIllegalSignsForPermit = (permitId: string) => async (dispatch: any) => {
  illegalSignApi
    .getIllegalSignsForPermit(permitId)
    .then((illegalSigns: IIlegalSign[]) => {
      dispatch(loadIllegalSigns(illegalSigns));
    })
    .catch((error: any) => {
      console.log(error);
    });
};
