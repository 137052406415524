"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionStatus = void 0;
var InspectionStatus;
(function (InspectionStatus) {
    InspectionStatus["NotNeeded"] = "Not Needed";
    InspectionStatus["Requested"] = "Requested";
    InspectionStatus["UnderReview"] = "Under Review";
    InspectionStatus["ReturnedIncomplete"] = "Returned Incomplete";
    InspectionStatus["Completed"] = "Completed";
    InspectionStatus["Compliant"] = "270 Complete";
    InspectionStatus["NotCompliant"] = "270 Not Complete";
})(InspectionStatus = exports.InspectionStatus || (exports.InspectionStatus = {}));
