import * as searchApi from "../../apis/SearchApi";
import * as mileageApi from "../../apis/MileageApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAccountRow,
  IPermitApplicationSearchCriteria,
  IAccountSearchCriteria,
  IPermitApplicationRow,
  ILitigationSearchCriteria,
  ILitigationRow,
  IPaymentRow,
  IPaymentSearchCriteria,
  IInvoiceRow,
  IInvoiceSearchCriteria,
  IPermitRow,
  IPermitSearchCriteria,
  INoticeRow,
  INoticeSearchCriteria,
  IStructureSearchCriteria,
  IStructureRow,
  ISignDetail,
  ISectionSearchCriteria,
  ISection,
  IMileage,
  IViolationRow,
  IViolationSearchCriteria,
  IIllegalSignSearchCriteria,
  IFindingSearchCriteria,
  IFindingRow,
  ITransferSearchCriteria,
  IDocumentSearchCriteria,
  ITransferRow,
  IDocumentRow,
  IIllegalSignRow,
  IFacingSearchCriteria,
  IFacingSearchRow,
} from "oda-shared";
import { toast } from "react-toastify";

type SliceState = {
  sectionResults: Array<ISection>;
  accountResults: Array<IAccountRow>;
  applicationResults: Array<IPermitApplicationRow>;
  permitResults: Array<IPermitRow>;
  litigationResults: Array<ILitigationRow>;
  litigationExportResults: Array<ILitigationRow>;
  noticeResults: Array<INoticeRow>;
  paymentResults: Array<IPaymentRow>;
  invoiceResults: Array<IInvoiceRow>;
  mileageResults: Array<IMileage>;
  structureResults: Array<IStructureRow>;
  sectionByCountyResults: Array<ISection>;
  violationResults: Array<IViolationRow>;
  findingResults: Array<IFindingRow>;
  documentResults: Array<IDocumentRow>;
  illegalSignResults: Array<IIllegalSignRow>;
  transferResults: Array<any>;
  isLoadingSearchResults: boolean;
  sortColumn: string;
  sortDirection: string;
  currentPageIndex: number;
  itemsPerPage: number;
  pageChanged: boolean;
  totalItemCount: number;
  currentSignDetail?: ISignDetail;
  currentSection?: ISection;
  sectionNotFound: boolean;
  facingResults: Array<IFacingSearchRow>;
  lastCriteriaUsed: any;
};

const searchSlice = createSlice({
  name: "search",
  initialState: {
    lastCriteriaUsed: {},
    sectionResults: [],
    transferResults: [],
    sectionByCountyResults: [],
    accountResults: [],
    applicationResults: [],
    permitResults: [],
    litigationResults: [],
    litigationExportResults: [],
    noticeResults: [],
    paymentResults: [],
    invoiceResults: [],
    structureResults: [],
    mileageResults: [],
    violationResults: [],
    findingResults: [],
    documentResults: [],
    illegalSignResults: [],
    facingResults: [],
    isLoadingSearchResults: false,
    sortColumn: "",
    sortDirection: "NONE" as any,
    currentPageIndex: 0,
    itemsPerPage: 10,
    pageChanged: false,
    totalItemCount: 0,
    sectionNotFound: false,
  } as SliceState,
  reducers: {
    resetToInitialState: (state: SliceState) => {
      state.sectionResults = [];
      state.transferResults = [];
      state.sectionByCountyResults = [];
      state.accountResults = [];
      state.applicationResults = [];
      state.permitResults = [];
      state.litigationResults = [];
      state.litigationExportResults = [];
      state.noticeResults = [];
      state.paymentResults = [];
      state.invoiceResults = [];
      state.structureResults = [];
      state.mileageResults = [];
      state.violationResults = [];
      state.findingResults = [];
      state.documentResults = [];
      state.illegalSignResults = [];
      state.facingResults = [];
      state.isLoadingSearchResults = false;
      //state.sortColumn = "";
      //state.sortDirection = "NONE" as any;
      state.currentPageIndex = 0;
      state.itemsPerPage = 10;
      state.pageChanged = false;
      state.totalItemCount = 0;
      state.sectionNotFound = false;
      return state;
    },
    updateFacingItems: (state: SliceState, action: PayloadAction<IFacingSearchRow[]>) => {
      state.facingResults = action.payload;
      return state;
    },
    updateIllegalSigns: (state: SliceState, action: PayloadAction<IIllegalSignRow[]>) => {
      state.illegalSignResults = action.payload;
      return state;
    },
    updateLastCriteriaUsed: (state: SliceState, action: PayloadAction<any>) => {
      state.lastCriteriaUsed = action.payload;
      return state;
    },
    updateTransferItems: (state: SliceState, action: PayloadAction<any[]>) => {
      state.transferResults = action.payload;
      return state;
    },
    updateCurrentSection: (state: SliceState, action: PayloadAction<ISection>) => {
      state.currentSection = action.payload;
      return state;
    },
    updateSectionByCountyItems: (state: SliceState, action: PayloadAction<ISection[]>) => {
      state.sectionByCountyResults = action.payload;
      return state;
    },
    updateSectionNotFound: (state: SliceState, action: PayloadAction<boolean>) => {
      state.sectionNotFound = action.payload;
      return state;
    },
    updateCurrentSignDetail: (state: SliceState, action: PayloadAction<ISignDetail>) => {
      state.currentSignDetail = action.payload;
      return state;
    },
    updateStructureItems: (state: SliceState, action: PayloadAction<IStructureRow[]>) => {
      state.structureResults = action.payload;
      return state;
    },
    updateSectionItems: (state: SliceState, action: PayloadAction<ISection[]>) => {
      state.sectionResults = action.payload;
      return state;
    },
    updateAccountItems: (state: SliceState, action: PayloadAction<IAccountRow[]>) => {
      state.accountResults = action.payload;
      return state;
    },
    updateApplicationItems: (state: SliceState, action: PayloadAction<IPermitApplicationRow[]>) => {
      state.applicationResults = action.payload;
      return state;
    },
    updatePermitItems: (state: SliceState, action: PayloadAction<IPermitRow[]>) => {
      state.permitResults = action.payload;
      return state;
    },
    updateLitigationItems: (state: SliceState, action: PayloadAction<ILitigationRow[]>) => {
      state.litigationResults = action.payload;
      return state;
    },
    updateLitigationExportResults: (state: SliceState, action: PayloadAction<ILitigationRow[]>) => {
      state.litigationExportResults = action.payload;
      return state;
    },
    updateNoticeItems: (state: SliceState, action: PayloadAction<INoticeRow[]>) => {
      state.noticeResults = action.payload;
      return state;
    },
    updatePaymentItems: (state: SliceState, action: PayloadAction<IPaymentRow[]>) => {
      state.paymentResults = action.payload;
      return state;
    },
    updateInvoiceItems: (state: SliceState, action: PayloadAction<IInvoiceRow[]>) => {
      state.invoiceResults = action.payload;
      return state;
    },
    loadAllMileages: (state: SliceState, action: PayloadAction<IMileage[]>) => {
      state.mileageResults = action.payload;
      return state;
    },
    updateViolationItems: (state: SliceState, action: PayloadAction<IViolationRow[]>) => {
      state.violationResults = action.payload;
      return state;
    },
    updateFindingItems: (state: SliceState, action: PayloadAction<IFindingRow[]>) => {
      state.findingResults = action.payload;
      return state;
    },
    updateDocumentItems: (state: SliceState, action: PayloadAction<IDocumentRow[]>) => {
      state.documentResults = action.payload;
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoadingSearchResults = action.payload;
      return state;
    },
    updateSortColumn: (state: SliceState, action: PayloadAction<string>) => {
      console.log("updateSortColumn", action.payload);
      state.sortColumn = action.payload;
      return state;
    },
    updateSortDirection: (state: SliceState, action: PayloadAction<string>) => {
      console.log("updateSortDirection", action.payload);
      state.sortDirection = action.payload;
      return state;
    },

    updateCurrentPageIndex: (state: SliceState, action: PayloadAction<number>) => {
      state.currentPageIndex = action.payload;
      return state;
    },
    updateItemsPerPage: (state: SliceState, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      return state;
    },
    updatePageChanged: (state: SliceState, action: PayloadAction<boolean>) => {
      state.pageChanged = action.payload;
      return state;
    },
    updateTotalItemCount: (state: SliceState, action: PayloadAction<number>) => {
      state.totalItemCount = action.payload;
      return state;
    },
  },
});

export const {
  updateAccountItems,
  resetToInitialState,
  updateTransferItems,
  updateApplicationItems,
  updateLitigationItems,
  updateNoticeItems,
  updatePaymentItems,
  updateInvoiceItems,
  loadAllMileages,
  updateIsLoading,
  updateSortColumn,
  updateSortDirection,
  updateCurrentPageIndex,
  updateItemsPerPage,
  updatePageChanged,
  updateTotalItemCount,
  updateLitigationExportResults,
  updatePermitItems,
  updateStructureItems,
  updateCurrentSignDetail,
  updateSectionItems,
  updateCurrentSection,
  updateSectionNotFound,
  updateSectionByCountyItems,
  updateViolationItems,
  updateFindingItems,
  updateDocumentItems,
  updateIllegalSigns,
  updateLastCriteriaUsed,
  updateFacingItems,
} = searchSlice.actions;
export default searchSlice.reducer;

export const performAccountSearch = (criteria: IAccountSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performAccountSearch(criteria)
    .then((data: { results: Array<IAccountRow>; totalCount: number }) => {
      dispatch(updateAccountItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performFacingSearch = (criteria: IFacingSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performFacingSearch(criteria)
    .then((data: { results: Array<IFacingSearchRow>; totalCount: number }) => {
      dispatch(updateFacingItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performTransferSearch = (criteria: ITransferSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performTransferSearch(criteria)
    .then((data: { results: Array<ITransferRow>; totalCount: number }) => {
      dispatch(updateTransferItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performViolationSearch = (criteria: IViolationSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performViolationSearch(criteria)
    .then((data: { results: Array<IViolationRow>; totalCount: number }) => {
      dispatch(updateViolationItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const loadSignDetails = (tagNumber: string) => async (dispatch: any) => {
  const signDetails = await searchApi.getSignDetails(tagNumber);

  dispatch(updateCurrentSignDetail(signDetails));
};

export const performStructureSearch = (criteria: IStructureSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performStructureSearch(criteria)
    .then((data: { results: Array<IStructureRow>; totalCount: number }) => {
      if (criteria.filterStructureNumber) {
        const filtered = data.results.filter(x => {
          return x.structureNumber.toString() !== criteria.filterStructureNumber?.toString();
        });

        dispatch(updateStructureItems(filtered));
        dispatch(updateTotalItemCount(data.totalCount - 1));
      } else {
        dispatch(updateStructureItems(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
      }

      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performSectionSearch = (criteria: ISectionSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  try {
    //const section = await searchApi.getSection(criteria.sectionNumber);

    //dispatch(updateCurrentSection(section));
    searchApi
      .performSectionSearch(criteria)
      .then((data: { results: Array<ISection>; totalCount: number }) => {
        dispatch(updateSectionItems(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
        dispatch(updateIsLoading(false));
        dispatch(updateSectionNotFound(false));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
      });
  } catch (ex) {
    dispatch(updateIsLoading(false));
    dispatch(updateSectionNotFound(true));
    return;
  }
};

export const performPermitApplicationSearch = (criteria: IPermitApplicationSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performPermitApplicationSearch(criteria)
    .then((data: { results: Array<IPermitApplicationRow>; totalCount: number }) => {
      dispatch(updateApplicationItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performPermitSearch = (criteria: IPermitSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performPermitSearch(criteria)
    .then((data: { results: Array<IPermitRow>; totalCount: number }) => {
      if (data.results.length > 0 && criteria.tagNumber) {
        if (data.results[0].currentTagNumber !== criteria.tagNumber) {
          toast.info(
            `The tag number ${criteria.tagNumber} has been replaced with ${data.results[0].currentTagNumber}.`,
          );
        }
      }
      dispatch(updatePermitItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performLitigationSearch =
  (criteria: ILitigationSearchCriteria, successCallback?: () => void, failureCallback?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    searchApi
      .performLitigationSearch(criteria)
      .then((data: { results: Array<ILitigationRow>; totalCount: number }) => {
        dispatch(updateLastCriteriaUsed(criteria));
        dispatch(updateLitigationItems(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        if (failureCallback) {
          failureCallback();
        }
      });
  };

export const performExportLitigationSearch =
  (criteria: ILitigationSearchCriteria, successCallback?: () => void, failureCallback?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    searchApi
      .performLitigationSearch(criteria)
      .then((data: { results: Array<ILitigationRow>; totalCount: number }) => {
        dispatch(updateLitigationExportResults(data.results));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        if (failureCallback) {
          failureCallback();
        }
      });
  };

export const performNoticeSearch =
  (criteria: INoticeSearchCriteria, successCallback?: () => void, failureCallback?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    searchApi
      .performNoticeSearch(criteria)
      .then((data: { results: Array<INoticeRow>; totalCount: number }) => {
        dispatch(updateNoticeItems(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        if (failureCallback) {
          failureCallback();
        }
      });
  };

export const performIllegalSignSearch =
  (
    criteria: IIllegalSignSearchCriteria,
    successCallback?: (results: Array<IIllegalSignRow>) => void,
    failureCallback?: () => void,
  ) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    searchApi
      .performIllegalSignSearch(criteria)
      .then((data: { results: Array<IIllegalSignRow>; totalCount: number }) => {
        dispatch(updateIllegalSigns(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          console.log(data.results);
          successCallback(data.results);
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        if (failureCallback) {
          failureCallback();
        }
      });
  };

export const performPaymentSearch =
  (criteria: IPaymentSearchCriteria, successCallback?: () => void, failureCallback?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    searchApi
      .performPaymentSearch(criteria)
      .then((data: { results: Array<IPaymentRow>; totalCount: number }) => {
        dispatch(updatePaymentItems(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        if (failureCallback) {
          failureCallback();
        }
      });
  };

export const performInvoiceSearch =
  (criteria: IInvoiceSearchCriteria, successCallback?: () => void, failureCallback?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    searchApi
      .performInvoiceSearch(criteria)
      .then((data: { results: Array<IInvoiceRow>; totalCount: number }) => {
        dispatch(updateInvoiceItems(data.results));
        dispatch(updateTotalItemCount(data.totalCount));
        dispatch(updateIsLoading(false));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(updateIsLoading(false));
        if (failureCallback) {
          failureCallback();
        }
      });
  };

export const performFindingSearch = (criteria: IFindingSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performFindingSearch(criteria)
    .then((data: { results: Array<IFindingRow>; totalCount: number }) => {
      dispatch(updateFindingItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const performDocumentSearch = (criteria: IDocumentSearchCriteria) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  searchApi
    .performDocumentSearch(criteria)
    .then((data: { results: Array<IDocumentRow>; totalCount: number }) => {
      dispatch(updateDocumentItems(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const searchSectionByCounty = (county: string) => async (dispatch: any) => {
  const sections = await searchApi.performSectionByCounty(county);

  dispatch(updateSectionByCountyItems(sections));
};

export const getAllMileages = (pageIndex: number) => async (dispatch: any) => {
  mileageApi
    .getAllMileages(pageIndex)
    .then((data: { results: Array<IMileage>; totalCount: number }) => {
      dispatch(loadAllMileages(data.results));
      dispatch(updateTotalItemCount(data.totalCount));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });

  //.then((Mileages: IMileage[]) => {
  //  dispatch(loadAllMileages(Mileages));
  //})
  //.catch((error: any) => {
  //  console.log(error);
  //});
};

export const deleteMileage =
  (mileageId: string, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    mileageApi
      .deleteMileage(mileageId)
      .then(() => {
        dispatch(getAllMileages(0));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        failureCallback();
      });
  };
