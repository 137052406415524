"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllegalSignResolution = void 0;
var IllegalSignResolution;
(function (IllegalSignResolution) {
    IllegalSignResolution["Adverse"] = "Adverse";
    IllegalSignResolution["Dismissed"] = "Dismissed";
    IllegalSignResolution["Duplicate"] = "Duplicate";
    IllegalSignResolution["Exemption"] = "Exemption";
    IllegalSignResolution["NoViolationFound"] = "No Violation Found";
    IllegalSignResolution["NotApplicable"] = "Not Applicable";
    IllegalSignResolution["OnPremise"] = "On-Premise";
    IllegalSignResolution["Paid"] = "Paid";
    IllegalSignResolution["Permitted"] = "Permitted";
    IllegalSignResolution["Removed"] = "Removed";
    IllegalSignResolution["Upheld"] = "Upheld";
})(IllegalSignResolution = exports.IllegalSignResolution || (exports.IllegalSignResolution = {}));
