import axios from "axios";
import { IIlegalSign, IllegalSignNoticeType } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/illegalSign`;

export const getIllegalSign = async (id: string): Promise<IIlegalSign> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const deleteIllegalSign = async (id: string): Promise<IIlegalSign> => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};

export const getAllIllegalSign = async (): Promise<IIlegalSign[]> => {
  const response = await axios.get(`${baseUrl}`);
  return response.data;
};

export const getMyIllegalSigns = async (): Promise<IIlegalSign[]> => {
  const response = await axios.get(`${baseUrl}/myIllegalSigns`);
  return response.data;
};

export const getIllegalSignsForPermit = async (permitId: string): Promise<IIlegalSign[]> => {
  const response = await axios.get(`${baseUrl}/permit/${permitId}`);
  return response.data;
};

export const searchIllegalSignsByNumber = async (number: string): Promise<IIlegalSign[]> => {
  const response = await axios.get(`${baseUrl}/search-illegalSigns/${number}`);
  return response.data;
};

export const updateIllegalSign = async (sign: IIlegalSign): Promise<IIlegalSign> => {
  const response = await axios.patch(`${baseUrl}/${sign._id}`, sign);
  return response.data;
};

export const addIllegalSign = async (sign: IIlegalSign): Promise<IIlegalSign> => {
  const response = await axios.post(`${baseUrl}/`, sign);
  return response.data;
};

export const addIllegalSignFromPermit = async (permitId: string): Promise<IIlegalSign> => {
  const response = await axios.post(`${baseUrl}/${permitId}`);

  return response.data;
};

export const getNoticeUrl = (noticeType: IllegalSignNoticeType, id: string, usePropertyOwner?: boolean) => {
    
  switch (noticeType) {
    case IllegalSignNoticeType.LetterOfConcern:
      return `${baseUrl}/letterOfConcernSignOwner/pdf/${id}`;
    case IllegalSignNoticeType.NoticeOfViolationIllegalSignInTheRightOfWay:
      return `${baseUrl}/illegalSignRightOfWay/pdf/${id}`;
    case IllegalSignNoticeType.TaskWorkOrder:
      return `${baseUrl}/taskWorkOrder/pdf/${id}`;
    case IllegalSignNoticeType.SignRemovalInvoice:
      return `${baseUrl}/signRemovalInvoice/pdf/${id}`;
    case IllegalSignNoticeType.FinalDemandLetter:
      return `${baseUrl}/finalDemandLetter/pdf/${id}`;
    case IllegalSignNoticeType.NewJurisdictionNoticeIssued:      
      return `${baseUrl}/noticeOfJurisdictionalChange/pdf/${id}`;
    case IllegalSignNoticeType.RemovalOfRemainingStructureLetter:
      return `${baseUrl}/removalOfRemainingStructure/pdf/${id}`;
    case IllegalSignNoticeType.NewJurisdictionSecondNoticeIssued:
      return `${baseUrl}/secondNoticeOfJurisdictionalChange/pdf/${id}`;
    case IllegalSignNoticeType.FinalNoticeOfSignRemoval:
      return `${baseUrl}/finalNoticeOfSignRemoval/pdf/${id}`;
    case IllegalSignNoticeType.NoticeOfViolation:    
      if (usePropertyOwner){
        return `${baseUrl}/illegalSignPropertyOwner/pdf/${id}`;
      }  
      return `${baseUrl}/illegalSignSignOwner/pdf/${id}`;
    case IllegalSignNoticeType.TaskWorkOrderPackage:
      return `${baseUrl}/taskWorkOrderPackage/pdf/${id}`;
    case IllegalSignNoticeType.RemovalCostLetterForCancelled:
      return `${baseUrl}/removalCostLetter/pdf/${id}/Cancelled`;
    case IllegalSignNoticeType.RemovalCostLetterForExpired:
      return `${baseUrl}/removalCostLetter/pdf/${id}/Expired`;
    case IllegalSignNoticeType.RemovalCostLetterForRevoked:
      return `${baseUrl}/removalCostLetter/pdf/${id}/Revoked`;
    case IllegalSignNoticeType.OrderToCeaseWork:
      return `${baseUrl}/orderToCeaseWork/pdf/${id}`;
    case IllegalSignNoticeType.NoticeOfUnauthorizedRemoval:
      return `${baseUrl}/noticeOfUnauthorizedRemoval/pdf/${id}`;
  }
  return "";
};



//export const getSecondNoticeOfJurisdictionalUrl = (id: string) =>  `${baseUrl}/secondNoticeOfJurisdictionalChange/pdf/${id}`;
//export const getLetterOfConcernPropertyOwnerUrl = (id: string) => `${baseUrl}/letterOfConcernPropertyOwner/pdf/${id}`;
//export const getLetterOfConcernSignOwnerUrl = (id: string) => `${baseUrl}/letterOfConcernSignOwner/pdf/${id}`;
//export const getIllegalSignRightOfWayUrl = (id: string) => `${baseUrl}/illegalSignRightOfWay/pdf/${id}`;
//export const getIllegallyErectedSignPropertyOwnerUrl = (id: string) => `${baseUrl}/illegalSignPropertyOwner/pdf/${id}`;
//export const getIllegallyErectedSignSignOwnerUrl = (id: string) => `${baseUrl}/illegalSignSignOwner/pdf/${id}`;
//export const getRemovalOfRemainingStructureUrl = (id: string) => `${baseUrl}/removalOfRemainingStructure/pdf/${id}`;
//export const getTaskWorkOrderUrl = (id: string) => `${baseUrl}/taskWorkOrder/pdf/${id}`;
//export const getSignRemovalInvoiceUrl = (id: string) => `${baseUrl}/signRemovalInvoice/pdf/${id}`;
//export const getFinalDemandLetterUrl = (id: string) => `${baseUrl}/finalDemandLetter/pdf/${id}`;
export const getCombinedIllegalSignDocumentsPdf = (documents: string) => `${baseUrl}/combine-pdf/${documents}`;
//export const getTaskWorkOrderPackageUrl = (id: string) => `${baseUrl}/taskWorkOrderPackage/pdf/${id}`;
export const getNoticeOfNonPaymentUrl = (id: string) => `${baseUrl}/noticeOfNonPayment/pdf/${id}`;
export const generateFinalNoticeOfSignRemovalUrl = (id: string) => `${baseUrl}/finalNoticeOfSignRemoval/pdf/${id}`;
//export const generateRemovalCostLetterCanceledUrl = (id: string) => `${baseUrl}/removalCostLetter/pdf/${id}/Cancelled`;
//export const generateRemovalCostLetterRevokedUrl = (id: string) => `${baseUrl}/removalCostLetter/pdf/${id}/Revoked`;
//export const generateRemovalCostLetterExpiredUrl = (id: string) => `${baseUrl}/removalCostLetter/pdf/${id}/Expired`;
//export const generateOrderToCeaseWorkUrl = (id: string) => `${baseUrl}/orderToCeaseWork/pdf/${id}`;
//export const generateNoticeOfUnauthorizedRemovalUrl = (id: string) => `${baseUrl}/noticeOfUnauthorizedRemoval/pdf/${id}`;
