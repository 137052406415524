"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViolationType = void 0;
var ViolationType;
(function (ViolationType) {
    ViolationType["07A"] = "07A Failure to display permit tag";
    ViolationType["07D"] = "07D Has intermittent lights not embodied in display and displays rotating or flashing lights < 100ft from ROW";
    ViolationType["07E"] = "07E Violation of spacing, sq ft, height, width, height above crown of road limits";
    ViolationType["07H"] = "07H Is <= 15ft from ROW";
    ViolationType["07I"] = "07I Uses words Stop or Danger and imitates official traffic display";
    ViolationType["07M"] = "07M Permission to maintain has been withdrawn by person lawfully controlling site";
    ViolationType["07N"] = "07N Other";
    ViolationType["08A"] = "08A Failure to pay license renewal fees";
    ViolationType["08B"] = "08B Failure to pay permit renewal fees";
    ViolationType["08C"] = "08C Submission of dishonored check";
    ViolationType["09S"] = "09S Nonconforming sign repaired or refurbished to enhance visibility (sign dimensions enlarged, HAGL raised, lights added/altered)";
    ViolationType["09T"] = "09T Nonconforming sign altered so no longer substantially same as was when became nonconforming (structure altered, type materials in face altered, another face added to sign)";
    ViolationType["09U"] = "09U Nonconforming sign.  Other";
    ViolationType["09Y"] = "09Y Nonconforming sign destroyed by act of god.  Cannot be corrected";
    ViolationType["09Z"] = "09Z Nonconforming sign void of adverts for 12 months or more";
    ViolationType["New"] = "Area of Sign exceeds allowable size ";
    ViolationType["11A"] = "11A Failure to display permit tag in 30 days of issuance";
    ViolationType["11B"] = "11B Permit void for failure to build in 270 days";
    ViolationType["16B"] = "16B Nonconforming sign removed, and may not be re-erected";
    ViolationType["27A"] = "27A Nonpayment Final Notice";
    ViolationType["27B"] = "27B Cancellation Final Notice";
    ViolationType["09X"] = "09X Nonconforming sign removed";
    ViolationType["12A"] = "12A Illegal Vegetation Removal";
})(ViolationType = exports.ViolationType || (exports.ViolationType = {}));
