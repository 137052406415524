import React, { useState, useEffect, CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/RootReducer";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Persona, PersonaSize } from "office-ui-fabric-react";
import {
  AccountRole,
  AccountUserStatus,
  EmbeddedReports,
  IAccountIdMap,
  IAccountUser,
  InternalRole,
  staffUtils,
  Rights,
} from "oda-shared";
import PhotoCallout from "./common/persona/PhotoCallout";
//import useHasInternalRoles from "./../hooks/useHasInternalRole";
import { ButtonWithIcon } from "./common/ButtonWithIcon";
import { fetchShoppingCart } from "../redux/slices/ShoppingCartSlice";
import { fetchIsUserOnAnyAccount } from "../redux/slices/UserSlice";
import AccountSearchModal from "./accounts/AccountSearchModal";
import { toast } from "react-toastify";
import { saveAccountUserRequest } from "../redux/slices/OdaAccountSlice";

const Header = () => {
  const dropDownMenuItemStyle: CSSProperties = {
    color: "black",
  };
  const dropDownSubMenuItemStyle: CSSProperties = {
    color: "black",
    paddingLeft: "40px",
  };

  const headerTextStyle: CSSProperties = {
    color: "white",
    padding: "10px",
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20,
  };

  const loginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/login`;
  const b2cLoginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/loginB2c`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [prodUrl, setProdUrl] = useState("");
  const [showCallout, setShowCallout] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, user, isUserOnAnyAccount } = useSelector((state: RootState) => state.userReducer);
  const { items } = useSelector((state: RootState) => state.shoppingCartReducer);
  //const isAdmin = useHasInternalRoles([InternalRole.FdotAdmin]);
  const isAdmin = Rights.hasAdminRole(user);
  const isTestEnvironment = fdot.process.env.APP_ENVIRONMENT === "development" || fdot.process.env.APP_ENVIRONMENT === "test";  

  useEffect(() => {
    setProdUrl(`${fdot.process.env.PROD_URL}`);
    if (isAuthenticated && user) {
      dispatch(fetchShoppingCart());
      dispatch(fetchIsUserOnAnyAccount());
    }
  }, [dispatch, isAuthenticated, user]);

  const toggle = () => setIsExpanded(!isExpanded);
  const toggleAccountSearchModal = () => setIsOpen(!isOpen);
  // const loginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}`;
  //const prodUrl = ;

  const requestAccessToAccount = (selectedAccount: IAccountIdMap | null) => {
    if (!user) {
      toast.error("Couldn't find logged in user. Please try log in.");
    } else if (!selectedAccount) {
      toast.error("Please select a account to request access.");
    } else {
      const body: IAccountUser = {
        _id: null,
        user: user?._id,
        isPrimaryContact: false,
        status: AccountUserStatus.Requested,
        role: AccountRole.AuthorizedUser,
        odaAccount: selectedAccount?._id as any,
      };
      dispatch(saveAccountUserRequest(body));
      setIsOpen(false);
    }
  };

  const getShoppingCart = () => {
    if (isAdmin) {
      return (
        <ButtonWithIcon
          buttonText={items.length.toString()}
          buttonType="outline-danger"
          icon="shopping-cart"
          additionalClass="my-2 mx-3"
          titleText={`shopping cart with ${items.length.toString()} items`}
          onClick={() => navigate("/ShoppingCart")}></ButtonWithIcon>
      );
    } else if (Rights.canViewShoppingCart(user)) {
      return (
        <ButtonWithIcon
          buttonText={items.length.toString()}
          buttonType="primary"
          icon="shopping-cart"
          additionalClass="my-2 mx-3"
          titleText={`shopping cart with ${items.length.toString()} items`}
          onClick={() => navigate("/ShoppingCart")}></ButtonWithIcon>
      );
    }
    return null;
  };

  function getLoginLink(): React.ReactNode {
    return isAuthenticated ? (
      ""
    ) : (
      <a className="nav-link" href={loginUrl} style={headerTextStyle}>
        FDOT User Login
      </a>
    );
  }

  function getB2CLoginLink(): React.ReactNode {
    return isAuthenticated ? (
      ""
    ) : (
      <a className="nav-link" href={b2cLoginUrl} style={headerTextStyle}>
        External User Login
      </a>
    );
  }

  const getLoggedInUserDisplay = () => {
    if (user === undefined) {
      return null;
    }
    const nameOfStaff = staffUtils.getFirstNameLastName(user);
    const initials = staffUtils.getUserInitials(user);
    return isAuthenticated ? (
      <>
        <div style={headerTextStyle}>
          <div className="d-none d-xl-block">
            <Persona
              tabIndex={0}
              id="LoggedInUserPhoto"
              key="LoggedInUserPhoto"
              title={nameOfStaff}
              imageUrl={user.photoUri || undefined}
              imageAlt={`Profile Pic for: ${nameOfStaff}`}
              size={PersonaSize.size40}
              text={nameOfStaff}
              styles={{ primaryText: { color: "white" } }}
              imageInitials={initials}
              imageShouldFadeIn={true}
              onClick={() => setShowCallout(true)}
            />
          </div>
          <div className="d-xl-none">
            <button
              className="btn btn-link nav-link"
              onClick={() => setShowCallout(true)}
              title={`User Profile for ${nameOfStaff}`}>
              {nameOfStaff}
            </button>
          </div>
          {showCallout && (
            <PhotoCallout
              personaId="LoggedInUserPhoto"
              showOptions={true}
              staff={user}
              imageUrl={user.photoUri || undefined}
              nameOfStaff={nameOfStaff}
              imageInitials={initials}
              close={() => setShowCallout(false)}></PhotoCallout>
          )}
        </div>
      </>
    ) : (
      <></>
    );
  };

  const menuItem = (url: string, headerText: string, showIf: boolean) => {
    if (!showIf) {
      return <></>;
    }
    return (
      <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={url}>
        {headerText}
      </DropdownItem>
    );
  };

  const subMenuItem = (url: string, headerText: string, showIf: boolean) => {
    if (!showIf) {
      return <></>;
    }
    return (
      <DropdownItem style={dropDownSubMenuItemStyle} tag={Link} to={url}>
        {headerText}
      </DropdownItem>
    );
  };

  const subMenuHeader = (headerText: string, showIf: boolean) => {
    if (!showIf) {
      return <></>;
    }
    return (
      <DropdownItem disabled={true} style={dropDownMenuItemStyle}>
        <u>{headerText}</u>
      </DropdownItem>
    );
  };

  const getAdminLinks = () => {
    if (isAuthenticated && (isTestEnvironment || Rights.hasAdminMenu(user))) {
      return (
        <UncontrolledDropdown key="AdminLinks" nav inNavbar>
          <DropdownToggle nav caret>
            Admin
          </DropdownToggle>
          <DropdownMenu end>
            {isTestEnvironment && menuItem("/Impersonate#maincontent", "Impersonation", true)}
            {isTestEnvironment && menuItem("/DateChanger#maincontent", "Date Changer", true)}
            {menuItem(
              "/DropDownConfiguration#maincontent",
              "Drop Down Configuration",
              Rights.canManageConfiguration(user),
            )}
            {menuItem("/InspectionRegions#maincontent", "Inspection Regions", Rights.canManageConfiguration(user))}
            {menuItem("/PermitRenewalDate#maincontent", "Permit Renewal Dates", Rights.canManageConfiguration(user))}
            {menuItem(
              "/Roles#maincontent",
              "Roles",
              Rights.canManageConsultantRoles(user) || Rights.canManageInternalRoles(user),
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const requestAccountModalMenuItem = () => {
    return (
      Rights.canRequestAccountAccess(user) && (
        <DropdownItem
          style={dropDownMenuItemStyle}
          tag={Link}
          to={"#"}
          onClick={() => {
            setIsOpen(true);
          }}>
          Request Access to Existing Account
        </DropdownItem>
      )
    );
  };

  const AccountFunctionality = () => {
    if (Rights.hasMyAccountsMenu(user)) {
      return (
        <UncontrolledDropdown key="AccountLinks" nav inNavbar>
          <DropdownToggle nav caret>
            My Accounts
          </DropdownToggle>
          <DropdownMenu>
            {menuItem(
              "/accounts/account#maincontent",
              "Create New Outdoor Advertising Account",
              Rights.canCreateAccount(user),
            )}
            {requestAccountModalMenuItem()}
            {/* //These will not display for external users with no accounts */}
            {menuItem("/accounts/#maincontent", "View My Accounts", Rights.canViewAccounts(user))}
            {menuItem("/PermitApplication#maincontent", "Apply for Permit", Rights.canApplyForPermit(user))}
            {menuItem("/PermitApplications#maincontent", "View My Applications", Rights.canViewApplications(user))}
            {/*//These will only appear for internal users with the correct roles*/}
            {subMenuHeader("Reports", Rights.isInternalUser(user))}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.ApplicationStatus}#maincontent`,
              "Application Status",
              Rights.isInternalUser(user),
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return;
  };

  const getSectionOptions = () => {
    if (Rights.hasSectionsMenu(user)) {
      return (
        <UncontrolledDropdown key="SectionLinks" nav inNavbar>
          <DropdownToggle nav caret>
            Sections
          </DropdownToggle>
          <DropdownMenu>
            {menuItem(
              "/sections#maincontent",
              "Section Inventory",
              Rights.canEditSectionInventories(user) || Rights.canCreateEditSections(user),
            )}
            {menuItem("/sectionPatrols#maincontent", "Section Patrols", Rights.canAddEditSectionPatrols(user))}
            {menuItem("/patrols/patrol/", "Add Patrol", Rights.canAddEditSectionPatrols(user))}
            {menuItem("/patrols#maincontent", "List Patrols", Rights.canAddEditSectionPatrols(user))}
            {menuItem("/patrolSummary#maincontent", "Patrol Summary", Rights.canViewPatrolSummary(user))}
            {menuItem("/signsRemoved#maincontent", "Signs Removed Query", Rights.canViewSignsRemoved(user))}
            {menuItem("/Mileage#maincontent", "Mileage List", Rights.canQueryMileage(user))}
            {menuItem("/MileageQuery#maincontent", "Query Mileage", Rights.canQueryMileage(user))}
            {subMenuHeader("Reports", Rights.canViewSectionReports(user))}
            {subMenuItem("/Reports/Report270#maincontent", "270 Day Report", Rights.canViewSectionReports(user))}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.MovedFacings}#maincontent`,
              "Moved Facings",
              Rights.canViewSectionReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.MovedStructures}#maincontent`,
              "Moved Structures",
              Rights.canViewSectionReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.MultipleFacingsOneSign}#maincontent`,
              "Multiple Facings Per One Sign",
              Rights.canViewSectionReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.PermitsBySection}#maincontent`,
              "Permits By Section",
              Rights.isInternalUser(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.SectionsByCounty}#maincontent`,
              "Sections By County",
              Rights.canViewSectionReports(user),
            )}
            {subMenuItem(
              "/reports/sectionInventoryReport#maincontent",
              "Section Inventory Report",
              Rights.canViewSectionReports(user),
            )}
            {/* Removed as per work item 60595 
               {subMenuItem(
              "/reports/sectionInventoryByField#maincontent",
              "Section Inventory By Field Consultant",
              Rights.canViewSectionReports(user),
            )} */}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.SectionInventoryProgress}#maincontent`,
              "Section Inventory Progress Report",
              Rights.canViewSectionReports(user),
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }

    return;
  };



  const getTestEnvironmentBanner = () => {
    if (isTestEnvironment) {
      return (
        <div className="container-fluid bg-warning">
          <div className="row justify-content-center">
            <span className="text-danger font-weight-bold">Warning:</span>&nbsp;This is a&nbsp;{" "}
            <span className="text-danger font-weight-bold">Test Environment!</span>&nbsp;Any data entered here may be
            edited or purged at any time without warning.
            <br />
          </div>
          <div className="row justify-content-center font-weight-bold">
            Visit the official site at&nbsp;{" "}
            <a target="_blank" href={prodUrl} rel="noreferrer">
              {prodUrl}
            </a>
          </div>
        </div>
      );
    }
    return null;
  };

  const getDashboardLinks = () => {
    //First bit - a few roles don't have dashboard so they don't need the link.
    //Second bit - users with no role get sent to the dashboard but can't do anything else so they don't need the link.
    if (Rights.hasDashboard(user) && Rights.hasAnyFdotRole(user)) {
      return (
        <Link className="nav-link" to="/Dashboard#maincontent" style={headerTextStyle}>
          Dashboard
        </Link>
      );
    }
    return null;
  };

  const getAuthenticatedReportLinks = () => {
    return (
      <>
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.AccountInformation}#maincontent`,
          "Account Information",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ActiveAccounts}#maincontent`,
          "Active Accounts",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(`/EmbeddedReport/${EmbeddedReports.Licensees}#maincontent`, "Licensees", Rights.hasAnyFdotRole(user))}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ApplicationDenied}#maincontent`,
          "Applications Denied in Last 90 Days",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitNotRequired}#maincontent`,
          "Applications Permit Not Required in Last 90 Days",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ApplicationReturned}#maincontent`,
          "Applications Returned As Incomplete in Last 90 Days",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ApplicatinUnderReview}#maincontent`,
          "Applications Under Review",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {/* //This one is for everybody */}
        {menuItem("/OdaDataFile#maincontent", "ODA Data File", isAuthenticated)}

        {/* //This one is FDOT only */}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ActivePermitCounts}#maincontent`,
          "Permit Counts for Active Accounts",
          Rights.hasAnyFdotRole(user),
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitsIssuedIn90Days}#maincontent`,
          "Permits Issued in Last 90 Days",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitsForAccount}#maincontent`,
          "Permits For Account",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitActivityByAccount}#maincontent`,
          "Permit Activity by Account Query",          
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.RegionCountyReport}#maincontent`,
          "Region County Report",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.SignDetails}#maincontent`,
          "Sign Details",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.StructureCountsByCounty}#maincontent`,
          "Structure and Active Permit Counts By County",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.StructuresOnSection}#maincontent`,
          "Structures on Section",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.SummaryReport}#maincontent`,
          "Summary Report",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.TopAccounts}#maincontent`,
          "Top Accounts by Number of Active Permit",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.TransferredPermits}#maincontent`,
          "Transferred Permits",
          //Rights.hasAnyFdotRole(user),
          isAuthenticated,
        )}
      </>
    );
  };

  const getPublicReportLinks = () => {
    return (
      <>
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.AccountInformation}#maincontent`,
          "Account Information",
          !isAuthenticated,
        )}
        {menuItem(`/EmbeddedReport/${EmbeddedReports.ActiveAccounts}#maincontent`, "Active Accounts", !isAuthenticated)}
        {menuItem(`/EmbeddedReport/${EmbeddedReports.Licensees}#maincontent`, "Licensees", !isAuthenticated)}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ApplicationDenied}#maincontent`,
          "Applications Denied in Last 90 Days",
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ApplicationReturned}#maincontent`,
          "Applications Returned As Incomplete in Last 90 Days",
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitNotRequired}#maincontent`,
          "Applications Permit Not Required in Last 90 Days",
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.ApplicatinUnderReview}#maincontent`,
          "Applications Under Review",
          !isAuthenticated,
        )}
        {menuItem("/OdaDataFile#maincontent", "ODA Data File", !isAuthenticated)}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitActivityByAccount}#maincontent`,
          "Permit Activity by Account Query",          
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.PermitsIssuedIn90Days}#maincontent`,
          "Permits Issued in Last 90 Days",
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.RegionCountyReport}#maincontent`,
          "Region County Report",
          !isAuthenticated,
        )}        
        {menuItem(`/EmbeddedReport/${EmbeddedReports.SignDetails}#maincontent`, "Sign Details", !isAuthenticated)}
        {menuItem(`/EmbeddedReport/${EmbeddedReports.SectionsByCounty}#maincontent`,"Sections By County",!isAuthenticated)}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.StructureCountsByCounty}#maincontent`,
          "Structure and Active Permit Counts By County",
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.StructuresOnSection}#maincontent`,
          "Structures on Section",        
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.TopAccounts}#maincontent`,
          "Top Accounts by Number of Active Permit",
          !isAuthenticated,
        )}
        {menuItem(
          `/EmbeddedReport/${EmbeddedReports.TransferredPermits}#maincontent`,
          "Transferred Permits",
          !isAuthenticated,
        )}
      </>
    );
  };

  const getReportLinks = () => {
    return (      
      //Rights.hasReportsMenu(user) && ( //Actually everyone gets reports.
        <UncontrolledDropdown key="ReportLinks" nav inNavbar>
          <DropdownToggle nav caret>
            Reports
          </DropdownToggle>
          <DropdownMenu>
            {getAuthenticatedReportLinks()}
            {getPublicReportLinks()}
          </DropdownMenu>
        </UncontrolledDropdown>
      //)
    );
  };

  const getSearchLinks = () => {
    if (Rights.hasAnyFdotRole(user) || user?.isFieldConsultantInspector || user?.isFieldConsultantManager) {
      return (
        <UncontrolledDropdown key="SearchLinks" nav inNavbar>
          <DropdownToggle nav caret>
            Search
          </DropdownToggle>
          <DropdownMenu end>
            {/* //Not for consultants */}
            {menuItem("/Search/Account#maincontent", "Accounts", Rights.hasAnyFdotRole(user))}

            {/* Ok for consultants or FDOT staff */}
            {menuItem("/Search/PermitApplication#maincontent", "Applications", Rights.isInternalUser(user))}

            {/* //Not for consultants */}
            {menuItem("/Search/Case#maincontent", "Cases", Rights.canViewCases(user))}
            {menuItem("/Search/Document#maincontent", "Documents", Rights.hasAnyFdotRole(user))}

            {/* Ok for consultants or FDOT staff */}
            {menuItem("/Search/Facing#maincontent", "Facings", Rights.isInternalUser(user))}
            {menuItem("/Search/Finding#maincontent", "Findings", Rights.isInternalUser(user))}
            {menuItem("/Search/IllegalSign#maincontent", "Illegal Signs", Rights.isInternalUser(user))}
            {menuItem("/Search/Invoice#maincontent", "Invoices", Rights.canViewAccountingInfo(user))}
            {menuItem("/Search/Notice#maincontent", "Notices", Rights.isInternalUser(user))}
            {menuItem("/Search/Payment#maincontent", "Payments", Rights.canViewAccountingInfo(user))}
            {menuItem("/Search/Permit#maincontent", "Permits", Rights.isInternalUser(user))}
            {menuItem("/Search/Section#maincontent", "Sections", Rights.isInternalUser(user))}
            {menuItem("/Search/Structure#maincontent", "Structures", Rights.isInternalUser(user))}
            {menuItem("/Search/Transfer#maincontent", "Transfers", Rights.isInternalUser(user))}
            {menuItem("/Search/Violation#maincontent", "Violations", Rights.isInternalUser(user))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };

  const getAccountingLinks = () => {
    if (Rights.canViewAccountingInfo(user)) {
      return (
        <UncontrolledDropdown key="AccountingLinks" nav inNavbar>
          <DropdownToggle nav caret>
            Accounting
          </DropdownToggle>
          <DropdownMenu end>
            {menuItem("/accounts/annual-renewals#maincontent", "Annual Renewals", Rights.canViewAccountingInfo(user))}
            {menuItem("/Invoices/#maincontent", "Invoices", Rights.canViewAccountingInfo(user))}
            {menuItem("/Search/Payment#maincontent", "Payments", Rights.canViewAccountingInfo(user))}
            {subMenuHeader("Reports", Rights.canViewAccountingInfo(user))}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.AccountsWithUnallocatedLines}#maincontent`,
              "Accounts with Unallocated Lines",
              Rights.canViewAccountingInfo(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.AccountsWithUnpaidInvoices}#maincontent`,
              "Accounts with Unpaid Invoices",
              Rights.canViewAccountingInfo(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.InvoicePaymentExceptionsReport}#maincontent`,
              "Invoice/Payment Exceptions Report",
              Rights.canViewAccountingInfo(user),
            )}
            {subMenuItem(
              "/Reports/LineItemReports#maincontent",
              "Payments By Line Item",
              Rights.canViewAccountingInfo(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.ODAAccountingDeposits}#maincontent`,
              "ODA Accounting Deposits",
              Rights.canViewAccountingInfo(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.PaidInvoiceLineItems}#maincontent`,
              "Paid Invoice Line Items",
              Rights.canViewAccountingInfo(user),
            )}
            {subMenuItem("/Reports/rps#maincontent", "Manual Payment RPS Report", Rights.canViewAccountingInfo(user))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const getNonComplianceLinks = () => {
    if (Rights.hasComplianceMenu(user)) {
      return (
        <UncontrolledDropdown key="NonCompliantLinks" nav inNavbar>
          <DropdownToggle nav caret>
            Compliance
          </DropdownToggle>
          <DropdownMenu end>
            {menuItem("/Search/Case#maincontent", "Case Management", Rights.canViewCases(user))}
            {menuItem("/IllegalSigns/#maincontent", "My Illegal Signs", Rights.canCreateEditIllegalSigns(user))}
            {subMenuHeader("Reports", true)}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.IllegalSignsActivityByStatus}#maincontent`,
              "Illegal Signs Activity By Status",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.IllegalSignsCostRecovery}#maincontent`,
              "Illegal Signs Cost Recovery",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.IllegalSignsList}#maincontent`,
              "Illegal Signs List",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.IllegalSignsRemovalsSummary}#maincontent`,
              "Illegal Signs Removals Summary",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.IllegalSignsResolutionsSummary}#maincontent`,
              "Illegal Signs Resolutions Summary",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.IllegalSignsStatusSummary}#maincontent`,
              "Illegal Signs Status Summary",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.ViolationsQualityControlReport}#maincontent`,
              "Violations Quality Control Report",
              Rights.canViewComplianceReports(user),
            )}
            {subMenuItem(
              `/EmbeddedReport/${EmbeddedReports.InactivePermitsNoRemovalDate}#maincontent`,
              "Inactive Permits (No Removal Date)",
              Rights.canViewComplianceReports(user),
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const getNavbar = () => {
    return (
      <div>
        <AccountSearchModal
          isOpen={isOpen}
          onRequestAccess={(accountSelected: IAccountIdMap | null) => {
            requestAccessToAccount(accountSelected);
          }}
          toggle={toggleAccountSearchModal}
        />
        <Navbar color="dark" dark expand="xl">
          <NavbarBrand href="/" style={brandStyle}>
            <img src="/fdot-logo-white.png" alt="FDOT Logo" height={42}></img>&nbsp;&nbsp;
            <span title="Outdoor Advertising">Outdoor Advertising&nbsp;&nbsp;</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isExpanded} navbar>
            <Nav navbar>
              {[
                getAdminLinks(),
                AccountFunctionality(),
                getAccountingLinks(),
                getSectionOptions(),
                getNonComplianceLinks(),
                getReportLinks(),
                getSearchLinks(),
              ]}
            </Nav>
            {getDashboardLinks()}
            {user && user._id && !user.isExternal && Rights.canViewTasks(user) && (
              <Link className="nav-link" to="/Tasks" style={headerTextStyle}>
                Tasks
              </Link>
            )}
            <Link className="nav-link" to="/Help" style={headerTextStyle}>
              Help
            </Link>
            <Nav className="ml-auto">
              <NavItem>{getShoppingCart()}</NavItem>
              <NavItem>{getLoggedInUserDisplay()}</NavItem>
              {/* <NavItem>{getLoginLink()}</NavItem>
              <NavItem>{getB2CLoginLink()}</NavItem> */}
            </Nav>
             
          </Collapse>
        </Navbar>
        {getTestEnvironmentBanner()}
      </div>
    );
  };

  return getNavbar();
};

export default Header;
