import axios from "axios";
import { IFacing, IFacingRow, IInspectionRegion, ISection, stringUtils } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/sections`;

export const deleteSectionDocument = async (sectionId: string, documentId: string) => {
  const response = await axios.delete(`${baseUrl}/section/${sectionId}/document/${documentId}`);
  return response.data;
};

export const getSection = async (id: string): Promise<ISection> => {
  const response = await axios.get(`${baseUrl}/section/${id}`);
  return response.data;
};

export const getSectionByNumber = async (id: string): Promise<ISection> => {
  const response = await axios.get(`${baseUrl}/sectionByNumber/${id}`);
  return response.data;
};

export const createSection = async (section: ISection, region: IInspectionRegion): Promise<ISection> => {
  const response = await axios.post(`${baseUrl}/section/${region?._id}`, section);
  return response.data;
};
export const updateSection = async (section: ISection, region: IInspectionRegion): Promise<ISection> => {
  const response = await axios.patch(`${baseUrl}/section/${section._id}/${region?._id}`, section);
  return response.data;
};

export const getSections = async (): Promise<ISection[]> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getAllSections = async (): Promise<ISection[]> => {
  const response = await axios.get(baseUrl + "/all");
  return response.data;
};

export const searchSections = async (
  regionId: string,
  county: string,
  notPatrolledYear: string,
): Promise<ISection[]> => {
  const countyParam = stringUtils.isNullOrWhitespace(county) ? "-" : county;
  const response = await axios.get(`${baseUrl}/region/${regionId}/county/${countyParam}/${notPatrolledYear}`);
  return response.data;
};

const baseInventoryUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/inventories`;

/*export const getInventory = async (id: string): Promise<IAnnualInventory> => {
  const response = await axios.get(`${baseInventoryUrl}/inventory/${id}`);
  return response.data;
};
export const createInventory = async (inventory: IAnnualInventory): Promise<IAnnualInventory> => {
  const response = await axios.post(`${baseInventoryUrl}/inventory/`, inventory);
  return response.data;
};
export const updateInventory = async (inventory: IAnnualInventory): Promise<IAnnualInventory> => {
  const response = await axios.patch(`${baseInventoryUrl}/inventory/${inventory._id}`, inventory);
  return response.data;
};

export const getInventories = async (): Promise<IAnnualInventory[]> => {
  const response = await axios.get(baseInventoryUrl);
  return response.data;
};*/

export const getSectionsNotInventoried = async (sinceDate: Date | null): Promise<ISection[]> => {
  const date = sinceDate ?? new Date();
  const response = await axios.get(`${baseUrl}/sectionsNotInventoried/${date}`);
  return response.data;
};

const baseStructureUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/facings`;

export const getFacingsForSection = async (rciSection: string): Promise<IFacing[]> => {
  const response = await axios.get(`${baseStructureUrl}/section/${rciSection}`);
  return response.data;
};

export const searchFacings = async (rciSection: string, showOnlyActive: boolean): Promise<IFacingRow[]> => {
  const response = await axios.get(`${baseStructureUrl}/search/section/${rciSection}/${showOnlyActive}`);
  return response.data;
};

export const sectionReportUri = (id: string) => `${fdot.process.env.BACKEND_SERVER_HOST}/api/section-report/${id}`;
