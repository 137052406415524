import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAccountIdMap } from "oda-shared";
import { getAccountIdMaps, getAccountIdMapsForUser } from "../../redux/slices/OdaAccountSlice";
import { RootState } from "../../redux/RootReducer";

interface IProps {
  id: string;
  selectedAccount: IAccountIdMap | null;
  disabled?: boolean;
  onChange: (selected: IAccountIdMap | null) => void;
  restrictToUserRoles?: boolean;
  activeOnly?: boolean;
}

export const AccountSelector: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Array<IAccountIdMap>>([]);
  const [selectedAccount, setSelectedAccount] = useState<Array<IAccountIdMap>>([]);

  useEffect(() => {
    //used in places where an external user can only see accounts they are associated with.
    if (props.restrictToUserRoles) {
      dispatch(getAccountIdMapsForUser());
    } else {
      dispatch(getAccountIdMaps());
    }
  }, [dispatch, props.restrictToUserRoles]);

  const accountIdMapsFromStore = useSelector((state: RootState) => state.odaAccountReducer.accountIdMaps);

  const accountIdMaps = useMemo(() => {
    if (props.activeOnly === true && accountIdMapsFromStore) {
      return accountIdMapsFromStore.filter(a => {
        return a.isActive === true;
      });
    }
    return accountIdMapsFromStore;
  }, [accountIdMapsFromStore, props.activeOnly]);

  useEffect(() => {
    const selectedAccount = props.selectedAccount === null ? [] : [props.selectedAccount];
    setSelectedAccount(selectedAccount);
  }, [props.selectedAccount]);

  const onChange = (selected: Array<IAccountIdMap>) => {
    let returnVal: IAccountIdMap | null = null;
    if (selected && selected.length > 0) {
      returnVal = selected[0];
    }
    setSelectedAccount(selected);
    props.onChange(returnVal);
  };

  const handleSearch = async (searchString: string) => {
    if (!accountIdMaps || accountIdMaps.length < 1) {
      return;
    }
    setIsLoading(true);
    const matches = accountIdMaps.filter(
      item =>
        item.accountNumber.toString().toUpperCase().startsWith(searchString.toUpperCase()) ||
        item.accountName.toUpperCase().startsWith(searchString.toUpperCase()),
    );
    setOptions(matches);
    setIsLoading(false);
  };

  const getLabel = (acct: IAccountIdMap | null | undefined): string => {
    return acct ? `# ${acct.accountNumber} - ${acct.accountName}` : "";
  };

  return (
    <AsyncTypeahead
      id={props.id}
      inputProps={{ "aria-label": props.id, title: "Enter Account Number or Name to search", id: props.id }}
      allowNew={false}
      isLoading={isLoading}
      multiple={false}
      minLength={1}
      options={options}
      selected={selectedAccount}
      onSearch={handleSearch}
      onChange={onChange}
      placeholder="Enter Account Number or Name to search"
      disabled={props.disabled}
      labelKey={(option: any) => getLabel(option)}
    />
  );
};
