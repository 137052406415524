"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaseResolution = void 0;
var CaseResolution;
(function (CaseResolution) {
    CaseResolution["NotApplicable"] = "Not Applicable";
    CaseResolution["AmendedHearingRequestRejected"] = "Amended Hearing Request Rejected";
    CaseResolution["AppealComplete"] = "Appeal Complete";
    CaseResolution["ClerksOrderOfDismissal"] = "Clerks Order of Dismissal";
    CaseResolution["Duplicate"] = "Duplicate";
    CaseResolution["FinalOrderAlterSignForExemption"] = "Final Order \u2013 Alter Sign for Exemption";
    CaseResolution["FinalOrderDenyPermit"] = "Final Order \u2013 Deny Permit";
    CaseResolution["FinalOrderIssuePermit"] = "Final Order \u2013 Issue Permit";
    CaseResolution["FinalOrderRemoveSign"] = "Final Order \u2013 Remove Sign";
    CaseResolution["FinalOrderRevokePermit"] = "Final Order \u2013 Revoke Permit";
    CaseResolution["OrderOfDismissal"] = "Order of Dismissal";
})(CaseResolution = exports.CaseResolution || (exports.CaseResolution = {}));
