import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppRoutes from "./components/routing/RouteWrapper";
import AxiosInit from "./components/AxiosInit";

import "@syncfusion/ej2-base/styles/bootstrap4.css";
import "@syncfusion/ej2-buttons/styles/bootstrap4.css";
import "@syncfusion/ej2-calendars/styles/bootstrap4.css";
import "@syncfusion/ej2-dropdowns/styles/bootstrap4.css";
import "@syncfusion/ej2-inputs/styles/bootstrap4.css";
import "@syncfusion/ej2-popups/styles/bootstrap4.css";
import "@syncfusion/ej2-icons/styles/bootstrap4.css";
import "@syncfusion/ej2-splitbuttons/styles/bootstrap4.css";
import "@syncfusion/ej2-lists/styles/bootstrap4.css";
import "@syncfusion/ej2-navigations/styles/bootstrap4.css";
import "@syncfusion/ej2-richtexteditor/styles/bootstrap4.css";
import "@syncfusion/ej2-richtexteditor/styles/bootstrap4.css";

import "@progress/kendo-theme-bootstrap/dist/all.css";

import { initializeIcons } from "./Icons";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { CalendarProps, CalendarPropsContext } from "@progress/kendo-react-dateinputs";

export const App: React.FC = props => {
  // This is required for React-Router - useNavigate hook uses history under the hood
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const browserHistory = createBrowserHistory();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  initializeIcons();

  // added this to disable kendo grid date filter side bar which doesn't work in React 18
  const calendarProps = React.useCallback(
    (calendarProps: CalendarProps) => ({
      ...calendarProps,
      navigation: false,
    }),
    [],
  );

  return (
    <>
      <CalendarPropsContext.Provider value={calendarProps}>
        <ToastContainer
          position="bottom-right"
          autoClose={8000}
          pauseOnHover={true}
          pauseOnFocusLoss={true}></ToastContainer>
        <BrowserRouter>
          <AxiosInit></AxiosInit>
          <AppRoutes></AppRoutes>
        </BrowserRouter>
      </CalendarPropsContext.Provider>
    </>
  );
};

export default App;
