"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateInspectionRegion = void 0;
exports.ValidateInspectionRegion = function (inspectionRegion, allInspectionRegions) {
    var _a, _b;
    var result = { success: true, messages: [] };
    if (!inspectionRegion.name) {
        result.success = false;
        result.messages.push("The inspection region does not have a name.");
    }
    var _loop_1 = function (county) {
        for (var _i = 0, allInspectionRegions_1 = allInspectionRegions; _i < allInspectionRegions_1.length; _i++) {
            var currentInspectionRegion = allInspectionRegions_1[_i];
            if (((_a = currentInspectionRegion._id) === null || _a === void 0 ? void 0 : _a.toString()) == ((_b = inspectionRegion._id) === null || _b === void 0 ? void 0 : _b.toString())) {
                continue;
            }
            var index = currentInspectionRegion.counties.findIndex(function (c) {
                return c === county;
            });
            if (index > -1) {
                result.messages.push("County " + county + " has already been added to inspection regions " + inspectionRegion.name + ".");
                result.success = false;
                break;
            }
        }
    };
    for (var _i = 0, _c = inspectionRegion.counties; _i < _c.length; _i++) {
        var county = _c[_i];
        _loop_1(county);
    }
    return result;
};
