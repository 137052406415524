import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITaskAssignment } from "oda-shared";
import * as taskAssignmentApi from "../../apis/TaskAssignmnentApi";
import { toast } from "react-toastify";
import { removeTimeFromDate } from "../../utils/DateUtils";

type SliceState = {
  isLoading: boolean;
  taskAssignments: ITaskAssignment[] | null;
  currentTaskAssignment: ITaskAssignment | null;
  totalCount: number;
};

const taskAssignmentSlice = createSlice({
  name: "taskAssignment",
  initialState: {
    isLoading: false,
    taskAssignments: [],
    currentTaskAssignment: null,
    totalCount: 0,
  } as SliceState,
  reducers: {
    setIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    loadTaskAssignments: (state: SliceState, action: PayloadAction<ITaskAssignment[]>) => {
      state.taskAssignments = action.payload;
      for (let i = 0; i < state.taskAssignments.length; i++) {
        //have to parse ISO date strings into Date objects for Kendo UI grid use...        
        state.taskAssignments[i].statusChange = removeTimeFromDate(state.taskAssignments[i].statusChange);
        if (state.taskAssignments[i].assignedDate) {
          state.taskAssignments[i].assignedDate = removeTimeFromDate(state.taskAssignments[i].assignedDate);
        }
        if (state.taskAssignments[i].dueDate270) {
          // state.taskAssignments[i].dueDate270 = new Date(state.taskAssignments[i].dueDate270);
          // state.taskAssignments[i].dueDate270.setHours(0, 0, 0, 0);
          state.taskAssignments[i].dueDate270 = removeTimeFromDate(state.taskAssignments[i].dueDate270);
        }
        if (state.taskAssignments[i].dueDate) {
          // state.taskAssignments[i].dueDate = new Date(state.taskAssignments[i].dueDate);
          // state.taskAssignments[i].dueDate.setHours(0, 0, 0, 0);          
          state.taskAssignments[i].dueDate = removeTimeFromDate(state.taskAssignments[i].dueDate);
        }
        if (state.taskAssignments[i].completedDate) {
          //state.taskAssignments[i].completedDate = new Date(state.taskAssignments[i].completedDate);
          //state.taskAssignments[i].completedDate.setHours(0, 0, 0, 0);
          state.taskAssignments[i].completedDate = removeTimeFromDate(state.taskAssignments[i].completedDate);
        }

        //Set display task type for some
        if (state.taskAssignments[i].violationId) {
          state.taskAssignments[i].taskTypeDisplay = "Violation";
        }
        if (state.taskAssignments[i].litigationId) {
          state.taskAssignments[i].taskTypeDisplay = "Litigation Support";
        }
        else {
          state.taskAssignments[i].taskTypeDisplay = state.taskAssignments[i].taskType ?? "";
        }
      }
      state.currentTaskAssignment = null;
      state.isLoading = false;
    },
    loadTotalCount: (state: SliceState, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    loadCurrentTaskAssignment: (state: SliceState, action: PayloadAction<ITaskAssignment | null>) => {
      console.log("loadCurrentTaskAssignment", action.payload);
      state.currentTaskAssignment = action.payload;
    },
    updateLoadedTaskAssignment: (state: SliceState, action: PayloadAction<ITaskAssignment>) => {
      state.currentTaskAssignment = action.payload;
      console.log("updateLoadedTaskAssignment", action.payload);
      if (state.taskAssignments) {
        const taskIndex = state.taskAssignments.findIndex(x => x._id === action.payload._id);
        state.taskAssignments[taskIndex] = action.payload;
      }
    },
    addTask: (state: SliceState, action: PayloadAction<ITaskAssignment>) => {
      if (state.taskAssignments) {
        state.taskAssignments?.push(action.payload);
      } else {
        state.taskAssignments = [action.payload];
      }
    },
    deleteTask: (state: SliceState, action: PayloadAction<ITaskAssignment>) => {
      state.taskAssignments =
        state.taskAssignments !== null ? state.taskAssignments.filter(i => i._id !== action.payload._id) : null;
      return state;
    },
  },
});

export const {
  setIsLoading,
  loadTaskAssignments,
  loadCurrentTaskAssignment,
  loadTotalCount,
  addTask,
  updateLoadedTaskAssignment,
  deleteTask,
} = taskAssignmentSlice.actions;

export default taskAssignmentSlice.reducer;

export const getNoticeTaskAssignments = (noticeId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getNoticeTaskAssignments(noticeId)
    .then((taskAssignments: ITaskAssignment[]) => {
      dispatch(loadTaskAssignments(taskAssignments));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getIllegalSignTaskAssignments = (noticeId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getIllegalSignTaskAssignments(noticeId)
    .then((taskAssignments: ITaskAssignment[]) => {
      dispatch(loadTaskAssignments(taskAssignments));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getFindingTaskAssignments = (findingId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getFindingTaskAssignments(findingId)
    .then((taskAssignments: ITaskAssignment[]) => {
      dispatch(loadTaskAssignments(taskAssignments));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getLitigationTaskAssignments = (litigationId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getLitigationTaskAssignments(litigationId)
    .then((taskAssignments: ITaskAssignment[]) => {
      dispatch(loadTaskAssignments(taskAssignments));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getPermitTaskAssignments = (permitId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getPermitTaskAssignments(permitId)
    .then((taskAssignments: ITaskAssignment[]) => {
      dispatch(loadTaskAssignments(taskAssignments));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getTaskAssignment = (taskId: string) => async (dispatch: any) => {
  taskAssignmentApi
    .getTaskAssignment(taskId)
    .then((taskAssignment: ITaskAssignment) => {
      dispatch(updateLoadedTaskAssignment(taskAssignment));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getViolationTaskAssignments = (violationId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getViolationTaskAssignments(violationId)
    .then((taskAssignments: ITaskAssignment[]) => {
      dispatch(loadTaskAssignments(taskAssignments));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getTaskAssignments = (criteria: any) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  taskAssignmentApi
    .getTaskAssignments(criteria)
    .then((data: any) => {
      dispatch(loadTaskAssignments(data.results));
      dispatch(loadTotalCount(data.totalCount));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateTaskAssignment = (taskAssignment: ITaskAssignment, successCallback: (id: string) => void) => async (dispatch: any) => {
  await taskAssignmentApi.updateTaskAssignment(taskAssignment)
  .then((taskAssignment: ITaskAssignment) => {
    toast.success("Task Assignment Saved!");
    successCallback(taskAssignment._id);
    dispatch(updateLoadedTaskAssignment(taskAssignment));
  });
};

export const addTaskAssignment =
  (taskAssignment: ITaskAssignment, successCallback: (id: string) => void) => async (dispatch: any) => {
    console.log("addTaskAssignment", taskAssignment);

    taskAssignmentApi
      .addTaskAssignment(taskAssignment)
      .then((taskAssignment: ITaskAssignment) => {
        dispatch(loadCurrentTaskAssignment(taskAssignment));
        dispatch(addTask(taskAssignment));
        toast.success("Task Assignment created!");
        successCallback(taskAssignment._id);
      })
      .catch((error: any) => {
        console.log(error);
        //toast.error("Something went wrong");
      });
  };

export const deleteTaskItem = (task: ITaskAssignment) => async (dispatch: any) => {
  taskAssignmentApi
    .deleteTask(task)
    .then(item => {
      dispatch(deleteTask(item));
      toast.success("Task deleted successfully!");
    })
    .catch(error => {
      console.log(error);
      //toast.error("Something went wrong");
    });
};
