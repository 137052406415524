import { Theme } from "oda-shared";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";

interface IProps {}

export const ThemeStyle: React.FC<IProps> = (props: IProps) => {
  const { theme } = useSelector((state: RootState) => state.userReducer);

  if (theme === Theme.Dark) {
    const styleSheetOverridePath = "/themes/dark/dark-overrides.css";

    return (
      <>
        {/* Note I pulled this down to client/public/themes/dark/darkly-bootstrap.min.css in case this CDN ever goes away  */}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootswatch/4.5.2/darkly/bootstrap.min.css"
          integrity="sha384-nNK9n28pDUDDgIiIqZ/MiyO3F4/9vsMtReZK39klb/MtkZI3/LtjSjlmyVPS3KdN"
          crossOrigin="anonymous"></link>

        <link rel="stylesheet" type="text/css" href={styleSheetOverridePath} />
      </>
    );
  }

  return null;
};
