"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdaCosts = void 0;
exports.OdaCosts = {
    OdaLicenseRenewalCost: 300,
    permitRenewalCost: 71,
    //lateFees: 7.1,
    lateFeePercent: 0.1,
    permitReinstatementFee: 300
};
