"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermitCancellationStatus = void 0;
var PermitCancellationStatus;
(function (PermitCancellationStatus) {
    PermitCancellationStatus["Draft"] = "Draft";
    PermitCancellationStatus["Submitted"] = "Submitted";
    PermitCancellationStatus["Denied"] = "Denied";
    PermitCancellationStatus["CancellationComplete"] = "Complete";
})(PermitCancellationStatus = exports.PermitCancellationStatus || (exports.PermitCancellationStatus = {}));
