"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.State = void 0;
var State;
(function (State) {
    State["Alabama"] = "AL";
    State["Alaska"] = "AK";
    State["Arizona"] = "AZ";
    State["Arkansas"] = "AR";
    State["California"] = "CA";
    State["Colorado"] = "CO";
    State["Connecticut"] = "CT";
    State["DistrictOfColumbia"] = "DC";
    State["Delaware"] = "DE";
    State["Florida"] = "FL";
    State["Georgia"] = "GA";
    State["Hawaii"] = "HI";
    State["Idaho"] = "ID";
    State["Illinois"] = "IL";
    State["Indiana"] = "IN";
    State["Iowa"] = "IA";
    State["Kansas"] = "KS";
    State["Kentucky"] = "KY";
    State["Louisiana"] = "LA";
    State["Maine"] = "ME";
    State["Maryland"] = "MD";
    State["Massachusetts"] = "MA";
    State["Michigan"] = "MI";
    State["Minnesota"] = "MN";
    State["Mississippi"] = "MS";
    State["Missouri"] = "MO";
    State["Montana"] = "MT";
    State["Nebraska"] = "NE";
    State["Nevada"] = "NV";
    State["NewHampshire"] = "NH";
    State["NewJersey"] = "NJ";
    State["NewMexico"] = "NM";
    State["NewYork"] = "NY";
    State["NorthCarolina"] = "NC";
    State["NorthDakota"] = "ND";
    State["Ohio"] = "OH";
    State["Oklahoma"] = "OK";
    State["Oregon"] = "OR";
    State["Pennsylvania"] = "PA";
    State["RhodeIsland"] = "RI";
    State["SouthCarolina"] = "SC";
    State["SouthDakota"] = "SD";
    State["Tennessee"] = "TN";
    State["Texas"] = "TX";
    State["Utah"] = "UT";
    State["Vermont"] = "VT";
    State["Virginia"] = "VA";
    State["Washington"] = "WA";
    State["WestVirginia"] = "WV";
    State["Wisconsin"] = "WI";
    State["Wyoming"] = "WY";
})(State = exports.State || (exports.State = {}));
