import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISection, ISectionAnnualData, SectionAnnualDataStatus } from "oda-shared";
import * as sectionAnnualDataApi from "../../apis/SectionAnnualDataApi";
import { toast } from "react-toastify";
import { loadSection } from "./SectionSlice";

type SliceState = {
  currentSectionAnnualData: ISectionAnnualData;
  sectionAnnualDataRecords: ISectionAnnualData[];
};

const SectionInventorySlice = createSlice({
  name: "section",
  initialState: {} as SliceState,
  reducers: {
    setCurrentSectionAnnualData: (state: SliceState, action: PayloadAction<ISectionAnnualData>) => {
      if (state.sectionAnnualDataRecords) {
        state.sectionAnnualDataRecords[action.payload._id] = action.payload;
      }
      state.currentSectionAnnualData = action.payload;
    },
    loadSectionAnnualDataRecords: (state: SliceState, action: PayloadAction<ISectionAnnualData[]>) => {
      state.sectionAnnualDataRecords = action.payload;
    },
  },
});

export const { setCurrentSectionAnnualData, loadSectionAnnualDataRecords } = SectionInventorySlice.actions;

export default SectionInventorySlice.reducer;

export const loadCurrentSectionAnnualData = (id: string) => async (dispatch: any) => {
  const sectionAnnualData = await sectionAnnualDataApi.getSectionAnnualData(id);
  dispatch(setCurrentSectionAnnualData(sectionAnnualData));
};

export const saveSectionAnnualData =
  (sectionAnnualData: ISectionAnnualData, section: ISection) => async (dispatch: any) => {
    if (sectionAnnualData?._id) {
      sectionAnnualDataApi
        .updateSectionAnnualData(sectionAnnualData)
        .then((sectionAnnualData: ISectionAnnualData) => {
          dispatch(setCurrentSectionAnnualData(sectionAnnualData));
          toast.success("Section Annual Data saved!");
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    } else {
      sectionAnnualDataApi
        .createSectionAnnualData(sectionAnnualData, section)
        .then((section: ISection) => {
          dispatch(loadSection(section._id));
          toast.success("Section Annual Data created!");
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong");
        });
    }
  };

export const getSectionAnnualDataRecords = (sectionId: string) => async (dispatch: any) => {
  sectionAnnualDataApi
    .getSectionAnnualDataRecords(sectionId)
    .then((sectionAnnualDataRecords: ISectionAnnualData[]) => {
      dispatch(loadSectionAnnualDataRecords(sectionAnnualDataRecords));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const changeSectionAnnualDataStatus =
  (annualData: ISectionAnnualData, changeToStatus: SectionAnnualDataStatus) => async (dispatch: any) => {
    if (annualData) {
      sectionAnnualDataApi
        .changeSectionAnnualDataStatus(annualData, changeToStatus)
        .then((section: ISectionAnnualData) => {
          dispatch(setCurrentSectionAnnualData(section));
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };
