import axios from "axios";
import { INotice } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/notice`;

export const getNotice = async (id: string): Promise<INotice> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createNotice = async (notice: INotice): Promise<INotice> => {
  const response = await axios.post(baseUrl, notice);
  return response.data;
};

export const updateNotice = async (notice: INotice): Promise<INotice> => {
  const response = await axios.put(`${baseUrl}/${notice._id}`, notice);
  return response.data;
};

export const searchNoticeByNumber = async (searchTerm: string): Promise<INotice[]> => {
  const response = await axios.get(`${baseUrl}/searchNoticeByNumber/${searchTerm}`);
  return response.data;
};
