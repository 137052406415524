import axios from "axios";
import {
  IOdaAccount,
  IAccountUser,
  IAccountIdMap,
  AccountRole,
  AccountUserStatus,
  ILicenseApplication,
  IAccountSearchCriteria,
  IPermitApplication,
  IDocument,
} from "oda-shared";

export const apiUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api`;

export const getAccountsForUser = async (): Promise<Array<IOdaAccount>> => {
  const response = await axios.get(`${apiUrl}/accounts/user`);
  return response.data;
};

export const getAccountIdMaps = async (): Promise<Array<IAccountIdMap>> => {
  const response = await axios.get(`${apiUrl}/accounts/ids`);
  return response.data;
};

export const getAccountIdMapsForUser = async (): Promise<Array<IAccountIdMap>> => {
  const response = await axios.get(`${apiUrl}/accounts/ids/user`);
  return response.data;
};

//todo add criteria
export const getAllAccounts = async (filters: any): Promise<Array<IOdaAccount>> => {
  const response = await axios.get(`${apiUrl}/accounts/all`);
  return response.data;
};

export const getOdaAccountsCard = async (criteria: IAccountSearchCriteria) => {
  const response = await axios.post(`${apiUrl}/accounts/getAllAccounts`, { criteria });
  return response.data;
};

export const checkAccountName = async (name: string) => {
  try {
    await axios.get(`${apiUrl}/checkAccountName/${name}`);
  } catch (error: any) {
    if (error.request.status === 422) {
      alert(error.response.data.message);
    }
  }
};

export const getAccount = async (id: string, isPublic?: boolean): Promise<IOdaAccount> => {
  let url = `${apiUrl}/account/${id}`;

  if (isPublic) {
    url = `${apiUrl}/publicAccount/${id}`;
  }

  const response = await axios.get(url);
  return response.data;
};

export const getAccountDocuments = async (
  id: string,
  indexStart: number,
  limit: number,
  filter?: any,
  sort?: any,
): Promise<IDocument[]> => {
  let url = `${apiUrl}/account/${id}/documents/${indexStart}/${limit}`;

  const response = await axios.post(url, { filter, sort });
  return response.data;
};

export const getAccountPermitApplications = async (
  id: string,
  indexStart: number,
  limit: number,
  filter?: any,
  sort?: any,
): Promise<IPermitApplication[]> => {
  let url = `${apiUrl}/account/${id}/permitApplications/${indexStart}/${limit}`;

  const response = await axios.post(url, { filter, sort });
  return response.data;
};

export const newAccount = async (account: IOdaAccount): Promise<IOdaAccount> => {
  const response = await axios.post(`${apiUrl}/account/`, account);
  return response.data;
};

export const deleteAccountDocument = async (accountId: string, documentId: string) => {
  const response = await axios.delete(`${apiUrl}/account/${accountId}/document/${documentId}`);
  return response.data;
};
export const undoDeleteAccountDocument = async (accountId: string, documentId: string) => {
  const response = await axios.patch(`${apiUrl}/account/${accountId}/undo-document/${documentId}`);
  return response.data;
};

export const updateAccount = async (account: IOdaAccount): Promise<IOdaAccount> => {
  const response = await axios.patch(`${apiUrl}/account/${account._id}`, account);
  return response.data;
};

export const requestAccountAccess = async (user: IAccountUser): Promise<{ type: string; message: string }> => {
  const response = await axios.post(`${apiUrl}/accountUser/request`, user);
  return response.data;
};

export const addUserToAccount = async (id: string, userId: string, role: AccountRole): Promise<IAccountUser> => {
  const response = await axios.post(`${apiUrl}/account/${id}/user/`, {
    userId,
    role,
  });
  return response.data;
};

export const inactivateUserFromAccount = async (id: string): Promise<IAccountUser> => {
  const response = await axios.delete(`${apiUrl}/accountUser/${id}`);
  return response.data;
};

export const changeUserAccountStatus = async (id: string, status: AccountUserStatus): Promise<IAccountUser> => {
  const response = await axios.patch(`${apiUrl}/accountUser/${id}/status/${status}`);
  return response.data;
};

export const getActiveLicenseByAccountId = async (accountId: string): Promise<ILicenseApplication | null> => {
  const response = await axios.get(`${apiUrl}/account/${accountId}/active-license`);
  return response.data;
};

export const suspendLicense = async (accountId: string) => {
  const response = await axios.patch(`${apiUrl}/account/${accountId}/suspendLicense`);
  return response.data;
};

export const generateIntentToSuspendedLicensePdfUrl = (accountId: string, reason: string) => {
  return `${apiUrl}/licenseApplication/${accountId}/intentToSuspendPdf/${reason}`;
};

export const generateSuspendedLicensePdfUri = (accountId: string) =>
  `${apiUrl}/licenseApplication/${accountId}/suspendPdf`;

  export const generateRenewalSummaryPdfUri = (accountId: string) =>
  `${apiUrl}/account/${accountId}/renewalSummaryPdf`;

  export const generateRenewalSupplementPdfUri = (accountId: string) =>
  `${apiUrl}/account/${accountId}/renewalSupplementPdf`;



export const sendAccountEmail = async (accountId: string, email: string) => {
  try {
    await axios.get(`${apiUrl}/account/email/${accountId}/${email}`);
    return true;
  } catch (error) {
    return false;
  }
};

export const getAccountEmailsApi = async (id: string) => {
  const response = await axios.get(`${apiUrl}/account/emails/${id}`);
  return response.data;
};

export const getAccountViolationsApi = async (id: string) => {
  const response = await axios.get(`${apiUrl}/account/violations/${id}`);
  return response.data;
};

export const getAccountLineItemsApi = async (id: string) => {
  const response = await axios.get(`${apiUrl}/account/unallocated-lines/${id}`);
  return response.data;
};

export const getAccountBalance = async (id: string) => {
  const response = await axios.get(`${apiUrl}/account/balance/${id}`);
  return response.data;
};

