"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signCosts = void 0;
var IllegalSignMaterial_1 = require("../models/enums/notices/IllegalSignMaterial");
exports.signCosts = [
    {
        id: 1,
        itemNo: "0001",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 10,
        services: "Wood signs (0-200 SF)",
    },
    {
        id: 2,
        itemNo: "0002",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 8,
        services: "Wood signs (201-400 SF)",
    },
    {
        id: 3,
        itemNo: "0003",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 5,
        services: "Wood signs (401-600 SF)",
    },
    {
        id: 4,
        itemNo: "0004",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 4,
        services: "Wood signs (greater than-600 SF)",
    },
    {
        id: 5,
        itemNo: "0001",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 9,
        services: "Wood signs (0-200 SF)",
    },
    {
        id: 6,
        itemNo: "0002",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 7,
        services: "Wood signs (201-400 SF)",
    },
    {
        id: 7,
        itemNo: "0003",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 5,
        services: "Wood signs (401-600 SF)",
    },
    {
        id: 8,
        itemNo: "0004",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 4,
        services: "Wood signs (greater than-600 SF)",
    },
    {
        id: 9,
        itemNo: "0001",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 8,
        services: "Wood signs (0-200 SF)",
    },
    {
        id: 10,
        itemNo: "0002",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 6,
        services: "Wood signs (201-400 SF)",
    },
    {
        id: 11,
        itemNo: "0003",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 4,
        services: "Wood signs (401-600 SF)",
    },
    {
        id: 12,
        itemNo: "0004",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 3,
        services: "Wood signs (greater than-600 SF)",
    },
    {
        id: 13,
        itemNo: "0001",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 7,
        services: "Wood signs (0-200 SF)",
    },
    {
        id: 14,
        itemNo: "0002",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 5,
        services: "Wood signs (201-400 SF)",
    },
    {
        id: 15,
        itemNo: "0003",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 4,
        services: "Wood signs (401-600 SF)",
    },
    {
        id: 16,
        itemNo: "0004",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 3,
        services: "Wood signs (greater than-600 SF)",
    },
    {
        id: 17,
        itemNo: "0001",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 12,
        services: "Wood signs (0-200 SF)",
    },
    {
        id: 18,
        itemNo: "0002",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 10,
        services: "Wood signs (201-400 SF)",
    },
    {
        id: 19,
        itemNo: "0003",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 10,
        services: "Wood signs (401-600 SF)",
    },
    {
        id: 20,
        itemNo: "0004",
        material: IllegalSignMaterial_1.IllegalSignMaterial.WoodOrVinyl,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 5,
        services: "Wood signs (greater than-600 SF)",
    },
    {
        id: 21,
        itemNo: "0005",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 7,
        services: "Metal signs <65ft. in height 0-200 SF",
    },
    {
        id: 22,
        itemNo: "0006",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 6,
        services: "Metal signs <65ft. in height 201-400 SF",
    },
    {
        id: 23,
        itemNo: "0007",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 4,
        services: "Metal signs <65ft. in height 401-600 SF",
    },
    {
        id: 24,
        itemNo: "0008",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 4,
        services: "Metal signs <65ft. in height greater than 600 SF",
    },
    {
        id: 25,
        itemNo: "0005",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 7,
        services: "Metal signs <65ft. in height 0-200 SF",
    },
    {
        id: 26,
        itemNo: "0006",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 6,
        services: "Metal signs <65ft. in height 201-400 SF",
    },
    {
        id: 27,
        itemNo: "0007",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 4,
        services: "Metal signs <65ft. in height 401-600 SF",
    },
    {
        id: 28,
        itemNo: "0008",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 4,
        services: "Metal signs <65ft. in height greater than 600 SF",
    },
    {
        id: 29,
        itemNo: "0005",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 5,
        services: "Metal signs <65ft. in height 0-200 SF",
    },
    {
        id: 30,
        itemNo: "0006",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 4,
        services: "Metal signs <65ft. in height 201-400 SF",
    },
    {
        id: 31,
        itemNo: "0007",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 3,
        services: "Metal signs <65ft. in height 401-600 SF",
    },
    {
        id: 32,
        itemNo: "0008",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 3,
        services: "Metal signs <65ft. in height greater than 600 SF",
    },
    {
        id: 33,
        itemNo: "0005",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 5,
        services: "Metal signs <65ft. in height 0-200 SF",
    },
    {
        id: 34,
        itemNo: "0006",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 4,
        services: "Metal signs <65ft. in height 201-400 SF",
    },
    {
        id: 35,
        itemNo: "0007",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 3,
        services: "Metal signs <65ft. in height 401-600 SF",
    },
    {
        id: 36,
        itemNo: "0008",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 3,
        services: "Metal signs <65ft. in height greater than 600 SF",
    },
    {
        id: 37,
        itemNo: "0005",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 8,
        services: "Metal signs <65ft. in height 0-200 SF",
    },
    {
        id: 38,
        itemNo: "0006",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 8,
        services: "Metal signs <65ft. in height 201-400 SF",
    },
    {
        id: 39,
        itemNo: "0007",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 7,
        services: "Metal signs <65ft. in height 401-600 SF",
    },
    {
        id: 40,
        itemNo: "0008",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 6,
        services: "Metal signs <65ft. in height greater than 600 SF",
    },
    {
        id: 41,
        itemNo: "0009",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 9,
        services: "Metal signs >65ft. in height 0-200 SF",
    },
    {
        id: 42,
        itemNo: "0010",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 7,
        services: "Metal signs >65ft. in height 201-400 SF",
    },
    {
        id: 43,
        itemNo: "0011",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 6,
        services: "Metal signs >65ft. in height 401-600 SF",
    },
    {
        id: 44,
        itemNo: "0012",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 5,
        services: "Metal signs >65ft. in height greater than 600 SF",
    },
    {
        id: 45,
        itemNo: "0009",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 8,
        services: "Metal signs >65ft. in height 0-200 SF",
    },
    {
        id: 46,
        itemNo: "0010",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 6,
        services: "Metal signs >65ft. in height 201-400 SF",
    },
    {
        id: 47,
        itemNo: "0011",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 5,
        services: "Metal signs >65ft. in height 401-600 SF",
    },
    {
        id: 48,
        itemNo: "0012",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 4,
        services: "Metal signs >65ft. in height greater than 600 SF",
    },
    {
        id: 49,
        itemNo: "0009",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 5,
        services: "Metal signs >65ft. in height 0-200 SF",
    },
    {
        id: 50,
        itemNo: "0010",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 5,
        services: "Metal signs >65ft. in height 201-400 SF",
    },
    {
        id: 51,
        itemNo: "0011",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 4,
        services: "Metal signs >65ft. in height 401-600 SF",
    },
    {
        id: 52,
        itemNo: "0012",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 3,
        services: "Metal signs >65ft. in height greater than 600 SF",
    },
    {
        id: 53,
        itemNo: "0009",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 5,
        services: "Metal signs >65ft. in height 0-200 SF",
    },
    {
        id: 54,
        itemNo: "0010",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 4,
        services: "Metal signs >65ft. in height 201-400 SF",
    },
    {
        id: 55,
        itemNo: "0011",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 4,
        services: "Metal signs >65ft. in height 401-600 SF",
    },
    {
        id: 56,
        itemNo: "0012",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 3,
        services: "Metal signs >65ft. in height greater than 600 SF",
    },
    {
        id: 57,
        itemNo: "0009",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 9,
        services: "Metal signs >65ft. in height 0-200 SF",
    },
    {
        id: 58,
        itemNo: "0010",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 9,
        services: "Metal signs >65ft. in height 201-400 SF",
    },
    {
        id: 59,
        itemNo: "0011",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 8,
        services: "Metal signs >65ft. in height 401-600 SF",
    },
    {
        id: 60,
        itemNo: "0012",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Metal,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 8,
        services: "Metal signs >65ft. in height greater than 600 SF",
    },
    {
        id: 61,
        itemNo: "0013",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 15,
        services: "Monopole signs <65ft. in height 0-200 SF",
    },
    {
        id: 62,
        itemNo: "0014",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 14,
        services: "Monopole signs <65ft. in height 201-400 SF",
    },
    {
        id: 63,
        itemNo: "0015",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 10,
        services: "Monopole signs <65ft. in height 401-600 SF",
    },
    {
        id: 64,
        itemNo: "0016",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 7,
        services: "Monopole signs <65ft. in height greater than 600 SF",
    },
    {
        id: 65,
        itemNo: "0013",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 13,
        services: "Monopole signs <65ft. in height 0-200 SF",
    },
    {
        id: 66,
        itemNo: "0014",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 12,
        services: "Monopole signs <65ft. in height 201-400 SF",
    },
    {
        id: 67,
        itemNo: "0015",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 9,
        services: "Monopole signs <65ft. in height 401-600 SF",
    },
    {
        id: 68,
        itemNo: "0016",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 6,
        services: "Monopole signs <65ft. in height greater than 600 SF",
    },
    {
        id: 69,
        itemNo: "0013",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 11,
        services: "Monopole signs <65ft. in height 0-200 SF",
    },
    {
        id: 70,
        itemNo: "0014",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 5,
        services: "Monopole signs <65ft. in height 201-400 SF",
    },
    {
        id: 71,
        itemNo: "0015",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 8,
        services: "Monopole signs <65ft. in height 401-600 SF",
    },
    {
        id: 72,
        itemNo: "0016",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 6,
        services: "Monopole signs <65ft. in height greater than 600 SF",
    },
    {
        id: 73,
        itemNo: "0013",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 10,
        services: "Monopole signs <65ft. in height 0-200 SF",
    },
    {
        id: 74,
        itemNo: "0014",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 4,
        services: "Monopole signs <65ft. in height 201-400 SF",
    },
    {
        id: 75,
        itemNo: "0015",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 8,
        services: "Monopole signs <65ft. in height 401-600 SF",
    },
    {
        id: 76,
        itemNo: "0016",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 5,
        services: "Monopole signs <65ft. in height greater than 600 SF",
    },
    {
        id: 77,
        itemNo: "0013",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 18,
        services: "Monopole signs <65ft. in height 0-200 SF",
    },
    {
        id: 78,
        itemNo: "0014",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 16,
        services: "Monopole signs <65ft. in height 201-400 SF",
    },
    {
        id: 79,
        itemNo: "0015",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 14,
        services: "Monopole signs <65ft. in height 401-600 SF",
    },
    {
        id: 80,
        itemNo: "0016",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 0,
        totalHeightMax: 65,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 12,
        services: "Monopole signs <65ft. in height greater than 600 SF",
    },
    {
        id: 91,
        itemNo: "0017",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 15,
        services: "Monopole signs >65ft. in height 0-200 SF",
    },
    {
        id: 82,
        itemNo: "0018",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 12,
        services: "Monopole signs >65ft. in height 201-400 SF",
    },
    {
        id: 83,
        itemNo: "0019",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 11,
        services: "Monopole signs >65ft. in height 401-600 SF",
    },
    {
        id: 84,
        itemNo: "0020",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 11,
        services: "Monopole signs >65ft. in height greater than 600 SF",
    },
    {
        id: 85,
        itemNo: "0017",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 13,
        services: "Monopole signs >65ft. in height 0-200 SF",
    },
    {
        id: 86,
        itemNo: "0018",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 11,
        services: "Monopole signs >65ft. in height 201-400 SF",
    },
    {
        id: 87,
        itemNo: "0019",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 10,
        services: "Monopole signs >65ft. in height 401-600 SF",
    },
    {
        id: 88,
        itemNo: "0020",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 10,
        services: "Monopole signs >65ft. in height greater than 600 SF",
    },
    {
        id: 89,
        itemNo: "0017",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 10,
        services: "Monopole signs >65ft. in height 0-200 SF",
    },
    {
        id: 90,
        itemNo: "0018",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 9,
        services: "Monopole signs >65ft. in height 201-400 SF",
    },
    {
        id: 91,
        itemNo: "0019",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 8,
        services: "Monopole signs >65ft. in height 401-600 SF",
    },
    {
        id: 92,
        itemNo: "0020",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 10,
        services: "Monopole signs >65ft. in height greater than 600 SF",
    },
    {
        id: 93,
        itemNo: "0017",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 10,
        services: "Monopole signs >65ft. in height 0-200 SF",
    },
    {
        id: 94,
        itemNo: "0018",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 9,
        services: "Monopole signs >65ft. in height 201-400 SF",
    },
    {
        id: 95,
        itemNo: "0019",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 8,
        services: "Monopole signs >65ft. in height 401-600 SF",
    },
    {
        id: 96,
        itemNo: "0020",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 10,
        services: "Monopole signs >65ft. in height greater than 600 SF",
    },
    {
        id: 97,
        itemNo: "0017",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 16,
        services: "Monopole signs >65ft. in height 0-200 SF",
    },
    {
        id: 98,
        itemNo: "0018",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 14,
        services: "Monopole signs >65ft. in height 201-400 SF",
    },
    {
        id: 99,
        itemNo: "0019",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 15,
        services: "Monopole signs >65ft. in height 401-600 SF",
    },
    {
        id: 100,
        itemNo: "0020",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Monopole,
        totalHeightMin: 65,
        totalHeightMax: 100000,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 15,
        services: "Monopole signs >65ft. in height greater than 600 SF",
    },
    {
        id: 101,
        itemNo: "0021",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 11,
        services: "Monument signs (0-200 SF)",
    },
    {
        id: 102,
        itemNo: "0022",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 9,
        services: "Monument signs (201-400 SF)",
    },
    {
        id: 103,
        itemNo: "0023",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 8,
        services: "Monument signs (401-600 SF)",
    },
    {
        id: 104,
        itemNo: "0024",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 1,
        cost: 8,
        services: "Monument signs (greater than 600 SF)",
    },
    {
        id: 105,
        itemNo: "0021",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 10,
        services: "Monument signs (0-200 SF)",
    },
    {
        id: 106,
        itemNo: "0022",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 9,
        services: "Monument signs (201-400 SF)",
    },
    {
        id: 107,
        itemNo: "0023",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 7,
        services: "Monument signs (401-600 SF)",
    },
    {
        id: 108,
        itemNo: "0024",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 2,
        cost: 7,
        services: "Monument signs (greater than 600 SF)",
    },
    {
        id: 109,
        itemNo: "0021",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 7,
        services: "Monument signs (0-200 SF)",
    },
    {
        id: 110,
        itemNo: "0022",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 7,
        services: "Monument signs (201-400 SF)",
    },
    {
        id: 111,
        itemNo: "0023",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 6,
        services: "Monument signs (401-600 SF)",
    },
    {
        id: 112,
        itemNo: "0024",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 3,
        cost: 5,
        services: "Monument signs (greater than 600 SF)",
    },
    {
        id: 113,
        itemNo: "0021",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 7,
        services: "Monument signs (0-200 SF)",
    },
    {
        id: 114,
        itemNo: "0022",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 7,
        services: "Monument signs (201-400 SF)",
    },
    {
        id: 115,
        itemNo: "0023",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 6,
        services: "Monument signs (401-600 SF)",
    },
    {
        id: 116,
        itemNo: "0024",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 4,
        cost: 5,
        services: "Monument signs (greater than 600 SF)",
    },
    {
        id: 117,
        itemNo: "0021",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 0,
        sFMax: 200,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 20,
        services: "Monument signs (0-200 SF)",
    },
    {
        id: 118,
        itemNo: "0022",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 201,
        sFMax: 400,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 18,
        services: "Monument signs (201-400 SF)",
    },
    {
        id: 119,
        itemNo: "0023",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 401,
        sFMax: 600,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 11,
        services: "Monument signs (401-600 SF)",
    },
    {
        id: 120,
        itemNo: "0024",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Masonry,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: 601,
        sFMax: 10000,
        numFacings: 1,
        unit: "SF",
        region: 5,
        cost: 11,
        services: "Monument signs (greater than 600 SF)",
    },
    {
        id: 121,
        itemNo: "0031",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 0,
        totalAreaMax: 800,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 1,
        cost: 3.5,
        services: "Banner Removal 0 - 800 SF",
    },
    {
        id: 122,
        itemNo: "0031",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 0,
        totalAreaMax: 800,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 2,
        cost: 3,
        services: "Banner Removal 0 - 800 SF",
    },
    {
        id: 123,
        itemNo: "0031",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 0,
        totalAreaMax: 800,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 3,
        cost: 2,
        services: "Banner Removal 0 - 800 SF",
    },
    {
        id: 124,
        itemNo: "0031",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 0,
        totalAreaMax: 800,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 4,
        cost: 1.5,
        services: "Banner Removal 0 - 800 SF",
    },
    {
        id: 125,
        itemNo: "0031",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 0,
        totalAreaMax: 800,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 5,
        cost: 5,
        services: "Banner Removal 0 - 800 SF",
    },
    {
        id: 126,
        itemNo: "0032",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 800,
        totalAreaMax: 100000,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 1,
        cost: 2.5,
        services: "Banner Removal > 800 SF",
    },
    {
        id: 127,
        itemNo: "0032",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 800,
        totalAreaMax: 100000,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 2,
        cost: 2,
        services: "Banner Removal > 800 SF",
    },
    {
        id: 128,
        itemNo: "0032",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 800,
        totalAreaMax: 100000,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 3,
        cost: 1.5,
        services: "Banner Removal > 800 SF",
    },
    {
        id: 129,
        itemNo: "0032",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 800,
        totalAreaMax: 100000,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 4,
        cost: 1,
        services: "Banner Removal > 800 SF",
    },
    {
        id: 130,
        itemNo: "0032",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Banner,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: 800,
        totalAreaMax: 100000,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 5,
        cost: 3,
        services: "Banner Removal > 800 SF",
    },
    {
        id: 131,
        itemNo: "0041",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerHitchBallRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 1,
        cost: 600,
        services: "Towing of a Trailer Hitch Ball Required",
    },
    {
        id: 132,
        itemNo: "0041",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerHitchBallRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 2,
        cost: 500,
        services: "Towing of a Trailer Hitch Ball Required",
    },
    {
        id: 133,
        itemNo: "0041",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerHitchBallRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 3,
        cost: 500,
        services: "Towing of a Trailer Hitch Ball Required",
    },
    {
        id: 134,
        itemNo: "0041",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerHitchBallRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 4,
        cost: 400,
        services: "Towing of a Trailer Hitch Ball Required",
    },
    {
        id: 135,
        itemNo: "0041",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerHitchBallRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 5,
        cost: 800,
        services: "Towing of a Trailer Hitch Ball Required",
    },
    {
        id: 136,
        itemNo: "0042",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerFifthWheelGooseneckHitchOrSemiTractorTrailerRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 1,
        cost: 800,
        services: "Towing of a Trailer Fifth Wheel Gooseneck Hitch or Semi Tractor Trailer Required",
    },
    {
        id: 137,
        itemNo: "0042",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerFifthWheelGooseneckHitchOrSemiTractorTrailerRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 2,
        cost: 600,
        services: "Towing of a Trailer Fifth Wheel Gooseneck Hitch or Semi Tractor Trailer Required",
    },
    {
        id: 138,
        itemNo: "0042",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerFifthWheelGooseneckHitchOrSemiTractorTrailerRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 3,
        cost: 600,
        services: "Towing of a Trailer Fifth Wheel Gooseneck Hitch or Semi Tractor Trailer Required",
    },
    {
        id: 139,
        itemNo: "0042",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerFifthWheelGooseneckHitchOrSemiTractorTrailerRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 4,
        cost: 500,
        services: "Towing of a Trailer Fifth Wheel Gooseneck Hitch or Semi Tractor Trailer Required",
    },
    {
        id: 140,
        itemNo: "0042",
        material: IllegalSignMaterial_1.IllegalSignMaterial.TowingOfATrailerFifthWheelGooseneckHitchOrSemiTractorTrailerRequired,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 5,
        cost: 1200,
        services: "Towing of a Trailer Fifth Wheel Gooseneck Hitch or Semi Tractor Trailer Required",
    },
    {
        id: 141,
        itemNo: "0051",
        material: IllegalSignMaterial_1.IllegalSignMaterial.PaintOverSign,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 1,
        cost: 14,
        services: "Paint over Sign",
    },
    {
        id: 142,
        itemNo: "0051",
        material: IllegalSignMaterial_1.IllegalSignMaterial.PaintOverSign,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 2,
        cost: 12,
        services: "Paint over Sign",
    },
    {
        id: 143,
        itemNo: "0051",
        material: IllegalSignMaterial_1.IllegalSignMaterial.PaintOverSign,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 3,
        cost: 10,
        services: "Paint over Sign",
    },
    {
        id: 144,
        itemNo: "0051",
        material: IllegalSignMaterial_1.IllegalSignMaterial.PaintOverSign,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 4,
        cost: 8,
        services: "Paint over Sign",
    },
    {
        id: 145,
        itemNo: "0051",
        material: IllegalSignMaterial_1.IllegalSignMaterial.PaintOverSign,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: 5,
        cost: 15,
        services: "Paint over Sign",
    },
    {
        id: 146,
        itemNo: "52",
        material: IllegalSignMaterial_1.IllegalSignMaterial.Unknown,
        totalHeightMin: null,
        totalHeightMax: null,
        totalAreaMin: null,
        totalAreaMax: null,
        sFMin: null,
        sFMax: null,
        numFacings: null,
        unit: null,
        region: null,
        cost: 0,
        services: null,
    },
];
