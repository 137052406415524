"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskAssignmentStatus = void 0;
var TaskAssignmentStatus;
(function (TaskAssignmentStatus) {
    TaskAssignmentStatus["NotAssigned"] = "Not Assigned";
    TaskAssignmentStatus["Assigned"] = "Assigned";
    TaskAssignmentStatus["Cancelled"] = "Cancelled";
    TaskAssignmentStatus["Complete"] = "Complete";
    //These were removed as of work item 60026
    //FieldTaskComplete = "Field Task Complete",
    //CorrectionsReceived = "Corrections Received",
    //CorrectionsRequested = "Corrections Requested",
    //Received = "Received",
    //RequestMade = "Request Made",
})(TaskAssignmentStatus = exports.TaskAssignmentStatus || (exports.TaskAssignmentStatus = {}));
