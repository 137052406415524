import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/RootReducer";

const LogOut: React.FC = () => {
  const { isAuthenticated, user } = useSelector((state: RootState) => state.userReducer);
  const backendHost = fdot.process.env.BACKEND_SERVER_HOST;
  const logoutUrl = isAuthenticated && user.isExternal ? `${backendHost}/logoutB2c` : `${backendHost}/logout`;

  return (
    <a className="nav-link" title="Sign Out" href={logoutUrl}>
      <span style={{ color: "black", textDecoration: "underline" }}>Sign Out</span>
    </a>
  );
};

export default LogOut;
