"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaseType = void 0;
var CaseType;
(function (CaseType) {
    CaseType["Denied500Foot"] = "Denied Application - 500 Foot Interchange Ramp";
    CaseType["DeniedGovernmentPermission"] = "Denied Application - Government Permission";
    CaseType["DeniedLandUse"] = "Denied Application - Land Use";
    CaseType["DeniedRowViolation"] = "Denied Application - ROW Violation";
    CaseType["DeniedScenicHighwayDesignation"] = "Denied Application - Scenic Highway Designation";
    CaseType["DeniedSpacing"] = "Denied Application - Spacing";
    CaseType["IllegalSignPrivateProperty"] = "Illegal Sign - Private Property";
    CaseType["IllegalSignROW"] = "Illegal Sign - ROW Violation";
    CaseType["IllegalVegetationCutting"] = "Illegal Vegetation Cutting";
    CaseType["NotDetermined"] = "Not Determined";
    CaseType["NoticeOfIntentToRevokeFalseInfo"] = "Notice of Intent to Revoke - False and Misleading Information";
    CaseType["NoticeOfIntentToRevokeSignHeight"] = "Notice of Intent to Revoke - Height of Sign";
    CaseType["NoticeOfIntentToRevokeLandownerPermissionWithdrawn"] = "Notice of Intent to Revoke - Landowner Permission Withdrawn";
    CaseType["NoticeOfIntentToRevokeNonConformingSignAltered"] = "Notice of Intent to Revoke - Nonconforming Sign - Altered";
    CaseType["NoticeOfIntentToRevokeNonConformingSignRemoved"] = "Notice of Intent to Revoke - Nonconforming Sign - Removed";
    CaseType["NoticeOfIntentToRevokeNonConformingSignRemovedAndRebuilt"] = "Notice of Intent to Revoke - Nonconforming Sign - Removed and Rebuilt";
    CaseType["NoticeOfIntentToRevokeNonConformingSignVoidOfCopy"] = "Notice of Intent to Revoke - Nonconforming Sign - VoidOfCopy";
    CaseType["Other"] = "Other";
})(CaseType = exports.CaseType || (exports.CaseType = {}));
