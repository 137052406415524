"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaseStatus = void 0;
var CaseStatus;
(function (CaseStatus) {
    CaseStatus["InLitigationInformalHearingRequestReceived"] = "In Litigation - Informal Hearing Request Received";
    CaseStatus["InLitigationFormalHearingRequestReceived"] = "In Litigation - Formal Hearing Request Received";
    CaseStatus["ClerksOrderOfDismissal"] = "Clerk's Order of Dismissal";
    CaseStatus["FinalOrderIssued"] = "Final Order Issued";
    CaseStatus["FinalOrderUnderAppeal"] = "Final Order Under Appeal";
})(CaseStatus = exports.CaseStatus || (exports.CaseStatus = {}));
