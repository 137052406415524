import axios from "axios";
import {
  IAccountSearchCriteria,
  IPermitApplicationSearchCriteria,
  IAccountRow,
  IPermitApplicationRow,
  ILitigationRow,
  ILitigationSearchCriteria,
  INoticeRow,
  INoticeSearchCriteria,
  IPaymentSearchCriteria,
  IPaymentRow,
  IInvoiceRow,
  IInvoiceSearchCriteria,
  IPermitRow,
  IPermitSearchCriteria,
  IStructureSearchCriteria,
  IStructureRow,
  ISignDetail,
  ISectionSearchCriteria,
  ISection,
  IViolationRow,
  IViolationSearchCriteria,
  IFindingSearchCriteria,
  IFindingRow,
  IDocumentSearchCriteria,
  IDocumentRow,
  IIllegalSignSearchCriteria,
  IIllegalSignRow,
  ITransferSearchCriteria,
  ITransferRow,
  IFacingSearchCriteria,
  IFacingSearchRow,
} from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/search`;

export const performAccountSearch = async (
  criteria: IAccountSearchCriteria,
): Promise<{ totalCount: number; results: Array<IAccountRow> }> => {
  const response = await axios.post(`${baseUrl}/account`, { criteria });
  return response.data;
};

export const performFacingSearch = async (
  criteria: IFacingSearchCriteria,
): Promise<{ totalCount: number; results: Array<IFacingSearchRow> }> => {
  const response = await axios.post(`${baseUrl}/facing`, { criteria });
  return response.data;
};

export const performViolationSearch = async (
  criteria: IViolationSearchCriteria,
): Promise<{ totalCount: number; results: Array<IViolationRow> }> => {
  const response = await axios.post(`${baseUrl}/violation`, { criteria });
  return response.data;
};
export const performFindingSearch = async (
  criteria: IFindingSearchCriteria,
): Promise<{ totalCount: number; results: Array<IFindingRow> }> => {
  const response = await axios.post(`${baseUrl}/finding`, { criteria });
  return response.data;
};

export const performDocumentSearch = async (
  criteria: IDocumentSearchCriteria,
): Promise<{ totalCount: number; results: Array<IDocumentRow> }> => {
  const response = await axios.post(`${baseUrl}/document`, { criteria });
  return response.data;
};

export const getSignDetails = async (tagNumber: string): Promise<ISignDetail> => {
  const response = await axios.get(`${baseUrl}/signDetail/${tagNumber}`);
  return response.data;
};

export const performPermitApplicationSearch = async (
  criteria: IPermitApplicationSearchCriteria,
): Promise<{ totalCount: number; results: Array<IPermitApplicationRow> }> => {
  const response = await axios.post(`${baseUrl}/permit-application`, { criteria });
  return response.data;
};

export const performPermitSearch = async (
  criteria: IPermitSearchCriteria,
): Promise<{ totalCount: number; results: Array<IPermitRow> }> => {
  const response = await axios.post(`${baseUrl}/permit`, { criteria });
  return response.data;
};

export const performLitigationSearch = async (
  criteria: ILitigationSearchCriteria,
): Promise<{ totalCount: number; results: Array<ILitigationRow> }> => {
  const response = await axios.post(`${baseUrl}/litigation`, { criteria });
  return response.data;
};

export const performNoticeSearch = async (
  criteria: INoticeSearchCriteria,
): Promise<{ totalCount: number; results: Array<INoticeRow> }> => {
  const response = await axios.post(`${baseUrl}/notice`, { criteria });
  return response.data;
};

export const performTransferSearch = async (
  criteria: ITransferSearchCriteria,
): Promise<{ totalCount: number; results: Array<ITransferRow> }> => {
  const response = await axios.post(`${baseUrl}/transfer`, { criteria });
  return response.data;
};

export const performIllegalSignSearch = async (
  criteria: IIllegalSignSearchCriteria,
): Promise<{ totalCount: number; results: Array<IIllegalSignRow> }> => {
  const response = await axios.post(`${baseUrl}/illegal-sign`, { criteria });
  return response.data;
};

export const performPaymentSearch = async (
  criteria: IPaymentSearchCriteria,
): Promise<{ totalCount: number; results: Array<IPaymentRow> }> => {
  const response = await axios.post(`${baseUrl}/payment`, { criteria });
  return response.data;
};

export const performInvoiceSearch = async (
  criteria: IInvoiceSearchCriteria,
): Promise<{ totalCount: number; results: Array<IInvoiceRow> }> => {
  const response = await axios.post(`${baseUrl}/invoice`, { criteria });
  return response.data;
};

export const performStructureSearch = async (
  criteria: IStructureSearchCriteria,
): Promise<{ totalCount: number; results: Array<IStructureRow> }> => {
  const response = await axios.post(`${baseUrl}/structure`, { criteria });
  return response.data;
};

export const performSectionSearch = async (
  criteria: ISectionSearchCriteria,
): Promise<{ totalCount: number; results: Array<ISection> }> => {
  const response = await axios.post(`${baseUrl}/section`, { criteria });
  return response.data;
};

export const performSectionByCounty = async (county: string): Promise<ISection[]> => {
  const response = await axios.get(`${baseUrl}/sectionByCounty/${county}`);
  return response.data;
};

export const getSection = async (rciSectionNumber: string): Promise<ISection> => {
  const response = await axios.get(`${baseUrl}/section/${rciSectionNumber}`);
  return response.data;
};
