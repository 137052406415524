import axios from "axios";
import { IUserProfile } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/fieldConsultant`;

export const getFieldConsultantInspectors = async (): Promise<IUserProfile[]> => {
  const response = await axios.get(`${baseUrl}/inspectors`);
  return response.data;
};

export const addFieldConsultantInspector = async (id: string): Promise<IUserProfile> => {
  const response = await axios.post(`${baseUrl}/inspectors/${id}`);
  return response.data;
};

export const addOrCreateFieldConsultantInspector = async(azureAdOid: string, staff: IUserProfile) : Promise<IUserProfile> =>{
  const response = await axios.post(`${baseUrl}/${azureAdOid}/`, staff);
  return response.data;
}

export const removeFieldConsultantInspector = async (id: string): Promise<IUserProfile> => {
  const response = await axios.delete(`${baseUrl}/inspectors/${id}`);
  return response.data;
};

export const getFieldConsultantManager = async (): Promise<IUserProfile> => {
  const response = await axios.get(`${baseUrl}/manager`);
  return response.data;
};

export const addFieldConsultantManager = async (id: string): Promise<IUserProfile> => {
  const response = await axios.post(`${baseUrl}/manager/${id}`);
  return response.data;
};
