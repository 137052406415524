import React, { useEffect, useMemo, useState } from "react";
import { ButtonWithIcon } from "../../common/ButtonWithIcon";
import { CustomCard } from "../../common/CustomCard";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/RootReducer";
import {
  performLitigationSearch,
  updateCurrentPageIndex,
  updatePageChanged,
  updateSortColumn,
  updateSortDirection,
} from "../../../redux/slices/SearchSlice";
import { CaseResolution, HearingCaseStatus, Rights } from "oda-shared";
import EnumToOptions from "../../common/EnumOptions";
import { useNavigate } from "react-router";

const CaseSearchCriteria = () => {
  const [caseNumber, setCaseNumber] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const [lastStatusChangeDateFrom, setLastStatusChangeDateFrom] = useState("");
  const [resolution, setResolution] = useState("");
  const [fdotAttorney, setFdotAttorney] = useState("");
  const [petitioner, setPetitioner] = useState("");
  const user = useSelector((state: RootState) => state.userReducer.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sortColumn, sortDirection, currentPageIndex, itemsPerPage, pageChanged } = useSelector(
    (state: RootState) => state.searchReducer,
  );

  const criteria = useMemo(() => {
    return {
      caseNumber,
      status: caseStatus,
      lastStatusChangeDateFrom,
      resolution,
      fdotAttorney,
      petitioner,
      sortColumn,
      sortDirection,
      currentPageIndex,
      itemsPerPage,
      pageChanged,
    };
  }, [
    caseNumber,
    caseStatus,
    lastStatusChangeDateFrom,
    resolution,
    fdotAttorney,
    petitioner,
    sortColumn,
    sortDirection,
    currentPageIndex,
    itemsPerPage,
    pageChanged,
  ]);

  useEffect(() => {
    if (pageChanged) {
      dispatch(performLitigationSearch(criteria as any));
      dispatch(updatePageChanged(false));
    }
  }, [criteria, dispatch, pageChanged]);

  const reset = () => {
    setCaseNumber("");
    setCaseStatus("");
    setLastStatusChangeDateFrom("");
    setResolution("");
    setFdotAttorney("");
    setPetitioner("");
  };

  useEffect(() => {
    dispatch(updateSortColumn("petitionFiledOn"));
    dispatch(updateSortDirection("desc" as any));
  }, [dispatch]);

  return (
    <>
      <CustomCard headerText="Case Search Criteria">
        <div className="row">
          <div className="col-lg">
            <label htmlFor="caseNumber" title="Case Number" className="font-weight-bold">
              Case Number:
            </label>
            <input
              type="text"
              name="caseNumber"
              id="caseNumber"
              pattern="^-?\d+(\.\d+)?$"
              title="Name Contains"
              className="form-control"
              value={caseNumber}
              onChange={e => {
                const inputValue = e.target.value;
                const maskedValue = inputValue.replace(/[^\d.-]/g, "");
                setCaseNumber(maskedValue);
              }}
            />
          </div>
          <div className="col-lg">
            <label htmlFor="caseStatus" className="font-weight-bold">
              Status:
            </label>
            <select
              name="caseStatus"
              id="caseStatus"
              title="Status"
              className="form-control"
              value={caseStatus}
              onChange={e => setCaseStatus(e.target.value)}>
              <EnumToOptions enumeration={HearingCaseStatus}></EnumToOptions>
            </select>
          </div>
          <div className="col-lg">
            <label className="font-weight-bold" htmlFor="lastStatusChangeDateFrom" title="Last Status Change Date From">
              Since Last Status Change:
            </label>
            <input
              type="date"
              className="form-control"
              name="lastStatusChangeDateFrom"
              id="lastStatusChangeDateFrom"
              value={lastStatusChangeDateFrom}
              onChange={event => setLastStatusChangeDateFrom(event.target.value)}></input>
          </div>
          <div className="col-lg">
            <label htmlFor="resolution" className="font-weight-bold">
              Resolution:
            </label>
            <select
              className="form-control"
              name="resolution"
              id="resolution"
              value={resolution}
              onChange={event => setResolution(event.target.value)}>
              <EnumToOptions enumeration={CaseResolution}></EnumToOptions>
            </select>
          </div>
          <div className="col-lg">
            <label htmlFor="fdotAttorney" className="font-weight-bold">
              FDOT Attorney:
            </label>
            <input
              title="FDOT Attorney"
              type="text"
              name="fdotAttorney"
              id="fdotAttorney"
              className="form-control"
              value={fdotAttorney}
              onChange={e => setFdotAttorney(e.target.value)}
            />
          </div>
          <div className="col-lg">
            <label htmlFor="petitioner" className="font-weight-bold">
              Petitioner:
            </label>
            <input
              type="text"
              name="petitioner"
              id="petitioner"
              title="Petitioner"
              className="form-control"
              value={petitioner}
              onChange={e => setPetitioner(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="col-lg-8">
            <div className="text-center">
              <ButtonWithIcon
                buttonType="danger"
                icon="undo"
                buttonText="Reset"
                onClick={reset}
                titleText="Reset Search"
              />
              &nbsp;&nbsp;
              <ButtonWithIcon
                buttonType="primary"
                icon="search"
                buttonText="Search"
                titleText="Search"
                onClick={() => {
                  dispatch(updateCurrentPageIndex(0));
                  dispatch(performLitigationSearch({ ...criteria, currentPageIndex: 0 } as any));
                }}
              />
            </div>
          </div>
          {Rights.canCreateEditCases(user) && <div className="col-lg">
            <ButtonWithIcon
              onClick={() => {
                navigate("/Litigation/");
              }}
              titleText="Add Case Record"
              buttonText="Add Case Record"
              icon="plus"              
              buttonType="success"
            />
          </div>}
        </div>
      </CustomCard>
    </>
  );
};

export default CaseSearchCriteria;
