"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSignRemovalItem = exports.getSignRemovalCost = void 0;
var SignRemovalCostData_1 = require("./SignRemovalCostData");
exports.getSignRemovalCost = function (illegalSign, regions) {
    var region = regions.find(function (r) {
        return r._id.toString() == illegalSign.regionId ? illegalSign.regionId.toString() : "";
    });
    if (region) {
        var filteredByMaterial = SignRemovalCostData_1.signCosts.filter(function (signCost) {
            var _a;
            var regionName = (_a = signCost.region) === null || _a === void 0 ? void 0 : _a.toString().toUpperCase().trim();
            if (!regionName)
                return false;
            return signCost.material === illegalSign.material && (region === null || region === void 0 ? void 0 : region.name.toUpperCase().trim().includes(regionName));
        });
        var filteredByHeight = filteredByMaterial.filter(function (m) {
            if (m.totalHeightMin === null || m.totalHeightMax === null) {
                return true;
            }
            return (m.totalHeightMin <= illegalSign.dimensions[0].facingHeight &&
                m.totalHeightMax >= illegalSign.dimensions[0].facingHeight);
        });
        var facingArea_1 = 0;
        for (var i = 0; i < illegalSign.dimensions.length; i++) {
            facingArea_1 += illegalSign.dimensions[i].facingArea;
        }
        var filteredBySf = filteredByHeight.find(function (m) {
            if (m.sFMin === null || m.sFMax === null) {
                return true;
            }
            return m.sFMin <= facingArea_1 && m.sFMax >= facingArea_1;
        });
        if (filteredBySf) {
            return filteredBySf.cost * facingArea_1;
        }
    }
    return 0;
};
exports.getSignRemovalItem = function (illegalSign, regions) {
    var region = regions.find(function (r) {
        return r._id.toString() == illegalSign.regionId.toString();
    });
    if (region) {
        var filteredByMaterial = SignRemovalCostData_1.signCosts.filter(function (signCost) {
            var _a;
            var regionName = (_a = signCost.region) === null || _a === void 0 ? void 0 : _a.toString().toUpperCase().trim();
            if (!regionName)
                return false;
            return signCost.material === illegalSign.material && (region === null || region === void 0 ? void 0 : region.name.toUpperCase().trim().includes(regionName));
        });
        var filteredByHeight = filteredByMaterial.filter(function (m) {
            if (m.totalHeightMin === null || m.totalHeightMax === null) {
                return true;
            }
            return (m.totalHeightMin <= illegalSign.dimensions[0].facingHeight &&
                m.totalHeightMax >= illegalSign.dimensions[0].facingHeight);
        });
        var facingArea_2 = 0;
        for (var i = 0; i < illegalSign.dimensions.length; i++) {
            facingArea_2 += illegalSign.dimensions[i].facingArea;
        }
        var filteredBySf = filteredByHeight.find(function (m) {
            if (m.sFMin === null || m.sFMax === null) {
                return true;
            }
            return m.sFMin <= facingArea_2 && m.sFMax >= facingArea_2;
        });
        if (filteredBySf) {
            return filteredBySf;
        }
    }
    return {};
};
