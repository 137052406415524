import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addTaskAssignment,
  getTaskAssignment,
  loadCurrentTaskAssignment,
  updateLoadedTaskAssignment,
  updateTaskAssignment,
} from "../../redux/slices/TaskAssignmentSlice";
import { CustomCard } from "../common/CustomCard";
import { RootState } from "../../redux/RootReducer";
import { formatDateWithNoTimeZone, inputFieldDateFormat } from "../../utils/DateUtils";
import EnumToOptions from "../common/EnumOptions";
import {
  FloridaCounty,
  TaskAssignmentStatus,
  TaskType,
  SectionSide,
  ITaskAssignment,
  IApplicableSection,
  Rights,
  IValidationResult,
} from "oda-shared";
import useInspectorProfiles from "../../hooks/useInspectorProfiles";
//import useHasInternalRole from "../../hooks/useHasInternalRole";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ButtonWithIcon } from "../common/ButtonWithIcon";
import DocumentTypeSelector from "../common/DocumentTypeSelector";
import { FilePond } from "react-filepond";
import { documentUrl, taskAssignmentUrl } from "../../apis/DocumentApi";
import DocumentLink from "../common/DocumentLink";
import { toast } from "react-toastify";

const TaskPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [changedTask, setChangedTask] = useState<any>(null);
  const [documentType, setDocumentType] = useState<string>("");
  const { register, errors, handleSubmit, setValue, getValues, formState } = useForm<ITaskAssignment>({
    mode: "onSubmit",
  });
  //const [canEdit, setCanEdit] = useState(false);
  const currentTaskAssignment = useSelector((state: RootState) => state.taskAssignmentReducer.currentTaskAssignment);
  const user = useSelector((state: RootState) => state.userReducer.user);
  const inspectorsUserProfiles = useInspectorProfiles();
  const navigate = useNavigate();
  const filePondRef = useRef(null);
  // const hasRoleToEdit = useHasInternalRole([
  //   InternalRole.OfficeStaff,
  //   InternalRole.OfficeSupervisor,
  //   InternalRole.ManagementReviewer,
  //   InternalRole.FieldSupervisor,
  // ]);

  const hasRoleToEdit =
    Rights.canEditAllTasks(user) || (currentTaskAssignment?.isConsultantTask && user?.isFieldConsultantManager);
  const isAssignedInspector =
    currentTaskAssignment?.assignedInspectorId === user?._id ||
    currentTaskAssignment?.assignedConsultantInspectorId === user?._id;

  const canEdit = hasRoleToEdit || isAssignedInspector;

  useEffect(() => {
    if (id) {
      dispatch(getTaskAssignment(id));
    } else if (!id) {
      setChangedTask({} as ITaskAssignment);
      dispatch(updateLoadedTaskAssignment({} as ITaskAssignment));
    }
  }, [dispatch, id]);

  // useEffect(() => {
  //   if (currentTaskAssignment && user) {
  //     if (isAssignedInspector || hasRoleToEdit) {
  //       //setCanEdit(true);
  //       return;
  //     }
  //   }
  //   setCanEdit(false);
  // }, [currentTaskAssignment, user, hasRoleToEdit]);

  useEffect(() => {
    setChangedTask(currentTaskAssignment);
  }, [currentTaskAssignment]);

  const saveTaskAssignment = () => {
    //e.preventDefault();
    const validation = validateSubmit();
    if (!validation?.success) {
      for (let message of validation.messages) {
        toast.error(message);
      }
      return;
    }
    if (id) {
      dispatch(
        updateTaskAssignment(
          {
            ...changedTask,
          } as any,
          () => {},
        ),
      );
    } else {
      dispatch(
        addTaskAssignment({ ...changedTask } as any, id => {
          navigate(`/Task/${id}`);
        }),
      );
    }
  };

  const validateSubmit = () => {
    const result: IValidationResult = { success: true, messages: [] };
    if (!changedTask.taskType) {
      result.messages.push("'Task Type' is a required field.");
    }
    if (!changedTask.requestDetails) {
      result.messages.push("'Action Required' is a required field.");
    }
    if (result.messages.length > 0) {
      result.success = false;
    }
    return result;
  };

  const removeAdditionalDocument = (index: number) => {
    const documents = [...currentTaskAssignment!.documents];
    documents.splice(index, 1);
    const updatedCurrentTask = {
      ...currentTaskAssignment,
      documents: documents,
    };

    dispatch(updateTaskAssignment(updatedCurrentTask as any, () => {}));
  };

  const getVocSection = () => {
    if (!changedTask?.findingId) {
      return <> </>;
    }

    return (
      <>
        <div className="row font-weight-bold">
          <div className="col alert-primary">VOC Details</div>
        </div>
        <div className="row font-weight-bold">
          <div className="col">VOC Inspection Date:</div>
          <div className="col">Void of Copy:</div>
          <div className="col">Postcard Sent Date:</div>
        </div>
        <div className="row">
          <div className="col">
            <input
              title="Void of Copy Inspection Date"
              type="date"
              className="form-control"
              disabled={!canEdit}
              value={inputFieldDateFormat(changedTask?.voidOfCopyInspectionDate)}
              onChange={e => {
                setChangedTask({ ...changedTask, voidOfCopyInspectionDate: e.target.value });
              }}
            />
          </div>
          <div className="col">
            <input
              title="Void Of Copy?"
              type="Checkbox"
              className="form-control-checkbox"
              disabled={!canEdit}
              checked={changedTask?.voidOfCopy}
              onChange={e => {
                setChangedTask({ ...changedTask, voidOfCopy: e.target.checked });
              }}
            />
          </div>
          <div className="col">
            <input
              title="Postcard Sent Date"
              type="date"
              className="form-control"
              disabled={!canEdit}
              value={inputFieldDateFormat(changedTask?.postCardSentDate)}
              onChange={e => {
                setChangedTask({ ...changedTask, postCardSentDate: e.target.value });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  if (id && (changedTask === null || !currentTaskAssignment)) {
    return <div>Loading Task</div>;
  }

  return (
    <form onSubmit={handleSubmit(saveTaskAssignment)}>
      <div className="container">
        <CustomCard
          headerText={currentTaskAssignment?.taskNumber ? `Task # ${currentTaskAssignment?.taskNumber}` : "New Task"}>
          <div className="row">
            <div className="col-2">
              <label title="Task Number" className={`font-weight-bold`}>
                Task No.
              </label>
            </div>
            <div className="col-5">
              <label title="Task Type" className={`font-weight-bold required-field`}>
                Task Type:
              </label>
            </div>
            <div className="col-3">
              <label title="Status" className={`font-weight-bold`}>
                Status:
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-2">{currentTaskAssignment?.taskNumber}</div>
            <div className="col-5">
              
              {//If this task is not of a selectable task type then just display it. This can happen for some converted tasks.
              !currentTaskAssignment || !currentTaskAssignment?.taskType ||  
              [undefined, null, "", TaskType.LocationCheck, TaskType.InventoryCheck, TaskType.ReportedIllegalSign].includes(
                currentTaskAssignment?.taskType,
              ) ? (
                <div>
                  <select
                    className="form-control required-field"
                    value={changedTask?.taskType}
                    title="Task Type"
                    name="taskType"
                    disabled={!canEdit}
                    ref={register({
                      required: { value: true, message: "Task type is a required field." },
                    })}
                    onChange={e => {
                      setChangedTask({ ...changedTask, taskType: e.target.value });
                    }}>
                    <option value="">Select</option>
                    <option value={TaskType.LocationCheck}>{TaskType.LocationCheck}</option>
                    <option value={TaskType.InventoryCheck}>{TaskType.InventoryCheck}</option>
                    <option value={TaskType.ReportedIllegalSign}>{TaskType.ReportedIllegalSign}</option>
                  </select>
                  <ErrorMessage as="span" className="text-danger" errors={errors} name="taskType" />
                </div>
              ) : (
                <label>{currentTaskAssignment?.taskType}</label>
              )}
            </div>
            <div className="col-3">
              <select
                className="form-control"
                title="Status"
                value={changedTask?.taskStatus}
                disabled={!canEdit}
                onChange={e => {
                  setChangedTask({ ...changedTask, taskStatus: e.target.value });
                }}>
                <EnumToOptions enumeration={TaskAssignmentStatus}></EnumToOptions>
              </select>
            </div>
          </div>
          <div className="row font-weight-bold">
            <div className="col-2">Assigned Date:</div>
            <div className="col-5">Assigned To:</div>
            <div className="col-3">Due Date:</div>
          </div>
          <div className="row">
            <div className="col-2">{formatDateWithNoTimeZone(changedTask?.assignedDate)}</div>
            <div className="col-5">
              <select
                title="Select FDOT Inspector to Assign"
                className="form-control"
                id="inspectorSelector"
                disabled={!hasRoleToEdit}
                value={changedTask?.assignedInspectorId ?? ""}
                onChange={e => {
                  setChangedTask({ ...changedTask, assignedInspectorId: e.target.value });
                }}>
                <>
                  <option value="">Select</option>
                  {inspectorsUserProfiles.map(inspector => {
                    return (
                      <option key={inspector.azureAdOid} value={inspector._id}>
                        {inspector.displayName} ({inspector.emailAddress})
                      </option>
                    );
                  })}
                </>
              </select>
            </div>
            <div className="col-3">
              <input
                type="date"
                title="Due Date"
                className="form-control"
                disabled={!canEdit}
                value={inputFieldDateFormat(changedTask?.dueDate)}
                onChange={e => {
                  setChangedTask({ ...changedTask, dueDate: new Date(e.target.value) });
                }}
              />
            </div>
          </div>
          <div className="row font-weight-bold">
            <div className="col alert-primary">Sign Details</div>
          </div>
          <div className="row font-weight-bold">
            <div className="col">Application #:</div>
            <div className="col">Permit 1 #:</div>
            <div className="col">Permit 2 #:</div>
            <div className="col">Structure ID:</div>
            <div className="col">Finding #:</div>
          </div>
          <div className="row">
            <div className="col">
              <input
                type="number"
                title="Application Number"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.applicationNumber}
                onChange={e => {
                  setChangedTask({ ...changedTask, applicationNumber: e.target.value });
                }}
              />
            </div>
            <div className="col">
              <input
                title="Permit Number 1"
                type="number"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.permitNumber1}
                onChange={e => {
                  setChangedTask({ ...changedTask, permitNumber1: e.target.value });
                }}
              />
            </div>
            <div className="col">
              <input
                title="Permit Number 2"
                type="number"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.permitNumber2}
                onChange={e => {
                  setChangedTask({ ...changedTask, permitNumber2: e.target.value });
                }}
              />
            </div>
            <div className="col">
              <input
                title="Structure ID"
                type="number"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.structureId}
                onChange={e => {
                  setChangedTask({ ...changedTask, structureId: e.target.value });
                }}
              />
            </div>
            <div className="col">
              {currentTaskAssignment?.finding ? (
                <Link
                  onClick={() => {
                    dispatch(loadCurrentTaskAssignment(null));
                  }}
                  to={`/facing/${currentTaskAssignment?.finding.facing}/finding/finding/${currentTaskAssignment?.finding._id}`}>
                  {currentTaskAssignment?.finding.findingNumber}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          {getVocSection()}
          <div className="row font-weight-bold">
            <div className="col alert-primary">Location Details</div>
          </div>
          <div className="row font-weight-bold">
            <div className="col">County:</div>
            <div className="col">RCI Section:</div>
            <div className="col">Milepost:</div>
            <div className="col">Side of Road:</div>
            <div className="col">Road Name:</div>
          </div>
          <div className="row">
            <div className="col">
              <select
                title="Select County"
                className="form-control"
                disabled={!canEdit}
                value={changedTask?.county}
                onChange={e => {
                  setChangedTask({ ...changedTask, county: e.target.value });
                }}>
                <EnumToOptions enumeration={FloridaCounty}></EnumToOptions>
              </select>
            </div>
            <div className="col">
              <input
                title="RCI Section"
                type="string"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.rciSection}
                onChange={e => {
                  setChangedTask({ ...changedTask, rciSection: e.target.value });
                }}
              />
            </div>
            <div className="col">
              <input
                title="Milepost"
                type="number"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.milepost}
                onChange={e => {
                  setChangedTask({ ...changedTask, milepost: e.target.value });
                }}
              />
            </div>
            <div className="col">
              <select
                title="Side of Road"
                className="form-control"
                disabled={!canEdit}
                value={changedTask?.sideOfRoad}
                onChange={e => {
                  setChangedTask({ ...changedTask, sideOfRoad: e.target.value });
                }}>
                <EnumToOptions enumeration={SectionSide}></EnumToOptions>
              </select>
            </div>
            <div className="col">
              <input
                title="Road Name"
                type="text"
                disabled={!canEdit}
                className="form-control"
                value={changedTask?.roadName}
                onChange={e => {
                  setChangedTask({ ...changedTask, roadName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="row font-weight-bold">
            <div className="col alert-primary required-field">Action Required</div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                className="form-control"
                disabled={!canEdit}
                name="requestDetails"
                title="Request Details/Action Required"
                ref={register({
                  required: { value: true, message: "Action Required details is a required field." },
                })}
                value={changedTask?.requestDetails}
                onChange={e => {
                  setChangedTask({ ...changedTask, requestDetails: e.target.value });
                }}></textarea>
              <ErrorMessage as="span" className="text-danger" errors={errors} name="requestDetails" />
            </div>
          </div>
          <div className="row font-weight-bold">
            <div className="col alert-primary">Inspector Comments</div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                title="Inspector Comments"
                className="form-control"
                disabled={!canEdit}
                value={changedTask?.comment}
                onChange={e => {
                  setChangedTask({ ...changedTask, comment: e.target.value });
                }}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col font-weight-bold">Assigned By:</div>
            <div className="col">{currentTaskAssignment?.assignedBy?.displayName}</div>
            <div className="col font-weight-bold">Last Updated By:</div>
            <div className="col">{currentTaskAssignment?.lastUpdatedBy?.displayName}</div>
            <div className="col font-weight-bold">Last Updated Date:</div>
            <div className="col">{formatDateWithNoTimeZone(currentTaskAssignment?.lastUpdated)}</div>
            <div className="col font-weight-bold">Completed Date:</div>
            <div className="col">{formatDateWithNoTimeZone(currentTaskAssignment?.completedDate)}</div>
          </div>
          {canEdit && (
            <div className="row">
              <div className="col">
                <ButtonWithIcon
                  titleText="Save"
                  buttonType="primary"
                  buttonText="Save"
                  icon="save"
                  buttonAction="submit"
                />
              </div>
            </div>
          )}
        </CustomCard>
        {canEdit ? (
          <CustomCard collapsible={true} headerText="Documents">
            {!canEdit ? (
              <></>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="documentType" title="Document Type" className={`font-weight-bold required-field`}>
                      Document Type
                    </label>
                    <DocumentTypeSelector
                      idSuffix="Tasks"
                      applicableSections={[IApplicableSection.Tasks]}
                      isInternalUser={true}
                      isExternalUser={false}
                      selectedValue={documentType}
                      onChange={(selectedOption: string) => {
                        setDocumentType(selectedOption);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor="fileType" title="File Type">
                      <i>Accepted file types (Pdf, Images)</i>
                    </label>
                    <FilePond
                      server={{
                        process: {
                          url: taskAssignmentUrl(currentTaskAssignment?._id, documentType),
                          withCredentials: true,
                        },
                      }}
                      disabled={documentType === ""}
                      acceptedFileTypes={["image/*", "application/pdf"]}
                      allowRevert={false}
                      ref={filePondRef}
                      onprocessfile={(error, file) => {
                        if (!error) {
                          setTimeout(() => {
                            (filePondRef.current as any).removeFile(file);
                          }, 1000);
                        }
                      }}
                      onprocessfiles={() => {
                        dispatch(getTaskAssignment(currentTaskAssignment?._id));
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {currentTaskAssignment && currentTaskAssignment.documents ? (
              [...currentTaskAssignment.documents]
                ?.sort((a, b) => {
                  return new Date(b.added).getTime() - new Date(a.added).getTime();
                })
                .map((doc, i) => {
                  return (
                    <div className="row" key={doc._id}>
                      <div className="col">
                        <a
                          title={`Download ${doc.originalname}`}
                          download={doc.originalname}
                          href={documentUrl(doc._id)}>
                          <DocumentLink doc={doc}></DocumentLink>
                        </a>{" "}
                      </div>

                      {!canEdit ? (
                        <>
                          <div className="col">
                            <ButtonWithIcon
                              icon="trash"
                              titleText="Remove Document"
                              onClick={() => {
                                if (window.confirm("Are you sure you want to delete this document?")) {
                                  removeAdditionalDocument(i);
                                }
                              }}
                              buttonType="danger"></ButtonWithIcon>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })
            ) : (
              <></>
            )}
          </CustomCard>
        ) : (
          <></>
        )}
      </div>
    </form>
  );
};

export default TaskPage;
