"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagStatus = void 0;
var TagStatus;
(function (TagStatus) {
    TagStatus["Active"] = "Active";
    TagStatus["RequestReplacement"] = "Request Replacement";
    TagStatus["Denied"] = "Denied";
    TagStatus["Retired"] = "Retired";
    //Added for conversion.
    TagStatus["Unknown"] = "Unknown";
})(TagStatus = exports.TagStatus || (exports.TagStatus = {}));
