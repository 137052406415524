"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeConfiguration = void 0;
var NoticeConfiguration;
(function (NoticeConfiguration) {
    NoticeConfiguration["BackToBack"] = "Back to Back";
    NoticeConfiguration["Monumental"] = "Monumental";
    NoticeConfiguration["SideBySide"] = "Side by Side";
    NoticeConfiguration["SingleFace"] = "Single Face";
    NoticeConfiguration["Stacked"] = "Stacked";
    NoticeConfiguration["VShaped"] = "V Shaped";
    NoticeConfiguration["Unknown"] = "Unknown";
})(NoticeConfiguration = exports.NoticeConfiguration || (exports.NoticeConfiguration = {}));
